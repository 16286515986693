.highcharts-figure {
  .graph-header {
    background-color: $baselineSecondaryLight;
    border: 1px solid $neutralGray300;
    margin: 20px;
    .graph-top-heading {
      height: 80px;
      // display: none !important;
      background: $baselineSecondaryLight;
      display: flex;
      .heading-container-1 {
        border: 0.5px solid lightgrey;
        padding-top: 10px;
        border-radius: 5px;
        margin: 6px;
        text-align: center;
        width: 22%;
        @media #{$vpMobile600} {
          width: 48%;
        }
        .heading-text-1 {
          font-weight: bold;
          font-size: 15px;
          margin: 0px;
        }
        .heading-text-2 {
          font-weight: bold;
          color: #c39b6a;
          font-size: 15px;
          margin: 0px;
        }
      }
      .heading-container-2 {
        border: 0.5px solid lightgrey;
        border-radius: 5px;
        padding-top: 10px;
        margin: 6px;
        text-align: center;
        width: 22%;
        @media #{$vpMobile600} {
          width: 48%;
        }
        .heading-text-1 {
          font-weight: bold;
          font-size: 15px;
          margin: 0px;
        }
        .heading-text-2 {
          font-weight: bold;
          color: #c39b6a;
          font-size: 15px;
          margin: 0px;
        }
      }
    }
    .barStacked-wrapper {
      display: flex;
      @media #{$vpMobile600} {
        flex-direction: column;
      }

      .bar-stack-left {
        width: 50%;

        @media #{$vpMobile600} {
          width: 100%;
        }
      }
      .bar-stack-right {
        width: 50%;

        @media #{$vpMobile600} {
          width: 100%;
        }
      }
    }
  }
}
