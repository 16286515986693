.image-marquee {
  background-color: $white;

  .slide-wrapper {
    .ada-slide {
      width: 89.625rem;

      @media #{$vpMobile600} {
        width: 18rem;
      }

      .slick-list {
        overflow: visible;

        .slick-track {
          display: flex;
          align-items: flex-end;

          .slider-item {
            margin-left: 2.5rem;

            @media #{$vpMobile600} {
              margin-left: 1.5rem;
            }

            img {
              width: 100%;
              border-radius: 1rem;

              @media #{$vpMobile600} {
                min-height: 12.5rem;
                object-fit: fill;
              }
            }
          }
        }
      }
    }
  }

  &.initiativesDetail {
    background-color: $baselineSecondaryLight;
  }
}

.image-marquee {
  background-color: $white;

  &.empowering-youth-image-marquee {
    background-color: $baselineSecondaryLight;
  }

  @media #{$vpDesktop1440} {
    padding: 5rem 0;
  }

  &.horizontal-scroll {
    .horizontal-scroll-wrapper {
      overflow: hidden;
      height: auto;

      .horizontal {
        display: flex;
        height: 100%;
        gap: 2.5rem;
        margin-left: -25%;
        .img-item {
          overflow: hidden;
          border-radius: 1rem;

          img {
            width: 100%;
            border-radius: 1rem;
            max-width: 47.313rem;
            object-fit: cover;
            transition: all 0.35s ease;
            @media #{$vpMobile600} {
              max-width: 17rem;
              max-height: 17rem;
              min-height: 17rem;
              transform: scale(0.8);
              transform-origin: bottom;
            }
          }
          .slick-slide {
            &.slick-active {
              img {
                max-width: 18rem;
                max-height: 18rem;
              }
            }
          }
        }
        &>div {
          display: flex;
          flex-shrink: 0;
          align-items: end;
          &:nth-child(odd){
            .img-item {
              img{
                // height: 485px;
                // object-fit: cover;
                height: 30.313rem;
                @media #{$vpMobile600} {
                  height: auto;
                }
              }
            }
          }
          &:nth-child(even){
            .img-item {
              img{
                // height: 620px;
                // object-fit: cover;
                height: 38.75rem;
                @media #{$vpMobile600} {
                  height: auto;
                }
              }
            }
          }
        }

        &.ada-slide {
          @media #{$vpMobile600} {
            &.ada-slide {
              width: 83%;
              // margin-left: 3rem;
              margin-left: 4rem;
              margin-top: 2rem;
  
              .slick-list {
                overflow: visible;
  
                .slick-track {
                  display: flex;
                  align-items: flex-end;
                  // gap: 1.25rem;
                  gap: 0.5rem;
  
                  .slider-item {
                    margin-left: 1.75rem;
  
                    img {
                      width: 100%;
                      border-radius: 1rem;
                      min-height: 12.5rem;
                      object-fit: fill;
                    }
                  }
                  .img-item {
                    img {
                      width: 100%;
                      border-radius: 1rem;
                      // min-height: 12.5rem;
                      object-fit: cover;
                      // transform: scale(1);
                    }
                  }
                  .slick-slide {
                    &.slick-active {
                      .img-item {
                        img {
                          // max-width: 18rem;
                          // max-height: 18rem;
                          transform: scale(1);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}