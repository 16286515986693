@font-face {
    font-family: 'helvetica';
    src: url('../fonts/Helvetica.woff') format('woff'), url('../fonts/Helvetica.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../fonts/helveticaneue-webfont.woff2') format('woff2'),
         url('../fonts/helveticaneue-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'HelveticaNeueLT-Arabic';
    src: url('../fonts/HelveticaNeueLTArabic-Bold.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTArabic-Bold.woff') format('woff'),
        url('../fonts/HelveticaNeueLTArabic-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'HelveticaNeueLT-Arabic';
    src: url('../fonts/HelveticaNeueLTArabic-Light.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTArabic-Light.woff') format('woff'),
        url('../fonts/HelveticaNeueLTArabic-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}


