.who-we-sec {
  .work-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .conent-wrapper {
      max-width: 41.38%;
      flex: 0 0 41.38%;

      .heading2 {
        margin: 0;
        padding-bottom: 40px;
        @media #{$vpMobile600} {
          padding-bottom: 20px;
        }
      }

      .body2 {
        margin: 0;
      }

      .btn-wrapper {
        padding-top: 2.5rem;

        @media #{$vpMobile600} {
          display: none;
        }
        a {
          display: block;
        }
      }

      @media #{$vpTablet1229} {
        max-width: 45%;
        flex: 0 0 45%;
      }

      @media #{$vpTablet767} {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    .logo-wrapper {
      max-width: 41.38%;
      flex: 0 0 41.38%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      @media #{$vpTablet1229} {
        max-width: 50%;
        flex: 0 0 50%;
      }
      .logo {
        padding: 30px 10px;
        flex: 0 0 26%;
        max-width: 26%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        @media #{$vpTablet991} {
          flex: 0 0 31%;
          max-width: 31%;
          padding: 20px 10px;
        }
      }

      @media #{$vpMobile600} {
        max-width: 100%;
        flex: 0 0 100%;
        padding-top: 2.5rem;
      }

      @media #{$vpMobile390} {
        justify-content: center;
      }
    }

    .brands-logo-wrapper {
      width: 43rem;
      display: flex;
      flex-wrap: wrap;
      gap: 5.75rem 6.5rem;
      justify-content: flex-end;

      @media #{$vpMobile600} {
        width: 100%;
        padding-top: 2rem;
        gap: 2.25rem 4rem;
        justify-content: space-between;
      }

      .logo {
        img {
          width: 8.60538rem;
          height: 2.6455rem;
        }
      }
    }

    @media #{$vpTablet767} {
      flex-direction: column;
    }
  }

  .btn-wrapper-mob {
    display: none;

    .main-secondary-btn {
      @media #{$vpMobile600} {
        display: flex;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center;
      }
    }

    @media #{$vpMobile600} {
      display: block;
      padding-top: 2.5rem;
    }
  }
}
