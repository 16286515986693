.sec-Initatives {

  .sec-wrapper {
    .underline-title {
      border: none;

      @media #{$vpMobile600} {
        padding: 0;
      }
    }

    .items-wrapper {
      display: flex;
      justify-content: space-between;

      @media #{$vpMobile600} {
        flex-wrap: wrap;
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;
        width: 51.875rem;

        @media #{$vpMobile600} {
          flex-wrap: wrap;
          width: 100%;
          flex-direction: column-reverse;
          z-index: 1;
        }

        .nav-title-wrap {
          .nav {
            width: 100%;
            flex-direction: column;
            position: relative;
            left: auto;
            top: auto;

            .nav-item {
              padding-left: 0;
              &::before {
                display: none;
              }
              .nav-link {
                background-color: transparent;
                color: $baselineSecondary;
                opacity: 0.5;
                padding: 0;
                margin-top: 1.5rem;
                max-width: max-content;
                white-space: normal;
                @media #{$vpMobile600} {
                  margin-top: 0;
                  font-size: 1.125rem;
                }

                &.active,
                &.last-item,
                &:hover {
                  opacity: 1;
                  position: relative;
                  // max-width: max-content;
                  padding: 0;

                  &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 0.063rem;
                    background-color: $baselinePrimary;
                    bottom: 0;
                    left: 0;
                  }
                }
              }

              .dropdown-menu {
                width: 100%;
                border: 0;
                padding: 0.5rem;
                .dropdown-item {
                  margin: 10px 0;
                  max-width: max-content;
                }

                &.current {
                  display: block;
                }
              }

              &.nav--desktop {
                @media #{$vpMobile600} {
                  display: none;
                }
              }

              &.dropdown {
                position: relative;

                .dropdown-arrow {
                  position: absolute;
                  right: 0;
                  top: 0;
                  @media #{$vpMobile600} {
                    width: 1rem;
                    height: auto;
                  }
                  &.js-tosvg {
                    g {
                      path {
                        fill: #000;
                      }
                    }
                  }

                  @media #{$vpMobile600} {
                    position: relative;
                  }
                }

                .dropdown-menu {
                  display: none;

                  &.current {
                    display: block;
                  }
                }

                &.nav--mbl {
                  max-width: max-content;
                  display: none;
                  .js-tosvg {
                    path {
                      fill: #000;
                    }
                  }

                  @media #{$vpMobile600} {
                    display: flex;
                    gap: 0.75rem;
                    flex-direction: row-reverse;
                    align-items: center;
                    margin-top: 2.5rem;
                  }
                }
              }
            }
          }
        }

        .content-box-inner {
          width: 34.1875rem;
          display: flex;
          flex-direction: column;

          @media #{$vpTablet991} {
            width: 20.625rem;
          }

          @media #{$vpMobile600} {
            max-width: 100%;
          }

          .main-primary-btn {
            margin-top: 2.5rem;
            max-width: max-content;
            border: 1px solid $baselinePrimary;
            @media #{$vpMobile600} {
              margin-top: 1.25rem;
            }
          }
        }
      }

      .tab-content-wrapper {
        width: 46rem;

        @media #{$vpTablet991} {
          width: 28.625rem;
        }

        @media #{$vpMobile600} {
          width: 100%;
          margin-top: 2.5rem;
        }

        .tab-content {
          .tab-pane {
            .sec-slider1 {
              .carousel-wrapper {
                height: auto;
                width: 100%;
                .carousel-items {
                  position: relative;
                  overflow: hidden;
                  border-radius: 1rem;
                  transform: translateZ(0);

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: relative;
                    z-index: 2;
                    max-height: 30.313rem;
                    min-height: 30.313rem;
                    @media #{$vpTablet991} {
                      max-height: 15.313rem;
                      min-height: 15.313rem;
                    }
                    @media #{$vpMobile600} {
                      max-height: 12.313rem;
                      min-height: 12.313rem;
                    }
                  }

                  .content-box {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    display: none;
                    justify-content: space-between;
                    padding: 0 2.5rem 2.125rem 1.5rem;
                    align-items: flex-end;
                    height: 100%;
                    transition: all 0.35s ease;
                    display: flex;
                    @media #{$vpMobile600} {
                      padding: 1.25rem;
                    }

                    .heading5 {
                      margin: 0;
                      max-width: 70%;
                      z-index: 3;

                      @media #{$vpDesktop1440} {
                        max-width: 50%;
                      }
                    }

                    .main-primary-btn {
                      z-index: 2;

                      @media #{$vpMobile600} {
                        width: auto;
                      }
                    }

                    .overlay {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      background-color: $black;
                      opacity: 0.4;
                      display: block;
                      z-index: 2;
                    }
                  }
                }

                // &.slick-active {
                //   .content-box {
                //     display: flex;
                //   }
                // }
              }

              .slick-list {
                padding-left: 2.5rem;

                @media #{$vpMobile600} {
                  padding-top: 2.5rem;
                  padding-left: 0;
                }

                .slick-slide {
                  opacity: 1 !important;
                  transform: scale(0.85) translateX(-2.5rem);
                  z-index: 1 !important;
                  transform-origin: center left;
                  transition: transform 0.4s ease 0s !important;

                  @media #{$vpMobile600} {
                    transform: scale(0.85) translateY(-2.5rem);
                    transform-origin: center top;
                  }
                }

                .slick-active {
                  z-index: 3 !important;
                  transform: scale(1) translateX(0);
                }

                .slick-next-slide {
                  transform: scale(0.95) translateX(-1.25rem);
                  z-index: 2 !important;
                  transform-origin: center left;

                  @media #{$vpMobile600} {
                    transform: scale(0.95) translateY(-1.25rem);
                    transform-origin: center top;
                  }
                }
              }
            }
          }
        }

        .slider-arrow-pagination {
          padding-top: 3rem;

          .arrow-wrap {
            position: relative;
            bottom: 0;
            width: 100%;
            .slide-arrow {
              .custom-arrows {
                opacity: 1;
                transition: all 0.35s ease;
                &.disabled {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &.masterplan-InitiativesDetail {
    background-color: #fff;
  }
  &.dark-bg {
    .sec-wrapper {
      .items-wrapper {
        .content-wrapper {
          .nav-title-wrap {
            .nav {
              .nav-item {
                .nav-link {
                  color: rgba($color: #fff, $alpha: 0.4);
                  &.active {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.no-bg {
    .sec-wrapper {
      .items-wrapper {
        .content-wrapper {
          .content-box-inner {
            .main-primary-btn {
              border: 1px solid #9f8332;
            }
          }
        }
      }
    }
  }
}

.darkTheme1 {
  .sec-Initatives {
    &.darkSec {
      background-color: transparent;

      .sec-wrapper {
        @media #{$vpMobile600} {
          position: relative;
        }

        .dsk-hide {
          display: none !important;

          @media #{$vpMobile600} {
            display: block !important;
            padding-bottom: 1rem;
          }
        }

        .mbl-hide {
          display: block !important;

          @media #{$vpMobile600} {
            display: none !important;
          }
        }

        .dsk-btn-hide {
          display: none;
          position: absolute;
          right: 1.25rem;
          bottom: -6rem;
          background-color: transparent;
          color: $white;
          border: 0.063rem solid $white;
          transition: all 0.4s ease-out;

          &:hover {
            background-color: $white;
            color: #1d1e20;
            border: 0.063rem solid #1d1e20;
            transition: all 0.4s ease-out;
          }

          @media #{$vpMobile600} {
            display: block;
          }
        }

        .slick-dots {
          @media #{$vpMobile600} {
            position: absolute;
            margin-top: 4rem;
          }
        }

        .items-wrapper {
          .content-wrapper {
            height: 42.5rem;

            @media #{$vpMobile600} {
              height: 100%;
            }

            .content-box-inner {
              .main-primary-btn {
                background-color: transparent;
                color: $white;
                border: 0.063rem solid $white;
                transition: all 0.4s ease-out;

                &:hover {
                  background-color: $white;
                  color: #1d1e20;
                  border: 0.063rem solid #1d1e20;
                  transition: all 0.4s ease-out;
                }
              }
            }

            .nav-title-wrap {
              .nav {
                .nav-item {
                  &.dropdown {
                    .dropdown-menu {
                      &.current {
                        background: #181818;
                      }
                    }

                    .dropdown-arrow {
                      path {
                        fill: $white;
                      }
                    }
                  }

                  .nav-link {
                    color: $white;

                    &.active,
                    &.last-item,
                    &:hover {
                      &::after {
                        background-color: $white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.darkSec1 {
  &.sec-Initatives {
    background-color: transparent;

    .sec-wrapper {
      @media #{$vpMobile600} {
        position: relative;
      }

      .dsk-hide {
        display: none !important;

        @media #{$vpMobile600} {
          display: block !important;
          padding-bottom: 1rem;
        }
      }

      .mbl-hide {
        display: block !important;

        @media #{$vpMobile600} {
          display: none !important;
        }
      }

      .dsk-btn-hide {
        display: none;
        position: absolute;
        right: 1.25rem;
        bottom: -6rem;
        background-color: transparent;
        color: $white;
        border: 0.063rem solid $white;
        transition: all 0.4s ease-out;

        &:hover {
          background-color: $white;
          color: #1d1e20;
          border: 0.063rem solid #1d1e20;
          transition: all 0.4s ease-out;
        }

        @media #{$vpMobile600} {
          display: block;
        }
      }

      .slick-dots {
        @media #{$vpMobile600} {
          position: absolute;
          margin-top: 4rem;
        }
      }

      .items-wrapper {
        .content-wrapper {
          height: 42.5rem;

          @media #{$vpMobile600} {
            height: 100%;
          }

          .content-box-inner {
            .main-primary-btn {
              background-color: transparent;
              color: $white;
              border: 0.063rem solid $white;
              transition: all 0.4s ease-out;

              &:hover {
                background-color: $white;
                color: #1d1e20;
                border: 0.063rem solid #1d1e20;
                transition: all 0.4s ease-out;
              }
            }
          }

          .nav-title-wrap {
            .nav {
              .nav-item {
                &.dropdown {
                  .dropdown-menu {
                    &.current {
                      background: #181818;
                    }
                  }

                  .dropdown-arrow {
                    path {
                      fill: $white;
                    }
                  }
                }

                .nav-link {
                  color: $white;

                  &.active,
                  &.last-item,
                  &:hover {
                    &::after {
                      background-color: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

body{
  &.darkTheme{
    .sec-Initatives{
      .sec-wrapper{
        .items-wrapper {
          .content-wrapper{
            .nav-title-wrap{
              .nav{
                .nav-item{
                  .nav-link{
                    color: $text-white;
                  }
                }
              }
            }
            .content-box-inner{
              .main-primary-btn{
                background-color: transparent;
                border-color: $baselinePrimary;
                color: $text-white;
                &:hover{
                  background-color: $secondaryLight;
                  color: $baselineSecondary;
                  transition: all .4s ease-out;
                }
              }
            }
          }
        }
      }
    }
  }
}