.highcharts-figure {
  .column-stacked-graph-header {
    background-color: $baselineSecondaryLight;
    border: 1px solid $neutralGray300;
    margin: 20px;
    .column-stacked-graph-top-heading {
      display: none !important;
      height: auto;
      min-height: 80px;
      background: #f3f1ec;
      border: 10px solid black;
      //   display: flex;
      margin: 0px 20px;
      justify-content: space-between;
      @media #{$vpMobile600} {
        display: block;
        height: auto;
      }
      .column-stacked-heading-container-1 {
        padding-top: 10px;
        border-radius: 5px;
        margin: 6px;
        justify-content: center;
        width: 22%;
        display: flex;
        flex-wrap: wrap;

        @media #{$vpMobile600} {
          width: 100%;
        }
        .column-stacked-button-1,
        .column-stacked-button-2 {
          button {
            width: 130px;
            height: 28px;
            background-color: white;
            &:hover {
              background-color: #333333;
              color: white;
            }
          }
        }
      }
      .column-stacked-heading-container-2 {
        padding-top: 10px;
        margin: 6px;
        justify-content: center;
        width: 22%;
        display: flex;
        flex-wrap: wrap;
        @media #{$vpMobile600} {
          width: 100%;
        }
        .column-stacked-button-1,
        .column-stacked-button-2 {
          button {
            width: 130px;
            height: 28px;
            background-color: white;
            &:hover {
              background-color: #333333;
              color: white;
            }
          }
        }
      }
      .column-stacked-heading-container-3 {
        padding-top: 10px;
        justify-content: center;
        width: 22%;
        margin: 6px;
        display: flex;
        flex-wrap: wrap;
        @media #{$vpMobile600} {
          width: 100%;
        }
        .column-stacked-button-1,
        .column-stacked-button-2 {
          button {
            width: 130px;
            height: 28px;
            background-color: white;
            &:hover {
              background-color: #333333;
              color: white;
            }
          }
        }
      }
    }
  }
}
