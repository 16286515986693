.sec-faq-search {
    background-color: $baselineSecondaryLight;
    padding: 6.25rem 0 12.56rem 0;

    @media #{$vpMobile600} {
        flex-direction: column;
        padding: 2.5rem 0;
    }

    .heading-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4rem 0 2.5rem 0;
        
    
        .wrapper-heading {
          min-width: 88.5rem;
          padding-top: 0;
    
          @media #{$vpMobile600} {
            min-width: 100%;
          }
        }
    
        .btn-wraper {
          &.desk-btn {
            @media #{$vpMobile600} {
              display: none;
            }
          }
    
          .main-primary-btn {
            padding: 1.313rem 1.875rem;
    
            @media #{$vpMobile600} {
              padding: 0.75rem;
            }
          }
        }
        @media #{$vpMobile600} {
            padding-bottom: 0.5rem;
          }
      }

    .main-content-box {
        display: flex;
        justify-content: space-between;

        @media #{$vpMobile600} {
            flex-direction: column;
        }

        .left-panal {
            width: 25.3125rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            position: sticky;
            top: 0;
            height: fit-content;

            @media #{$vpMobile600} {
                width: 100%;
                position: unset;
            }

            .search-box {
                width: 100%;
                position: relative;

                .input-field {
                    background: $white;
                    border: 0.063rem solid $secondarySimiLight;
                    padding: 1.5rem 1rem;
                    border-radius: 0.5rem;
                    width: 100%;
                    height: 4.563rem;

                    @media #{$vpMobile600} {
                        height: 3.438rem;
                    }
                }

                .btn-search {
                    position: absolute;
                    left: auto;
                    top: 50%;
                    right: 1rem;
                    transform: translateY(-50%);
                    border: 0;
                    background-color: transparent;
                    outline: 0;
                    padding: 0;

                    &.active {
                        .search-icon {
                            display: none;
                        }
                    }

                    .search-icon,
                    .cancel-icon {
                        width: 1.5rem;
                        height: 1.5rem;

                        path {
                            fill: $baselineSecondary;

                        }
                    }

                    .cancel-icon {
                        display: none;
                    }
                }

            }

            .button-list {
                display: flex;
                flex-direction: column;
                height: 100%;
                gap: 1rem;

                @media #{$vpMobile600} {
                    flex-direction: row;
                    overflow: scroll;
                    flex-wrap: nowrap;
                    padding: 0rem 0 1rem;
                    margin-right: -1.25rem;
                }

                li {
                    display: flex;
                    padding-left: 0;
                    &::before {
                        display: none;
                    }
                    @media #{$vpMobile600} {
                        flex: 0 0 auto;
                    }

                    a {
                        max-width: max-content;
                        background-color: transparent;
                        color: $baselineSecondary;
                        padding: 1rem 1.5rem;
                        ;
                        position: relative;
                        transition: all 0.35s ease;
                        border: 0.063rem solid $secondarySemiLight;
                        border-radius: 0.25rem;
                        cursor: pointer;

                        &.active {
                            background-color: $secondarySemiLight;
                            padding-right: 2.75rem;

                            &::after {
                                opacity: 1;
                            }
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            background-color: $baselinePrimary;
                            width: 0.5rem;
                            height: 0.5rem;
                            border-radius: 100%;
                            right: 1.25rem;
                            top: 50%;
                            transform: translateY(-50%);
                            opacity: 0;
                        }
                    }
                }

            }
        }

        .accordion-box {
            width: 69.5625rem;
            @media #{$vpTablet991} {
                width: 32rem;
              }

            @media #{$vpMobile600} {
                width: 100%;
            }

            .searchResults {
                .heading5 {
                    margin-bottom: 2.5rem;

                    @media #{$vpMobile600} {
                        margin-top: 1rem;
                        margin-bottom: 1.5rem;
                    }
                }
            }

            .no-results {
                margin-bottom: 2.5rem;

                @media #{$vpMobile600} {
                    margin-top: 1rem;
                    margin-bottom: 1.5rem;
                }
            }

            .accordion {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                gap: 1.5rem;
                flex-direction: column;
                margin-top: 0;
                // padding-top: 6.25rem;
                &#acc-initiatives{
                    padding-top: 0;
                }

                @media #{$vpMobile600} {
                    flex-direction: column;
                    padding-top: 3rem;
                   

                    &:first-child {
                        margin-top: 2.5rem;
                    }
                }

                

                   

                    .card {
                        padding: 2.5rem;
                        border-radius: 1rem;
                        background-color: $white;
                        width: 100%;
                        border: 0;
                     

                        @media #{$vpMobile600} {
                            padding: 1.25rem;
                        }

                        .card-header {
                            background-color: transparent;
                            border-bottom: 0;
                            padding: 0;

                            a {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .heading4 {
                                    color: $baselineSecondary;
                                    width: calc(100% - 5rem);
                                    margin-bottom: 0;

                                    // @media #{$vpMobile600} {
                                    //     font-size: 1.5rem;
                                    //     line-height: 1.938rem;
                                    // }
                                }

                                .icon {
                                    width: 3rem;
                                    height: 3rem;
                                    border: 0.063rem solid $baselinePrimary;
                                    border-radius: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    .plus-icon,
                                    .minus-icon {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }

                                &[aria-expanded="true"] .plus-icon {
                                    display: none;
                                }

                                &[aria-expanded="true"] .plus-icon {
                                    display: none;
                                }

                                &[aria-expanded="false"] .minus-icon {
                                    display: none;
                                }

                                &[aria-expanded="false"] .plus-icon {
                                    display: block;
                                }
                            }
                        }

                        .card-body {
                            padding: 0;
                            margin-top: 2.188rem;

                            @media #{$vpMobile600} {
                                margin-top: 1rem;
                            }

                            p {
                                @media #{$vpMobile600} {
                                    font-size: 0.875rem;
                                    line-height: 1.225rem;
                                }

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }

                    @media #{$vpMobile600} {
                        width: 100%;
                    }

                // &:last-child {
                //     .col-left {
                //         @media #{$vpMobile600} {
                //             margin-bottom: 0;

                //             .card {
                //                 &:last-child {
                //                     margin-bottom: 0;
                //                 }
                //             }

                //         }
                //     }
                // }
            }

            .btn-wraper {
                display: none;

                &.mobl-btn {
                    @media #{$vpMobile600} {
                        display: block;
                        width: 100%;

                        .main-primary-btn {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &.dark-bg {
        .main-content-box {
            .left-panal {
                .search-box {
                    .input-field {
                        background-color: rgba($color: #fff, $alpha: 0.1);
                        &::placeholder {
                            color: $white;
                        }
                    }
                    .btn-search {
                        .cancel-icon {
                            path {
                                fill: $white;
                            }
                        }
                        .search-icon {
                            path {
                                fill: $white;
                            }
                        }
                    }
                }
                .button-list {
                    li {
                        a {
                            color: rgba($color: #fff, $alpha: 0.4);
                            &.active {
                                color: #2c2e34;
                            }
                        }
                    }
                }
            }
            .accordion-box {
                .accordion {
                    .card {
                        background-color: rgba($color: #fff, $alpha: 0.1);
                        .card-header {
                            a {
                                .heading4 {
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &.no-bg {
        .main-content-box {
            .accordion-box {
                .accordion {
                    .card {
                        background-color: #f9f7f3;
                    }
                }
            }
        }
    }
}