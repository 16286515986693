.sec-masterplan {
  .sec-wrapper {
    .underline-title {
      border: none;

      @media #{$vpMobile600} {
        padding: 0;
      }
    }

    .items-wrapper {
      display: flex;
      // flex-wrap: wrap;
      justify-content: space-between;

      @media #{$vpMobile600} {
        flex-wrap: wrap;
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;
        width: 51.875rem;
        justify-content: space-between;
        // padding-left: 7.5rem;

        // @media #{$vpTablet1229} {
        //     max-width: 50%;
        //     flex: 0 0 50%;
        // }
        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
          padding: 0;
          width: 100%;
          height: 100%;
        }

        .slider-title-wrap {
          display: flex;
          flex-direction: column;
          height: 100%;
          text-align: center;

          @media #{$vpMobile600} {
            flex-direction: row;
            overflow-x: auto;
            white-space: nowrap;
            width: 100vw;
            padding: 0 .5rem 0 1.25rem;
            margin: 0 -1.25rem;

            &::-webkit-scrollbar {
              display: none;
            }
          }

          .slider-title {
            margin-right: 0.75rem;
            margin-bottom: 0.75rem;
            max-width: max-content;
            background-color: transparent;
            color: $baselineSecondary;
            padding: 0.688rem 1.5rem;
            position: relative;
            transition: all 0.35s ease;
            border: 0.063rem solid $secondarySemiLight;
            border-radius: 0.25rem;
            cursor: pointer;
            width: 100%;
            @media #{$vpMobile600} {
              flex: 0 0 auto;
              font-size: 0.88rem;
              padding: 1rem;
            }
            &::before {
              display: none;
            }
            &::after {
              content: '';
              position: absolute;
              background-color: $baselinePrimary;
              width: 0.5rem;
              height: 0.5rem;
              border-radius: 50%;
              right: 1.25rem;
              top: 50%;
              transform: translateY(-50%);
              opacity: 0;
            }

            &.active-slide {
              padding-right: 2.75rem;
              background-color: $secondarySemiLight;

              @media #{$vpMobile600} {
                padding-right: 2.125rem;
              }

              &::after {
                content: '';
                opacity: 1;
              }
            }
          }

          .mCSB_container {
            display: flex;
          }

          .mCSB_container {
            margin-bottom: 0.313rem;
          }
        }

        .content-box-inner {
          max-width: 37.5rem;

          @media #{$vpMobile600} {
            max-width: 100%;
            padding: 1.25rem 0;
          }

          .heading2 {
            margin-bottom: 2.5rem;
            @media #{$vpMobile600} {
              margin-bottom: 1rem;
            }
          }

          p {
            margin-bottom: 3.75rem;

            @media #{$vpMobile600} {
              margin-bottom: 2.5rem;
            }
          }
          .body2{
            margin-bottom: 3.75rem;

            @media #{$vpMobile600} {
              margin-bottom: 1rem;
            }
          }

          ;
        }
      }

      .tab-content-wrapper {
        width: 45.5rem;
        // padding-right: 7.5rem;

        @media #{$vpTablet991} {
          width: 30rem;
          margin-left: 1rem;
        }

        @media #{$vpMobile600} {
          width: 100%;
          margin-top: 2.5rem;
          margin-left: 0;
          padding: 1.25rem 0;
        }

        .tab-content {
          .tab-pane {
            .sec-slider1 {
              .carousel-wrapper {
                .carousel-items {
                  position: relative;
                  overflow: hidden;
                  border-radius: 1rem;

                  img {
                    position: relative;
                    z-index: 2;
                    width: 100%;
                    height: 30rem;
                    object-fit: cover;
                    @media #{$vpMobile600} {
                      height: 15rem;
                    }
                  }

                  .content-box {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    display: none;
                    justify-content: space-between;
                    padding: 0 2.5rem 2.125rem 1.5rem;
                    align-items: flex-end;
                    height: 100%;
                    transition: all 0.35s ease;

                    @media #{$vpMobile600} {
                      padding: 1.25rem;
                    }

                    .heading5 {
                      margin: 0;
                      z-index: 3;
                      font-size: 2rem;

                      @media #{$vpDesktop1440} {
                        max-width: 50%;
                      }
                      @media #{$vpMobile600} {
                        font-size: 1.375rem;
                      }
                    }

                    .main-primary-btn {
                      padding: 0.75rem 2.19rem;
                      z-index: 2;
                      height: auto;
                      @media #{$vpMobile600} {
                        padding: 0.5rem 1.12rem;
                      }
                    }

                    .overlay {
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      background-color: $white;
                      opacity: 0.4;
                      display: none;
                    }
                  }
                }

                &.slick-active {
                  .content-box {
                    display: flex;
                  }
                }
              }

              .slick-list {
                padding-left: 2.5rem;

                @media #{$vpMobile600} {
                  padding-top: 2.5rem;
                  padding-left: 0;
                }

                .slick-slide {
                  opacity: 1 !important;
                  transform: scale(0.85) translateX(-2.5rem);
                  z-index: 1 !important;
                  transform-origin: center left;
                  transition: transform 0.4s ease 0s !important;

                  @media #{$vpMobile600} {
                    transform: scale(0.85) translateY(-2.5rem);
                    transform-origin: center top;
                  }
                }

                .slick-active {
                  z-index: 3 !important;
                  transform: scale(1) translateX(0);


                }

                .slick-next-slide {
                  transform: scale(0.95) translateX(-1.25rem);
                  z-index: 2 !important;
                  transform-origin: center left;

                  @media #{$vpMobile600} {
                    transform: scale(0.95) translateY(-1.25rem);
                    transform-origin: center top;
                  }
                }

              }
            }
          }
        }

        .slider-arrow-pagination {
          padding-top: 3rem;

          .arrow-wrap {
            position: relative;
            bottom: 0;
            width: 100%;
          }
        }
      }
    }
  }

  .items-wrapper {
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;

    @media #{$vpMobile600} {
      flex-wrap: wrap;
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      width: 51.875rem;
      justify-content: space-between;

      // @media #{$vpTablet1229} {
      //     max-width: 50%;
      //     flex: 0 0 50%;
      // }
      @media #{$vpMobile600} {
        max-width: 100%;
        flex: 0 0 100%;
      }

      .slider-title-wrap {
        display: flex;
        flex-direction: column;
        height: 100%;
        text-align: center;

        @media #{$vpMobile600} {
          flex-direction: row;
          overflow-x: auto;
          white-space: nowrap;
          width: auto;
          padding-left: 1.25rem;
        }

        .slider-title {
          margin-right: 0.75rem;
          margin-bottom: 0.75rem;
          max-width: max-content;
          background-color: transparent;
          color: $baselineSecondary;
          padding: 0.688rem 1.5rem;
          position: relative;
          transition: all 0.35s ease;
          border: 0.063rem solid $secondarySemiLight;
          border-radius: 0.25rem;
          cursor: pointer;

          @media #{$vpMobile600} {
            flex: 0 0 auto;
            font-size: 0.88rem;
            padding: 1rem;
            height: 2.75rem;
            width: 11.75rem;
            display: flex;
            align-items: center;
          }

          &::after {
            content: "";
            position: absolute;
            background-color: $baselinePrimary;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            right: 1.25rem;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
          }

          &.active-slide {
            padding-right: 2.75rem;
            background-color: $secondarySemiLight;

            @media #{$vpMobile600} {
              padding-right: 2.125rem;
            }

            &::after {
              content: "";
              opacity: 1;
            }
          }
        }

        .mCSB_container {
          display: flex;
        }

        .mCSB_container {
          margin-bottom: 0.313rem;
        }

        .mCSB_draggerRail {
          background-color: $baselinePrimary;
        }
      }

      .content-box-inner {
        max-width: 37.5rem;

        @media #{$vpMobile600} {
          max-width: 100%;
        }

        .heading2 {
          margin-bottom: 2.5rem;
        }

        p {
          margin-bottom: 3.75rem;

          @media #{$vpMobile600} {
            margin-bottom: 2.5rem;
          }
        }
      }
    }

    .tab-content-wrapper {
      width: 50rem;

      @media #{$vpTablet991} {
        width: 30rem;
        margin-left: 1rem;
      }

      @media #{$vpMobile600} {
        width: 100%;
        margin-top: 2.5rem;
        margin-left: 0;
      }

      .tab-content {
        .tab-pane {
          .sec-slider1 {
            .carousel-wrapper {
              .carousel-items {
                position: relative;
                overflow: hidden;
                border-radius: 1rem;

                img {
                  position: relative;
                  z-index: 2;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }

                .content-box {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  display: none;
                  justify-content: space-between;
                  padding: 0 2.5rem 2.125rem 1.5rem;
                  align-items: flex-end;
                  height: 100%;
                  transition: all 0.35s ease;

                  @media #{$vpMobile600} {
                    padding: 1.25rem;
                  }

                  .heading5 {
                    margin: 0;
                    z-index: 3;

                    @media #{$vpDesktop1440} {
                      max-width: 50%;
                    }
                  }

                  .main-primary-btn {
                    padding: 0.5rem 1.125rem;
                    z-index: 2;
                    height: auto;
                  }

                  .overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: $white;
                    opacity: 0.4;
                    display: none;
                  }
                }
              }

              &.slick-active {
                .content-box {
                  display: flex;
                }
              }
            }

            .slick-list {
              padding-left: 2.5rem;

              @media #{$vpMobile600} {
                padding-top: 2.5rem;
                padding-left: 0;
              }

              .slick-slide {
                opacity: 1 !important;
                transform: scale(0.85) translateX(-2.5rem);
                z-index: 1 !important;
                transform-origin: center left;
                transition: transform 0.4s ease 0s !important;

                @media #{$vpMobile600} {
                  transform: scale(0.85) translateY(-2.5rem);
                  transform-origin: center top;
                }
              }

              .slick-active {
                z-index: 3 !important;
                transform: scale(1) translateX(0);
              }

              .slick-next-slide {
                transform: scale(0.95) translateX(-1.25rem);
                z-index: 2 !important;
                transform-origin: center left;

                @media #{$vpMobile600} {
                  transform: scale(0.95) translateY(-1.25rem);
                  transform-origin: center top;
                }
              }
            }
          }
        }
      }

      .slider-arrow-pagination {
        padding-top: 3rem;

        .arrow-wrap {
          position: relative;
          bottom: 0;
          width: 100%;
        }
      }
    }
  }
}