.sec-press-release {
  .sec-press-release-wrapper {
    @media #{$vpMobile600} {
      position: relative;
    }
    .filter-slider {
      .filter {
        .nav-pills {
          display: flex;
          gap: 1rem;
          margin-bottom: 3rem;

          @media #{$vpMobile600} {
            flex-direction: row;
            overflow: scroll;
            flex-wrap: nowrap;
            padding: 0 0 0.938rem 0;
            margin-right: -1.25rem;
          }

          .nav-item {
            @media #{$vpMobile600} {
              flex: 0 0 auto;
            }

            .nav-link {
              border-radius: 0.25rem;
              max-width: fit-content;
              padding: 0.813rem 1.5rem;
              line-height: normal;
              border: 0.063rem solid $secondarySimiLight;
              background-color: transparent;
              color: $baselineSecondary;
              transition: all 0.4s ease;

              @media #{$vpMobile600} {
                flex: 0 0 auto;
              }

              &:hover {
                background-color: $secondarySimiLight;
              }

              &.active {
                background-color: $secondarySimiLight;
                display: flex;
                align-items: center;
                gap: 0.75rem;

                &:after {
                  content: "";
                  width: 0.5rem;
                  height: 0.5rem;
                  display: block;
                  border-radius: 3.125rem;
                  background-color: $baselinePrimary;
                  transition: all 0.4s ease;
                }
              }
            }
          }
        }
      }
    }

    .sec-heading-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0 3rem;

      @media #{$vpMobile600} {
        flex-wrap: wrap;
        padding: 1rem 0 2.6rem;
      }

      .sec-heading-inner {
        max-width: 40%;
        flex: 0 0 40%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media #{$vpTablet1229} {
          max-width: 49%;
          flex: 0 0 49%;
        }

        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
          margin-bottom: 0;
        }

        .heading2 {
          margin: 0;
        }
      }

      .sec-para-inner {
        max-width: 50%;
        flex: 0 0 50%;

        // @media #{$vpTablet1229} {
        //     max-width: 45%;
        //     flex: 0 0 45%;
        // }
        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
          padding-left: 0;
        }
      }
    }

    .sec-press-release-post-wrapper {
      width: 92.5rem;
      position: relative;
      @media #{$vpTablet991} {
        width: 100%;
      }
      @media #{$vpMobile600} {
        width: 21.875rem;
      }

      &::after {
        content: "";
        position: absolute;
        width: 12.5rem;
        height: 100%;
        background-color: #fff;
        left: -13.125rem;
        top: 0;
      }

      .custom-arrows {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 3.125rem;
        height: 3.125rem;
        background-color: #333;
        color: #fff;
        text-align: center;
        line-height: 3.125rem;
        cursor: pointer;
      }

      .custom-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1.25rem;
      }

      .slick-dots {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;

        @media #{$vpMobile600} {
          justify-content: flex-start;
          position: absolute;
          left: 0;
          bottom: -3.438rem;
          margin-top: 0;
        }

        .custom-pagination-dot {
          width: 0.625rem;
          height: 0.625rem;
          margin: 0 0.313rem;
          background-color: transparent;
          border-radius: 50%;
          border: 0.063rem solid $baselinePrimary;
          cursor: pointer;
        }

        .slick-active {
          .custom-pagination-dot {
            background-color: $baselinePrimary;
          }
        }
      }

      .slick-list {
        margin-right: -0.563rem;
        margin-left: -0.563rem;
        overflow: visible;

        @media #{$vpMobile600} {
          margin-right: -0.313rem;
          margin-left: -0.313rem;
        }
      }

      .slick-slide {
        padding: 0 0.563rem;

        @media #{$vpMobile600} {
          padding: 0 0.313rem;
        }
      }

      .sec-press-release-post {
        border-radius: 1rem;
        overflow: hidden;
        margin-right: 10.313rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        @media #{$vpMobile600} {
          border-radius: 0.5rem;
        }
        &:last-child {
          margin-right: 0;
        }

        .sec-press-release-post-img {
          width: 100%;
          position: relative;
          overflow: hidden;
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
          flex: 0 0 auto;
          img.play-icon {
            position: absolute;
            width: 3rem;
            height: 3rem;
            top: 50%;
            left: 0;
            right: 0;
            margin: auto;
            transform: translateY(-50%);
          }

          img {
            width: 100%;
            height: 15rem;
            object-fit: cover;
            // transition: all .4s ease;
            // &:hover {
            //   transform: scale(1.25);
            // }
          }

          .press-release-header {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            padding: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;

            .press-release-post-date {
              color: $white;
              margin: 0;
            }

            .press-release-category {
              background-color: $secondarySimiLight;
              padding: 0.375rem 1rem;
              border-radius: 0.25rem;
              color: $baselineSecondary;
              margin: 0;
              font-size: 1rem;

              @media #{$vpMobile600} {

                display: flex;
                align-items: center;
                font-size: 0.875rem;
              }
            }
          }
        }

        .sec-press-release-post-content-box {
          background: $baselineSecondaryLight;
          padding: 1.5rem 1.5rem 2.44rem 1.5rem;
          height: 100%;
          flex: 1 1 auto;
          @media #{$vpMobile600} {
            padding: 1.25rem;
          }

          .sec-press-release-post-content-info {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding-bottom: 3rem;
            position: relative;
            // justify-content: space-between;
            .heading5 {
              margin-bottom: 2rem;
              color: $baselineSecondary;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // display: -webkit-box;
              // -webkit-line-clamp: 3;
              // -webkit-box-orient: vertical;
              // min-height: 5.625rem;
              padding: 0;
              @media #{$vpMobile600} {  
                margin-bottom: 1rem;
                // min-height: 5rem;
              }
            }

            p {
              margin: 0 0 2rem;
              @media #{$vpMobile600} {
                margin: 0 0 1rem;
              }
            }

            .main-primary-btn {
              margin-top: auto;
              position: absolute;
              bottom: 0;
            }

            .heading4 {
              color: $baselineSecondary;
              font-weight: 300;
              line-height: 1.3;
              font-size: 1.75rem;
              min-height: 6.75rem;
              margin-bottom: 0;
              width: 25.125rem;
              @media #{$vpTablet991} {
                width: 100%;
              }
              @media #{$vpMobile600} {
                width: 18.875rem;
                min-height: 4.875rem;
                font-size: 1.25rem;
              }

              &:hover {
                color: $baselineSecondary;
              }
            }
            .body3 {
              margin: 0;
              margin-bottom: 2rem;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              @media #{$vpMobile600} {
                margin: 0 0 1rem;
              }
            }
            &.explore-more-content {
              .heading5 {
                min-height: 4.5rem;
                margin-bottom: 1rem;
              }

              .body3 {
                margin: 0;
                min-height: 8.125rem;
                padding-bottom: 1.69rem;
              }
            }
          }
        }
      }
      &.noSlider {
        .slide {
          flex: 0 0 33%;
          max-width: 33%;
          @media #{$vpTablet991} {
            flex: 0 0 50%;
            max-width: 50%;
          }
          @media #{$vpMobile600} {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }
    }
    .pagination--arrow {
      // margin-top: 3rem;
      margin-top: 2.894rem;
      position: relative;
      @media #{$vpMobile600} {
        margin-top: 5rem;
      }
      .slider-arrow-pagination {
        position: relative;
        padding-top: 0;
        @media #{$vpMobile600} {
          display: block;
        }

        .arrow-wrap {
          position: absolute;
          right: 0;
          bottom:auto;
          top: 50%;
          transform: translateY(-50%);
          width: 18.75rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          @media #{$vpMobile600} {
            display: none;
          }

          .slide-arrow {
            display: flex;
            justify-content: space-between;

            .custom-arrows {
              background-color: transparent;
              border: 0.063rem solid #9f8332;
              border-radius: 50%;
              color: $white;
              cursor: pointer;
              height: 2.875rem;
              line-height: 3.125rem;
              position: relative;
              text-align: center;
              width: 2.875rem;
              z-index: 1;

              &.next {
                right: 0;
                margin-left: 1rem;
              }

              &.prev {
                margin-left: 1rem;
              }

              img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 1rem;
                height: 1rem;
              }
            }
          }

          .pagination-wrap {
            .custom-pagination {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .custom-pagination-label {
              margin: 0 0.625rem;
              color: $baselineSecondary;
              font-weight: 500;
            }
          }
        }
        a {
          padding: 0;
          @media #{$vpMobile600} {
            margin-left: auto;
          }
        }
      }
    }

    a {
      display: flex;
      justify-content: flex-start;
      max-width: max-content;
      padding: 0.75rem 1.5rem;
      .main-secondary-btn {
        @media #{$vpMobile600} {
          height: 3rem;
        }
      }

      @media #{$vpMobile600} {
        justify-content: flex-end;
      }
    }
  }
  &.latest-news {
    .heading-para-box {
      .heading5 {
        min-height: auto !important;
      }
    }

    .sec-press-release-wrapper {
      .sec-press-release-post-wrapper {
        .sec-press-release-post {
          .sec-press-release-post-content-box {
            height: 19.1875rem;
            padding: 1.5rem;

            .sec-press-release-post-content-info {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
              button {
                width: fit-content;
              }
            }
          }
        }
      }
    }
  }

  &.rankings-awards {
    .sec-press-release-wrapper {
      .sec-press-release-post-wrapper {
        width: 105rem;

        @media #{$vpMobile600} {
          width: 21.875rem;
        }
        .sec-press-release-post {
          .sec-press-release-post-content-box {
            .sec-press-release-post-content-info {
              .heading-para-box {
                .heading5 {
                  min-height: auto !important;
                  padding: 0;
                  margin-bottom: 1rem;
                }
                p {
                  margin-bottom: 5.938rem;
                }
              }
            }
          }
        }
      }
      .slider-arrow-pagination {
        padding-top: 48px;
        @media #{$vpMobile600} {
          padding-top: 1.5rem;
        }
      }
    }
  }

  &.news-and-press {
    .sec-press-release-wrapper {
      .sec-press-release-post-wrapper {
        width: 105rem;

        @media #{$vpMobile600} {
          width: 21.875rem;
        }
        .sec-press-release-post {
          .sec-press-release-post-content-box {
            .sec-press-release-post-content-info {
              .heading-para-box {
                .heading5 {
                  min-height: auto !important;
                  padding: 0;
                  margin-bottom: 1rem;
                }
                p {
                  margin-bottom: 5.938rem;
                }
              }
            }
          }
        }
      }
    }
    .slider-arrow-pagination {
      a {
        padding: 0;
      }
    }
  }

  &.related-news {
    .sec-press-release-wrapper {
      .sec-heading-box {
        .sec-heading-inner {
          margin-bottom: 0;
        }
      }
      .sec-press-release-post-wrapper {
        .sec-press-release-post {
          .sec-press-release-post-content-box {
            .sec-press-release-post-content-info {
              .button-wrapper {
                .main-primary-btn {
                  padding: 0.75rem 1.5rem;
                  @media #{$vpMobile600} {
                    margin: 0;
                  }
                }
                .icon-primary-btn {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .heading-para-box {
      .heading5 {
        min-height: auto !important;
      }
    }
  }

  &.related-podcast {
    .sec-press-release-wrapper {
      .sec-heading-box {
        .sec-heading-inner {
          margin-bottom: 0;
        }
      }
    }

    .heading-para-box {
      .heading5 {
        min-height: auto !important;
      }
    }
    .sec-press-release-wrapper {
      .sec-press-release-post-wrapper {
        .sec-press-release-post {
          .sec-press-release-post-content-box {
            .sec-press-release-post-content-info {
              .button-wrapper {
                display: flex;
                align-items: center;
                gap: 0.5625rem;
                position: absolute;
                bottom: 0;
                margin-top: auto;
          
                .main-primary-btn {
                  position: relative;
                }
          
                .icon-primary-btn {
                  justify-content: center !important;
                  padding: 0 1rem;
                  @media #{$vpMobile600} {
                    // padding: 0 0.688rem;
                    // height: 36px;
                    // width: 36px;
                    padding: .75rem;
                    height: 2.25rem;
                    width: 2.25rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.related-interviews {
    .sec-press-release-wrapper {
      .sec-press-release-post-wrapper {
        width: 86.875rem;
        @media #{$vpMobile600} {
          width: 21.875rem;
        }
        .slick-list {
          margin-right: -0.625rem;
          margin-left: -0.625rem;
          @media #{$vpMobile600} {
            margin-right: -0.313rem;
            margin-left: -0.313rem;
          }
        }
        .slick-slide {
          padding: 0 0.625rem;
          @media #{$vpMobile600} {
            padding: 0 0.313rem;
          }
        }
        .sec-press-release-post {
          .sec-press-release-post-content-box {
            background-color: #E5E0D0;
            height: 100%;
            padding: 1.5rem;
            @media #{$vpTablet991} {
              height: 100%;
            }
            // @media #{$vpMobile600} {
            //   height: 12.1875rem;
            // }

            .sec-press-release-post-content-info {
              display: flex;
              flex-direction: column;
              // justify-content: space-between;
              height: 100%;
              .button-wrapper {
                @media #{$vpMobile600} {
                  display: flex;
                  // justify-content: flex-start;
                }
                .main-primary-btn {
                  margin-right: 1.25rem;
                }
                .icon-primary-btn {
                  display: none;
                  width: 2.25rem;
                  height: 2.25rem;
                  @media #{$vpMobile600} {
                    display: flex;
                    // max-width: 15%;
                    justify-content: center;
                    flex: 0 0 auto;
                    padding: .75rem;
                    height: 2.25rem;
                    width: 2.25rem;
                  }
                }
              }
              .heading4 {
                padding: 0;
                margin: 0 0 2rem;
                @media #{$vpMobile600} {
                  margin: 0 0 1rem;
                }
              }
            }
          }

          .sec-press-release-post-img {
            height: 15rem;

            @media #{$vpMobile600} {
              height: 12.1875rem;
            }
          }
        }
      }

      .sec-heading-box {
        padding: 0 0 3rem;
        .sec-heading-inner {
          @media #{$vpMobile600} {
            margin-bottom: 0;
          }
        }
      }
    }.sec-press-release-wrapper {
      .sec-press-release-post-wrapper {
        .sec-press-release-post {
          .sec-press-release-post-content-box {
            .sec-press-release-post-content-info {
              .button-wrapper {
                // display: flex;
                // align-items: center;
                // gap: 0.5625rem;
                position: absolute;
                bottom: 0;
                margin-top: auto;
          
                .main-primary-btn {
                  position: relative;
                  padding: 0.75rem 1.5rem;
                  // width: 7.875rem;
                  height: 2.875rem;

                  @media #{$vpMobile600} {
                    // padding: 0.5rem 1.12rem;
                    // width: 6.4375rem;
                    height: 2.25rem;
                  }
                }
          
                // .icon-primary-btn {
                //   justify-content: center !important;
                //   padding: 0 1rem;
                //   @media #{$vpMobile600} {
                //     padding: 0 0.688rem;
                //     height: 36px;
                //     width: 36px;
                //   }
                // }
              }
            }
          }
        }
      }
    }

    // .button-wrapper {
    //   // @media #{$vpTablet991} {
    //   //   // margin-top: 1rem;
    //   // }
    //   .main-primary-btn {
    //     padding: 0.75rem 1.5rem;
    //     width: 7.875rem;
    //     height: 2.875rem;

    //     @media #{$vpMobile600} {
    //       padding: 0.5rem 1.12rem;
    //       width: 6.4375rem;
    //       height: 2.25rem;
    //     }
    //   }
    // }

    // .slider-arrow-pagination {
    //   display: none;
    // }

    .heading-para-box {
      .heading5 {
        min-height: 6.75rem !important;

        @media #{$vpMobile600} {
          min-height: auto !important;
        }
      }
    }

    .button-wrapper {
      .main-primary-btn {
        width: fit-content;
        align-items: center;
      }
    }
  }

  &.media-gallery {
    .sec-press-release-wrapper {
      .sec-heading-box {
        .sec-heading-inner {
          margin-bottom: 0;
        }
      }
    }

    // .heading5 {
    //   min-height: 6.625rem !important;
    // }

    .sec-press-release-wrapper {
      .sec-press-release-post-wrapper {
        .sec-press-release-post {
          .sec-press-release-post-content-box {
            .sec-press-release-post-content-info {
              .button-wrapper {
                display: flex;
                align-items: center;
                gap: 0.5625rem;
                position: absolute;
                bottom: 0;
                margin-top: auto;
          
                .main-primary-btn {
                  position: relative;
                }
          
                .icon-primary-btn {
                  justify-content: center !important;
                  padding: 1rem;
                  @media #{$vpMobile600} {
                    padding: 0.75rem;
                    height: 2.25rem;
                    width: 2.25rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.open-data-library {
    .sec-press-release-wrapper {
      .sec-press-release-post-wrapper {
        width: 100% !important;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1.25rem;
        @media #{$vpTablet991} {
          grid-template-columns: repeat(2, 1fr);
        }
        @media #{$vpMobile600} {
          grid-template-columns: repeat(1, 1fr);
        }
        .sec-press-release-post {
          .sec-press-release-post-content-box {
            .sec-press-release-post-content-info {
              justify-content: flex-start;
              .heading3 {
                // height: 100%;
                height: auto;
                margin-bottom: 1rem;
                // display: -webkit-box;
                // // margin-bottom: 2.625rem;
                // height: 9.75rem;
                // overflow: hidden;
                // text-overflow: ellipsis;
                // -webkit-line-clamp: 3;
                // -webkit-box-orient: vertical;
                //  /*rtl:raw:height: 11.75rem;*/
                // @media #{$vpTablet991} {
                //   height: 7.75rem;
                //   /*rtl:raw:height: 11.75rem;*/
                // }
                // @media #{$vpMobile600} {
                //   height: 6.75rem;
                //   /*rtl:raw:height: 8.75rem;*/
                // }
              }
              // .main-primary-btn {
              //   margin-top: 1.69rem;
              // }
              .body1 {
                p {
                  // margin: 0;
                  // margin: 1.69rem 0 0;
                  display: -webkit-box;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                }
              }
              &.explore-more-content {
                .heading5 {
                  @media #{$vpMobile600} {
                    min-height: 100%;
                  }
                }
                .body3 {
                  @media #{$vpMobile600} {
                    min-height: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    .btn-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      display: none;
      button {
        @media #{$vpMobile600} {
          width: 100%;
        }
      }
    }

    .button-wrapper {
      width: fit-content;
    }
  }
  &.image-slider {
    padding: 6.25rem 0;

    @media #{$vpMobile600} {
      padding: 5rem 0;
    }
    .sec-press-release-wrapper {
      .slider-arrow-pagination {
        padding-top: 0;

        .arrow-wrap {
          top: 3.56rem;
          right: -0.625rem;
        }
      }

      .sec-press-release-post-wrapper {
        width: 100%;
        height: 100%;

        .slick-dots {
          @media #{$vpMobile600} {
            width: 100%;
            justify-content: center;
          }
        }

        .slick-slide {
          padding: 0 1.25rem;

          @media #{$vpMobile600} {
            padding: 0 0.31rem;
          }
        }

        // @media #{$vpMobile600} {
        //   width: 21.87rem;
        //   height: 35.5625rem;
        // }

        &::after {
          background-color: $baselineSecondaryLight;
          width: 12.6rem;
        }

        .slick-slide {
          .image-wrapper {
            img {
              width: 100%;
            }
          }
        }
      }
    }

    &.variant {
      padding: 0 0 19.5rem 0;

      @media #{$vpMobile600} {
        padding: 0 0 7.13rem 0;
      }

      .sec-press-release-wrapper {
        .slider-arrow-pagination {
          padding-top: 0;

          .arrow-wrap {
            right: 0;
          }
        }

        .sec-press-release-post-wrapper {
          height: 55.1rem;

          @media #{$vpTablet991} {
            height: 100%;
            width: 100%;
          }

          .slick-slide {
            padding: 0 2rem;

            @media #{$vpMobile600} {
              padding: 0 0.31rem;
            }
          }
        }
      }
    }
  }
  &.related-podcast {
    .content-banner {
      .inner-pages-banner {
        .content-wrapper {
          margin-bottom: 1rem;
        }
      }
    }
    .sec-press-release-wrapper {
      .sec-press-release-post-wrapper {
        .sec-press-release-post {
          .sec-press-release-post-content-box {
            background-color: #E5E0D0;
          }
        }
      }
    }
  }
  &.sec-media-resources {
    .sec-press-release-wrapper {
      .sec-press-release-post-wrapper {
        .sec-press-release-post {
          .sec-press-release-post-content-box {
            padding: 1.5rem;
          }
        }
      }
    }
  }
  &.no-bg {
    .sec-press-release-wrapper {

      .sec-press-release-post-wrapper {
     
        &::after {
          background-color: #ffffff;
        }
      }
    }

  }
  &.dark-bg {
    .sec-press-release-wrapper {
      .sec-press-release-post-wrapper {
        &:after {
          background-color: #000;
        }
        .main-primary-btn {
          background: #f9f7f3;
        }
        .sec-press-release-post {
          .sec-press-release-post-content-box {
            background-color: rgba($color: #fff, $alpha: 0.1); 
            .sec-press-release-post-content-info {
              &.explore-more-content {
                .heading5 {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
  &.gray-bg {
    .sec-press-release-wrapper {
      .sec-press-release-post-wrapper {
        &:after {
          background-color: #f9f7f3 !important;
        }
        .main-primary-btn {
          background: #f9f7f3;
        }
        .sec-press-release-post {
          .sec-press-release-post-content-box {
            background-color: #fff !important; 
          }
        }
      }
    }
  }
}