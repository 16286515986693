.sec-service-information {
  padding-right: 7.5rem;
  width: 100%;
  // @media #{$vpMobile600} {
  //   padding-right: 0;
  // }
  @media #{$vpTablet991} {
    padding-right: 0;
  }
  &.sticky {
    margin-top: -8.2rem;
    @media #{$vpTablet991} {
      margin-top: -7rem;
    }
    @media #{$vpMobile600} {
      margin-top: 0;
    }
    &.active {
      overflow: visible;
    }
    .main-wrapper {
      .items-wrapper-left {
        position: sticky;
        top: 8.2rem;
        @media #{$vpTablet991} {
          top: 7rem;
        }
      }
      .item-wrapper-right {
        padding-top: 12.2rem;
        @media #{$vpTablet991} {
          padding-top: 11rem;
        }
      }
    }
  }
  .main-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    // @media #{$vpMobile600} {
    //   flex-direction: column;
    // }
    @media #{$vpTablet991} {
      flex-direction: column;
    }

    .items-wrapper-left {
      height: 51.1875rem;
      padding: 4rem 2.5rem 2.5rem 7.5rem;
      width: 50.5rem;
      max-width: 100%;
      flex: none;
      background-color: $baselineSecondaryLight;
      @media #{$vpTablet991} {
        // width: 38.5rem;
        padding: 4rem 2.5rem 2.5rem 3rem;
        // padding: 2.5rem 1.25rem 1.5rem 1.25rem;
        height: auto;
        position: unset;
        flex: 0 0 100%;
        max-width: 100%;
        top: 0;
        width: 100%;
      }
      @media #{$vpMobile600} {
        padding: 2.5rem 1.25rem 1.5rem 1.25rem;
      //   height: auto;
      //   position: unset;
      //   flex: 0 0 100%;
      //   max-width: 100%;
      //   top: 0;
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        position: relative;
        height: 100%;

        .content-box-inner {
          width: 34.1875rem;

          @media #{$vpMobile600} {
            width: 100%;
          }
        }

        .title-wrap {
          width: 34.1875rem;

          @media #{$vpMobile600} {
            width: 100%;
          }

          .nav-pills {
            display: flex;
            gap: 1rem;
            flex-direction: column;
            @media #{$vpMobile600} {
              flex-direction: row;
              overflow: scroll;
              flex-wrap: nowrap;
              padding: .6rem 1.25rem 1rem;
              margin: 0 -1.25rem;

              &::-webkit-scrollbar {
                display: none;
              }
            }

            .nav-item {
              padding-left: 0;
              &::before {
                display: none;
              }
              @media #{$vpMobile600} {
                flex: 0 0 auto;
              }

              .nav-link {
                border-radius: 0.25rem;
                max-width: fit-content;
                line-height: normal;
                border: 0.063rem solid $secondarySimiLight;
                background-color: transparent;
                color: $baselineSecondary;
                transition: all 0.4s ease;
                height: 2.75rem;
                padding: 1rem 1.5rem;
                display: flex;
                align-items: center;
                cursor: pointer;

                @media #{$vpMobile600} {
                  flex: 0 0 auto;
                  padding: 1rem;
                  font-size: 0.875rem;
                  line-height: 1.4;
                }

                &:hover {
                  background-color: $secondarySimiLight;
                }

                &.active {
                  background-color: $secondarySimiLight;
                  display: flex;
                  align-items: center;
                  gap: 0.75rem;

                  &:after {
                    content: "";
                    width: 0.5rem;
                    height: 0.5rem;
                    display: block;
                    border-radius: 3.125rem;
                    background-color: $baselinePrimary;
                    transition: all 0.4s ease;
                  }
                }
              }
            }
          }
        }

        .action-btn {
          position: absolute;
          left: auto;
          top: auto;
          right: 0;
          bottom: 0;

          .main-primary-btn {
            padding: 1.3125rem 2rem;
            background-color: #fff;
            display: flex;
            gap: 0.625rem;
            width: fit-content;

            .icon {
              .icon-arrow-right {
                 width: 1rem;
                 height: 1rem;
                /*rtl:raw:
                transform: rotate(-180deg);
                */
              }
            }

            @media #{$vpMobile600} {
              padding: 0.875rem 1.5rem;
              font-size: 0.875rem;
            }
          }
        }

        .for-desk {
          @media #{$vpMobile600} {
            display: none;
          }
        }


      }
    }

    .item-wrapper-right {
      width: 51.875rem;
      padding: 4rem 0;
      max-width: 100%;
      flex: none;
      @media #{$vpTablet991} {
        // width: 36.5rem;
        padding: 4rem 3rem 2.5rem 3rem;
        width: 100%;
        // padding: 2.5rem 1.25rem;
      }
      @media #{$vpMobile600} {
      //   width: 100%;
        padding: 2.5rem 1.25rem;
      }

      .tab-content-wrapper {
        .tab-content {
          .right-wrapper {
            .for-mobile {
              display: none;

              @media #{$vpMobile600} {
                display: block;
                position: sticky;
                top: auto;
                bottom: 0px;
                background-color: $baselineSecondaryLight;
                width: auto;
                left: 0;
                height: 5rem;
                display: flex !important;
                align-items: center;
                justify-content: flex-end;
                z-index: 999;
                margin-left: -1.25rem;
                margin-right: -1.25rem;
                padding: 1rem 1.25rem;

                .main-primary-btn {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 0.625rem;
                  padding: 0.875rem 1.5rem;
                  height: auto;

                  .icon-arrow-right {
                    width: 1rem;
                    height: 1rem;
                  }
                }
              }
            }

            &.termsAndConditions {
              .key-duties {
                grid-template-columns: repeat(1, 1fr);
              }
            }

            .about-the-role {
              display: flex;
              flex-direction: column;
              gap: 2rem;
              margin-bottom: 2rem;

              @media #{$vpMobile600} {
                gap: 1.5rem;
                margin-bottom: 1.5rem;
              }

              .heading4 {
                line-height: 1.3;
                margin-bottom: 0;

                @media #{$vpMobile600} {
                  font-size: 1.5rem;
                }
              }

              .body2,
              .body3 {
                margin: 0;

                @media #{$vpMobile600} {
                  font-size: 0.875rem;
                  line-height: 1.4;
                }
              }
            }

            .key-duties {

              .alphabet-ol {
                color: $black;
                list-style: none;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                padding-left: 0;

                a {
                  color: $black;
                  text-decoration: underline !important;
                }

                li {
                  &.text-align {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                  }


                  p {
                    margin: 0;
                    display: inline;
                  }

                  &::before {
                    display: inline-block;
                    width: 1rem;
                    margin-right: 1rem;
                    margin-left: 0;
                    text-align: left;
                    color: #9f8332;
                    font-size: 1.25rem;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 1.4;
                  }

                  &:nth-child(1)::before {
                    content: "a";
                    /*rtl:raw:content: "أ" */
                  }

                  &:nth-child(2)::before {
                    content: "b";
                    /*rtl:raw:content: "ب" */
                  }

                  &:nth-child(3)::before {
                    content: "c";
                    /*rtl:raw:content: "ج" */
                  }

                  &:nth-child(4)::before {
                    content: "d";
                    /*rtl:raw:content: "د" */
                  }

                  &:nth-child(5)::before {
                    content: "e";
                    /*rtl:raw:content: "ه" */
                  }

                  &:nth-child(6)::before {
                    content: "f";
                    /*rtl:raw:content: "ف" */
                  }

                  &:nth-child(7)::before {
                    content: "g";
                    /*rtl:raw:content: "ز" */
                  }

                  &:nth-child(8)::before {
                    content: "h";
                    /*rtl:raw:content: "ح" */
                  }

                  &:nth-child(9)::before {
                    content: "i";
                    /*rtl:raw:content: "أنا" */
                  }

                  &:nth-child(10)::before {
                    content: "j";
                    /*rtl:raw:content: "ي" */
                  }

                  &:nth-child(11)::before {
                    content: "k";
                    /*rtl:raw:content: "ك" */
                  }

                  &:nth-child(12)::before {
                    content: "l";
                    /*rtl:raw:content: "ل" */
                  }

                  &:nth-child(13)::before {
                    content: "m";
                    /*rtl:raw:content: "م" */
                  }

                  &:nth-child(14)::before {
                    content: "n";
                    /*rtl:raw:content: "ن" */
                  }

                  &:nth-child(15)::before {
                    content: "o";
                    /*rtl:raw:content: "س" */
                  }

                  &:nth-child(16)::before {
                    content: "p";
                    /*rtl:raw:content: "ص" */
                  }

                  &:nth-child(17)::before {
                    content: "q";
                    /*rtl:raw:content: "س" */
                  }

                  &:nth-child(18)::before {
                    content: "r";
                    /*rtl:raw:content: "ص" */
                  }

                  &:nth-child(19)::before {
                    content: "s";
                    /*rtl:raw:content: "س" */
                  }

                  &:nth-child(20)::before {
                    content: "t";
                    /*rtl:raw:content: "ر" */
                  }

                  &:nth-child(21)::before {
                    content: "u";
                    /*rtl:raw:content: "ش" */
                  }

                  &:nth-child(22)::before {
                    content: "v";
                    /*rtl:raw:content: "الخامس" */
                  }

                  &:nth-child(23)::before {
                    content: "w";
                    /*rtl:raw:content: "ث" */
                  }

                  &:nth-child(24)::before {
                    content: "x";
                    /*rtl:raw:content: "س" */
                  }

                  &:nth-child(25)::before {
                    content: "y";
                    /*rtl:raw:content: "ذ" */
                  }

                  &:nth-child(26)::before {
                    content: "z";
                    /*rtl:raw:content: "ض" */
                  }


                  .number-ol {
                    color: $black;
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    margin-top: 1rem;

                    @media #{$vpMobile600} {
                      padding-left: 1.5rem;
                    }


                    li {

                      &:nth-child(1)::before {
                        content: "01";
                      }

                      &:nth-child(2)::before {
                        content: "02";
                      }

                      &:nth-child(3)::before {
                        content: "03";
                      }

                      &:nth-child(4)::before {
                        content: "04";
                      }

                      &:nth-child(5)::before {
                        content: "05";
                      }

                      &:nth-child(6)::before {
                        content: "06";
                      }

                      &:nth-child(7)::before {
                        content: "07";
                      }

                      &:nth-child(8)::before {
                        content: "08";
                      }

                      &:nth-child(9)::before {
                        content: "09";
                      }

                      &:nth-child(10)::before {
                        content: "10";
                      }

                      .roman-ol {
                        color: $black;
                        list-style: none;
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        margin-top: 1rem;

                        li {

                          &:nth-child(1)::before {
                            content: "I";
                          }

                          &:nth-child(2)::before {
                            content: "II";
                          }

                          &:nth-child(3)::before {
                            content: "III";
                          }

                          &:nth-child(4)::before {
                            content: "IV";
                          }

                          &:nth-child(5)::before {
                            content: "V";
                          }

                          &:nth-child(6)::before {
                            content: "VI";
                          }

                          &:nth-child(7)::before {
                            content: "VII";
                          }

                          &:nth-child(8)::before {
                            content: "VIII";
                          }

                          &:nth-child(9)::before {
                            content: "IX";
                          }

                          &:nth-child(10)::before {
                            content: "X";
                          }
                        }
                      }
                    }
                  }
                }
              }

              .key-duties-wrapper-left {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                margin-bottom: 4rem;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 1.25rem;

                @media #{$vpMobile600} {
                  gap: 1rem;
                  margin-bottom: 2rem;
                  grid-template-columns: repeat(1, 1fr);
                }

                .key-duty {
                  display: flex;
                  align-items: flex-start;
                  gap: 1.125rem;

                  .heading5 {
                    color: $baselinePrimary;
                    margin-bottom: 0;
                    line-height: 1.3;

                    @media #{$vpMobile600} {
                      font-size: 1.25rem;
                    }
                  }

                  .body3 {
                    margin: 0;
                    padding-top: 0.313rem;

                    @media #{$vpMobile600} {
                      font-size: 0.875rem;
                      line-height: 1.4;
                      padding-top: 0.188rem;
                    }
                  }
                }
              }

              .key-duties-wrapper-right {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                margin-bottom: 4rem;

                @media #{$vpMobile600} {
                  gap: 1rem;
                  margin-bottom: 2rem;
                }

                .key-duty {
                  display: flex;
                  align-items: flex-start;
                  gap: 1.125rem;

                  .heading5 {
                    color: $baselinePrimary;
                    margin-bottom: 0;
                    line-height: 1.3;
                  }

                  .body3 {
                    margin: 0;
                    padding-top: 0.313rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.info-step-form {
    .main-wrapper {
      .items-wrapper-left {
        .content-wrapper {
          .action-btn {
            .main-primary-btn {
              &:hover {
                background-color: #f2efe7;
              }
            }
          }
        }
      }
      .item-wrapper-right {
        .tab-content-wrapper {
          @media #{$vpTablet991} {
            width: 100%;
          }
          .sec-press-release {
            .sec-heading-box {
              margin-bottom: 2rem;
            }
            .sec-press-release-wrapper {
              .sec-press-release-post-wrapper {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                justify-content: space-between;
                &::after {
                  display: none;
                  width: 0;
                }
                .slide {
                  width: 25.313rem;
                  flex: 0 0 auto;
                  margin-bottom: 1.25rem;
                  @media #{$vpMobile600} {
                    width: 100%;
                  }
                  .sec-press-release-post {
                    .sec-press-release-post-content-box {
                      .sec-press-release-post-content-info {
                        .main-primary-btn {
                          max-width: max-content;
                          .btn-text {
                            margin-right: 0.625rem;
                          }
                          .icon {
                            path {
                              fill: $baselineSecondary;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .right-wrapper {
            .key-duties {
              .key-duties-wrapper-left {
                @media #{$vpTablet991} {
                  display: flex;
                }
              }
            }
            .content-wrapper {
              margin-bottom: 4rem;
              .heading4 {
                margin-bottom: 2rem;
              }
            }
            a {
              color: $baselineSecondary;
            }
          }
        }
        .sec-faq {
          &.form--faq {
            width: 51.875rem;
            @media #{$vpTablet991} {
              width: 30.5rem;
            }
            @media #{$vpMobile600} {
              width: 100%;
            }
          }
        }
      }
    }
  }

}