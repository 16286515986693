  .sec-get-tickets {
    &.interest {
        .c-form {
            margin-top: 0;
        } 
        .form-group {
            &.submit {
                text-align: right;
            }
        }
        .interest-wrapper {
            margin-bottom: 100px;
            @media #{$vpMobile600} {
                margin-bottom: 20px;
            }
        }
        .sec-form {
            padding: 65px 80px 55px 80px;
            @media #{$vpMobile600} {
                padding: 65px 30px 55px;
            }
        }
        .form-steps {
            padding: 80px 0; 
            @media #{$vpMobile600} {
                padding: 20px 0;
            }
        }
        .container {
            &:before {
                background: blue;
                right: 40%;
            }
        }
    }
    .c-form {
        margin-top: 50px;
    }
    .btn-link {
        &.c-btn-back {
            background: transparent; 
            .tosvg {
                margin: 0 20px 0 0;  
                animation: none;
            }
            &:hover {
                @media #{$vpMobile600} {
                  color: grey;
                    .tosvg {
                        animation: none;
                        transform: none;
                    }
                }
            }
        }
    } 

    .container {
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 45%;
            background: blue;
            height: 100%;
            width: 100vw;
            z-index: -1;
            box-sizing: content-box;
            max-height: 538px;
            @media #{$vpMobile600} {
                right: auto;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .form-steps {
        text-align: right;
        color: rgba(grey, 0.5);
        padding: 40px 0;
        margin: 0;
        font-size: 30px;
        font-weight: 700;
        @media #{$vpMobile600} {
            color: $white;
            font-size: 22px;
            padding: 30px 0;
        }
    }

    .sec-txt {
        padding-bottom: 75px;
        margin-top: -30px;

        @media #{$vpMobile600} {
            padding-bottom: 35px;
            margin-top: 50px;
        }

        @media #{$vpMobile600} {
            margin-top: 0;
            padding-bottom: 40px;
        }
        .heading1 {
            margin: 0 0 25px 0;
        }

        p {
            color: #fff;
        }
    }

    .sec-form {
        background: #fff;
        padding: 50px 75px;
        box-shadow: 16px 24px 57px rgba(0, 0, 0, 0.04);
        margin: 0 0 0 20px;

        @media #{$vpMobile600} {
            margin-left: 0;
            padding: 25px 30px;
        }

        @media #{$vpMobile600} {
            padding: 20px 25px;
            margin-right: -15px;
            margin-left: -15px;
        }
    }
    .error-message,
    .success-message,
    .error {
        display: none;
    }
    .action-bar {
        button {
            margin-left: auto;
        }
    }
    @media #{$vpMobile600} {
        .contact-col {
            margin: 0;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    &.tickets--booking {
      background: #f2f2f2;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: -133px;
        background: url(../img/bg/book-ticket-bg.png);
        background-repeat: no-repeat;
        background-size: contain;
        width: 395px;
        height: 432px;
        z-index: 1;
        display: none;
        @media #{$vpMobile600} {
          width: 295px;
          height: 322px;
          bottom: -153px;
        }
        @media #{$vpMobile600} {
          width: 195px;
          height: 214px;
          bottom: -130px;
        }
      }
      .form-steps {
        padding: 0;
      }
      .container {
        &::before {
          display: none;
        }
      }
      .step--wrapper {
        padding: 56px 0 32px;
        @media #{$vpMobile600} {
          padding: 26px 0 15px;
        }
        .step--title {
          padding-bottom:28px;
          @media #{$vpMobile600} {
            padding-bottom: 12px;
          }
          .heading5 {
            margin: 0;
            margin-bottom: 10px;
            color: #1D1D1B;
            @media #{$vpMobile600} {
              font-size: 24px;
            }
          }
          p {
            font-size: 24px;
            margin: 0;
            font-weight: 500;
            @media #{$vpMobile600} {
              font-size: 16px;
            }
          }
        }
        .input-wrapper {
          position: relative;
          justify-content: center;
          z-index: 2;
          flex-wrap: wrap;
          @media #{$vpMobile600} {
            justify-content: space-between;
          }
          // &.initialStep {
          //   padding-bottom: 80px;
          // }
          .input-box {
              flex: 0 0 19.2%;
              max-width: 19.2%;
              background: #ffffff;
              margin: 0;
              border-radius: 4px;
              overflow: hidden;
              margin: 5px;
              @media (max-width: 992px) {
                flex: 0 0 30%;
                max-width: 30%;
                margin: 10px;
              }
              @media #{$vpMobile600} {
                flex: 0 0 48%;
                max-width: 48%;
                margin: 4px 0;
              }
              .input-style1:checked + label:before,
              .input-style1:not(:checked) + label:before {
                border: 2px solid #e7e7e7;
                background-color: rgba(#575756, 0.05);
                border-radius: 7px;
              }
              .input-style1:checked + label:after,
              .input-style1:not(:checked) + label:after {
                border-radius: 7px;
                background: transparent;
                background-image: url(../img/icons/tick-white.svg);
                background-position: center;
                width: 14px;
                height: 14px;
                right: 19px;
                top: 18px;
                background-repeat: no-repeat;
              }
              .input-style1:checked + label {
                background: yellow;
                color: $white;
                svg {
                  path {
                    fill: #ffffff;
                  }
                }
              }
              label {
                flex-direction: column;
                align-items: flex-start;
                padding: 20px;
                margin: 0;
                height: 256px;
                justify-content: space-between;
                font-size: 24px;
                font-weight: 500;
                color: #1D1D1B;
                &:after,
                &:before {
                  right: 16px;
                  left: auto;
                  top: 16px;
                }
                .box-img {
                  svg {
                    width: 80px;
                    height: 80px;
                  }
              }
              @media (max-width: 1400px) {
                font-size: 18px;
                height: 200px;
                .box-img {
                  svg {
                    width: 60px;
                    height: 60px;
                  }
                } 
              }
              @media #{$vpMobile600} {
                height: 136px;
                font-size: 16px;
                .box-img {
                  svg {
                    width: 32px;
                    height: 32px;
                  }
                } 
              }
            }
          }
        }
      }
      .sec-form {
          padding: 0;
          margin: 0;
          box-shadow: none;
      }
      .nextOption {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 135px 0 0;
        z-index: 2;
        @media (max-width: 991px) {
          padding-top: 50px;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
        @media (max-width: 991px) {
          padding-top: 12px;
        }
        p {
          font-weight: 500;
          @media #{$vpMobile600} {
            font-weight: 400;
          }
        }
        .btn-primary {
          @media #{$vpMobile600} {
            margin-top: 10px;
          }
        }
      }
      .c-form {
        margin-top: 0;
      }
      .resident--field {
        display: flex;
        justify-content: space-between;
        padding-right: 12px;
        @media #{$vpMobile600} {
          padding-right: 0;
        }
        .input-box {
          flex: 0 0 48% !important;
          max-width: 48% !important;
          @media (max-width: 1199px) {
            margin-right: 0;
          }
        }
        label {
          text-transform: capitalize !important;
        }
      }
      .ticket-forms {
        position: relative;
        padding: 40px 0 0;
        z-index: 3;
        .exhibitorBox {
          max-width: 90%;
          @media #{$vpMobile600} {
            max-width: 100%;
          }
          .heading5 {
            color: #1D1D1B;
          }
          p {
            font-size: 24px;
            @media #{$vpMobile600} {
              font-size: 16px;
            }
          }
          .imgBox {
            margin: 16px 0 30px;
          }
          .nextOption {
            p {
              font-size: 16px;
              line-height: 24px;
              font-weight: 500;
            }
          }
        }
        .emailInfo {
          display: flex;
          align-items: center;
          position: absolute;
          left: 0;
          bottom: -40px;
          p {
            font-size: 14px;
            margin-right: 6px;
          }
          img,
          svg {
            max-width: 16px;
            max-height: 16px;
          }
          @media (max-width: 991px) {
            position: relative;
            bottom: 0;
            p {
              margin-bottom: 0;
            }
            img,svg {
              margin-top: 10px;
            }
          }
        }
        .form--fields {
          margin-top: 40px;
          @media #{$vpMobile600} {
            margin-top: 10px;
          }
          .form-group {
            margin-bottom: 38px;
            @media (max-width: 991px) {
              margin-bottom: 14px;
            }
            label {
              margin-bottom: 6px;
              &::before {
                border: 2px solid rgba($color: #575756, $alpha: 0.1);
                border-radius: 7px;
              }
            }
            input,
            .select2-box .select2-container .select2-selection--single,
            .input-box,
            .iti.iti--allow-dropdown.iti--separate-dial-code input[type=tel] {
              @media #{$vpMobile600} {
                min-height: 52px;
                max-height: 52px;
              }
            }
          }
        }
        .step--title {
          .heading5 {
            color: #1D1D1B;
            margin: 0;
          }
          p {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: -0.02em;
            margin: 0;
            @media #{$vpMobile600} {
              font-size: 16px;
            }
          }
          &.subTitle {
            margin-top: 30px;
          }
        }
        .numOfMembers {
          display: flex;
          align-items: center;
          margin-top: 15px;
          @media #{$vpMobile600} {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
          }
          .title {
            p {
              color: #121210;
              font-weight: 500;
              font-size: 16px;
              text-transform: uppercase;
              @media #{$vpMobile600} {
                font-size: 14px;
              }
              &.sub-para {
                color: #575756;
                font-weight: 400;
                font-size: 14px;
                text-transform: capitalize;
              }
            }
          }
          .counterBox {
            display: flex;
            align-items: center;
            margin-left: 75px;
            @media #{$vpMobile600} {
              margin-left: 0;
            }
            .btn {
              border-radius: 50%;
              overflow: hidden;
              width: 56px;
              height: 56px;
              padding: 0;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 30px;
              margin: 0;
              @media #{$vpMobile600} {
                width: 36px;
                height: 36px;
                font-size: 20px;
              }
              &:focus {
                box-shadow: none;
              }
            }
            p {
              padding: 0 32px;
              @media #{$vpMobile600} {
                padding: 0 16px;
                font-size: 16px;
              }
            }
          }
          &.limit-excedeed {
            .title {
              p {
                &.sub-para {
                  color: #f8363d;
                }
              }
            }
          }
        }
        .counter--fieldWrapper {
          margin-top: 75px;
          @media #{$vpMobile600} {
            margin-top: 25px;
          }
          .fieldTitle {
            display: flex;
            justify-content: space-between;
            p {
              flex: 0 0 48%;
              max-width: 48%;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #A82692;
              text-transform: uppercase;
              @media #{$vpMobile600} {
                font-size: 14px;
                line-height: 1;
                font-weight: 700;
              }
            }
          }
          .postBox {
            border-bottom: 2px solid #575756;
            margin-bottom: 10px;
          }
          .memberDetail {
            padding: 10px 0;
            border-bottom: 1px solid rgba($color: #575756, $alpha: 0.1);
            .row {
              @media #{$vpMobile600} {
                display: flex;
                align-items: center;
              }
            }
            .detailBox {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .form-group {
                flex: 0 0 48%;
                max-width: 48%;
                margin: 0;
              }
            }
          }
          .form-group .select2-box .select2-container .select2-selection--single {
            height: 48px;
            &::placeholder {
              text-transform: uppercase;
            }
          }
        }
      }
      .form--submit {
        position: relative;
        text-align: right;
        z-index: 2;
        padding-bottom: 24px;
        margin-top: 15px;
        @media #{$vpMobile600} {
          text-align: left;
          margin-top: 0;
        }
      }
      .ticket-forms {
        &.exhibitor {
          .nextOption {
            padding: 50px 0 140px;
            flex-direction: column;
            align-items: flex-start;
            .box-btn {
              margin-top: 34px;
              a {
                margin: 0 8px;
                &:first-child {
                  margin-left: 0;
                }
              }
            }
          }
        }
      } 
      @media (max-width: 1400px) {
        .ticket-forms .form--fields .form-group .iti.iti--allow-dropdown.iti--separate-dial-code .iti__selected-flag {
          min-width: 130px;
          border-right: 12px solid #f2f2f2;
        }
        .ticket-forms .form--fields .form-group .iti.iti--allow-dropdown.iti--separate-dial-code input[type=tel] {
          padding-left: 145px !important;
        }
      }
    }
  }
  .resident--field {
    .input-box {
      background: $white !important;
      height: 60px;
      padding: 10px 0;
    }
  }
  .btn-prev {
    margin-right: 16px;
    img,.tosvg {
      // transform:rotate(180deg);
      margin-left: 0;
      margin-right: 10px;
      transform: translateX(0px) !important;
      animation: arrowLeftReverse .6s;
      path {
        fill: #A82692;
      }
    }
    &:hover {
      img,.tosvg {
        transform: translateX(-5px) !important;
      }
    }
  }
  .ticket-forms {
    padding-top: 20px;
    .form--fields {
      margin-top: 12px;
      .form-group {
        border: none;
        margin-bottom: 24px;
        @media #{$vpMobile600} {
          margin-bottom: 24px;
        }
        label {
          position: relative;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-transform: uppercase;
          color: #121210;
          margin-bottom: 10px;
          padding-top: 4px;
          top: 0;
          @media #{$vpMobile600} {
            font-size: 14px;
          }
        }
        textarea {
          background: #ffffff;
          min-height: 280px;
          padding: 18px;
          font-size: 16px;
          // opacity: 50%;
          &::placeholder {
            opacity: 0.5;
          }
          @media #{$vpMobile600} {
            min-height: 180px;
          }
        }
        input {
          background: #FFFFFF;
          border: 1px solid rgba(87, 87, 86, 0.1);
          border-radius: 1px;
          padding: 15px 18px;
          font-size: 16px;
          max-height: 60px;
          &::placeholder {
            opacity: 0.5;
          }
          @media #{$vpMobile600} {
            font-size: 14px;
          }
        }
        .select2-box {
          background: #FFFFFF;
          border: 1px solid rgba(87, 87, 86, 0.1);
          border-radius: 1px;
          font-size: 16px;
          &::placeholder {
            opacity: 0.5;
          }
          .select2-container {
            .select2-selection--single {
              height: 60px;
              padding: 0 15px;
              background-color: #ffffff;
            }
          }
          .select2-selection__placeholder {
            opacity: 0.5;
            text-transform: capitalize;
          }
        }
        .iti.iti--allow-dropdown.iti--separate-dial-code .iti__selected-flag {
          background-color: transparent;
          border-radius: 1px;
          border-right: 32px solid #f2f2f2;
          border-top: none;
          border-bottom: none;
          height: 60px;
          min-width: 240px;
          padding: 0 16px;
        }
        .iti.iti--allow-dropdown.iti--separate-dial-code input[type=tel] {
          padding-left: 250px !important;
          height: 60px;
          border: none;
        }
        .input-wrapper {
          .row {
            width: 100%;
          }
          .input-box {
            flex: 0 0 100%;
            max-width: 100%;
            label {
              height: 37px;
              margin: 0;
              font-size: 16px;
              padding-left: 45px;
              &:after,
              &:before {
                left: 16px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
            .input-style1:checked + label {
              background: #ffffff;
              color: #575756;
            }
            .input-style1:checked + label:before {
              border: 2px solid #00B33C;
              background-color: rgba(#00B33C, 0.05);
              border-radius: 7px;
            }
            .input-style1:checked + label:after, 
            .input-style1:not(:checked) + label:after {
              border-radius: 7px;
              background: transparent;
              background-image: url(../img/icons/tick-green.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              width: 12px;
              height: 12px;
              left: 20px;
            }
          }
        }
        &.invalid-field {
          input {
            &::placeholder {
              color: #f8363d;
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .title-bar--pageTop {
    background-color: purple;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: url(../img/bg/booking-pattern.png);
        background-repeat: no-repeat;
        background-size: cover;
        width: 501px;
        height: 100%;
        @media #{$vpMobile600} {
          background: url(../img/bg/booking-pattern-mbl.png);
          width: 303px;
          height: 136px;
          top: 20px;
        }
    }
    .postBox {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 1;
        padding: 28px 0;
    }
    &.sty2 {
      padding: 126px 0 56px;
      @media (max-width: 1199px) {
        padding: 50px 0;
      }
      @media #{$vpMobile600} {
        padding: 21px 0 30px;
      }
      &:after {
        background: url(../img/bg/form-top--pattern.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
        height: 100%;
        width: 100%;
        right: 100px;
        left: auto;
        @media (max-width: 1650px) {
          right: -50px;
        }
        @media #{$vpMobile600} {
          background-position: bottom;
          height: 97px;
          width: 212px;
          right: 0;
          left: auto;
          bottom: 0px;
          top: auto;
        }
      }
      .postBox {
        padding: 0;
        .heading2 {
          max-width: 35%;
          @media (max-width: 1199px) {
            max-width: 50%;
          }
          // @media #{$vpMobile600} {
          //   max-width: 80%;
          // }
          &.main-title {
            max-width: 32%;
            @media (max-width: 991px) {
            max-width: 50%;
          }
          }
        }
      }
    }
    &.sty3 {
      .postBox {
        padding: 68px 0 40px;
        align-items: flex-end;
        @media #{$vpMobile600} {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 20px 0;
          .heading4 {
            max-width: 50%;
          }
        }
      }
      .heading4 {
        margin-bottom: 0;
      }
      .stepCount {
        display: flex;
        align-items: center;
        @media #{$vpMobile600} {
          justify-content: flex-end;
          align-items: center;
          width: 100%;
        }
        span {
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          margin: 0 8px;
          &.stepBar {
            position: relative;
            background: rgba(#ffffff, 0.45);
            width: 312px;
            height: 2px;
            @media #{$vpMobile600} {
              width: 166px;
            }
            &:after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 50%;
              height: 100%;
              background: #ffffff;
              transition: all 0.35s ease;
            }
            &.step2 {
              &:after {
                width: 100%;
              }
              &.additional {
                &:after {
                  width: 66.66%;
                }
              }
            }
            &.step3 {
              &:after {
                width: 100%;
              }
            }
          }
        }
      }
    }
    &.sty4 {
      &:after {
        content: "";
        background: url(../img/bg/ticket-pattern.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right;
        height: 100%;
        width: 100%;
        right: 105px;
        left: auto;
        @media (max-width: 1199px) {
          width: 600px;
          height: 240px;
        }
        @media #{$vpMobile600} {
          width: 420px;
          height: 136px;
          right: 0;
        }
      }
      .postBox {
        padding: 60px 0 !important;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        @media (max-width: 1199px) {
          padding: 48px 0 !important;
        }
        @media #{$vpMobile600} {
          padding: 38px 0 !important;
          .heading1 {
            font-size: 28px;
          }
          .heading4 {
            font-weight: 500;
            font-size: 16px;
            margin: 0;
          }
        }
      }
    }
  }
  .c-form-2 {
    min-height: 375px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  .form-steps-2 {
    .step {
      display: none;

      &.step--active {
          display: block;
      }
    }
  }

    label {
        cursor: pointer;
    }

    .form-item {
        margin-bottom: 25px;

        .fi-label {
            display: block;
            margin-bottom: 3px;

            span {
                color: #9e9e9e;
            }
        }

        input,
        textarea {
            border: 1px solid #ebebeb;
            resize: none;
            width: 100%;
            padding: 12px 20px 11px;
            font-size: 14px;
            line-height: 1.6em;
            outline: none;

            &::-webkit-input-placeholder {
                color: grey;
            }

            &:-ms-input-placeholder {
                color: grey;
            }

            &::placeholder {
                color: grey;
            }
        }

        textarea {
            height: 100px;
            padding: 15px 20px;
        }
    }

    .selector {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @media (min-width: 768px) {
            &.selector--2inrow {
                .s-item {
                    width: 50%;
                }
            }

            &.selector--5inrow {
                .s-item {
                    width: 20%;

                    &:nth-child(5n) {
                        label {
                            border: 1px solid #ebebeb;
                        }
                    }

                    &:nth-child(n+6) {
                        label {
                            border-top: none;
                        }
                    }
                }
            }

            &.selector--4inrow {
                .s-item {
                    width: 25%;

                    &:nth-child(4n) {
                        label {
                            border: 1px solid #ebebeb;
                        }
                    }

                    &:nth-child(n+5) {
                        label {
                            border-top: none;
                        }
                    }
                }
            }

            &.selector--3inrow {
                .s-item {
                    width: 33.3333%;

                    &:nth-child(3n) {
                        label {
                            border: 1px solid #ebebeb;
                        }
                    }

                    &:nth-child(n+4) {
                        label {
                            border-top: none;
                        }
                    }
                }
            }
        }

        @media #{$vpMobile600} {
            .s-item {
                width: 50%;

                &:nth-child(2n) {
                    label {
                        border-right: 1px solid #ebebeb;
                    }
                }

                &:nth-child(n+3) {
                    label {
                        border-top: none;
                    }
                }
            }
        }

        .s-item {
            position: relative;

            &:last-child {
                label {
                    border-right: 1px solid #ebebeb;
                }
            }
        }

        label {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #9e9e9e;
            flex-direction: column;
            text-align: center;
            height: 100%;
            padding: 12px 10px 10px 10px;
            border: 1px solid #ebebeb;
            border-right: none;
            transition: color .35s;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 4px solid #34acbb;
                opacity: 0;
                transition: opacity .35s;
            }

            strong {
                display: block;
                font-weight: bold;
            }
        }

        input[type="radio"],
        input[type="checkbox"] {
            opacity: 0;
            position: absolute;
            width: 0;
            height: 0;
            border: none;

            &:checked {
                &+label {
                    color: #34acbb;

                    &:before {
                        opacity: 1;
                    }
                }
            }

            &.invalid {
                &+label {
                    border-color: #f36e7f !important;
                    color: #f36e7f;
                }
            }
        }
    }

    .action-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 9px;
        margin-top: 10px;
    }
    &.sty1 {
        min-height: 418px;
    }
  }
  .error {
    span {
        color: red;
    }
  }
  .custom-error{
      color: red;
  }
  .sec-get-tickets .form-group .select2-box.select2-box--style1 .invalid + .select2-container .select2-selection--single .select2-selection__placeholder {
    color: red;
  }
  .sec-get-tickets .form-group .invalid + .label {
    color: red;
  }
  // sor start
  .userForms {
    background: #f2f2f2;
    .form--wrapper {
      display: flex;
      justify-content: space-between;
      padding-top: 50px;
      padding-bottom: 190px;
      @media (max-width: 991px) {
        padding-top: 0;
      }
      .form--box {
        flex: 0 0 66%;
        max-width: 66%;
        margin-left: 10%;
        @media #{$vpMobile600} {
          flex: 0 0 100%;
          max-width: 100%;
          margin-left: 0;
        }
        .form--fields {
          margin-top: 60px;
          @media #{$vpMobile600} {
                margin-top: 30px;
            }
          &.sty1 {
            margin-top: 12px;
          }
          .form--title {
            margin-bottom: 40px;
            @media #{$vpMobile600} {
                margin-bottom: 24px;
            }
            .heading4 {
              letter-spacing: -0.02em;
              color: #1D1D1B;
            }
            p {
              color: #1D1D1B;
            }
          }
          .book--form {
            .bookFormWrapper {
              padding: 24px 0;
              border-bottom: 1px solid rgba(#575756, 0.1);
              @media #{$vpMobile600} {
                padding-bottom: 0;
              }
              &:first-child {
                padding-top: 0;
              }
              &:last-child {
                border: none;
              }
              .book-num {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .heading5 {
                  color: #1D1D1B;
                  margin: 0;
                  @media #{$vpMobile600} {
                    font-size: 24px;
                  }
                }
                .form-edit--buttons {
                  display: flex;
                  position: relative;
                  button {
                    border: none;
                    padding: 0 12px;
                    background: transparent !important;
                    &:hover {
                      .tosvg {
                        path {
                          fill: #1D1D1B;
                        }
                      }
                    }
                    &:focus {
                      border: none;
                      outline: none;
                    }
                    &.btn-edit {
                      display: none;
                    }
                    svg {
                      width: 24px;
                      height: 24px;
                    }
                  }
                  .deleteBook--box {
                    position: absolute;
                    top: -150px;
                    width: 300px;
                    left: -140px;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.35s ease;
                    z-index: 1;
                    @media (max-width: 1024px) {
                      width: 320px;
                    }
                    @media #{$vpMobile600} {
                      left: -220px;
                    }
                    .postBox {
                      position: relative;
                      text-align: center;
                      padding: 30px 20px 20px;
                      box-shadow: 0 0 5px 5px rgba($color: #000000, $alpha: 0.1);
                      max-width: 100%;
                      background: #f2f2f2;
                      .confimation--btn {
                        margin-top: 20px;
                        .btn {
                          margin: 0 10px;
                        }
                      }
                      &:after {
                        content: "";
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        border-width: 15px;
                        border-style: solid;
                        border-color: rgba($color: #A82692, $alpha: 1) transparent transparent transparent;
                        @media #{$vpMobile600} {
                          left: 72%;
                        }
                      }
                    }
                  }
                  &.showDelete {
                    .deleteBook--box {
                      opacity: 1;
                      visibility: visible;
                      top: -188px;
                    }
                  }
                }
              }
              .input-box {
                label {
                  padding-left: 30px;
                  text-transform: capitalize;
                  @media #{$vpMobile600} {
                    font-size: 14px;
                    font-weight: 400;
                    height: auto;
                    margin-bottom: 10px;
                  }
                }
                .input-style1:checked + label {
                  background: transparent;
                  color: #575756;
                }
                .input-style1 + label:before {
                  left: 0;
                  border-radius: 7px;
                }
                .input-style1:checked + label:before {
                  border: 2px solid #00B33C;
                  background-color: rgba(#00B33C, 0.05);
                  border-radius: 9px;
                  left: 0;
                }
                .input-style1:checked + label:after, 
                .input-style1:not(:checked) + label:after {
                  border-radius: 4px;
                  background: #00B33C;
                  background-image: none;
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                  width: 12px;
                  height: 12px;
                  left: 6px;
                }
              }
              &.closedForm {
                .book-num {
                  .form-edit--buttons {
                    button {
                      &.btn-edit {
                        display: block;
                      }
                    }
                  }
                }
              }
              
              &.book--error {
                .book-num {
                  .heading5 {
                    color: #f8363d;
                  }
                }
                .error-text {
                  display: block;
                  color: #f8363d;
                }
              }
              .error-text {
                display: none;
              }
            }
          }
          .form-group {
            label {
              color: #575756;
            }
            .iti.iti--allow-dropdown.iti--separate-dial-code .iti__selected-flag {
              background-color: transparent;
              border-right: 12px solid #f2f2f2;
              height: 60px;
              min-width: 160px;
            }
            .iti.iti--allow-dropdown.iti--separate-dial-code input[type=tel] {
              padding-left: 175px !important;
            } 
            &.file-attachment {
              input {
                background: transparent;
                border: none;
                padding-left: 0;
                  @media #{$vpMobile600} {
                  padding-top: 0;
                  }
              }
            }
            .input-style1:checked + label {
              background: transparent;
              color: #575756;
            }
            .input-style1 + label:before {
              left: 0;
              border-radius: 10px;
              border: 2px solid rgba(#575756, 0.1);
              width: 24px;
              height: 24px;
            }
            .input-style1:checked + label:before {
              border: 2px solid #00B33C;
              background-color: rgba(#00B33C, 0.05);
              border-radius: 10px;
              left: 0;
            }
            .input-style1:checked + label:after, 
            .input-style1:not(:checked) + label:after {
              border-radius: 7px;
              background: #00B33C;
              background-image: none;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              width: 12px;
              height: 12px;
              left: 6px;
            }
            .datepicker-box {
              position: relative;
              svg {
                left: auto;
                right: 22px;
                path {
                  fill: #A82692;
                }
                
              }
              .calanderShadow {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                height: 100%;
                z-index: 1;
                background: #951D87;
                opacity: 0;
                cursor: pointer;
              }
            }
          }
          .termsBar {
            margin-top: 75px;
            @media (max-width: 991px) {
              margin-top: 40px;
            }
            .input-wrapper .input-box .input-style1:checked + label,
            .input-wrapper .input-box .input-style1:checked + label, .input-wrapper .input-box .input-style1:not(:checked) + label {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.02em;
              color: #575756;
              @media #{$vpMobile600} {
                font-size: 14px;
              }
            }
            .input-wrapper.invalid-field-wrapper .input-box .input-style1:checked + label,
            .input-wrapper.invalid-field-wrapper .input-box .input-style1:checked + label, .input-wrapper.invalid-field-wrapper .input-box .input-style1:not(:checked) + label {
              color: #f36e7f;
            }
            .input-wrapper.invalid-field-wrapper .input-box .input-style1:checked + label:before,
          .input-wrapper.invalid-field-wrapper .input-box .input-style1:not(:checked) + label:before {
            border: 2px solid #f36e7f;
          }
          .input-wrapper .input-box .input-style1:checked + label:before,
          .input-wrapper .input-box .input-style1:not(:checked) + label:before {
              border: 2px solid #e7e7e7;
              background-color: rgba(87, 87, 86, 0.05);
              border-radius: 7px;
          }
            .input-wrapper .input-box .input-style1:checked + label:before {
              border: 2px solid #00B33C;
              background-color: rgba(0, 179, 60, 0.05);
              border-radius: 7px;
          }
            .input-wrapper .input-box .input-style1:checked + label:after,
            .input-wrapper .input-box .input-style1:not(:checked) + label:after {
              border-radius: 7px;
              background: transparent;
              background-image: url(../img/icons/tick-green.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              width: 14px;
              height: 9px;
              left: 3px;
              top: 5px;
          }
            .btn-primary {
              width: 100%;
              justify-content: center;
              margin-top: 28px;
              padding: 23px 25px 18px;
              @media #{$vpMobile600} {
                padding: 13px 25px 11px;
                margin-top: 0;
              }
            }
          }
        }
      }
      .form--steps {
        flex: 0 0 4%;
        max-width: 4%;
        position: relative;
        @media #{$vpMobile600} {
          display: none;
        }
        .c-side-nav {
          .c-categoryBox {
            background: transparent;
            box-shadow: none;
            ul {
              li {
                background: $white;
                border-radius: 50%;
                width: 56px;
                height: 56px;
                margin-bottom: 126px;
                position: relative;
                &:before {
                  content: "";
                  position: absolute;
                  top: -115px;
                  width: 1px;
                  height: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  background: #A82692;
                  transition: all 0.35s ease;
                }
                &:after {
                  content: "";
                  position: absolute;
                  top: -115px;
                  width: 1px;
                  height: 102px;
                  background-color: #121210;
                  left: 50%;
                  transform: translateX(-50%);
                  background: #575756;
                  opacity: 0.3;
                }
                &:first-child {
                  &:after,
                  &:before {
                    display: none;
                  }
                }
                &:last-child {
                  margin-bottom: 0;
                }
                a {
                  padding: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                  font-size: 28px;
                  font-weight: 700;
                  color: #A82692;
                  line-height: 1;
                  &:hover {
                    background: transparent;
                  }
                  &::before {
                    display: none;
                  }
                }
                &.active,
                &:hover {
                  background-color: #A82692;
                  &:before {
                    height: 102px;
                  }
                  a {
                    background: transparent;
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }
      }
    }
    .btn-secondary {
      &.sty2 {
        width: 100%;
        justify-content: center;
        padding: 23px 25px 18px;
          @media #{$vpMobile600} {
          padding: 11px 25px 7px;
          }
        &:after,
        &:before {
          display: none;
        }
        svg {
          width: 20px;
          height: 20px;
          margin: 0 10px 0 0;
          path {
            fill: #A82692;
            stroke: #A82692;
          }
        }
        &:hover {
          background: #A82692;
          color: #ffffff;
          svg {
            transform: none;
            animation: none;
            path {
              fill: #ffffff;
              stroke: #ffffff;
            }
          }
        }
      }
    }
    .form-group .select2-box.select2-box--style1 .select2-container .select2-selection--single .select2-selection__rendered span {
      display: flex;
      align-items: center;
      line-height: 1;
      flex: 0 0 100%;
      max-width: 100%;
        @media #{$vpMobile600} {
        font-size: 14px;
        }
      img {
        margin-right: 16px;
      }
    }
    &.book--signing {
      .form--wrapper {
        padding-top: 50px;
        .form--box {
          .form--fields {
            @media #{$vpMobile600} {
              
              .form--title {
                margin-bottom: 20px;
              }
            }
            .book--form {
              .bookFormWrapper {
                @media #{$vpMobile600} {
                  padding: 20px 0;
                }
              }
            }
            .termsBar {
              .btn-primary {
                @media #{$vpMobile600} {
                  margin-top: 0;
                }
              }
            }
            .form-group {
              .input-wrapper {
                .input-box {
                  label {
                    @media #{$vpMobile600} {
                      height: auto;
                      margin-top: 8px;
                      font-size: 14px;
                      font-weight: 400;  
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .select2-container .select2-dropdown {
    border: 1px solid #cccccc;
  }
  .select2-container .select2-results__option[aria-selected=false], .select2-container .select2-results__option[aria-selected] {
    padding: 14px 20px;
  }
  .select2-container .select2-results__option[aria-selected=false]:after, .select2-container .select2-results__option[aria-selected]:after,
  .select2-container.select2-container--open .select2-dropdown--below:before {
    display: none;
  }
  .formBox {
    margin-top: 20px;
    h5,
    .heading5 {
      color: #1D1D1B;
      @media #{$vpMobile600} {
        font-size: 24px;
        font-weight: 500;
      }
    }
    .selectDay {
      color: #1D1D1B;
    }
    .selectDay {
      margin-bottom: 32px;
      @media #{$vpMobile600} {
        margin-bottom: 15px;
      }
    }
    .select2-container {
      width: 100% !important;
    }
  }
  .closedForm {
    .formBox {
      // display: none;
      visibility: hidden;
      opacity: 0;
      height: 0;
      margin: 0;
    }
    // &:last-child {
    //   .formBox {
    //     visibility: visible;
    //     opacity: 1;
    //     height: 100%;
    //     margin-top: 20px;
    //   }
    // }
  }
  .book--content {
    margin-left: 140px;
    padding: 70px 0 125px;
    max-width: 1328px;
    @media (max-width: 1440px) {
      padding: 40px 0 100px;
    }
    @media (max-width: 991px) {
      padding: 15px 0 198px;
      margin-left: 0;
    }
    .heading4 {
      color: #1D1D1B;
    }
      .postBox {
        margin-top: 40px;
          @media #{$vpMobile600} {
          margin-top: 10px;
          }
        .sec-content {
          margin-bottom: 40px;
          .heading5 {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            margin: 0;
            letter-spacing: -0.02em;
            margin-bottom: 12px;
            color: #1D1D1B;
            @media #{$vpMobile600} {
              font-size: 16px;
              line-height: 24px;
            }
          }
          p {
            margin: 0;
          }
          ul {
            li {
              list-style-type: disc;
            }
          }
          .content-listing {
            margin: 32px 0;
          }
        }
      }
      &.thankyou {
        max-width: 780px;
        margin: 75px auto;
        padding: 48px 72px;
        border: 1px solid rgba($color: #575756, $alpha: 0.16);
        text-align: center;
        border-radius: 5px;
        @media #{$vpMobile600} {
          padding: 10px;
          margin: 20px auto 65px;
        }
        .thankyou-icon {
          margin-bottom: 35px;
          @media #{$vpMobile600} {
            margin-bottom: 25px;
          }
          svg {
            width: 72px;
            height: 72px;
            @media #{$vpMobile600} {
              width: 56px;
              height: 56px;
            }
          }
        }
        .sec-content {
          .heading4 {
          }
          p {
            font-size: 24px;
            color: #575756;
            padding: 15px 0;
            margin: 0;
            font-weight: 400;
            @media #{$vpMobile600} {
              font-size: 16px;
            }
            &:first-child {
              padding-top: 0;
            }
            &:last-child {
              padding-bottom: 0;
            }
            span {
              color: #00A3E0;
              font-weight: 700;
            }
          }
        }
      }
    }
    .form-group .iti.iti--allow-dropdown.iti--separate-dial-code .iti__selected-dial-code {
      @media #{$vpMobile600} {
        font-size: 14px;
      }
    }
    .bookFormWrapper {
      &:only-child {
        .book-num {
          .form-edit--buttons {
            .recycle,
            .btn-edit {
              display: none;
            }
          }
        }
      }
    }
  // sor end
  input,
  textarea {
  position: relative;
  &::placeholder {
    line-height: 1;
  }
  }
  .bookDetail--sec {
  &.limitExceed {
    .addForm--btn {
      opacity: 0.5;
      pointer-events: none;
    }
    .limit--error {
      display: block;
      color: #f36e7f;
    }
  }
  }
  .limit--error {
  display: none;
  }
  #st-el-2 .st-btn {
  margin: 14px;
  }
  .sec-banner.overlay-hidden {
  padding-top: 0;
  }
  .sec-tickeConfirm {
  background: #f2f2f2;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: -133px;
    background: url(../img/bg/book-ticket-bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    width: 395px;
    height: 432px;
    z-index: 1;
    display: none;
    @media (max-width: 991px) {
      width: 295px;
      height: 322px;
      bottom: -153px;
    }
    @media #{$vpMobile600} {
      width: 195px;
      height: 214px;
      bottom: -130px;
    }
  }
  .postBox {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    @media (max-width: 1199px) {
      flex-direction: column;
    }
    .postContent {
      flex: 0 0 40%;
      max-width: 40%;
      @media (max-width: 1199px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      p {
        font-size: 24px;
        font-weight: 500;
        @media (max-width: 1400px) {
          font-size: 18px;
        }
        @media #{$vpMobile600} {
          font-size: 16px;
          font-weight: 400;
        }
      }
      .post--btn {
        margin-top: 40px;
        a {
          margin-right: 8px;
          @media #{$vpMobile600} {
            margin-right: 4px;
          }
        } 
      }
    }
    .contentBoxes {
      display: flex;
      justify-content: space-between;
      flex: 0 0 50%;
      max-width: 50%;
      @media (max-width: 1650px) {
        flex: 0 0 55%;
        max-width: 55%;
      }
      @media (max-width: 1199px) {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 50px;
      }
      @media #{$vpMobile600} {
        flex-direction: column;
        margin-top: 30px;  
      }
      .detail-box {
        padding: 24px;
        background: #ffffff;
        flex: 0 0 47%;
        max-width: 47%;
        min-height: 344px;
        justify-content: space-between;
        height: 100%;
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(87, 87, 86, 0.12);
        border-radius: 4px;
        overflow: hidden;
        @media (max-width: 1400px) {
          flex: 0 0 49%;
          max-width: 49%;
        }
        @media #{$vpMobile600} {
          flex: 0 0 100%;
          max-width: 100%;
          min-height: 216px;
          margin: 8px 0;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .heading5 {
          color: #1D1D1B;
          margin: 0;
          @media #{$vpMobile600} {
            font-size: 24px;
            line-height: 1.3;
            font-weight: 500;
          }
        }
        .box-content {
          p {
            font-size: 14px;
            @media #{$vpMobile600} {
              margin-bottom: 0;
              margin-top: 20px;
            }
          }
          .app-logo {
            display: flex;
            margin-top: 18px;
            a {
              margin-right: 16px;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
  }
  @media #{$vpMobile600} {
    .sec-padded {
      padding: 20px 0 10px;
    }
  }
  &.sty2 {
    .ticket--wrapper {
      display: flex;
      justify-content: space-between;
      .postBox {
        flex-direction: column;
        flex: 0 0 48%;
        max-width: 48%;
        @media (max-width: 992px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .postContent {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .contentBoxes {
          flex: 0 0 100%;
          max-width: 100%;
          margin-top: 50px;
          @media (max-width: 991px) {
            margin-top: 30px;
            margin-bottom: 30px;
          }
        }
      }
      .generatedTicket {
        flex: 0 0 48%;
        max-width: 48%;
        z-index: 2;
        @media (max-width: 992px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .btn-primary {
          margin-top: 30px;
          &.downloadTop {
            margin-bottom: 30px;
            margin-top: 0;
            @media (max-width:992px) {
              margin-top: 30px;
            }
          }
        }
        @media #{$vpMobile600} {
          .logoBox {
            padding-top: 20px;
          }
          .block {
            width: 100%;
            display: block;
          }
          .hidden {
            display: none;
          }
        }
        
      }
    }
  }
  }

  .img-text {
  &.showText {
    .nofile {
      display: block;
    }
    span {
      display: none;
    }
  }
  .nofile {
    display: none;
  }
  span {
    display: block;
  }
  }
  .iti__flag-container {
    top: -1px;
  }

  .tabs-form-modifier {
    // padding-left: 5rem;
    position: relative;
    &::before {
      content: "";
      height: calc(100% - 2.5rem);
      width: 1px;
      background-color: #E5E0D0;
      position: absolute;
      top: 1.25rem;
      left: 1.25rem;
      counter-reset: section;
      @media #{$vpMobile600} {
        width: calc(100% - 5.5rem);
        height: 1px;
        left: 2rem;
        top: 50%;
      }
    }
    .tab-wrap {
      display: flex;
      position: relative;
      z-index: 5;
      padding: 0.5rem 0;
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 0;
        background-color: #9f8332;
        top: 1.25rem;
        left: 1.25rem;
        display: none;
      }
      // &:first-child {
      //   &::after {
      //     height: calc(100% - 0.625rem);
      //     top: 1.25rem;
      //   }
      // }
      .nav-integar {
        position: relative;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border: .063rem solid #9f8332;
        background-color: #f9f7f3;
        margin-right: 1.625rem;
        z-index: 6;
        box-shadow: 0px 0px 0px 10px rgba(229,224,208,0.2);
        @media #{$vpMobile600} {
          width: 2rem;
          height: 2rem;
          box-shadow: 0px 0px 0px 8px rgba(229,224,208,0.2);
        }
         &::before {
            content: "";
            position: absolute;
            height: 3.75rem;
            width: 3.75rem;
            background-color: transparent;
            border: 1px solid #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            @media #{$vpMobile600} {
              width: 3rem;
              height: 3rem;
            }
            }
      }
      .nav-btn-main {
        position: relative;
      }
      &.active {
        .nav-integar {
          background-color: #9f8332;
          border: 0;
          color: $white;
          // &::after {
          //   content: "";
          //     height: calc(100% - 1.75rem);
          //     background-color: #9F8332;
          //   }
        }
        .nav-btn-main {
          background-color: #e5e0d0;
          &::after {
            display: block;
          }
        }
        &::after {
          display: block;
          height: 60%;
        @media #{$vpMobile600} {
            display: block;
            width: 80%;
            z-index: 0;
            left: 0;
            height: 1px;
            top: 50%;
          }
        }
      }
      &.activated {
        .nav-integar {
          background-color: #9f8332;
          color: $white;
          // &::after {
          //   content: "";
          //     height: calc(100% - 20px);
          //     background-color: #9F8332;
          //   }
        }
        .nav-btn-main {
          background-color: #e5e0d0;
        }
        &::after {
          display: block;
          height: 100%;
          @media #{$vpMobile600} {
            display: block;
              width: 100%;
              height: 1px;
              top: 50%;
            }
          }
      }
      &.disabled {
        pointer-events: none;
        // .nav-btn-main {
        //   &:first-child {
        //     border: 1px solid #9F8332;
        //   }
        // }
      }
    }
  }