.sec-instruments {
  &:has(.highlight) {
    overflow: visible;
  }
  .img-txtBox-section {
    display: flex;
    justify-content: space-between;
    background-color: $baselineSecondaryLight;
    border-radius: 2rem;
  
    @media #{$vpMobile600} {
      flex-direction: column;
      border-radius: 1rem 1rem 0rem 0rem;
      height: 100%;
    }
  
    .content-wrapper {
      flex: 0 0 55%;
      max-width: 55%;
      padding: 4rem 0 4rem 4rem;
      display: flex;
      flex-direction: column;
  
  
      @media #{$vpTablet1229} {
        padding: 3.75rem 0 3.75rem 3.75rem;
      }
  
      @media #{$vpTablet991} {
        flex: 0 0 45rem;
        max-width: 45rem;
        padding: 2rem 0 2rem 2rem;
      }
  
      @media #{$vpMobile600} {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 2.5rem 1.25rem 2.5rem 1.25rem;
      }
  
      .heading2 {
        margin: 0;
        color: $baselineSecondary;
      }
  
      .body1 {
        padding-top: 0;
        margin: 0;
        color: $baselineSecondary;
        padding: 2rem 0;
  
        @media #{$vpMobile600} {
          padding: 1.5rem 0;
          font-size: 1.125rem;
        }
      }
  
      .btn-wraper {
        display: flex;
        padding-top: 2rem;
        .main-primary-btn {
          margin-right: 1.5rem;
        }
        .main-secondary-btn {
          margin-top: 0;
        }
        .dropdown {
          .dropdown-content-wrapper {
            padding-top: .5rem;
            position: absolute;
            width: 12.125rem;
            left: 1.5rem;
            visibility: hidden;
            &.dropdown-space-visible {
              visibility: visible;
            }
            .dropdown-content {
              position: absolute;
              width: 12.125rem;
              border: transparent;
              border-radius: 0.5rem;
              background-color: #f9f7f3;
              // margin-top: 0.5rem;
              transition: all 0.35s ease;
              z-index: 2;
              visibility: hidden;
              opacity: 0;
              height: 0;
              overflow: hidden;
              &.highlight {
                visibility: visible;
                opacity: 1;
                height: auto;
                border: 1px solid #e5e0d0;
                transition: all .35s ease-in-out;
              }
              .dropdown-title {
                color: $baselineSecondary;
                padding: 1rem;
              }
              a {
                color: $baselineSecondary;
                padding: 1rem;
                display: block;
                background-color: $white;
                &:hover {
                  background-color: $secondarySemiLight;
              }
              }
            }
          }
        }
        @media #{$vpMobile600} {
          flex-direction: column;
          padding-top: 0;
        }
        .main-primary-btn {
          @media #{$vpMobile600} {
            margin-bottom: 0;
            width: 100%;
            display: flex;
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
            justify-content: center;
          }
        }
        .with-ic {
          margin-right: 1.5rem;
          @media #{$vpMobile600} {
            margin-top: 1rem;
            width: 100%;
            display: flex;
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
            justify-content: center;
          }
          &.show {
            display: block;
          }
  
          @media #{$vpMobile600} {
            margin-left: 0;
          }
  
          &.no-margin {
            margin-left: 0;
  
            @media #{$vpMobile600} {
              height: 3rem;
              display: flex;
            }
  
            .btn-text {
              @media #{$vpMobile600} {
                font-size: 0.875rem;
              }
            }
          }
        }
      }
    }
  
    .img-box {
      flex: 0 0 33%;
      max-width: 33%;
      overflow: hidden;
      border-top-right-radius: 1rem;
      border-bottom-right-radius: 1rem;
      @media #{$vpTablet991} {
        flex: 0 0 20rem;
        max-width: 20rem;
      }
      @media #{$vpMobile600} {
        border-radius: 0 0 1rem 1rem;
      }
  
      img {
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        object-fit: cover;
  
        @media #{$vpMobile600} {
          border-radius: 0 0 1rem 1rem;
        }
      }
  
      @media #{$vpMobile600} {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
  &.dark-bg {
    .img-txtBox-section {
      background-color: rgba($color: #fff, $alpha: 0.1);
      .content-wrapper {
        .heading2 {
          color: $white;
        }
        .body1 {
          color: $white;
        }
      }
    }
  }
  &.gray-bg {
    .img-txtBox-section {
      background-color: $white;
      .btn-wraper {
        .main-primary-btn {
          border: .063rem solid #9f8332;;
        }
      }
    }
  }
}
