.portfolio-sec {
  .inner-pages-banner {
    padding-top: 4rem !important;

    .content-wrapper {
      padding-bottom: 8.75rem;
      margin-bottom: 0 !important;
      .para-sec {
        max-width: 33%;
        flex: 0 0 33%;

        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      @media #{$vpMobile600} {
        padding: 0 0 2rem 0;
      }
    }

    @media #{$vpMobile600} {
      padding-top: 5rem;
    }
  }
}
.content-banner{
  .inner-pages-banner {
    padding-top: 7.5rem;
    
    .content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 4rem;
      .heading1 {
        flex: 0 0 58.5%;
        max-width: 58.5%;
        margin: 0;
        @media #{$vpTablet991} {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @media #{$vpMobile600} {
          flex: 0 0 100%;
          max-width: 100%;
          align-items: flex-start;
          font-size: 2.5rem;
        }
      }
      .body3 {
        max-width: 34.188rem;
        width: 100%;
      }
      .para-sec {
        flex: 0 0 33%;
        max-width: 33%;
        @media #{$vpTablet991} {
          flex: 0 0 45%;
          max-width: 45%;
        }
        @media #{$vpMobile600} {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .body3 {
          margin: 0;
        }
  
        @media #{$vpMobile600} {
          width: 100%;
          padding-top: 2rem;
        }
      }
  
      @media #{$vpMobile600} {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 2rem;
      }
    }
    &.detailVariant {
      .inner-pages-banner .content-wrapper {
        align-items: flex-end;
      }
    }
  
    .img-wrapper {
      overflow: hidden;
      border-radius: 2rem 2rem 0 0;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2rem 2rem 0 0;
      }
    }
  
    @media #{$vpMobile600} {
      padding-top: 2rem;
    }
  }
  &.detailVariant{
    padding-top: 7.5rem;
    @media #{$vpMobile600} {
      padding-top: 5rem;
    }
    .inner-pages-banner
    .img-wrapper{
      height: 33.75rem;
      @media #{$vpMobile600} {
        height: 29.25rem;
      }
    }
  }
}

.banner-without-img {
  .inner-pages-banner {
    padding-top: 4rem;
    padding-bottom: 8.75rem;

    @media #{$vpMobile600} {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }
}

.news-detail-banner {
  color: $baselineSecondary;

  .content-wrapper {
    flex-direction: column;
    @media #{$vpMobile600} {
      padding-top: 0;
    }

    .heading2 {
      padding-bottom: 2rem;
      border-bottom: 0.063rem solid $secondarySemiLight;
    }

    .meta-content {
      padding: 2rem 0 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .date-tag-wrapper {
        display: flex;
        align-items: center;
        gap: 2rem;

        .title {
          p {
            font-size: 0.75rem;
            line-height: 1.4;
            font-weight: 700;
            color: $neutralGray400;
            margin: 0;
          }
        }

        .desc {
          p {
            margin: 0;
            padding-top: 0.5rem;
            color: $neutralGray400;

            @media #{$vpMobile600} {
              font-size: 0.875rem;
              line-height: 1.4;
            }
          }
        }
      }

      .button-wrapper {
        button {
          border: 0.063rem solid $secondarySimiLight;

          span:last-child {
            @media #{$vpMobile600} {
              padding-left: 0;
            }
          }

          img {
            width: 1.125rem;
            height: 1.125rem;
          }

          @media #{$vpMobile600} {
            border: 0.063rem solid $secondarySemiLight;
            border-radius: 100%;
            width: 3.475rem;
            height: 3.475rem;
            align-items: center;
            justify-content: center;
          }

          .btn-text {
            @media #{$vpMobile600} {
              display: none;
            }
          }
        }
      }
    }
  }

  &.financial-times-banner {
    .content-wrapper {
      @media #{$vpMobile600} {
        margin-bottom: 2rem !important;
      }
      .heading2 {
        width: 100%;
        margin: 0;

        @media #{$vpMobile600} {
          font-size: 1.75rem;
          line-height: 1.3;
        }
      }
    }
    &.dark-bg {
      .content-wrapper {
        .heading2 {
          color: $white;
        }
        .meta-content {
          .date-tag-wrapper {
            .title {
              p {
                color: $white;
              }
            }
            .desc {
              p {
                color: $white;
              }
            }
            .desc {
              p {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

.archaelogy-research-banner {
  .content-wrapper {
    .heading1 {
      width: 100%;
    }
  }
}
