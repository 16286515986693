.editorial-4 {

  &.discover-alula {
    background-color: transparent;
  }
  
  &.plan-trip {
    .box-wrapper {
      flex-direction: row-reverse;

      @media #{$vpMobile600} {
        flex-direction: column;
      }
    }
  }

  &.filming-in-alula {
    .box-wrapper {
      flex-direction: row-reverse;

      @media #{$vpMobile600} {
        flex-direction: column;
      }
    }
  }

  &.italian-partnership {
    .box-wrapper {
      flex-direction: row-reverse;

      @media #{$vpMobile600} {
        flex-direction: column;
      }
    }
  }

  &.ready-to-apply {
    .box-wrapper {
      flex-direction: row-reverse;

      @media #{$vpMobile600} {
        flex-direction: column;
      }
    }
  }

  &.story-of-alula {
    .box-wrapper {
      flex-direction: row-reverse;

      @media #{$vpMobile600} {
        flex-direction: column;
      }
    }
  }

  &.executive-summary {
    background-color: transparent;

    .box-wrapper {
      flex-direction: row-reverse;

      @media #{$vpMobile600} {
        flex-direction: column;
      }

      .col-right {
        .main-secondary-btn {
          margin-top: 3rem;
          display: block;
          @media #{$vpMobile600} {
            margin-top: 3rem;
            width: 100%;
            padding: 0.88rem 1.5rem;
            height: 3rem;
            display: block;
          }
        }

        .box-heading {
          .wrapper-heading {
            .heading2 {
              @media #{$vpMobile600} {
                margin: 0;
              }
            }
          }
        }

        .content-para {
          .content-box {
            @media #{$vpMobile600} {
              margin-bottom: 0;
            }

            p {
              @media #{$vpMobile600} {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  .box-wrapper {
    display: flex;
    justify-content: space-between;

    .underline-title {
      color: $baselinePrimary;
    }

    .col-left {
      flex: 0 0 33%;
      max-width: 33%;
      overflow: hidden;
      border-radius: 1rem;
      @media #{$vpMobile600} {
        flex: 0 0 100%;
        max-width: 100%;
      }
      img {
        border-radius: 1rem;
        width: 100%;

        @media #{$vpMobile600} {
          border-radius: 0.5rem;
        }
      }

      @media #{$vpMobile600} {
        width: 100%;
        border-radius: 0.5rem;
      }
    }

    .col-right {
      flex: 0 0 50%;
      max-width: 50%;
      @media #{$vpDesktop1920} {
        max-width: 49.5%;
        flex: 0 0 49.5%;
      }
      @media #{$vpMobile600} {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 2rem;
      }

      .box-heading {
        display: flex;
        flex-direction: column;
        height: 100%;

        @media #{$vpTablet991} {
          margin-left: 2rem;
        }

        @media #{$vpMobile600} {
          margin-left: 0;
        }

        .wrapper-heading {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          margin-bottom: 1rem;

          @media #{$vpMobile600} {
            gap: 1rem;
          }

          .heading2 {
            margin-top: 1rem;
            margin-bottom: 0;
          }
        }
        .content-para {
          margin-top: 1rem;
        }
      }

      .content-para {
        .content-box {
          display: flex;
          flex-direction: column;
          gap: 2.063rem;
          @media #{$vpTablet991} {
            padding-right: 2rem;
          }
          @media #{$vpMobile600} {
            padding-right: 0;
          }

          .body1 {
            color: $baselineSecondary;

            @media #{$vpMobile600} {
              font-size: 1.125rem;
            }
          }

          p {
            margin: 0;
          }

          a {
            width: fit-content;
            border: 1px solid #9f8332;
            @media #{$vpMobile600} {
              width: 100%;
            }
          }
        }

        @media #{$vpMobile600} {
          margin-top: 1.6rem;
        }
      }

      .action-btn-wrapper {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        gap: 20px;

        @media #{$vpMobile600} {
          flex-direction: column;

          .main-primary-btn,
          .main-secondary-btn {
            padding: 12px;
          }
        }
      }
    }
  }

  @media #{$vpMobile600} {
    .box-wrapper {
      flex-direction: column;
    }
  }

  &.modifier-flip {
    background-color: $white;

    .box-wrapper {
      flex-direction: row-reverse;

      @media #{$vpMobile600} {
        flex-direction: column;
      }

      .col-left {
        display: flex;
        align-items: center;
        justify-content: end;
      }
    }
  }
  &.dark-bg {
    .box-wrapper {
      .col-right {
        .content-para {
          .content-box {
            .body1 {
              color: $white;
            }
          }
        }
      }
    }
  }
  &.no-bg {
    .box-wrapper {
      .col-right {
        .content-para {
          .content-box {
            .main-primary-btn {
              border: .063rem solid #9f8332;
              @media #{$vpMobile600} {
                width: 100%;
                max-width: 100%;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}