.editorial-list {
  &.featured-programs-list{
    background-color: transparent;
  }
  .wrapper {
    .main-secondary-btn {
      &.with-ic {
        margin: 0 auto;
        margin-top: 2.5rem;

        img {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  .top-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 2.5rem;
    gap: 3rem;
    border-bottom: 0.063rem solid $secondarySemiLight;
    .heading-wrapper {
      flex: 0 0 42.5%;
      max-width: 42.5%;
      @media #{$vpMobile600} {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .para-div{
      flex: 0 0 50%;
      max-width: 50%;

      @media #{$vpMobile600} {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    @media #{$vpMobile600} {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 3rem;
      gap: 1rem;
    }

    .heading2 {
       max-width: 40rem;
      width: 100%;
      @media #{$vpMobile600} {
        padding-top: 1rem;
      }
    }

    .body2 {

      @media #{$vpMobile600} {
        width: 100%;
      }
    }
  }

  .bottom-content {
    display: flex;
    flex-direction: column;

    .horizontal-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2.5rem 0;
      border-bottom: 0.063rem solid $secondarySemiLight;

      &.video-card {
        align-items: center;
      }

      @media #{$vpMobile600} {
        flex-direction: column;
        padding: 2rem 0;
        gap: 2.5rem;
      }

      .image-div {
        display: flex;
        align-items: flex-start;
        gap: 2rem;
        justify-content: space-between;
        flex: 0 0 41.3712%;
        max-width: 41.3712%;
        @media #{$vpTablet991} {
          flex: 0 0 50%;
        max-width: 50%;
        }
        @media #{$vpMobile600} {
          flex: 0 0 100%;
          max-width: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 1rem;
          transition: all 0.4s ease-out;

          @media #{$vpMobile600} {
            width: 10rem;
            height: auto;
          }
        }

        .vjs-control-bar {
          display: none;
        }

        video,
        .video-js {
          width: 43rem;
          height: 25rem;
          border-radius: 1rem;
          transition: all 0.4s ease-out;
          object-fit: cover;
          @media #{$vpTablet991} {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
          }
          @media #{$vpMobile600} {
            width: 10rem;
            height: 5.8125rem;
          }
        }

        .body3 {
          display: none;

          @media #{$vpMobile600} {
            display: block;
            font-size: 1.25rem;
            width: 10.625rem;
          }
        }
      }

      .heading3 {
        transition: all 0.4s ease-out;
        @media #{$vpMobile600} {
          display: none;
        }
      }

      .main-secondary-btn {
        @media #{$vpMobile600} {
          display: none;
        }
      }

      &:hover {
        .card-content button {
          opacity: 1;
          pointer-events: all;
          transition: all 0.4s ease-out;
          width: fit-content;
        }

        img {
          transform: scale(1.1);
          transition: all 0.4s ease-out;

          @media #{$vpMobile600} {
            width: 10rem;
            height: auto;
            transform: scale(1);
          }
        }

        video,
        .video.js {
          width: 51.875rem;
          transition: all 0.4s ease-out;

          @media #{$vpMobile600} {
            width: 100%;
            height: 5.8125rem;
          }
        }
      }

      .card-content {
        display: flex;
        flex: 0 0 41.3712%;
        max-width: 41.3712%;
        flex-direction: column;
        gap: 2rem;
        @media #{$vpTablet991} {
          flex: 0 0 35%;
          max-width: 35%;
        }
        @media #{$vpMobile600} {
          justify-content: space-between;
          align-items: center;
          flex: 0 0 100%;
        max-width: 100%;
          flex-direction: row;
          gap: 2.5rem;
          .main-secondary-btn {
            display: none;
          }
        }

        .body1 {
          color: $baselineSecondary;
          @media #{$vpMobile600} {
            font-size: 0.875rem;
            // width: 16.375rem;
            max-width: 75%;
            flex: 0 0 75%;
          }
        }

        .arrow-icon {
          display: none;
          width: 3rem;
          height: 3rem;
          border: 0.063rem solid $baselinePrimary;
          align-items: center;
          justify-content: center;
          border-radius: 12.5rem;

          // .right-arrow {
          //   path {
          //     fill: $white;
          //   }
          // }

          img {
            width: 1rem;
            height: 1rem;
          }

          @media #{$vpMobile600} {
            display: flex;
          }
        }

        button {
          opacity: 0;
          transition: all 0.4s ease-out;
          pointer-events: none;
          width: fit-content;
        }
      }
    }
  }
  &.dark-bg {
    .bottom-content {
      .horizontal-card {
        .card-content {
          p {
            color: $white;
          }
        }
      }
    }
  }
}

body{
  &.darkTheme{
    .districts-by-2035{
      .top-content{
        align-items: flex-start;
      }
      .bottom-content{
        .horizontal-card{
          .image-div{
            .video-js{
              @media #{$vpMobile600} {
               background-color: transparent;
              }
            }
          }
          .card-content{
            .body2{
              color: $text-white;
            }
          }
        }
      }
    }
  }
}
.darkTheme1 {
  .editorial-list {
    .top-content {
      align-items: flex-start;

      .body2{
        font-size: 1.125rem;
      }
    }
    .bottom-content {
      .horizontal-card {
        padding: 2.5rem 0;
        @media #{$vpMobile600} {
          padding: 1.5rem 0;
        }
        .card-content {
          p {
            color: $white;
          }
        }
      }
    }

    &.districts-by-2035 {
      &.sec-padded {
        padding-top: 14.8rem;

        @media #{$vpMobile600} {
          padding-top: 5rem;
        }
      }
    }
  }
}

.darkSec1{
  &.editorial-list {
    .top-content {
      align-items: flex-start;

      .body2{
        font-size: 1.125rem;
      }
    }
    .bottom-content {
      .horizontal-card {
        padding: 2.5rem 0;
        @media #{$vpMobile600} {
          padding: 1.5rem 0;
        }
        .card-content {
          p {
            color: $white;
          }
        }
      }
    }

    
  }
}
