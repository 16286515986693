.sec-highlights {
  padding: 5.59rem 0;

  @media #{$vpMobile600} {
    padding: 0;
  }

  .highlights-wrapper {
    display: flex;
    flex-wrap: wrap;

    .highlight-box {
      width: 25%;
      height: 56.3125rem;
      position: relative;
      overflow: hidden;
      transition: all 0.4s ease-out;

      @media #{$vpMobile600} {
        width: 100%;
        height: 13.1875rem;
      }

      &::before {
        content: "";
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.6),
            rgba(0, 0, 0, 0.6)
          ),
          linear-gradient(180deg, transparent 61.72%, rgba(0, 0, 0, 0.6));
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 1;
        pointer-events: none;
        transition: all 0.4s ease-out;
      }

      img {
        height: 100%;
        object-fit: cover;

        @media #{$vpMobile600} {
          width: 100%;
        }
      }

      .highlight-content {
        position: absolute;
        bottom: 0;
        z-index: 2;
        padding: 4rem 2rem;
        pointer-events: none;

        @media #{$vpMobile600} {
          pointer-events: all;
        }

        .heading-wrapper {
          .heading3 {
            margin: 0;
            width: 26rem;
            color: $white;
            @media #{$vpTablet991} {
             width: 100%;
            }
            @media #{$vpMobile600} {
              width: 17.875rem;
            }
          }
          @media #{$vpMobile600} {
            width: 17.875rem;
          }
        }

        @media #{$vpMobile600} {
          padding: 7.4375rem 1.25rem 1.25rem 1.25rem;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          width: 100%;
        }

        .body1 {
          opacity: 0;
          height: 0;
          transition: all 0.4s ease-out;
          margin: 0;
          padding-top: 1rem;
        }

        .icons-wrapper {
          display: none;

          @media #{$vpMobile600} {
            display: block;
            border: 0.063rem solid $baselinePrimary;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3rem;
            height: 3rem;
            border-radius: 5rem;
            cursor: pointer;
          }

          img {
            @media #{$vpMobile600} {
              width: 1rem;
              height: 1rem;
              transition: all 0.4s ease-out;
            }
          }
        }
      }

      &:hover {
        .highlight-content {
          .body1 {
            opacity: 1;
            height: auto;
            transition: all 0.4s ease-out;

            @media #{$vpMobile600} {
              opacity: 0;
              height: 0;
            }
          }
        }
      }

      &.expand {
         height: 17.375rem;
          transition: all 0.4s ease-out;
          .highlight-content {
            .body1 {
              opacity: 1;
              height: auto;
              transition: all 0.4s ease-out;
            }
          }
          .icons-wrapper {
            img {
              transform: rotate(180deg);
              transition: all 0.4s ease-out;
           }
        }
      }
      &.contract{
        height: 13.1875rem;
        transition: all 0.4s ease-out;
      }
    }
  }
}

body{
  &.darkTheme {
    .sec-highlights{
      @media #{$vpMobile600} {
        padding: 2rem 0 0;
      }
    }
  }
}
