.media-gallery {
  .sec-pagination {
    padding-top: 2.5rem;
    padding-bottom: 5rem;

    @media #{$vpMobile600} {
      padding-top: 1rem;
      padding-bottom: 2.5rem;
    }
  }
}

.sec-pagination {
  padding: 2.5rem 0 5rem 0;

  @media #{$vpMobile600} {
    padding: 1.25rem 0;
  }

  .pagination-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .pagination-box {
      display: flex;
      align-items: center;
      margin: 0 auto;
      gap: 0.75rem;

      .custom-arrows {
        width: 3.438rem;
        height: 3.438rem;
        background-color: $white;
        color: $white;
        text-align: center;
        line-height: 3.125rem;
        cursor: pointer;
        border: 0.063rem solid $baselinePrimary;
        border-radius: 50%;
        z-index: 1;
        position: relative;

        &.next {
          right: 0;

        }

        &.prev {
          left: 0;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 1rem;
          height: 1rem;
          /*rtl:raw:transform: translate(50%, -50%) rotate(180deg); */
        }
      }

      li {
        color: #181818;
        line-height: 1.4;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        line-height: normal;
        font-size: 1.18181rem;
        padding-left: 0;
        &::before {
          display: none;
        }
        &.active,
        &:hover,
        &:focus {
          background-color: $baselineSecondaryLight;

          @media #{$vpMobile600} {
            width: 3rem;
            height: 3rem;
          }
        }
      }
    }
  }
}