.sec-editorial-carousel {

  .container--custom {
    @media #{$vpMobile600} {
      padding-left: 0;
    }

    .wrapper-box {
      display: flex;
      align-content: center;
      justify-content: space-between;

      @media #{$vpMobile600} {
        flex-direction: column;
      }

      .left-col {
        width: 51.875rem;
        margin-right: 6.25rem;
        display: flex;
        flex-direction: column;

        @media #{$vpMobile600} {
          width: 100%;
          padding-right: 0;
        }

        .heading-box {
          padding-right: 6.25rem;

          @media #{$vpMobile600} {
            padding: 0 1.25rem;
          }

          .wrapper-heading {
            .heading2 {
              margin-bottom: 1.5rem;
              line-height: normal;
            }
          }
        }

        .action {
          display: flex;
          flex-direction: column;
          gap: 0.75rem;
          margin-top: 2rem;
          @media #{$vpMobile600} {
            flex-direction: row;
            overflow: scroll;
            padding: 0 1.25rem 0.625rem;
            margin-top: 2.5rem;

            &::-webkit-scrollbar {
              display: none;
            }
          }

          .main-secondary-btn {
            border-radius: 0.25rem;
            max-width: fit-content;
            padding: 0.875rem 1.5rem;
            line-height: normal;
            border-color: $secondarySimiLight;
            height: 2.75rem;
            display: flex;
            align-items: center;

            @media #{$vpMobile600} {
              flex: 0 0 auto;
              height: 2.75rem;
              padding: 0.75rem 1rem;
            }

            &:hover {
              background-color: $secondarySimiLight;
            }

            &.active {
              background-color: $secondarySimiLight !important;
              display: flex;
              align-items: center;
              gap: 0.75rem;

              &:after {
                content: "";
                width: 0.5rem;
                height: 0.5rem;
                display: block;
                border-radius: 3.125rem;
                background-color: $baselinePrimary;
                transition: all 0.4s ease;
              }
            }
          }
        }
      }

      .right-col {
        width: 50.5rem;

        @media #{$vpMobile600} {
          width: 100%;
          padding-left: 0;
        }

        .slider-wrapper {
          position: relative;

          .natuer-wildlife-main {
            width: 24.375rem;
            height: 30rem;
            position: absolute;
            left: -9.375rem;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;

            @media #{$vpMobile600} {
              top: -10.25rem;
              width: 21.875rem;
              left: 50%;
              transform: translateX(-50%);
            }

            .item-content {
              position: relative;
              overflow: hidden;

              &:before {
                content: "";
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                border-radius: 1rem;
                background-color: #000;
                opacity: 0.5;

                @media #{$vpMobile600} {
                  border-radius: 0.5rem;
                }
              }

              img {
                border-radius: 1rem;
                width: 100%;
                height: 30rem;

                @media #{$vpMobile600} {
                  border-radius: 0.5rem;
                  height: 27rem;
                }
              }

              .contnet-wrapper-main {
                position: absolute;
                padding: 1.5rem;
                top: 0;
                left: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;
                color: $white;
                height: 30rem;

                @media #{$vpMobile600} {
                  width: 100%;
                  height: 27rem;
                }

                .heading-wrapper {
                  text-align: center;
                }

                .content-wrapper {
                  text-align: center;

                  .body2 {
                    margin-bottom: 2rem;
                    line-height: 1.575rem;
                  }

                  .main-secondary-btn {
                    font-weight: 300;
                    width: 100%;
                    background-color: transparent;
                    color: $white;
                    font-size: 1rem;
                    line-height: 1.4rem;
                    display: block;
                    max-width: 100%;
                  }
                }
              }
            }
          }

          .natuer-wildlife-nav {
            max-width: 50.5rem;
            width: 100%;
            @media #{$vpTablet991} {
              max-width: 32rem;
            }
            @media #{$vpMobile600} {
              margin-top: 8.75rem;
            }

            .slick-list {
              padding: 0 !important;

              .item-content {
                picture {
                  img {
                    width: 100%;
                    height: 67.5rem;
                    object-fit: fill;

                    @media #{$vpMobile600} {
                      height: auto;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
