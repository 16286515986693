.e-services-listing {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
  @media #{$vpTablet991} {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  @media #{$vpMobile600} {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  &.e-service-artistic-listing {
      .wrapper-box {
        .heading-col {
          flex-direction: column;
          gap: 4rem;

          @media #{$vpMobile600} {
            gap: 2rem;
          }

          .left-col,
          .right-col {
            width: 100%;

            .simple {
              padding-top: 0;
            }
          }
        }

        .listing-box {
          margin-top: 4rem;

          @media #{$vpMobile600} {
            margin-top: 2rem;
          }

          .listing-wrapper {
            grid-template-columns: repeat(2, 1fr);
            gap: 1.5rem;

            @media #{$vpTablet991} {
              grid-template-columns: repeat(1, 1fr);
            }

            .list-item {
              flex-direction: row;
              gap: 2rem;
              height: 10.25rem;
              align-items: center;
              background-color: $white;
              border: 1px solid $secondarySemiLight;

              @media #{$vpMobile600} {
                height: 9.75rem;
                border-radius: 0.5rem;
              }

              .top-wrapper {
                width: 42.1rem;

                @media #{$vpMobile600} {
                  width: 13.6rem;
                }

                .heading5 {
                  min-height: auto;
                }
              }

              .bottom-btn {
                .custom-arrows {
                  width: 3.625rem;
                  height: 3.625rem;
                  background-color: $white;
                  color: $white;
                  border: 0.063rem solid $baselinePrimary;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  @media #{$vpMobile600} {
                    width: 2.75rem;
                    height: 2.75rem;
                  }

                  .arrow-icon {
                    width: 1rem;
                    height: 1rem;
                  }
                }
              }
            }
          }
        }
      }
  }

  .wrapper-box {
    .underline {
      padding-bottom: 1rem;

      @media #{$vpMobile600} {
        margin-bottom: 2rem;
        padding-bottom: 0;
        font-size: 0.875rem;
      }
    }

    .simple {
      @media #{$vpMobile600} {
        padding-top: 2rem;
        font-size: 0.875rem;
      }
    }

    .heading-col {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media #{$vpMobile600} {
        flex-direction: column;
      }

      .left-col {
        width: 50%;

        .heading2 {
          margin-bottom: 0;
        }

        @media #{$vpMobile600} {
          width: 100%;
        }
      }

      .right-col {
        width: 50%;

        @media #{$vpMobile600} {
          width: 100%;
        }
      }
    }

    .listing-box {
      position: relative;

      @media #{$vpMobile600} {
        margin-bottom: -1.25rem;
      }

      .filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4rem 0;
        @media #{$vpTablet991} {
          flex-direction: column;
        }
        @media #{$vpMobile600} {
          padding: 2.5rem 0 0;
        }

        .tabs-wrapper {
          display: flex;
          align-items: center;
          position: relative;
          max-width: 43rem;
          width: 100%;
          @media #{$vpTablet991} {
            width: 100%;
            max-width: 100%;
            margin-bottom: 1rem;
          }

          .slider-arrow-pagination {
            padding: 0;
            width: 100%;
            display: flex;
            width: 100%;
            position: absolute;

            @media #{$vpMobile600} {
              display: none;
            }


            .arrow-wrap {
              width: 100%;
              bottom: auto;
              top: 50%;
              transform: translateY(-50%);

              .slide-arrow {
                width: 100%;
                height: 0;

                .custom-arrows {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: $white;
                  width: 3rem;
                  height: 3rem;
                  &.prev {
                    margin-left: 0;
                    top: -1.5rem;
                  }

                  &.next {
                    margin-left: 0;
                    top: -1.5rem;
                  }

                  .icon-prev {
                    width: 1rem;
                    height: 1rem;
                    transform: rotate(180deg);
                  }

                  .icon-next {
                    width: 1rem;
                    height: 1rem;
                  }
                  &.slick-disabled {
                    opacity: 0;
                    visibility: hidden;
                  }
                }
              }
            }
          }
        }

        .right-filter {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 1.25rem;
          max-width: 43rem;

          @media #{$vpTablet991} {
            width: 100%;
            max-width: 100%;
            padding-left: 0;
            flex-direction: row;
            justify-content: flex-start;
            gap: 1.5rem;
          }

          .search-box {
            width: 30.5rem;
            position: relative;

            @media #{$vpMobile600} {
              width: 80%;
            }

            .btn-search {
              position: absolute;
              left: auto;
              top: 50%;
              right: 1.25rem;
              transform: translateY(-50%);
              border: 0;
              background-color: transparent;
              outline: 0;

              @media #{$vpMobile600} {
                right: 0.5rem;
              }

              .serch-icon {
                width: 1.5rem;
                height: 1.5rem;

                path {
                  fill: $baselineSecondary;
                }
              }
            }
          }

          .filter-view-type {
            width: 16.4375rem;
            display: flex;
            gap: 1.25rem;

            @media #{$vpMobile600} {
              width: 20%;
            }

            .sort-box {
              width: 16.438rem;
              background: $white;
              border: 0.063rem solid $secondarySimiLight;
              padding: 1.5rem 1rem;
              border-radius: 0.5rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;


              .body3 {
                font-size: 1.125rem;
                color: $neutralGray400;
                margin: 0;
                @media #{$vpMobile600} {
                  display: none;
                }
              }

              @media #{$vpMobile600} {
                width: 13.813rem;
                height: 3.438rem;
                justify-content: center;
              }

              &.icon-rotate {
                .sort-icon {
                  transform: rotateX(180deg);
                  transition: all 0.4s ease-in;
                }
              }

              img {
                width: 1.5rem;
                height: 1.5rem;
                transition: all 0.4s ease-in;
              }
            }
          }

          .input-field {
            background: $white;
            border: 0.063rem solid $secondarySimiLight;
            padding: 1.5rem 4rem 1.5rem 1rem;
            border-radius: 0.5rem;
            width: 100%;
            font-size: 1.125rem;
            color: $neutralGray400;
            font-weight: 300;
            line-height: 1.4;

            @media #{$vpMobile600} {
              padding: 1rem 2.5rem 1rem 1rem;
              font-size: 1rem;
            }
          }
        }

        @media #{$vpMobile600} {
          flex-direction: column-reverse;
          align-items: flex-start;
          gap: 2rem;
        }

        .input-wrapper {
          width: 40%;
          position: relative;

          @media #{$vpMobile600} {
            width: 100%;
          }

          input {
            padding: 0.875rem 2rem;
            width: 100%;
            border: 0.063rem solid $secondarySemiLight;
            border-radius: 0.5rem;
          }

          svg {
            position: absolute;
            right: 0;
            top: 0.875rem;
            right: 2rem;

            path {
              fill: $baselineSecondary;
            }
          }
        }

        .tab-items {
          display: flex;
          gap: 1rem;
          width: 43rem;
          list-style: none;
          @media #{$vpTablet991} {
            width: 100%;
          }
          li {
            &::before {
              display: none;
            }
          }
          .slick-list {
            .slick-track {
              .nav-item {
                width: fit-content !important;
                margin-right: 1rem;
                padding-left: 0;
                @media #{$vpMobile600} {
                  margin-bottom: 1.5rem;
                }
                &:last-child {
                  margin-right: 0;
                }
                &::before {
                  display: none;
                }
              }
            }
          }

          @media #{$vpMobile600} {
            flex-direction: row;
            overflow: scroll;
            flex-wrap: nowrap;
            padding: 0 1.25rem .938rem;
            margin: 0 -1.25rem;
            width: 100vw;

            &::-webkit-scrollbar {
              display: none;
            }
          }

          .slick-prev,
          .slick-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border: 1px solid $baselinePrimary;
            border-radius: 50%;
            width: 2.875rem;
            height: 2.875rem;
            z-index: 999;

          }

          .slick-prev {
            left: 0;
          }

          .slick-next {
            right: 0;
          }

          .nav-item {
            @media #{$vpMobile600} {
              flex: 0 0 auto;
              padding-left: 0;
            }

            .nav-link {
              border-radius: 0.25rem;
              max-width: fit-content;
              padding: 1rem 2rem;
              line-height: normal;
              border: 0.063rem solid $secondarySemiLight;
              background-color: transparent;
              color: $baselineSecondary;
              transition: all 0.4s ease;
              height: 4.563rem;
              display: flex;
              align-items: center;

              @media #{$vpMobile600} {
                flex: 0 0 auto;
                height: 2.75rem;
                padding: 1rem;
                font-size: 0.875rem;
                line-height: 1.4;
                margin-right: 0 !important;
              }

              &:hover {
                background-color: $secondarySemiLight;
              }

              &.active {
                background-color: $secondarySemiLight;
                display: flex;
                align-items: center;
                gap: 0.75rem;

                &:after {
                  content: "";
                  width: 0.5rem;
                  height: 0.5rem;
                  display: block;
                  border-radius: 3.125rem;
                  background-color: $baselinePrimary;
                  transition: all 0.4s ease;
                }
              }
            }
          }
        }
      }
    }

    .listing-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.25rem;
      justify-content: space-between;

      @media #{$vpMobile600} {
        grid-template-columns: repeat(1, 1fr);
      }

      .list-item {
        padding: 2.5rem 2rem 2rem 2rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        background-color: $baselineSecondaryLight;

        @media #{$vpMobile600} {
          width: 100%;
          height: 100%;
          padding: 2rem 1.5rem 1.5rem 1.5rem;
          border-radius: 0.5rem;
        }

        .top-wrapper {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          @media #{$vpMobile600} {
            gap: 1.5rem;
          }

          .tag {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            height: 2.75rem;

            .icon-svg {
              width: 2.25rem;
              height: 2.25rem;
              &.arrow-icon{
                /*rtl:raw:transform: rotate(180deg);*/
              }
            }

            span {
              border: 0.063rem solid $secondarySemiLight;
              padding: 1rem 1.5rem;
              border-radius: 0.25rem;
              display: flex;
              align-items: center;
              height: 100%;
            }
          }

          h5 {
            line-height: 1.3;
            margin: 0;
            min-height: 4.5rem;
            color: $baselineSecondary;

            @media #{$vpMobile600} {
              font-size: 1.25rem;
              min-height: 3.25rem;
            }
          }

          .body1 {
            margin: 0;
            color: $baselineSecondary;

            @media #{$vpMobile600} {
              font-size: 1.125rem;
            }
          }
        }

        .bottom-btn {
          display: flex;

          .main-primary-btn {
            height: 2.875rem;
            display: flex;
            align-items: center;
            justify-content: center;
            display: inline;
            padding: 0.75rem 1.5rem;
            margin-top: 2.5rem;

            @media #{$vpMobile600} {
              padding: 0.5rem 1.125rem;
              height: 2.375rem;
              margin-top: 2rem;
            }

            .btn-text {
              @media #{$vpMobile600} {
                font-size: 1rem;
                line-height: 1.4;
              }
            }
          }
        }
      }
    }
  }
  &.dark-bg {
    .wrapper-box {
      .listing-box {
        .filter {
          .nav-pills {
            .nav-item {
              .nav-link {
                color: rgba($color: #fff, $alpha: 0.4);
                &:hover {
                  background-color: $white;
                  color: #2c2e34;
                }
                &.active {
                  color: $white;
                  background-color: transparent;
                }
              }
            }
          }
          .right-filter {
            .input-field {
              background-color: rgba($color: #fff, $alpha: 0.1);
              color: $white;
            }
            .search-box {
              .btn-search {
                .serch-icon {
                  path {
                    fill: $white;
                  }
                }
              }
            }
            .filter-view-type {
              .sort-box {
                background-color: rgba($color: #fff, $alpha: 0.1);
                p{
                  color: $white;
                }
              }
            }
          }
          .tabs-wrapper {
            .slider-arrow-pagination {
              .arrow-wrap {
                .slide-arrow {
                  .custom-arrows {
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }
      }
      .listing-box {
        .listing-wrapper {
          .list-item {
            background-color: rgba($color: #fff, $alpha: 0.1);
            .top-wrapper {
              .heading5 {
                color: $white;
              }
              .body1 {
                color: $white;
              }
            }
            .bottom-btn {
              .custom-arrows {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
  &.gray-bg {
    .wrapper-box {
      .listing-box {
        .listing-wrapper {
          .list-item {
            background-color: $white;
            .bottom-btn {
              .main-primary-btn {
                border: .063rem solid #9f8332;
              }
            }
          }
        }
      }
    }
  }
  &.no-bg {
    .wrapper-box {
      .listing-box {
        .listing-wrapper {
          .list-item {
            background-color: #f9f7f3;
          }
        }
      }
    }
  }
  &.data-library {
    padding-bottom: 2.5rem;
    overflow: visible;
    .wrapper-box {
        .media-gallery-box {
            .filter-bar {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 1.875rem;
              @media #{$vpTablet991} {
                  flex-direction: column-reverse;
                  margin-bottom: 1.25rem;
                  margin-top: 2.5rem;
              }
              @media #{$vpMobile600} {
                margin-bottom: 0.875rem;
              }
                .left-filter {
                  flex: 0 0 40%;
                  max-width: 40%;
                  @media #{$vpTablet991} {
                      flex: 0 0 100%;
                      max-width: 100%;
                      margin-top: 1.5rem;
                  }
                  @media #{$vpMobile600} {
                      margin-top: 0;
                  }
                    .tab-items {
                        width: 100%;
                        position: relative;
                        display: flex;
                        flex-wrap: nowrap;
                        overflow: hidden;
                            gap: 1rem;
                        @media #{$vpMobile600} {
                          flex-direction: row;
                          overflow: scroll;
                          flex-wrap: nowrap;
                          gap: 0.75rem;
                          padding: 2rem 1.25rem 1rem;
                          margin: 0 -1.25rem;
                          width: 100vw;

                          &::-webkit-scrollbar {
                            display: none;
                          }
                        }
                            .slick-track {
                                display: flex;
                            }
                            .nav-item {
                              max-width: max-content;
                              width: 100% !important;
                              flex: 0 0 auto;
                              padding-left: 0;
                              @media #{$vpMobile600} {
                                margin-right: 0;
                              }
                              &:last-child {
                                margin-right: 0;
                              }
                              &::before {
                                display: none;
                              }
                              .nav-link {
                                border-radius: 0.25rem;
                                max-width: fit-content;
                                padding: 1.594rem 1.5rem;
                                line-height: normal;
                                border: 0.063rem solid $secondarySimiLight;
                                background-color: transparent;
                                color: $baselineSecondary;
                                transition: all 0.4s ease;
                                display: flex;
                                align-items: center;
                                margin-right: 1rem;
                                cursor: pointer;
                                @media #{$vpMobile600} {
                                  padding: 0.75rem 1rem;
                                  flex: 0 0 auto;
                                  margin-right: 0;
                                }
                              &:hover {
                                  background-color: $secondarySimiLight;
                              }
                              &:focus {
                                  outline: none;
                              }

                              &.active {
                              background-color: $secondarySimiLight;
                              display: flex;
                              align-items: center;
                              gap: 0.75rem;

                              &:after {
                                  content: "";
                                  width: 0.5rem;
                                  height: 0.5rem;
                                  display: block;
                                  border-radius: 3.125rem;
                                  background-color: $baselinePrimary;
                                  transition: all 0.4s ease;
                              }
                              }
                              }
                            }
                            li {
                              &::before {
                                display: none;
                              }
                            }
                    }
                    .slider-arrow-pagination {
                        display: flex;
                        padding: 0;
                        position: absolute;
                        width: 100%;
                        top: 50%;
                        transform: translateY(-50%);
                        .arrow-wrap {
                            bottom: auto;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 100%;
                            .slide-arrow {
                                width: 100%;
                                height: 0;
                                .custom-arrows {
                                  background-color: $white;
                                  transform: translatex(0) translateY(-50%);
                                  position: absolute;
                                  transition: all 0.35s ease;
                                  @media #{$vpMobile600} {
                                    display: none;
                                  }
                                  &:hover {
                                      background-color: $secondaryLight !important;
                                  }
                                  &.prev {
                                      left: 0;
                                  }
                                  .insvg {
                                      width: 1rem;
                                      height: auto;
                                  }
                                  &.slick-disabled {
                                    opacity: 0;
                                    visibility: hidden;
                                  }
                              }
                            }
                        }
                    }
                }
                .right-filter {
                  display: flex;
                  align-items: center;
                  flex: 0 0 60%;
                  max-width: 60%;
                  justify-content: flex-end;
                  gap: 1.25rem;
                  @media #{$vpTablet991} {
                    justify-content: flex-start;
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                @media #{$vpMobile600} {
                    width: 100%;
                    padding-left: 0;
                    gap: 1.5rem;
                    justify-content: space-between;
                }
                .search-box {
                  width: 25.3125rem;
                  position: relative;
                  @media #{$vpMobile600} {
                    max-width: 17.3125rem;
                  }
                  @media #{$vpMobile390} {
                    max-width: 16.125rem;
                  }
                  .btn-search {
                    position: absolute;
                    left: auto;
                    top: 50%;
                    transform: translateY(-50%);
                    border: 0;
                    background-color: transparent;
                    outline: 0;
                    right: 1rem;
                    padding: 0;
                    .serch-icon {
                      width: 1.5rem;
                      height: 1.5rem;

                      path {
                          fill: $baselineSecondary;
                      }
                  }
                  }
                }
                  .filter-view-type {
                    width: auto;
                    display: flex;
                    gap: 1.25rem;
                    @media #{$vpMobile600} {
                      width: 3.25rem;
                  }
                  @media #{$vpMobile390} {
                    width: 4.5rem;
                  }
                    svg {
                      width: 1.5rem;
                      height: 1.5rem;
                      transition: all 0.4s ease-in;
                  }
                    .sort-box {
                      width: 16.438rem;
                      background: $white;
                      border: 0.063rem solid $secondarySimiLight;
                      padding: 0 1rem;
                      border-radius: 0.5rem;
                      height: 4.563rem;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      cursor: pointer;
                      @media #{$vpMobile600} {
                        max-width: 100%;
                        height: auto;
                        justify-content: center;
                        padding: 0.906rem 0;
                      }
                      &.icon-rotate {
                        .sort-icon {
                            transform: rotateX(180deg);
                            transition: all 0.4s ease-in;
                        }
                    }
                    p {
                        color: #5b5b5b;
                        @media #{$vpMobile600} {
                          display: none;
                        }
                      }
                    }
                    .view-type {
                      width: 8.94rem;
                      background: $white;
                      border-radius: 0.5rem;
                      border: 0.063rem solid $secondarySimiLight;
                      height: 4.563rem;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      overflow: hidden;
                      @media #{$vpMobile600} {
                        display: none;
                      }
                      ul {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        li {
                            padding-left: 0;
                            &::before {
                                display: none;
                            }
                            a {
                                width: 4.5rem;
                                padding: 1.5rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                @media #{$vpMobile600} {
                                    width: 3.4375rem;
                                    height: 3.4375rem;
                                    padding: 0;
                                }

                                &:hover {
                                    background-color: $secondarySimiLight;
                                }

                                &.active {
                                    background-color: $secondarySimiLight;
                                }

                                img {
                                    width: 1.5rem;
                                    height: 1.5rem;
                                }

                                &.grid-view-action {
                                    &.active {
                                        .list-view-listing {
                                            display: none;
                                        }
                                    }
                                }

                                &.list-view-action {
                                    &.active {
                                        .grid-view-listing {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    }
                  }
                  .input-field {
                    background: $white;
                    border: 0.063rem solid $secondarySimiLight;
                    padding: 0 4rem 0 1rem;
                    border-radius: 0.5rem;
                    width: 100%;
                    height: 4.563rem;

                    @media #{$vpMobile600} {
                        height: 3.438rem;
                        padding: 1.031rem 1rem;
                    }
                    &::placeholder {
                        color: #5B5B5B;
                    }
                }
                }
            }
            .tab-content-wrapper {
                .tab-content {
                    .filter-panel {
                        .media-listing {
                            &.grid-view-listing {
                                justify-content: space-between;
                                .data-card-wrapper {
                                    width: 100%;
                                    max-width: 33.33%;
                                    flex: 0 0 33.33%;
                                    padding: 1rem 0.625rem;
                                    @media #{$vpTablet991} {
                                        max-width: 49%;
                                        flex: 0 0 49%;
                                    }
                                    @media #{$vpMobile600} {
                                        max-width: 100%;
                                        flex: 0 0 100%;
                                        padding: 0.625rem 0;
                                    }
                                    .content-box {
                                        width: 100%;
                                        height: 100%;
                                        .btn-wrap {
                                          .dropdown {
                                            .dropdown-content-wrapper {
                                              padding-top: .5rem;
                                              position: absolute;
                                              width: 2.875rem;
                                              visibility: hidden;
                                              &.dropdown-space-visible {
                                                visibility: visible;
                                              }
                                              .dropdown-content {
                                                margin-top: 0;
                                              }
                                            }
                                          }
                                        }
                                    }
                                }
                            }
                            &.list-view-listing {
                              display: flex;
                              gap: 2rem;
                              flex-direction: column;
                              flex-wrap: wrap;
                              @media #{$vpMobile600} {
                                  gap: 1rem;
                              }

                              .listingWrapper {
                                  flex: 0 0 100%;
                                  max-width: 100%;
                                  margin: 0;
                                  padding: 0.625rem 0;
                                  background: transparent;
                                  width: 100%;
                                  height: auto;
                                  // .list-items {
                                  //     &.grid-view-item {
                                  //         display: none !important;
                                  //     }
                                  // }

                                  .grid-view-item {
                                      display: flex !important;
                                      align-items: center;
                                      width: 100%;
                                      border: 0.063rem solid $secondarySemiLight;
                                      border-radius: 1rem;
                                      overflow: hidden;
                                      cursor: pointer;
                                      height: 100%;

                                      @media #{$vpMobile600} {
                                          border-radius: 0.5rem;
                                          padding: 0.625rem;
                                          align-items: flex-start;
                                          gap: 1rem;
                                          height: auto;
                                      }

                                      &.images-cate {
                                          .play-icon {
                                              display: none;
                                          }
                                      }
                                      .main-content {
                                          display: flex;
                                          justify-content: space-between;
                                          height: 100%;
                                          width: 100%;
                                          .action-footer {
                                              display: none;
                                          }
                                          .inner-content {
                                              display: none;
                                          }
                                      }
                                      .light-box-triger {
                                          flex: 0 0 33.3334%;
                                          max-width: 33.3334%;
                                          position: relative;
                                          img {
                                              width: 100%;
                                              height: 100%;
                                              object-fit: cover;
                                              max-height: 18.75rem;
                                          }
                                          @media #{$vpMobile600} {
                                              flex: 0 0 38.5%;
                                              max-width: 38.5%;
                                              max-height: 4.5rem;
                                              img {
                                                  height: 4.5rem;
                                                  border-radius: 0.25rem;
                                              }
                                          }
                                          
                                          .play-icon {
                                              position: absolute;
                                              top: 50%;
                                              left: 50%;
                                              transform: translate(-50%, -50%);

                                              @media #{$vpMobile600} {
                                                  .icon-play {
                                                      width: 2rem;
                                                  }
                                              }
                                          }
                                          .podcast-detail {
                                              display: none;
                                          }
                                          .podcast-img {
                                              width: 100%;
                                              height: 100%;
                                          }
                                      }

                                      .right-content {
                                          padding: 2rem 2.5rem;
                                          display: flex;
                                          align-items: center;
                                          justify-content: space-between;
                                          flex: 0 0 66.6%;
                                          max-width: 66.6%;

                                          @media #{$vpMobile600} {
                                              padding: 0 1rem;
                                              flex: 0 0 61.5%;
                                              max-width: 61.5%;
                                          }

                                          .inner-left {
                                              width: 50.625rem;
                                              display: flex;
                                              flex-direction: column;
                                              gap: 1.5rem;

                                              .heading3 {
                                                  margin: 0;
                                              }

                                              @media #{$vpMobile600} {
                                                  gap: 1rem;

                                                  .heading3 {
                                                      font-size: 0.875rem;
                                                      line-height: 1.225rem;

                                                  }
                                              }

                                              .cate-date-header {
                                                  display: flex;
                                                  align-items: flex-start;
                                                  justify-content: center;
                                                  width: 100%;
                                                  margin: 0;
                                                  flex-direction: column;
                                                  gap: 1.5rem;
                                                  text-transform: capitalize;
                                                  width: max-content;
                                                  @media #{$vpMobile600} {
                                                      flex-direction: row;
                                                      align-items: center;
                                                      justify-content: flex-start;
                                                      gap: 1rem;
                                                  }

                                                  .post-date {
                                                      color: $secondaryColor;
                                                      margin: 0;

                                                      @media #{$vpMobile600} {
                                                          font-size: 0.75rem;
                                                          padding: 0;
                                                      }
                                                  }

                                                  .category {
                                                      color: $secondaryColor;
                                                      padding: 0.375rem 1rem;
                                                      background-color: $secondarySimiLight;
                                                      border-radius: 0.25rem;
                                                      margin: 0;

                                                      @media #{$vpMobile600} {
                                                          font-size: 0.75rem;
                                                          padding: 0.25rem 0.75rem;
                                                      }
                                                  }
                                              }
                                          }

                                          .inner-right {
                                              @media #{$vpMobile600} {
                                                  display: none;
                                              }

                                              .btn-box {
                                                  display: flex;
                                                  align-items: center;
                                                  gap: 1rem;
                                                  flex-direction: column;

                                                  .main-secondary-btn {
                                                      min-width: 10rem;
                                                      outline: none;
                                                      text-align: center;
                                                  }
                                              }
                                          }
                                      }

                                      // &.grid-view-item {
                                      //     display: none !important;
                                      // }
                                  }
                              }
                          }
                        }
                    }
                }
            }
        }
    }
}
}

html[lang="ar"] {
  .e-services-listing {
    &.data-library {
      .wrapper-box {
        .media-gallery-box {
          .filter-bar {
            .left-filter {
              .arrow-wrap {
                .slide-arrow {
                  .custom-arrows {
                    &.prev {
                      right: 0;
                      left: auto;
                    }
                    &.next {
                      right: auto;
                      left: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}