.sec-aspiration {
  background-color: #f9f7f3;
  .box-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .underline-title {
      color: $baselinePrimary;
    }

    .col-left {
      max-width: 33%;
      flex: 0 0 33%;
      overflow: hidden;
      border-radius: 1rem;
      @media #{$vpDesktop1920} {
        max-width: 33%;
        flex: 0 0 33%;
      }
      @media #{$vpTablet991} {
        max-width: 100%;
        flex: 0 0 100%;
      }
      img {
        border-radius: 1rem;
        width: 100%;
        height: auto;
        @media #{$vpMobile600} {
          border-radius: 0.5rem;
        }
      }

      @media #{$vpMobile600} {
        width: 100%;
        border-radius: 0.5rem;
      }
    }

    .col-right {
      max-width: 60%;
      flex: 0 0 60%;

      @media #{$vpDesktop1920} {
        max-width: 49.5%;
        flex: 0 0 49.5%;
      }
      @media #{$vpTablet991} {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        margin-top: 5rem;
      }
      @media #{$vpMobile600} {
        margin-top: 2rem;
      }
      .box-heading {
        display: block;
        // flex-direction: column;
        height: 100%;

        @media #{$vpTablet991} {
          min-height: auto;
          padding-right: 1rem;
        }

        @media #{$vpMobile600} {
          margin-left: 0;
          padding-right: 0;
        }

        .wrapper-heading {
          .heading2 {
            margin-top: 1rem;
          }
        }
        .content-para {
          margin-top: 1rem;
        }
      }

      .content-para {
        .content-box {
          @media #{$vpMobile600} {
            margin: 0;
          }

          .body1 {
            color: $baselineSecondary;
          }
        }
      }

      .action-btn-wrapper {
        margin-top: 2.5rem;
        display: flex;
        flex-direction: row;
        gap: 20px;
        // .main-primary-btn {
        //   border: .063rem solid #9f8332;
        // }
        @media #{$vpMobile600} {
          flex-direction: column;

          .main-primary-btn,
          .main-secondary-btn {
            padding: 0.88rem 1.5rem;
            height: auto;
            max-width: 100%;
            text-align: center;
          }
        }
      }
    }
  }

  @media #{$vpMobile600} {
    .box-wrapper {
      flex-direction: column;
    }
  }

  &.modifier-flip {

    .box-wrapper {
      flex-direction: row-reverse;

      @media #{$vpMobile600} {
        flex-direction: column;
      }

      .col-left {
        display: flex;
        align-items: center;
        justify-content: end;
      }
    }
  }
  &.design-home {
    background: $baselineSecondaryLight;

    .box-wrapper {
      flex-direction: row-reverse;

      @media #{$vpMobile600} {
        flex-direction: column;
      }
      .col-right{
        .content-para {
          .content-box {
            display: flex;
            flex-direction: column;
            gap: 2.063rem;
  
  
  
            .body1 {
              color: $baselineSecondary;
  
              @media #{$vpMobile600} {
                font-size: 1.125rem;
              }
            }
  
            p {
              margin: 0;
            }
  
            button {
              width: fit-content;
  
              @media #{$vpMobile600} {
                width: 100%;
              }
            }
          }
  
          @media #{$vpMobile600} {
            margin-top: 1.6rem;
          }
        }
      }
    }
  }
  &.discover-alula{
    
    .box-wrapper {
     

      @media #{$vpMobile600} {
        flex-direction: column;
      }
      .col-right{
        .box-heading {
          display: block;
          // justify-content: space-between;
          // flex-direction: column;
          height: 100%;
  
          @media #{$vpTablet991} {
            margin-left: 2rem;
          }
  
          @media #{$vpMobile600} {
            margin-left: 0;
          }
  
          .wrapper-heading {
            display: flex;
            flex-direction: column;
            gap: 2rem;
  
            @media #{$vpMobile600} {
              gap: 1rem;
            }
  
            .heading2 {
              margin-top: 1rem;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  &.alula-vendor{
    .box-wrapper{
      .col-right{
        .content-para{
          .content-box{
            display: flex;
            flex-direction: column;
            gap: 2.063rem;
            .main-primary-btn{
              width: fit-content;
              border: .063rem solid #9f8332;
            }
          }
        }
      }
    }
  }
  &.dark-bg {
    .box-wrapper {
      .col-right {
        .content-para {
          .content-box {
            .body1 {
              color: $white;
            }
          }
        }
      }
    }
  }
  &.no-bg {
    .box-wrapper {
      .col-right {
        .action-btn-wrapper {
          .main-primary-btn {
            border: .063rem solid #9f8332;
          }
        }
      }
    }
  }
}
