.sec-leadership-team {

  .sec-wrapper {
    .sec-heading-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-top: 1rem;

      .sec-heading-inner {
        max-width: 40%;
        flex: 0 0 40%;

        &.fullWidth {
          max-width: 100%;
          flex: 0 0 100%;
        }

        @media #{$vpTablet1229} {
          max-width: 30%;
          flex: 0 0 30%;
        }

        @media #{$vpTablet767} {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      .sec-para-inner {
        max-width: 53%;
        flex: 0 0 53%;

        @media #{$vpTablet1229} {
          max-width: 65%;
          flex: 0 0 65%;
        }

        @media #{$vpTablet767} {
          max-width: 100%;
          flex: 0 0 100%;
          padding-left: 0;
        }
      }
    }

    .slider-wrap {
      max-width: 96.25rem;
      position: relative;

      .tab-items {
        position: absolute;
        z-index: 5;
        flex-wrap: nowrap;
        @media #{$vpMobile600} {
          position: relative;
          width: 100%;
          overflow: scroll;
          max-width: 100%;
          padding-bottom: 2.5rem;
          margin-bottom: 0 !important;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .nav-btn {
          margin-right: 0.75rem;
          padding-left: 0;
          @media #{$vpMobile600} {
            flex: 0 0 auto;
          }
          &::before {
            display: none;
          }

          .nav-action {
            background-color: transparent;
            color: $baselineSecondary;
            padding: 0.688rem 1.5rem;
            position: relative;
            transition: all 0.35s ease;
            border: 0.063rem solid $secondarySemiLight;
            border-radius: 0.25rem;

            &::after {
              content: "";
              position: absolute;
              background-color: $baselinePrimary;
              width: 0.5rem;
              height: 0.5rem;
              border-radius: 50%;
              right: 1.25rem;
              top: 50%;
              transform: translateY(-50%);
              opacity: 0;
            }
            

            &.active {
              padding-right: 2.75rem;
              background-color: $secondarySemiLight;

              &::after {
                content: "";
                opacity: 1;
              }
            }

            @media #{$vpMobile600} {
              font-size: 0.875rem;
            }
          }
        }
      }

      .tab-content {
        .tab-slider {
          .item-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            position: relative;

            @media #{$vpTablet767} {
              flex-direction: column-reverse;
              align-items: center;
            }

            .leadership-content-slider {
              max-width: 51.8125rem;
              position: relative;
              z-index: 2;
              margin-top: 6.688rem;
              @media #{$vpTablet767} {
                max-width: 100%;
                margin-top: 0;
              }
              // .slick-list {
              //   height: 100%;
              // }
              // .slick-slide {
              //   display: none;
              //   height: 0;
              //   &.slick-active {
              //     display: block;
              //     height: 100%;
              //   }
              // }
              // .slick-track {
              //   height: 100%;
              // }
              .post-content-box {
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;
                height: 100%;

                .heading3 {
                  margin: 0;
                }

                @media #{$vpTablet767} {
                  justify-content: flex-start;
                  margin-top: 2.5rem;
                  gap: 1.5rem;
                }

                .post-content-info {
                  width: 100%;
                  margin-top: 1rem;
                  margin-bottom: 4rem;
                  @media #{$vpMobile600} {
                    width: 100%;
                  }

                  .subtitle {
                    color: $baselinePrimary;
                    margin-bottom: 1.5rem;
                  }

                  p {
                    margin-top: 0;

                    @media #{$vpMobile600} {
                      margin-bottom: 1.5rem;
                    }
                  }
                }
              }

              &::after {
                content: "";
                position: absolute;
                width: 180%;
                height: 100%;
                background-color: #fff;
                top: 0;
                left: -31.25rem;
                z-index: -1;

                @media #{$vpTablet767} {
                  display: none;
                }
              }

              .slick-dots {
                margin-top: 0;
              }
            }

            .leadership-image-slider {
              max-width: 34.125rem;
              position: relative;
              width: 100%;
              @media #{$vpTablet991} {
                padding: 3rem 0;
              }
              @media #{$vpTablet767} {
                margin-top: 3rem;
              }
              @media #{$vpMobile600} {
                width: 20.625rem;
                left: -0.625rem;
                margin-top: 0;
                padding: 0;
              }

              @media #{$vpMobile390} {
                width: 20.625rem;
                left: -0.625rem;
              }

              @media #{$vpTablet767} {
                &::after {
                  content: "";
                  position: absolute;
                  width: 180%;
                  height: 100%;
                  background-color: #fff;
                  top: 0;
                  left: -22.5rem;
                  z-index: 0;
                  display: none;
                }
              }

              .post-img {
                max-width: 34.125rem;
                  width: 34.125rem;
                &.slick-active {
                  img {
                    opacity: 1;
                    border-radius: 2rem;
                    width: 34.125rem;
                    height: 100%;
                    max-width: 100%;
                    object-fit: cover;
                    @media #{$vpMobile600} {
                      height: 100%;
                      object-fit: cover;
                      border-radius: 0.75rem;
                    }
                  }
                }

                img {
                  margin: 0;
                  opacity: 0.2;
                  max-width: 100%;
                  width: 34.125rem;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 2rem;
                  @media #{$vpTablet991} {
                    height: 100%;
                  }
                  @media #{$vpMobile600} {
                    margin: 0;
                  }
                }
              }

              .slick-dots {
                display: none !important;
              }

              .slick-slide {
                transition: all 0.35s ease;
                transform: scale(0.8);

                &.slick-active {
                  transform: scale(1);
                  position: relative;
                  z-index: 50;

                  @media #{$vpMobile390} {
                    width: 19.375rem !important;
                  }
                }
              }

              .slick-list {
                overflow: visible;
                //padding: 0 20% 0 0;
                position: relative;

                &::after {
                  content: " ";
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  width: 250rem;
                  right: 100%;
                  background-color: white;
                }
              }
            }

            .slider-nav-conter-main {
              position: absolute;
              top: auto;
              left: 0;
              right: 0;
              bottom: 0;
              max-width: 54%;
              z-index: 999;
              @media #{$vpTablet991} {
                bottom: -3rem;
              }
              .slider-arrow-pagination {
                .pagination-wrap {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  &.archelogical-team,
  &.about-the-research {
    background-color: $baselineSecondaryLight;

    .sec-wrapper {
      .sec-heading-box {
        @media #{$vpTablet991} {
          padding-bottom: 2rem;
        }
      }
      .slider-wrap {
        .tab-content {
          .tab-slider {
            .item-box {
              .leadership-content-slider {
                &::after {
                  background-color: $baselineSecondaryLight;
                }

                .slick-list {
                  height: 100%;

                  .slick-track {
                    height: 100% !important;
                  }
                }
                .post-content-box {
                  @media #{$vpTablet991} {
                    margin-top: 5rem;
                  }
                  @media #{$vpMobile600} {
                    margin-top: 0;
                  }
                  // .heading3 {
                  //   @media #{$vpTablet991} {
                  //     margin-bottom: 2rem;
                  //   }
                  // }
                  .button-wrapper {
                    display: flex;
                  }
                }
                .slick-dots {
                  margin-top: 4rem;
                }
              }

              .leadership-image-slider {
                .slick-list {
                  &::after {
                    background-color: $baselineSecondaryLight;
                  }
                }
              }

              .post-content-box {
                // justify-content: space-between;
                // margin-top: 0;

                .heading3 {
                  margin-bottom: 50px;
                  @media #{$vpMobile600} {
                    margin-top: 2.5rem;
                    margin-bottom: 0;
                  }
                }
              }
              .slider-arrow-pagination {
                .pagination-wrap {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  &.designeSpitial {
    padding-top: 12.5rem;
    @media #{$vpMobile600} {
      padding-top: 2.5rem;
    }
    .sec-wrapper {
      .sec-heading-box {
        padding-bottom: 3rem;
      }
    }
  }

  &.responsibleDev {
    .sec-wrapper {
      .sec-heading-box {
        align-items: flex-start;
        padding-top: 1rem;

        .sec-para-inner {
          .btn-wrapper {
            margin-top: 3rem;
            a {
              background: #fff;
              border-color: #fff;
            }
          }
        }
      }
    }
  }
  &.dark-bg {
    .sec-wrapper {
      .slider-wrap {
        .tab-items {
          .nav-btn {
            .nav-action {
              color: $white;
              &.active {
                color: #2c2e34;
              }
            }
          }
        }
        .tab-content {
          .tab-slider {
            .item-box {
              .leadership-content-slider {
                &:after {
                  background-color: #120f14;
                }
              }
            }
          }
        }
      }
    }
}
&.no-bg {
  .sec-wrapper {
    .slider-wrap {
      .tab-content {
        .tab-slider {
          .item-box {
            .leadership-content-slider {
              &:after {
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }
}
}