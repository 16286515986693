.job-listing {
  .wrapper-box {
    .underline {
      padding-bottom: 1rem;

      @media #{$vpMobile600} {
        margin-bottom: 2rem;
        padding-bottom: 0;
        font-size: 0.875rem;
      }
    }

    .simple {
      @media #{$vpMobile600} {
        padding-top: 2rem;
        font-size: 0.875rem;
      }
    }

    .heading-col {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media #{$vpMobile600} {
        flex-direction: column;
      }

      .left-col {
        width: 50%;

        @media #{$vpMobile600} {
          width: 100%;
        }
      }

      .right-col {
        width: 50%;

        @media #{$vpMobile600} {
          width: 100%;
        }
      }
    }

    .listing-box {
      position: relative;

      @media #{$vpMobile600} {
        margin-bottom: -1.25rem;
      }

      .filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3rem 0;
        @media #{$vpTablet991} {
          flex-direction: column;
          align-items: flex-start;
        }
        @media #{$vpMobile600} {
          padding: 2.5rem 0;
        }

        .right-filter {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 1.25rem;
          width: 53.188rem;
          @media #{$vpTablet991} {
            justify-content: flex-start;
            margin-top: 2rem;
          }
          @media #{$vpMobile600} {
            width: 100%;
            padding-left: 0;
            flex-direction: row;
            gap: 1.5rem;
          }

          .search-box {
            width: 30.5rem;
            position: relative;

            @media #{$vpMobile600} {
              width: 80%;
            }

            .btn-search {
              position: absolute;
              left: auto;
              top: 50%;
              right: 1.25rem;
              transform: translateY(-50%);
              border: 0;
              background-color: transparent;
              outline: 0;

              @media #{$vpMobile600} {
                right: 0.5rem;
              }

              .serch-icon {
                width: 1.5rem;
                height: 1.5rem;

                path {
                  fill: $baselineSecondary;
                }
              }
            }
          }

          .filter-view-type {
            width: 16.4375rem;
            display: flex;
            gap: 1.25rem;

            @media #{$vpMobile600} {
              width: 20%;
            }

            .sort-box {
              width: 16.438rem;
              background: $white;
              border: 0.063rem solid $secondarySimiLight;
              padding: 0 1.25rem;
              border-radius: 0.5rem;
              height: 4.375rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;

              .body3 {
                @media #{$vpMobile600} {
                  display: none;
                }
              }

              @media #{$vpMobile600} {
                width: 13.813rem;
                height: 3.438rem;
              }

              &.icon-rotate {
                .sort-icon {
                  transform: rotateX(180deg);
                  transition: all 0.4s ease-in;
                }
              }

              img {
                width: 1.5rem;
                height: 1.5rem;
                transition: all 0.4s ease-in;
              }
            }
          }

          .input-field {
            background: $white;
            border: 0.063rem solid $secondarySimiLight;
            padding: 0 4rem 0 1.25rem;
            border-radius: 0.5rem;
            width: 100%;
            height: 4.375rem;

            @media #{$vpMobile600} {
              height: 3.438rem;
              padding: 1rem 2.5rem 1rem 1rem;
            }
          }
        }

        @media #{$vpMobile600} {
          flex-direction: column-reverse;
          align-items: flex-start;
          gap: 2rem;
        }

        .input-wrapper {
          width: 40%;
          position: relative;

          @media #{$vpMobile600} {
            width: 100%;
          }

          input {
            padding: 0.875rem 2rem;
            width: 100%;
            border: 0.063rem solid $secondarySemiLight;
            border-radius: 0.5rem;
          }

          svg {
            position: absolute;
            right: 0;
            top: 0.875rem;
            right: 2rem;

            path {
              fill: $baselineSecondary;
            }
          }
        }

        .nav-pills {
          display: flex;
          gap: 0.6rem;

          @media #{$vpMobile600} {
            width: 100%;
          }

          @media #{$vpMobile600} {
            flex-direction: row;
            overflow: scroll;
            flex-wrap: nowrap;
            padding: 0 0 0.938rem 0;
            margin-right: -1.25rem;
          }

          .nav-item {
            position: relative;
            padding-left: 0;
            &::before {
              display: none;
            }
            @media #{$vpMobile600} {
              flex: 0 0 auto;
            }

            .nav-link {
              border-radius: .25rem;
              max-width: fit-content;
              padding: 1.59rem 1.5rem;
              line-height: normal;
              border: .063rem solid $secondarySemiLight;
              background-color: transparent;
              color: $baselineSecondary;
              -webkit-transition: all .4s ease;
              transition: all .4s ease;
              height: 4.375rem;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              cursor: pointer;
              margin-bottom: 0;



              @media #{$vpMobile600} {
                flex: 0 0 auto;
                height: 2.75rem;
              }

              &:hover {
                background-color: $secondarySemiLight;
              }

              &.active {
                background-color: $secondarySemiLight;
                display: flex;
                align-items: center;
                gap: 0.75rem;
                transition: all 0.4s ease;

                &::after {
                  opacity: 1;
                  right: 1rem;

                }
              }

              &::after {
                content: '';
                width: 0.5rem;
                height: 0.5rem;
                display: block;
                border-radius: 3.125rem;
                background-color: $baselinePrimary;
                opacity: 0;

              }
            }
          }
        }
      }
    }

    .listing-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1.25rem;
      justify-content: space-between;

      @media #{$vpMobile600} {
        grid-template-columns: repeat(1, 1fr);
        padding-bottom: 1.5rem;
      }

      .list-item {
        border: 0.063rem solid $secondarySemiLight;
        padding: 2rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 28.25rem;
        @media #{$vpTablet991} {
          height: 100%;
        }
        @media #{$vpMobile600} {
          width: 100%;
          height: 26.8125rem;
          border-radius: 0.5rem;
        }

        .top-wrapper {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          @media #{$vpMobile600} {
            gap: 1.5rem;
          }

          .tag {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            height: 2.75rem;

            span {
              border: 0.063rem solid $secondarySemiLight;
              padding: 1rem 1.5rem;
              border-radius: 0.25rem;
              display: flex;
              align-items: center;
              height: 100%;
              @media #{$vpMobile600} {
                padding: 0.75rem 1rem;
              }
            }
          }

          h5 {
            line-height: 1.3;
            margin: 0;

            @media #{$vpMobile600} {
              font-size: 1.25rem;
            }
          }

          .body1 {
            margin: 0;

            @media #{$vpMobile600} {
              font-size: 1.125rem;
            }
          }
        }

        .bottom-btn {
          @media #{$vpTablet991} {
            margin-top: 1.5rem;
          }
          @media #{$vpMobile600} {
            margin-top: 0.5rem;
          }
          .main-secondary-btn {
            height: 4rem;
            width: 6.4375rem;
            display: flex;
            align-items: center;
            justify-content: center;

            @media #{$vpMobile600} {
              padding: 0.875rem 1.5rem;
              height: 3rem;
              width: 5.125rem;
            }

            .btn-text {
              @media #{$vpMobile600} {
                font-size: 0.875rem;
                line-height: 1.4;
              }
            }
          }
        }
      }
    }
  }
}