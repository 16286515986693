.sec-keyproject {
  .sec-wrapper {
    .sec-heading-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 6.25rem;

      @media #{$vpMobile600} {
        padding: 1rem 0 5rem;
      }

      .sec-heading-inner {
        max-width: 40%;
        flex: 0 0 40%;

        @media #{$vpTablet1229} {
          max-width: 53%;
          flex: 0 0 53%;
        }

        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      .sec-para-inner {
        max-width: 50%;
        flex: 0 0 50%;

        .body1 {
          @media #{$vpMobile600} {
            font-size: 1.25rem;
          }
        }

        @media #{$vpTablet1229} {
          max-width: 45%;
          flex: 0 0 45%;
        }

        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
          padding-left: 0;
          margin-top: 2.375rem;
        }
      }
    }

    .sec-img-mbl {
      display: none;

      @media #{$vpMobile600} {
        display: none;
      }

      img {
        width: 100%;
      }
    }

    .slider-wrap {
      display: flex;
      // flex-wrap: wrap;
      max-width: 112.5rem;
      width: 100%;
      height: 65rem;
      justify-content: space-between;

      @media #{$vpMobile600} {
        flex-wrap: wrap;
        height: 100%;
      }

      .content-slider-wrap {
        width: 51.875rem;
        z-index: 2;
        padding: 6.25rem 0 0 0;
        max-height: 61.5rem;
        height: 100%;

        // overflow-y: scroll;
        @media #{$vpTablet991} {
          width: 35rem;
          padding: 3rem 0 0 0;
        }

        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
          max-height: 100%;
          padding: 2.5rem 1.25rem;
        }

        .keyproject-content {
          .sec-post-content-box {
            .sec-post-content-info {
              transform: translateX(-2.5rem);
              transition: all 0.35s ease;
              opacity: 0;
              width: 100%;

              img {
                width: 1.5rem;
                height: auto;
              }

              .heading3 {
                margin-bottom: 2.5rem;

                @media #{$vpMobile600} {
                  margin-bottom: 1rem;
                }
              }

              .main-primary-btn {
                margin-top: 2.5rem;

                @media #{$vpMobile600} {
                  margin-top: 3.75rem;
                }
              }

              .stas-wrap {
                max-width: 50%;
                flex: 0 0 50%;
                display: flex;
                justify-content: flex-start;
                padding-top: 5rem;
                padding-bottom: 3rem;
                @media #{$vpMobile600} {
                  padding: 2.5rem 0 1.5rem;
                  flex-wrap: wrap;
                  max-width: 100%;
                  border-bottom: 1px solid $secondarySemiLight;
                }

                .stats-content {
                  display: flex;
                  align-items: end;
                  padding-right: 3.75rem;

                  @media #{$vpMobile600} {
                    max-width: 100%;
                    flex: 0 0 100%;
                  }

                  h1 {
                    line-height: 1.1;
                    color: $baselinePrimary;
                    font-size: 10rem;
                    margin: 0;
                    height: 9.5rem;

                    @media #{$vpMobile600} {
                      font-size: 3.75rem;
                      margin-right: 0.5rem;
                      height: auto;
                      line-height: 4.875rem;
                    }
                  }

                  .body1 {
                    margin: 0;

                    @media #{$vpMobile600} {
                      margin-bottom: 0.75rem;
                    }

                    br {
                      @media #{$vpMobile600} {
                        display: none;
                      }
                    }
                  }
                }
              }
              .info-wrap {
                padding: 2.5rem 0;
                 border-bottom: 1px solid $secondarySemiLight;
                 &:last-child {
                  border: 0;
                }
                @media #{$vpMobile600} {
                  padding: 1rem 0;
                }
                .heading3 {
                  margin-bottom: 0;
                }
                .stats-info {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  gap: 2rem 0;
                  padding-top: 2rem;
  
                  @media #{$vpMobile600} {
                    // border-top: 1px solid $secondarySemiLight;
                    padding-top: 1.5rem;
                    gap: 1rem;
                  }
  
                  .info-items {
                    display: flex;
                    max-width: 49%;
                    flex: 0 0 49%;
                    align-items: flex-start;
  
                    .body3 {
                      margin: 0;
                    }
  
                    @media #{$vpTablet1229} {
                      max-width: 100%;
                      flex: 0 0 100%;
                    }
  
                    img {
                      width: 48px;
                      height: 48px;
                      margin-right: 1.125rem;
                      background: #F9F7F3;
                      border-radius: 50%;
                      padding: 0.5rem;
                      @media #{$vpMobile600} {
                        padding: 0.7rem;
                      }
                    }
                  }
                }
              }
            }

            .slide-promo {
              display: flex;
              width: 100%;
              background-color: $baselineSecondaryLight;
              border-radius: 1.25rem;
              position: relative;

              @media #{$vpMobile600} {
                border-radius: 0.75rem;
              }
              .slide-next {
                position: absolute;
                right: 1.25rem;
                bottom: 1.25rem;
                height: 2.75rem;
                border: 1px solid $baselinePrimary;
                padding: 0.75rem;
                border-radius: 50%;
                cursor: pointer;
                width: 2.75rem;
                display: flex;
                align-items: center;
                justify-content: center;
                /*rtl:raw:transform: rotate(180deg); */

                @media #{$vpTablet991} {
                  padding: 0.5rem;
                }

                @media #{$vpMobile600} {
                  right: 16px;
                  bottom: 12px;
                  width: 2rem;
                  height: auto;
                }
                .insvg {
                  max-width: 16px;
                  height: auto;
                  flex: 0 0 auto;
                }
              }

              .slide-img {
                width: 15.875rem;
                overflow: hidden;
                border-top-left-radius: 1.25rem;
                border-bottom-left-radius: 1.25rem;

                @media #{$vpMobile600} {
                  border-top-left-radius: 0.75rem;
                  border-bottom-left-radius: 0.75rem;
                  width: 24.13rem;
                }

                img {
                  border-top-left-radius: 1.25rem;
                  border-bottom-left-radius: 1.25rem;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;

                  @media #{$vpMobile600} {
                    border-top-left-radius: 0.75rem;
                    border-bottom-left-radius: 0.75rem;
                  }
                }
              }

              .slide-content-wrap {
                width: 36rem;
                padding: 2rem 7.31rem 4.38rem 1.94rem;

                @media #{$vpTablet991} {
                  padding: 1.5rem;
                }

                @media #{$vpMobile600} {
                  padding: 1rem;
                  padding-bottom: 2.602rem;
                }

                .heading4 {
                  @media #{$vpTablet991} {
                    font-size: 1rem;
                  }

                  @media #{$vpMobile600} {
                    font-size: 0.875rem;
                    max-width: 8.875rem;
                    line-height: 1.4;
                  }
                }
              }
            }
          }

          .slick-slide {
            &.slick-active {
              +.slick-slide {
                .sec-post-content-info {
                  transform: translateX(2.5rem);
                  opacity: 0;
                }
              }

              .sec-post-content-info {
                transform: translateX(0);
                opacity: 1;
              }
            }
          }

          .slick-dots {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin-top: 3rem;
          }
        }
      }

      .img-slider-wrap {
        width: 50.5rem;
        z-index: 0;

        @media #{$vpTablet991} {
          width: 35rem;
        }

        @media #{$vpMobile600} {
          max-width: 100%;
          border-bottom-right-radius: 0;
        }

        .slider-arrow-pagination {
          @media #{$vpMobile600} {
            display: block;
            padding-top: 0;
          }
        }

        .keyproject-image {
          height: 100%;

          @media #{$vpMobile600} {
            height: 31.25rem;
          }

          .sec-post-img {
            width: 100%;

            .image-block {
              position: relative;
              // width: 50.5rem;
              height: 100%;
              overflow: hidden;

              img {
                border-radius: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                z-index: 2;
                position: relative;
              }

              .img-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg,
                    rgba(0, 0, 0, 0.6) 15.18%,
                    transparent 47.21%);
                opacity: 1;
                z-index: 2;
              }

              .heading3 {
                position: absolute;
                top: 0;
                left: 0;
                color: #fff;
                padding: 3rem 3rem 3rem 7.44rem;
                z-index: 3;

                @media #{$vpTablet991} {
                  padding: 2.5rem;
                }

                @media #{$vpMobile600} {
                  padding: 2rem 1.25rem 0 1.25rem;
                }
              }
            }
          }

          .slick-list {
            padding: 0 !important;
            height: 100%;

            @media #{$vpMobile600} {
              overflow: hidden;
            }
          }

          .slick-track {
            height: 100% !important;
          }

          .slick-slide {
            padding: 0;
            height: 100%;

            @media #{$vpMobile600} {
              padding: 0;
            }
          }
        }

        .slider-arrow-pagination {
          .arrow-wrap {
            bottom: 5rem;
            right: 3rem;

            .pagination-wrap {
              font-weight: 500;
            }

            @media #{$vpTablet991} {
              bottom: 3rem;
            }

            @media #{$vpMobile600} {
              right: 0;
              padding: 0 1.25rem;
              bottom: 2rem;
              width: 100%;
              justify-content: space-between;
            }

            .slide-arrow {
              .custom-arrows {
                width: 4.25rem;
                height: 4.25rem;
                background-color: transparent;
                border: 1px solid $white;
                display: flex;
                align-items: center;
                justify-content: center;

                @media #{$vpMobile600} {
                  width: 3rem;
                  height: 3rem;
                }

                .js-tosvg {
                  width: 1.25rem;
                  height: 1.25rem;

                  path {
                    fill: $white;
                  }
                }

                &:hover {
                  border: 1px solid $baselineSecondary;

                  .js-tosvg {
                    path {
                      fill: $baselineSecondary;
                    }
                  }
                  @media #{$vpMobile600} {
                    background-color: transparent !important;
                    border: 1px solid $white;

                    .js-tosvg {
                      path {
                        fill: #fff;
                      }
                    }
                  }
                }
              }
            }

            .pagination-wrap {
              display: flex;

              .custom-pagination {
                color: $white;
              }

              .custom-pagination-label {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
  &.dark-bg {
    .sec-wrapper {
      .slider-wrap {
        .content-slider-wrap {
          .keyproject-content {
            .sec-post-content-box {
              .slide-promo {
                background-color: rgba(255, 255, 255, 0.1);
              }
            }
          }
        }
      }
    }
  }
  &.gray-bg {
    .sec-wrapper {
      .slider-wrap {
        .content-slider-wrap {
          .keyproject-content {
            .sec-post-content-box {
              .slide-promo {
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }
  // &.alula-history {
  //   .sec-post-content-info {
  //     .body2 {
  //       padding-bottom: 5rem;
  //       margin: 0;
  
  //       @media #{$vpMobile600} {
  //         padding-bottom: 2.5rem;
  //       }
  //     }
  //   }
  // }
}

