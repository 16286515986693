.sec-alula-map {
  .map-marker-wrapper {
    overflow: hidden;
  }

  .sec-wrapper {
    position: relative;
    height: 100%;
    // background-color: #F4EFE4;
    z-index: 0;
    // &::after {
    //   content: '';
    //   background: linear-gradient(to right, #d0cbc0, #f3efe5);
    //   content: "";
    //   height: 100%;
    //   left: 0;
    //   position: absolute;
    //   top: 0;
    //   width: 10%;
    //   z-index: 2;
    //   @media #{$vpTablet991} {
    //     background: linear-gradient(to bottom, #d0cbc0, #f3efe5);
    //     height: 10%;
    //     left: 0;
    //     position: absolute;
    //     top: 0;
    //     width: 100%;
    //   }
    //   @media #{$vpMobile600} {
    //     top: -41px;
    //   }
    // }
    // &::before {
    //   content: '';
    //   background: linear-gradient(to left, #d0cbc0, #f3efe5);
    //   content: "";
    //   height: 100%;
    //   right: 0;
    //   position: absolute;
    //   top: 0;
    //   width: 10%;
    //   z-index: 3;
    //   @media #{$vpTablet991} {
    //     display: none;
    //   }
    // }
    // @media #{$vpMobile390} {
    //   height: 52.75rem;
    // }

    .map-tab-content {
      display: none;
      transition: all 0.35s ease-in-out;
      height: 100%;
    }

    .image-bg {
      position: relative;
      width: 1920px;
      z-index: 2;
       left: 50%;
       transform: translateX(-50%);
        /*rtl:raw:
        transform: translateX(60%);
        */
      @media #{$vpTablet991} {
        bottom: auto;
        top: 50%;
        transform: translate(-50%, 7%);
        width: 991px;
        z-index: 0;
        height: 100%;
      }

      @media #{$vpMobile600} {
       width: 600px;
       transform: translate(-50%, -13%);
      }

      @media #{$vpMobile390} {
        top: 0;
      }

      picture {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .image--dark {
      display: none;
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
      }
    }

    h2 {
      position: absolute;
      z-index: 3;
      top: 5rem;
      left: 7.5rem;
      color: $baselineSecondary;

      @media #{$vpTablet991} {
        left: 3.5rem;
      }

      @media #{$vpMobile600} {
        top: 2.5rem;
        left: 1.25rem;
      }
    }

    .location-icon-wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: none;
    }

    .map-slider-wrap {
      height: 100%;
      width: 100%;
      top: 0;
      left: 50%;
      position: absolute;
      z-index: 3;
      transform: translateX(-50%);

      .marker {
        width: 2.5rem;
        height: 2.5rem;
        background-color: $white;
        position: absolute;
        border-radius: 50%;
        display: none;

        @media #{$vpMobile600} {
          width: 1rem;
          height: 1rem;
        }

        p {
          @media #{$vpMobile600} {
            font-size: 0.75rem;
          }
        }

        &.l1 {
          top: 30%;
          /*rtl:begin:ignore*/
          right: auto;
          left: 49%;
          transform: translateX(-50%);
          /*rtl:end:ignore*/
          /*rtl:raw:
          left: 59%;
          */
          @media #{$vpTablet991} {
            top: 37%;
            /*rtl:end:ignore*/
            /*rtl:raw:
            left: 49%;
            */
          }

          @media #{$vpMobile600} {
            /*rtl:begin:ignore*/
            left: 53%;
            /*rtl:end:ignore*/
            top: 27%;
          }

          @media #{$vpMobile390} {
            /*rtl:begin:ignore*/
            left: 53%;
            /*rtl:end:ignore*/
          }
        }

        &.l2 {
          /*rtl:begin:ignore*/
          right: auto;
          left: 35%;
          /*rtl:end:ignore*/
          top: 11%;
           /*rtl:end:ignore*/
          /*rtl:raw:
          left: 45%;
          */
          @media #{$vpTablet991} {
            /*rtl:begin:ignore*/
            top: 18%;
            left: 37%;
            /*rtl:end:ignore*/
          }

          @media #{$vpMobile600} {
            /*rtl:begin:ignore*/
            left: 47%;
            /*rtl:end:ignore*/
          }

          @media #{$vpMobile390} {
            /*rtl:begin:ignore*/
            left: 47%;
            /*rtl:end:ignore*/
            top: 17%;
          }
        }

        &.l3 {
          /*rtl:begin:ignore*/
          left: 53%;
          right: auto;
          /*rtl:end:ignore*/
          top: 72%;
           /*rtl:end:ignore*/
          /*rtl:raw:
          left: 63%;
          */
          @media #{$vpTablet991} {
            /*rtl:begin:ignore*/
            top: 79%;
            left: 53%;
          /*rtl:end:ignore*/
          }

          @media #{$vpMobile600} {
            /*rtl:begin:ignore*/
            left: 55%;
          /*rtl:end:ignore*/
            top: 51%;
          }

          @media #{$vpMobile390} {
            /*rtl:begin:ignore*/
            left: 55%;
          /*rtl:end:ignore*/
          }
        }

        &.l4 {
          /*rtl:begin:ignore*/
          left: 33%;
          right: auto;
          /*rtl:end:ignore*/
          top: 21%;
           /*rtl:end:ignore*/
          /*rtl:raw:
          left: 43%;
          */
          @media #{$vpTablet991} {
            /*rtl:begin:ignore*/
            left: 35%;
            top: 29%;
          /*rtl:end:ignore*/
          }

          @media #{$vpMobile600} {
            /*rtl:begin:ignore*/
            left: 44%;
          /*rtl:end:ignore*/
          top: 24%;
          }

          @media #{$vpMobile390} {
            /*rtl:begin:ignore*/
            left: 44%;
          /*rtl:end:ignore*/
          }
        }

        &.l5 {
          /*rtl:begin:ignore*/
          left: 61%;
          right: auto;
          /*rtl:end:ignore*/
          top: 79%;
           /*rtl:end:ignore*/
          /*rtl:raw:
          left: 71%;
          */
          @media #{$vpTablet991} {
            /*rtl:begin:ignore*/
            left: 60%;
          /*rtl:end:ignore*/
          top: 85%;
          }

          @media #{$vpMobile600} {
            /*rtl:begin:ignore*/
            left: 58%;
          /*rtl:end:ignore*/
          top: 57%;
          }

          @media #{$vpMobile390} {
            /*rtl:begin:ignore*/
            left: 58%;
          /*rtl:end:ignore*/
          }
        }

        &.l6 {
          /*rtl:begin:ignore*/
          left: 49%;
          /*rtl:end:ignore*/
          top: 79%;
           /*rtl:end:ignore*/
          /*rtl:raw:
          left: 59%;
          */
          @media #{$vpTablet991} {
            /*rtl:begin:ignore*/
            left: 49%;
            top: 87%;
          /*rtl:end:ignore*/
          }

          @media #{$vpMobile600} {
            /*rtl:begin:ignore*/
            left: 53%;
          /*rtl:end:ignore*/
          top: 56%;
          }

          @media #{$vpMobile390} {
            /*rtl:begin:ignore*/
            left: 53%;
          /*rtl:end:ignore*/
          }
        }

        &.l7 {
          /*rtl:begin:ignore*/
          left: 51%;
          /*rtl:end:ignore*/
          top: 86%;
           /*rtl:end:ignore*/
          /*rtl:raw:
          left: 61%;
          */
          @media #{$vpTablet991} {
            /*rtl:begin:ignore*/
            left: 51%;
            top: 93%;
          /*rtl:end:ignore*/
          }

          @media #{$vpMobile600} {
            /*rtl:begin:ignore*/
            left: 54%;
          /*rtl:end:ignore*/
          top: 60%;
          }

          @media #{$vpMobile390} {
            /*rtl:begin:ignore*/
            left: 54%;
          /*rtl:end:ignore*/
          }
        }

        .insvg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          height: auto;
          width: 1rem;

          @media #{$vpMobile600} {
            height: .525rem;
            width: .521rem;
          }

          path {
            fill: $baselinePrimary;
          }

          ;
        }

        &.active {
          background-color: $baselinePrimary;

          .insvg {
            path {
              fill: $white;
            }

            ;
          }
        }

        &::after {
          content: '';
          position: absolute;
          top: -5px;
          left: -5px;
          width: calc(100% + 10px);
          height: calc(100% + 10px);
          border-radius: 50%;
          box-shadow: 0 0 0 0 rgb(255, 255, 255, 0.9);
          animation: pulse 1.5s infinite ease-in-out;
        }

        &::before {
          content: '';
          position: absolute;
          top: -5px;
          left: -5px;
          width: calc(100% + 10px);
          height: calc(100% + 10px);
          border-radius: 50%;
          box-shadow: 0 0 0 0 rgb(0, 0, 0, 0.9);
          animation: pulse2 1.5s infinite ease-in-out;
          border: 1px solid $white;
        }

        @keyframes pulse {
          0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgb(229, 224, 208, 0.9);
          }

          70% {
            transform: scale(1);
            box-shadow: 0 0 0 4px rgb(229, 224, 208, 0);
          }

          100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgb(229, 224, 208, 0);
          }
        }

        @keyframes pulse2 {
          0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgb(255, 255, 255, 0.9);
          }

          70% {
            transform: scale(1);
            box-shadow: 0 0 0 5px rgb(255, 255, 255, 0);
          }

          100% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgb(255, 255, 255, 0);
          }
        }


      }
    }

    .slider-content-wrap {
      position: relative;
      border: none;
      z-index: 0;
      transition: all 0.35s ease;
      .slider-wrap {
        width: 100%;

        .items-wrap {
          background-color: $white;
          padding: 2rem 1.25rem;
          border-radius: 12px;

          @media #{$vpMobile600} {
            padding: 1.19rem 1.25rem;
            border-radius: 0.75rem;
          }

          @media #{$vpMobile390} {
            width: 21.875rem;
          }

          .slider-expand {
            border: 1px solid $baselineSecondary;
            border-radius: 50%;
            width: 3rem;
            height: 3rem;
            position: absolute;
            z-index: 3;
            right: 1.125rem;
            top: 1.5rem;
            cursor: pointer;
            background-color: inherit;

            @media #{$vpMobile600} {
              width: 2.875rem;
              height: 2.875rem;
              right: 1rem;
              top: 0.625rem;
            }


            .insvg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .map-slider-content {
            .slick-list {
              height: auto !important;
            }

            .map-content-box {
              .info-items {
                display: flex;
                cursor: pointer;

                .item-img {
                  background-color: $baselinePrimary;
                  margin-right: 0.75rem;
                  border-radius: 50%;
                  width: 40px;
                  height: 40px;
                  position: relative;

                  @media #{$vpMobile600} {
                    width: 32px;
                    height: 32px;
                  }

                  .insvg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    path {
                      fill: #fff;
                    }
                  }

                  &.beige {
                    background-color: #CEB597;
                  }

                  &.red {
                    background-color: #FF0000;
                  }

                  &.maroon {
                    background-color: #CC3830;
                  }

                  &.green {
                    background-color: #00B259;
                  }

                  &.yellow {
                    background-color: #FFC480;
                  }

                  &.blue {
                    background-color: #1342B1;
                  }
                }
              }

              .sec-post-content-info {
                display: flex;
                justify-content: space-between;

                .title-wrap {
                  width: 22.75rem;

                  @media #{$vpMobile600} {
                    width: 16.625rem;
                  }

                  .heading4 {
                    color: $baselineSecondary;

                    @media #{$vpMobile600} {
                      font-size: 1.5rem;
                    }
                  }

                  .subtitle {
                    @media #{$vpMobile600} {
                      margin-bottom: 0.313rem;
                    }
                  }

                  p {
                    margin-bottom: 0;
                  }
                }

              }

              .slide-items {
                margin: 2rem 0 7.75rem;
                // display: none;
                max-height: 53.125rem;
                overflow: hidden;
                height: 100%;
                position: relative;
                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  width: 100%;
                  height: 8%;
                  z-index: 5;
                  box-shadow: inset 0px -30px 10px -8px rgba(255, 255, 255, 0.7);
                  display: block;
                  @media #{$vpTablet991} {
                    height: 6%;
                  }
                  @media #{$vpMobile600} {
                    height: 12%;
                  }
                }

                @media #{$vpMobile600} {
                  margin: 1.5rem 0;
                 
                }

                .slide-img {
                  margin-bottom: 2.5rem;

                  @media #{$vpMobile600} {
                    margin-bottom: 1.5rem;
                  }

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }

                &.no-slide {
                  display: block;
                  height: 100%;
                }

                .slide-content {
                  position: relative;
                  p {
                    color: $baselineSecondary;
                    margin: 0;
                    position: relative;
                    padding-bottom: 1.9rem;
                  }

                  @media #{$vpMobile600} {
                    max-height: 75px;
                  }

                  &.sec-height {
                    max-height: 100%;
                  }
                }
              }

              a {
                color: $baselineSecondary;
                justify-content: center;
                margin: 0 auto 1.125rem;
                max-width: max-content;
                display: flex;
                transition: all 0.35s ease;
                @media #{$vpMobile600} {
                  margin: 0 auto;
                }
                &.show {
                  display: flex;
                }
              }
            }
          }

          .slider-arrow-pagination {
            display: none;
            height: 0;

            &.show {
              display: block;
              height: 100%;
            }

            .arrow-wrap {
              width: 100%;
              bottom: 0;
              justify-content: space-between;
              z-index: 0;

              .pagination-wrap {
                display: none;
                width: 0;
              }

              .slide-arrow {
                width: 100%;
                align-items: center;
                position: relative;

                .custom-arrows {
                  /*rtl:raw:
                      transform: rotate(180deg)
*/
                  position: absolute;
                  bottom: 0;
                  background-color: $baselineSecondaryLight;

                  @media #{$vpMobile600} {
                    width: 2rem;
                    height: 2rem;
                  }

                  @media #{$vpMobile390} {
                    width: 3rem;
                    height: 3rem;
                  }

                  &.prev {
                    margin: 0;
                  }
                }

                a {
                  color: $baselineSecondary;
                }
              }
            }
          }
        }
      }

      &.modal {
        background-color: transparent;
        width: auto;
        height: auto;
        position: absolute;
        right: 7.5rem;
        z-index: 730;
        left: auto;
        bottom: auto;
        top: 5rem;
        padding: 0;
        width: 34.188rem;
        @media #{$vpTablet991} {
          width: 30rem;
          right: 3.5rem;
          /*rtl:raw:
            left: auto;
            right: 3.5rem;
            */
        }

        @media #{$vpMobile600} {
          left: 50%;
          transform: translateX(-50%);
          bottom: 1rem;
          top: auto;
          width: 22rem;
          height: auto;
        }

        @media #{$vpMobile390} {
          max-height: 65%;
          top: auto;
          width: 21.875rem;
        }

        &.active-modal {
          display: block;
        }
      }
    }
  }

  &.map-tabs {
    .sec-wrapper {
      &::after {
        display: none;
      }
      &::before {
        display: none;
      }
      .heading2 {
        &.color--modify {
          color: $white;
        }
      }

      .map-slider-wrap {
        display: none;

        &.markers-visible {
          display: block;
        }

        h2 {
          @media #{$vpMobile600} {
            top: 1rem;
          }
        }
      }

      .image-bg {
        width: 1920px;
        /*rtl:raw:transform: translateX(50%);
      */
        @media #{$vpDesktop1600} {
          /*rtl:begin:ignore*/
          transform: translateX(-55%);
          left: 50%;
          right: auto;
          /*rtl:end:ignore*/
           /*rtl:raw:transform: translateX(45%);
           left: auto;
           right: 50%;
            */
        }
        @media #{$vpTablet991} {
          width: 991px;
          transform: translateX(-50%);
          /*rtl:raw:transform: translateX(50%);
            */
        }
        @media #{$vpMobile600} {
          width: 600px;
        }

        // @media #{$vpMobile390} {
        //   transform: translateX(-50%);
        //   width: 100%;
        //   top: 0;
        // }

        img {
          object-fit: cover;
        }

        // &.map-img--2 {
        //   @media #{$vpTablet991} {
        //     width: 100%;
        //     height: 100%;
        //     /*rtl:begin:ignore*/
        //     transform: translateX(-50%);
        //     /*rtl:end:ignore*/
        //   }

        //   @media #{$vpDesktop1600} {
        //     top: 0;
        //   }
        // }
        &.image-animation {
          @media #{$vpMobile600} {
            // transform: translateX(-30%);
            height: 100vh;
          }
          @media #{$vpMobile390} {
            width: 1920px;
          }
          img {
            height: 100vh;
          }
          .animated-img-dark {
            display: none;
          }
        }
      }

      .map-slider-wrap {
        display: none;

        .marker {
          background-color: hsla(0, 0%, 100%, 1);
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 0 1px 9px hsla(0, 0%, 100%, .5);
          @media #{$vpMobile600} {
            box-shadow: 0 0 1px 5px hsla(0, 0%, 100%, .5);
            width: 1.125rem;
            height: 1.125rem;
          }

          &::before {
            height: calc(100% + 20px);
            left: -10px;
            top: -10px;
            width: calc(100% + 20px);
            @media #{$vpMobile600} {
              height: calc(100% + 10px);
              left: -5px;
              top: -5px;
              width: calc(100% + 10px);
            }
          }

          &::after {
            height: calc(100% + 20px);
            left: -10px;
            top: -10px;
            width: calc(100% + 20px);
            @media #{$vpMobile600} {
              height: calc(100% + 10px);
              left: -5px;
              top: -5px;
              width: calc(100% + 10px);
            }
          }

          &.l1 {
            /*rtl:begin:ignore*/
            left: 50%;
            right: auto;
            transform: translateX(0);
            /*rtl:end:ignore*/
            top: 90%;
            .body1 {
              color: $baselineSecondary;
            }

            @media #{$vpDesktop1600} {
              /*rtl:begin:ignore*/
              left: 43%;
              /*rtl:end:ignore*/
            }

            @media #{$vpTablet991} {
              /*rtl:begin:ignore*/
              left: 49%;
              /*rtl:end:ignore*/
              top: 93%;
            }

            @media #{$vpMobile600} {
              /*rtl:begin:ignore*/
              left: 48%;
              /*rtl:end:ignore*/
              top: 71%;
            }

            @media #{$vpMobile390} {
              /*rtl:begin:ignore*/
              left: 49%;
              /*rtl:end:ignore*/
              top: 73%;
            }
          }

          &.l2 {
            /*rtl:begin:ignore*/
            right: auto;
            left: 53%;
            /*rtl:end:ignore*/
            top: 79%;
            .body1 {
              color: $baselineSecondary;
            }

            @media #{$vpDesktop1600} {
              /*rtl:begin:ignore*/
              left: 48%;
              /*rtl:end:ignore*/
              top: 83%;
            }

            @media #{$vpTablet991} {
              /*rtl:begin:ignore*/
              top: 83%;
              left: 51%;
              /*rtl:end:ignore*/
            }

            @media #{$vpMobile600} {
              /*rtl:begin:ignore*/
              left: 50%;
              /*rtl:end:ignore*/
              top: 64%;
            }

            @media #{$vpMobile390} {
              /*rtl:begin:ignore*/
              left: 59%;
              /*rtl:end:ignore*/
              top: 66%;
            }
          }

          &.l3 {
            /*rtl:begin:ignore*/
            left: 49%;
            right: auto;
              /*rtl:end:ignore*/
            top: 63%;

            .body1 {
              color: $baselineSecondary;
            }

            @media #{$vpDesktop1600} {
              /*rtl:begin:ignore*/
              left: 43%;
              /*rtl:end:ignore*/
              top: 61%;
            }

            @media #{$vpTablet991} {
              /*rtl:begin:ignore*/
              left: 49%;
              /*rtl:end:ignore*/
              top: 68%;
            }

            @media #{$vpMobile600} {
              /*rtl:begin:ignore*/
              left: 49%;
              /*rtl:end:ignore*/
              top: 55%;
            }

            @media #{$vpMobile390} {
              /*rtl:begin:ignore*/
              left: 46%;
              /*rtl:end:ignore*/
              top: 56%;
            }
          }

          &.l4 {
            /*rtl:begin:ignore*/
            left: 48%;
            right: auto;
              /*rtl:end:ignore*/
            top: 42%;

            .body1 {
              color: $baselineSecondary;
            }

            @media #{$vpDesktop1600} {
              /*rtl:begin:ignore*/
              left: 43%;
              /*rtl:end:ignore*/
            }

            @media #{$vpTablet991} {
              /*rtl:begin:ignore*/
              left: 46%;
              /*rtl:end:ignore*/
              top: 52%;
            }

            @media #{$vpMobile600} {
              /*rtl:begin:ignore*/
              left: 45%;
              /*rtl:end:ignore*/
              top: 46%;
            }

            @media #{$vpMobile390} {
              /*rtl:begin:ignore*/
              left: 41%;
              /*rtl:end:ignore*/
              top: 45%;
            }
          }

          &.l5 {
            /*rtl:begin:ignore*/
            left: 60%;
            right: auto;
            /*rtl:end:ignore*/
            top: 12%;

            .body1 {
              color: $baselineSecondary;
            }

            @media #{$vpDesktop1600} {
              /*rtl:begin:ignore*/
              left: 55%;
              /*rtl:end:ignore*/
            }

            @media #{$vpTablet991} {
              /*rtl:begin:ignore*/
              left: 60%;
              /*rtl:end:ignore*/
              top: 28%;
            }

            @media #{$vpMobile600} {
              /*rtl:begin:ignore*/
              left: 58%;
              /*rtl:end:ignore*/
              top: 29%;
            }

            @media #{$vpMobile390} {
              /*rtl:begin:ignore*/
              left: 81%;
              /*rtl:end:ignore*/
              top: 30%;
            }
          }

          &.l6 {
            /*rtl:begin:ignore*/
            left: 57%;
            right: auto;
              /*rtl:end:ignore*/
            top: 44%;

            .body1 {
              color: $baselineSecondary;
            }

            @media #{$vpDesktop1600} {
              /*rtl:begin:ignore*/
              left: 52%;
              /*rtl:end:ignore*/
            }

            @media #{$vpTablet991} {
              /*rtl:begin:ignore*/
              left: 56%;
              /*rtl:end:ignore*/
              top: 53%;
            }

            @media #{$vpMobile600} {
              /*rtl:begin:ignore*/
              left: 55%;
              /*rtl:end:ignore*/
              top: 46%;
            }

            @media #{$vpMobile390} {
              /*rtl:begin:ignore*/
              left: 73%;
              /*rtl:end:ignore*/
              top: 44%;
            }
          }

          &.l7 {
            /*rtl:begin:ignore*/
            left: 49%;
            right: auto;
            /*rtl:end:ignore*/
            top: 72%;

            .body1 {
              color: $baselineSecondary;
            }

            @media #{$vpDesktop1600} {
              /*rtl:begin:ignore*/
              left: 45%;
              /*rtl:end:ignore*/
            }

            @media #{$vpTablet991} {
              /*rtl:begin:ignore*/
              left: 48%;
              /*rtl:end:ignore*/
              top: 76%;
            }

            @media #{$vpMobile600} {
              /*rtl:begin:ignore*/
              left: 45%;
              /*rtl:end:ignore*/
              top: 60%;
            }

            @media #{$vpMobile390} {
              /*rtl:begin:ignore*/
              left: 53%;
              /*rtl:end:ignore*/
              top: 62%;
            }
          }

          &.l8 {
            /*rtl:begin:ignore*/
            left: 58%;
            right: auto;
              /*rtl:end:ignore*/
            top: 94%;
            @media #{$vpDesktop1600} {
              /*rtl:begin:ignore*/
              left: 53%;
              /*rtl:end:ignore*/
              top: 94%;
            }

            @media #{$vpTablet991} {
              /*rtl:begin:ignore*/
              left: 57%;
              /*rtl:end:ignore*/
              top: 94%;
            }

            @media #{$vpMobile600} {
              /*rtl:begin:ignore*/
              left: 56%;
              /*rtl:end:ignore*/
              top: 71%;
            }

            @media #{$vpMobile390} {
              /*rtl:begin:ignore*/
              left: 74%;
              /*rtl:end:ignore*/
              top: 75%;
            }
          }

          &.active {
            background-color: #9f8332;
            .body1 {
              color: #EAE8FD;
            }
            p {
            }
          }
        }

        &.markers-visible {
          display: block;
        }
      }

      .btn-wrapper {
        position: absolute;
        z-index: 5;
        top: 13.125rem;
        left: 7.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media #{$vpTablet991} {
          left: 3.5rem;
        }

        @media #{$vpMobile600} {
          left: 0;
          top: 6rem;
          flex-direction: row;
          width: 100%;
          overflow: scroll;
          padding-left: 1.25rem;
          padding-bottom: 2px;
        }

        .tab-button {
          margin-bottom: 1.5rem;
          background-color: transparent;
          color: $baselineSecondary;
          opacity: 0.4;
          outline: 0;
          border: 0;
          position: relative;
          padding: 0;
          @media #{$vpMobile600} {
            font-size: .875rem;
            border: .063rem solid $baselineSecondary;
            border-radius: .25rem;
            color: #2c2e34;
            padding: 0.75rem 1rem;
            position: relative;
            transition: all .35s ease;
            width: max-content;
            margin-bottom: 0;
            margin-right: 0.75rem;
            flex: 0 0 auto;
          }

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $baselineSecondary;
            left: 0;
            bottom: 0;
            display: none;
          }

          &.active {
            opacity: 1;
            color: $baselineSecondary;

            @media #{$vpMobile600} {
              background-color: #e5e0d0;
              padding-right: 2.75rem;
              border: 0;
            }

            &::after {
              display: block;
              opacity: 1;

              @media #{$vpMobile600} {
                background-color: #9f8332;
                border-radius: 50%;
                content: "";
                height: .5rem;
                opacity: 0;
                position: absolute;
                right: 1.25rem;
                top: 50%;
                transform: translateY(-50%);
                width: .5rem;
                display: block;
                opacity: 1;
                left: auto;
                bottom: auto;
              }
            }
          }
        }

        &.color--modify {
          .tab-button:nth-child(1) {
            color: $white;
            opacity: 0.4;

            @media #{$vpMobile600} {
              color: $white;
              border: .063rem solid $secondarySemiLight;
            }
          }

          .tab-button:nth-child(2) {
            border: 0;
            color: #fff;

            &::after {
              background-color: $white;

              @media #{$vpMobile600} {
                background-color: $baselinePrimary;
              }
            }
          }

          .tab-button:nth-child(3) {
            color: $white;
            opacity: 0.4;

            @media #{$vpMobile600} {
              color: $white;
              border: .063rem solid $secondarySemiLight;
            }
          }

          .tab-button {
            &.active {
              border: 0;

              @media #{$vpMobile600} {
                color: $baselineSecondary;
              }
            }
          }
        }
      }

      .slider-content-wrap {
        .slider-wrap {
          height: 100%;

          .items-wrap {
            .map-slider-content {
              position: relative;

              .map-content-box {
                .sec-post-content-info {
                  .title-wrap {
                    p {
                      display: none;
                    }
                  }
                }

                .slide-items {
                  margin: 2rem 0 0 0;
                  max-height: 25.625rem;

                  @media #{$vpMobile600} {
                    max-height: 60vh;
                  }

                  @media #{$vpMobile390} {
                    max-height: 40vh;
                  }
                  .slide-content {
                    @media #{$vpMobile600} {
                      max-height: 100%;
                    }
                  }

                  .info-items {
                    margin-top: 2rem;

                    .item-img {
                      .insvg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      }

                      &.beige {
                        background-color: #CEB597;
                      }

                      &.red {
                        background-color: #FF0000;
                      }

                      &.maroon {
                        background-color: #CC3830;
                      }

                      &.green {
                        background-color: #00B259;
                      }

                      &.yellow {
                        background-color: #FFC480;
                      }

                      &.blue {
                        background-color: #1342B1;
                      }
                    }

                    .tab-button {
                      background-color: transparent;
                      border: 0;
                      color: #2C2E34;
                      &:focus {
                        outline: none;
                        border: none;
                      }
                    }
                  }

                  &.slide-popup {
                    margin: 2rem 0 5.44rem;
                    @media #{$vpMobile600} {
                      margin: 2rem 0 2rem;
                    }
                  }
                }
              }
            }
          }
        }

        &.modal {

          /*rtl:raw:transform: translateX(50%);
          left: 3.5rem;
          right: auto;
          transform: translateX(0);
      */
          @media #{$vpMobile600} {
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      &.active {
        .location-icon-wrap {
          display: block;
        }

        h2 {
          color: $white;
        }

        .tab-button {
          &::after {
            background-color: $secondarySemiLight;
          }
        }

        .slide-items {
          display: block;
        }
      }
    }
  }

  &.project-tabs {
    .sec-wrapper {
      &.wrap-2 {
        .map-marker-wrapper {
          .map-slider-wrap {
            .marker {
              &.l1 {
                top: 26%;
                 /*rtl:begin:ignore*/
                left: 48%;
                right: auto;
                 /*rtl:end:ignore*/

                @media #{$vpDesktop1600} {
                 /*rtl:raw:
                  left: 58%;
                  */
                }

                @media #{$vpTablet991} {
                   /*rtl:begin:ignore*/
                   left: 59%;
                 /*rtl:end:ignore*/
                  top: 31%;
                  /*rtl:raw:
                  left: 48%;
                  top: 54%;
                  */
                }

                @media #{$vpMobile600} {
                   /*rtl:begin:ignore*/
                   left: 52%;
                 /*rtl:end:ignore*/
                  top: 41%;
                }
                @media #{$vpMobile390} {
                   /*rtl:begin:ignore*/
                   left: 53%;
                 /*rtl:end:ignore*/
                }
              }

              &.l2 {
                top: 10%;
                 /*rtl:begin:ignore*/
                 left: 37%;
                 right: auto;
                 /*rtl:end:ignore*/
                 @media #{$vpDesktop1600} {
                  /*rtl:raw:
                   left: 47%;
                   */
                 }
                @media #{$vpTablet991} {
                   /*rtl:begin:ignore*/
                   left: 46%;
                 /*rtl:end:ignore*/
                  top: 11%;
                  /*rtl:raw:
                  left: 38%;
                  top: 37%;
                  */
                }

                @media #{$vpMobile600} {
                   /*rtl:begin:ignore*/
                   left: 40%;
                 /*rtl:end:ignore*/
                  top: 37%;
                }
                @media #{$vpMobile390} {
                  /*rtl:begin:ignore*/
                  left: 45%;
                /*rtl:end:ignore*/
               }
              }

              &.l3 {
                top: 20%;
                /*rtl:begin:ignore*/
                right: auto;
                 left: 33%;
                 /*rtl:end:ignore*/
                 @media #{$vpDesktop1600} {
                  /*rtl:raw:
                   left: 43%;
                   */
                 }
                @media #{$vpTablet991} {
                   /*rtl:begin:ignore*/
                   left: 45%;
                 /*rtl:end:ignore*/
                  top: 22%;
                  /*rtl:raw:
                  left: 35%;
                  top: 48%;
                  */
                }

                @media #{$vpMobile600} {
                   /*rtl:begin:ignore*/
                   left: 43%;
                 /*rtl:end:ignore*/
                  top: 30%;
                }
                @media #{$vpMobile390} {
                  /*rtl:begin:ignore*/
                  left: 47%;
                /*rtl:end:ignore*/
               }
              }

              &.l4 {
                top: 69%;
                 /*rtl:begin:ignore*/
                 left: 49%;
                 right: auto;
                 /*rtl:end:ignore*/
                 @media #{$vpDesktop1600} {
                  /*rtl:raw:
                   left: 59%;
                   */
                 }
                @media #{$vpTablet991} {
                  /*rtl:begin:ignore*/
                  left: 57%;
                /*rtl:end:ignore*/
                  top: 79%;
                  /*rtl:raw:
                  left: 49%;
                  top: 104%;
                  */
                }

                @media #{$vpMobile600} {
                  /*rtl:begin:ignore*/
                  left: 53%;
                /*rtl:end:ignore*/
                  top: 69%;
                }
              }

              &.l5 {
                top: 69%;
                 /*rtl:begin:ignore*/
                 left: 61%;
                 right: auto;
                 /*rtl:end:ignore*/
                 @media #{$vpDesktop1600} {
                  /*rtl:raw:
                   left: 71%;
                   */
                 }
                @media #{$vpTablet991} {
                  /*rtl:begin:ignore*/
                  left: 64%;
                /*rtl:end:ignore*/
                  top: 80%;
                  /*rtl:raw:
                  left: 58%;
                  top: 104%;
                  */
                }

                @media #{$vpMobile600} {
                  top: 69%;
                  /*rtl:begin:ignore*/
                  left: 59%;
                /*rtl:end:ignore*/
                }

                @media #{$vpMobile390} {
                  /*rtl:begin:ignore*/
                  left: 57%;
                /*rtl:end:ignore*/
                }
              }

              &.l6 {
                top: 75%;
                 /*rtl:begin:ignore*/
                 left: 51%;
                 right: auto;
                 /*rtl:end:ignore*/
                 @media #{$vpDesktop1600} {
                  /*rtl:raw:
                   left: 61%;
                   */
                 }
                @media #{$vpTablet991} {
                  /*rtl:begin:ignore*/
                  left: 59%;
                /*rtl:end:ignore*/
                  top: 87%;
                  /*rtl:raw:
                  left: 51%;
                  top: 112%;
                  */
                }

                @media #{$vpMobile600} {
                  /*rtl:begin:ignore*/
                  left: 54%;
                /*rtl:end:ignore*/
                  top: 74%;
                }
              }

              &.l7 {
                top: 63%;
                 /*rtl:begin:ignore*/
                 left: 54%;
                 right: auto;
                 /*rtl:end:ignore*/
                 @media #{$vpDesktop1600} {
                  /*rtl:raw:
                   left: 64%;
                   */
                 }
                @media #{$vpTablet991} {
                  /*rtl:begin:ignore*/
                  left: 61%;
                /*rtl:end:ignore*/
                  top: 71%;
                  /*rtl:raw:
                  left: 53%;
                  top: 96%;
                  */
                }

                @media #{$vpMobile600} {
                  /*rtl:begin:ignore*/
                  left: 55%;
                /*rtl:end:ignore*/
                  top: 65%;
                }
              }

              &.l8 {
                top: 20%;
                /*rtl:begin:ignore*/
                right: auto;
                 left: 33%;
                 /*rtl:end:ignore*/
                 @media #{$vpDesktop1600} {
                  /*rtl:raw:
                   left: 43%;
                   */
                 }
                @media #{$vpTablet991} {
                   /*rtl:begin:ignore*/
                   left: 45%;
                 /*rtl:end:ignore*/
                  top: 22%;
                  /*rtl:raw:
                  left: 35%;
                  top: 48%;
                  */
                }

                @media #{$vpMobile600} {
                   /*rtl:begin:ignore*/
                   left: 43%;
                 /*rtl:end:ignore*/
                  top: 30%;
                }
                @media #{$vpMobile390} {
                  /*rtl:begin:ignore*/
                  left: 47%;
                /*rtl:end:ignore*/
               }
              }

              &.l9 {
                top: 69%;
                 /*rtl:begin:ignore*/
                 left: 61%;
                 right: auto;
                 /*rtl:end:ignore*/
                 @media #{$vpDesktop1600} {
                  /*rtl:raw:
                   left: 71%;
                   */
                 }
                @media #{$vpTablet991} {
                  /*rtl:begin:ignore*/
                  left: 64%;
                /*rtl:end:ignore*/
                  top: 80%;
                  /*rtl:raw:
                  left: 58%;
                  top: 104%;
                  */
                }

                @media #{$vpMobile600} {
                  top: 69%;
                  /*rtl:begin:ignore*/
                  left: 59%;
                /*rtl:end:ignore*/
                }

                @media #{$vpMobile390} {
                  /*rtl:begin:ignore*/
                  left: 57%;
                /*rtl:end:ignore*/
                }
              }

              &.l10 {
                top: 63%;
                 /*rtl:begin:ignore*/
                 left: 54%;
                 right: auto;
                 /*rtl:end:ignore*/
                 @media #{$vpDesktop1600} {
                  /*rtl:raw:
                   left: 64%;
                   */
                 }
                @media #{$vpTablet991} {
                  /*rtl:begin:ignore*/
                  left: 61%;
                /*rtl:end:ignore*/
                  top: 71%;
                  /*rtl:raw:
                  left: 53%;
                  top: 96%;
                  */
                }

                @media #{$vpMobile600} {
                  /*rtl:begin:ignore*/
                  left: 55%;
                /*rtl:end:ignore*/
                  top: 65%;
                }
              }

              &.l11 {
                top: 26%;
                 /*rtl:begin:ignore*/
                left: 48%;
                right: auto;
                 /*rtl:end:ignore*/

                @media #{$vpDesktop1600} {
                 /*rtl:raw:
                  left: 58%;
                  */
                }

                @media #{$vpTablet991} {
                   /*rtl:begin:ignore*/
                   left: 59%;
                 /*rtl:end:ignore*/
                  top: 31%;
                  /*rtl:raw:
                  left: 48%;
                  top: 54%;
                  */
                }

                @media #{$vpMobile600} {
                   /*rtl:begin:ignore*/
                   left: 52%;
                 /*rtl:end:ignore*/
                  top: 41%;
                }
                @media #{$vpMobile390} {
                   /*rtl:begin:ignore*/
                   left: 53%;
                 /*rtl:end:ignore*/
                }
              }

              &.l12 {
                top: 10%;
                 /*rtl:begin:ignore*/
                 left: 37%;
                 right: auto;
                 /*rtl:end:ignore*/
                 @media #{$vpDesktop1600} {
                  /*rtl:raw:
                   left: 47%;
                   */
                 }
                @media #{$vpTablet991} {
                   /*rtl:begin:ignore*/
                   left: 46%;
                 /*rtl:end:ignore*/
                  top: 11%;
                  /*rtl:raw:
                  left: 38%;
                  top: 37%;
                  */
                }

                @media #{$vpMobile600} {
                   /*rtl:begin:ignore*/
                   left: 40%;
                 /*rtl:end:ignore*/
                  top: 37%;
                }
                @media #{$vpMobile390} {
                  /*rtl:begin:ignore*/
                  left: 45%;
                /*rtl:end:ignore*/
               }
              }

              &.l13 {
                top: 69%;
                 /*rtl:begin:ignore*/
                 left: 49%;
                 right: auto;
                 /*rtl:end:ignore*/
                 @media #{$vpDesktop1600} {
                  /*rtl:raw:
                   left: 59%;
                   */
                 }
                @media #{$vpTablet991} {
                  /*rtl:begin:ignore*/
                  left: 57%;
                /*rtl:end:ignore*/
                  top: 79%;
                  /*rtl:raw:
                  left: 49%;
                  top: 104%;
                  */
                }

                @media #{$vpMobile600} {
                  /*rtl:begin:ignore*/
                  left: 53%;
                /*rtl:end:ignore*/
                  top: 69%;
                }
              }

              &.l14 {
                top: 75%;
                 /*rtl:begin:ignore*/
                 left: 51%;
                 right: auto;
                 /*rtl:end:ignore*/
                 @media #{$vpDesktop1600} {
                  /*rtl:raw:
                   left: 61%;
                   */
                 }
                @media #{$vpTablet991} {
                  /*rtl:begin:ignore*/
                  left: 59%;
                /*rtl:end:ignore*/
                  top: 87%;
                  /*rtl:raw:
                  left: 51%;
                  top: 112%;
                  */
                }

                @media #{$vpMobile600} {
                  /*rtl:begin:ignore*/
                  left: 54%;
                /*rtl:end:ignore*/
                  top: 74%;
                }
              }
            }
          }
        }

        .image-bg {
          @media #{$vpDesktop1600} {
            /*rtl:begin:ignore*/
            transform: translateX(-50%);
            /*rtl:end:ignore*/
            /*rtl:raw:
             transform: translateX(60%);
            */
          }
          // @media #{$vpDesktop1440} {
          //   /*rtl:begin:ignore*/
          //   transform: translateX(-45%);
          //   /*rtl:end:ignore*/
          //   /*rtl:raw:
          //    transform: translateX(60%);
          //   */
          // }

          @media #{$vpTablet991} {
             /*rtl:begin:ignore*/
             transform: translateX(-35%);
             /*rtl:end:ignore*/
             width: 991px;
             height: auto;
             /*rtl:raw:
             transform: translate(50%, 25%);
            */
          }

          @media #{$vpMobile600} {
            top: 0;
             transform: translate(-55%);
             left: 50%;
             right: auto;
            width: 991px;
            bottom: auto;
            /*rtl:raw:
             transform: translate(45%);
             right: 50%;
              left: auto;
            */
          }
          @media #{$vpMobile390} {
            transform: translateX(-30%);
          }
          img {
            @media #{$vpMobile390} {
              width: auto;
              height: auto;
            }
          }
        }

        .slider-content-wrap {
          .slider-wrap {
            .items-wrap {
              background-color: $baselineSecondaryLight;

              .map-slider-content {
                .map-content-box {
                  margin-bottom: 2.5rem;
                  .sec-post-content-info {
                    .title-wrap {
                      p {
                        display: block;
                      }
                    }
                  }

                  .slide-items {
                    &::before {
                      box-shadow: inset 0 -30px 10px -8px rgb(249 247 243 / 70%);
                    }
                    &.slide-popup {
                      margin-bottom: 0;

                      @media #{$vpMobile600} {
                        max-height: 35vh;
                        margin: 1rem auto 0;
                      }
                    }

                    .slide-content {
                      max-height: 6rem;
                    }
                  }

                  .main-primary-btn {
                    margin: 2rem auto 0;
                    max-width: 100%;

                    @media #{$vpMobile600} {
                      margin: 0.625rem auto 0;
                    }
                  }

                  a {
                    margin: 2.5rem auto 0;

                    @media #{$vpMobile600} {
                      margin: 0.5rem auto 0;
                    }
                  }
                  .slide-detail {
                    padding-top: 1.437rem;
                  }
                }
              }
              .slider-arrow-pagination {
                .arrow-wrap {
                  bottom: 1.563rem;
                  .slide-arrow {
                    .custom-arrows {
                      .insvg {
                        @media #{$vpMobile390} {
                          height: 1rem;
                          width: 1rem;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.archelogicalMap {
    .sec-wrapper {
      .heading2 {
        width: 28rem;
      }

      .map-marker-wrapper {
        .map-slider-wrap {
          .marker {
            .body1 {
              display: flex;
              margin: 0;
              justify-content: center;
              align-items: center;
              height: 100%;
            }

            &.l1 {
              bottom: 23%;
              /*rtl:begin:ignore*/
              left: 58%;
              right: auto;
              /*rtl:end:ignore*/
              top: auto;
              /*rtl:raw:right: auto;*/
              /*rtl:raw:transform: translateX(0);*/

              @media #{$vpTablet991} {
                bottom: 9%;

              }

              @media #{$vpMobile600} {
                display: none;
              }

            }

            &.l2 {
              bottom: 10%;
              /*rtl:begin:ignore*/
              left: 53%;
              /*rtl:end:ignore*/
              top: auto;
              /*rtl:raw:right: auto;*/

              @media #{$vpTablet991} {
                bottom: -4%;
              }

              @media #{$vpMobile600} {
                display: none;
              }
            }

            &.l3 {
              bottom: 30%;
              /*rtl:begin:ignore*/
              left: 50%;
              /*rtl:end:ignore*/
              top: auto;
              /*rtl:raw:right: auto;*/

              @media #{$vpTablet991} {
                bottom: 17%;
              }

              @media #{$vpMobile600} {
                display: none;
              }
            }
            &.active {
              .body1{
                color: $white;
              }
            }
          }
        }
      }

      .image-bg {
         /*rtl:raw:
        transform: translateX(50%);
        */
        @media #{$vpTablet991} {
           /*rtl:raw:
          transform: translate(50%, 7%);
          */
        }
        &::after {
          display: none;
        }
        &::before {
          display: none;
        }
       

        @media #{$vpMobile600} {
          /*rtl:raw:top: 0;*/
        }
      }

      .slider-content-wrap {
        .slider-wrap {
          .items-wrap {
            .js-slide-arrow {
              .slider-arrow-pagination {
                .arrow-wrap {
                  .slide-arrow {
                    .custom-arrows {
                      svg {
                        @media #{$vpMobile600} {
                          height: 100%;
                          display: flex;
                          margin: 0 auto;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.theme--dark {
  .sec-alula-map {
    .sec-wrapper {
      background-color: rgba(0, 0, 0, 0.25);

      .image-bg {
        display: none;
        &.image--dark--3 {
          display: block;
          .animated-img {
            display: none;
          }
          .animated-img-dark {
            display: block;
          }
          .mbl-img {
            display: none;
          }
          .mbl-img--dark {
            display: none;
            @media #{$vpMobile600} {
              display: block;
              object-fit: contain;
            }
            @media #{$vpMobile390} {
              object-fit: cover;
            }
          }
        }
      }

      .image--dark {
        display: block;
        position: relative;
        @media #{$vpDesktop1600} {
          /*rtl:begin:ignore*/
          transform: translateX(50%);
          right: 50%;
          /*rtl:end:ignore*/
          position: relative;
        }
        @media #{$vpMobile600} {
          /*rtl:begin:ignore*/
          left: 50%;
          right: auto;
          transform: translateX(-50%);
          /*rtl:end:ignore*/
          width: 750px;
        }
      }

      h2 {
        color: $white;
      }

      .btn-wrapper {
        .tab-button {
          color: $white;

          &::after {
            background-color: $white;
          }
        }
      }

      .map-slider-wrap {
        .marker {
          box-shadow: 0 0 1px 4px hsla(0, 0%, 100%, .5);

          p {
            color: $white;
          }

          .insvg {
            path {
              fill: $baselinePrimary;
            }

            ;
          }

          &.active {
            background-color: $baselineSecondary;

            .insvg {
              path {
                fill: $white;
              }

              ;
            }
          }
        }
      }

      .slider-content-wrap {
        .slider-wrap {
          .items-wrap {
            background-color: $baselineSecondary;

            .slider-expand {
              border: 1px solid $white;

              .insvg {
                path {
                  fill: $white;
                }
              }
            }

            .map-slider-content {
              .map-content-box {
                .info-items {
                  cursor: pointer;

                  .item-img {
                    background-color: $baselinePrimary;

                    .insvg {
                      path {
                        fill: #fff;
                      }
                    }
                  }
                }

                .sec-post-content-info {
                  .title-wrap {
                    .heading4 {
                      color: $white;
                    }

                    .subtitle {
                      color: $white;
                    }

                    p {
                      color: $white;
                    }
                  }

                }

                .slide-items {
                  .slide-content {
                    p {
                      color: $white;
                    }
                  }
                }

                a {
                  color: $white;
                }
              }
            }
          }
        }

        &.modal {
          background-color: transparent;
        }
      }
    }

    &.map-tabs {
      .sec-wrapper {
        .heading2 {
          &.color--modify {
            color: $white;
          }
        }

        .map-slider-wrap {
          .marker {
            box-shadow: 0 0 1px 9px hsla(0, 0%, 100%, .5);
              p {
                color: $baselineSecondary;
              }
            &.l1 {
              /*rtl:begin:ignore*/
              left: 50%;
              right: auto;
              transform: translateX(0);
              /*rtl:end:ignore*/
              top: 90%;
              .body1 {
                color: $baselineSecondary;
              }
  
              // @media #{$vpDesktop1600} {
              //   /*rtl:begin:ignore*/
              //   left: 43%;
              //   /*rtl:end:ignore*/
              // }
  
              @media #{$vpTablet991} {
                /*rtl:begin:ignore*/
                left: 49%;
                /*rtl:end:ignore*/
                top: 93%;
              }
  
              @media #{$vpMobile600} {
                /*rtl:begin:ignore*/
                left: 51%;
                /*rtl:end:ignore*/
                top: 78%;
              }
  
              @media #{$vpMobile390} {
                /*rtl:begin:ignore*/
                left: 49%;
                /*rtl:end:ignore*/
                top: 73%;
              }
            }
  
            &.l2 {
              /*rtl:begin:ignore*/
              right: auto;
              left: 53%;
              /*rtl:end:ignore*/
              top: 79%;
              .body1 {
                color: $baselineSecondary;
              }
  
              // @media #{$vpDesktop1600} {
              //   /*rtl:begin:ignore*/
              //   left: 48%;
              //   /*rtl:end:ignore*/
              //   top: 83%;
              // }
  
              @media #{$vpTablet991} {
                /*rtl:begin:ignore*/
                top: 83%;
                left: 51%;
                /*rtl:end:ignore*/
              }
  
              @media #{$vpMobile600} {
                /*rtl:begin:ignore*/
                left: 53%;
                /*rtl:end:ignore*/
                top: 71%;
              }
  
              @media #{$vpMobile390} {
                /*rtl:begin:ignore*/
                left: 51%;
                /*rtl:end:ignore*/
                top: 66%;
              }
            }
  
            &.l3 {
              /*rtl:begin:ignore*/
              left: 49%;
              right: auto;
                /*rtl:end:ignore*/
              top: 63%;
  
              .body1 {
                color: $baselineSecondary;
              }
  
              // @media #{$vpDesktop1600} {
              //   /*rtl:begin:ignore*/
              //   left: 43%;
              //   /*rtl:end:ignore*/
              //   top: 61%;
              // }
  
              @media #{$vpTablet991} {
                /*rtl:begin:ignore*/
                left: 49%;
                /*rtl:end:ignore*/
                top: 68%;
              }
  
              @media #{$vpMobile600} {
                /*rtl:begin:ignore*/
                left: 51%;
                /*rtl:end:ignore*/
                top: 57%;
              }
  
              @media #{$vpMobile390} {
                /*rtl:begin:ignore*/
                left: 49%;
                /*rtl:end:ignore*/
                top: 56%;
              }
            }
  
            &.l4 {
              /*rtl:begin:ignore*/
              left: 48%;
              right: auto;
                /*rtl:end:ignore*/
              top: 42%;
  
              .body1 {
                color: $baselineSecondary;
              }
  
              @media #{$vpDesktop1600} {
                /*rtl:begin:ignore*/
                left: 49%;
                /*rtl:end:ignore*/
              }
  
              @media #{$vpTablet991} {
                /*rtl:begin:ignore*/
                left: 48%;
                /*rtl:end:ignore*/
                top: 52%;
              }
  
              @media #{$vpMobile600} {
                /*rtl:begin:ignore*/
                left: 49%;
                /*rtl:end:ignore*/
                top: 40%;
              }
  
              @media #{$vpMobile390} {
                /*rtl:begin:ignore*/
                left: 48%;
                /*rtl:end:ignore*/
                top: 45%;
              }
            }
  
            &.l5 {
              /*rtl:begin:ignore*/
              left: 58%;
              right: auto;
              /*rtl:end:ignore*/
              top: 12%;
  
              .body1 {
                color: $baselineSecondary;
              }
  
              @media #{$vpDesktop1600} {
                /*rtl:begin:ignore*/
                left: 57%;
                /*rtl:end:ignore*/
              }
  
              @media #{$vpTablet991} {
                /*rtl:begin:ignore*/
                left: 55%;
                /*rtl:end:ignore*/
                top: 28%;
              }
  
              @media #{$vpMobile600} {
                /*rtl:begin:ignore*/
                left: 57%;
                /*rtl:end:ignore*/
                top: 22%;
              }
  
              @media #{$vpMobile390} {
                /*rtl:begin:ignore*/
                left: 54%;
                /*rtl:end:ignore*/
                top: 27%;
              }
            }
  
            &.l6 {
              /*rtl:begin:ignore*/
              left: 57%;
              right: auto;
                /*rtl:end:ignore*/
              top: 44%;
  
              .body1 {
                color: $baselineSecondary;
              }
  
              @media #{$vpDesktop1600} {
                /*rtl:begin:ignore*/
                left: 55%;
                /*rtl:end:ignore*/
              }
  
              @media #{$vpTablet991} {
                /*rtl:begin:ignore*/
                left: 53%;
                /*rtl:end:ignore*/
                top: 53%;
              }
  
              @media #{$vpMobile600} {
                /*rtl:begin:ignore*/
                left: 56%;
                /*rtl:end:ignore*/
                top: 44%;
              }
  
              @media #{$vpMobile390} {
                /*rtl:begin:ignore*/
                left: 52%;
                /*rtl:end:ignore*/
                top: 44%;
              }
            }
  
            &.l7 {
              /*rtl:begin:ignore*/
              left: 49%;
              right: auto;
              /*rtl:end:ignore*/
              top: 72%;
  
              .body1 {
                color: $baselineSecondary;
              }
  
              @media #{$vpDesktop1600} {
                /*rtl:begin:ignore*/
                left: 50%;
                /*rtl:end:ignore*/
              }
  
              @media #{$vpTablet991} {
                /*rtl:begin:ignore*/
                left: 48%;
                /*rtl:end:ignore*/
                top: 76%;
              }
  
              @media #{$vpMobile600} {
                /*rtl:begin:ignore*/
                left: 50%;
                /*rtl:end:ignore*/
                top: 64%;
              }
  
              @media #{$vpMobile390} {
                /*rtl:begin:ignore*/
                left: 48%;
                /*rtl:end:ignore*/
                top: 62%;
              }
            }
  
            &.l8 {
              /*rtl:begin:ignore*/
              left: 58%;
              right: auto;
                /*rtl:end:ignore*/
              top: 94%;
              @media #{$vpDesktop1600} {
                /*rtl:begin:ignore*/
                left: 56%;
                /*rtl:end:ignore*/
                top: 94%;
              }
  
              @media #{$vpTablet991} {
                /*rtl:begin:ignore*/
                left: 53%;
                /*rtl:end:ignore*/
                top: 94%;
              }
  
              @media #{$vpMobile600} {
                /*rtl:begin:ignore*/
                left: 56%;
                /*rtl:end:ignore*/
                top: 79%;
              }
  
              @media #{$vpMobile390} {
                /*rtl:begin:ignore*/
                left: 52%;
                /*rtl:end:ignore*/
                top: 73%;
              }
            }
            &.active {
              .body1 {
                color: $white;
              }
            }
          }
        }

        .btn-wrapper {
          .tab-button {
            &.active {
              color: $white;
              opacity: 1;
            }
          }

          &.color--modify {
            .tab-button:nth-child(1) {
              color: $white;
              opacity: 0.4;

              @media #{$vpMobile600} {
                color: $white;
                border: .063rem solid $secondarySemiLight;
              }
            }

            .tab-button:nth-child(2) {
              border: 0;
              color: #fff;

              &::after {
                background-color: $white;

                @media #{$vpMobile600} {
                  background-color: $baselinePrimary;
                }
              }
            }

            .tab-button:nth-child(3) {
              color: $white;
              opacity: 0.4;

              @media #{$vpMobile600} {
                color: $white;
                border: .063rem solid $secondarySemiLight;
              }
            }

            .tab-button {
              &.active {
                border: 0;

                @media #{$vpMobile600} {
                  color: $baselineSecondary;
                }
              }
            }
          }
        }

        .slider-content-wrap {
          .slider-wrap {
            height: 100%;

            .items-wrap {
              .map-slider-content {
                position: relative;

                .map-content-box {
                  .sec-post-content-info {
                    .title-wrap {
                      p {
                        display: none;
                      }
                    }
                  }

                  .slide-items {
                    margin: 2rem 0 0 0;
                    max-height: 70vh;

                    @media #{$vpMobile600} {
                      max-height: 60vh;
                    }

                    @media #{$vpMobile390} {
                      max-height: 40vh;
                    }

                    .info-items {
                      .tab-button {
                        color: $white;

                        &:focus {
                          outline: none;
                          border: none;
                        }
                      }
                    }

                    &.slide-popup {
                      margin: 2rem 0 5.44rem;
                    }
                  }
                }
              }
            }
          }
        }

        &.active {
          .location-icon-wrap {
            display: block;
          }

          h2 {
            color: $white;
          }

          .tab-button {
            &::after {
              background-color: $secondarySemiLight;
            }
          }

          .slide-items {
            display: block;
          }
        }
      }
    }
  }
}


.amp-section8 {
  .map-wrap {
    width: 100%;
    background: #eeece5;
    position: relative;
    display: none;

    >img,
    >.points svg #Layer_1-2 {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: right center;
    }

    &.active {
      display: block;
    }

    .map-container {
      width: 100%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-height: 1080px;
      }

      .points {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;

        svg {
          max-height: 1080px;
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 999px) {
  .amp-section8 {
    .map-wrap {
      &:before {
        display: none;
        content: "";
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #000;
        opacity: 0.2;
      }
    }

    .map-container {
      img {
        width: 100%;
        object-fit: cover;
        object-position: 100% center;
      }
    }
  }
}

@media only screen and (max-width: 699px),
screen and (max-width: 991px) and (orientation: landscape) {
  .amp-section8 {
    .map-wrap {
      &.map-mobility {
        min-height: 550px;

        .map-container {
          transform: translateX(0%) translateY(0);
        }

        img {
          width: 100%;
        }

        .points {
          svg {
            height: 100%;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 600px) {
  .map-section {
    height: 100%;
  }
  .amp-section8 {
    .map-wrap {
      &.map-mobility {
        height: 425px;
        min-height: unset;

        .map-container {
          .points {
            top: 40%;
          }
        }

        img {
          width: auto;
          height: 425px;
        }
      }
    }
  }
}

@media only screen and (max-width: 390px) {
  .map-section {
    height: 100%;
  }
}