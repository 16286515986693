.form-sec {
  .error {
    color: red;
    display: none;
  }

  .invalid-email-error {
    color: red;
    display: none;
  }

  .invalid-number-error {
    color: red;
    display: none;
  }

  // .error-border {
  //   border: 0.063rem solid red !important;
  //   border-radius: 0.5rem !important;
  // }

  .wrapper {
    display: flex;
    align-items: flex-start;
    padding: 5rem;
    background: $baselineSecondaryLight;
    justify-content: space-between;
    border-radius: 2rem;

    @media #{$vpTablet991} {
      padding: 3rem;
    }

    @media #{$vpMobile600} {
      padding: 2.5rem 1.25rem;
      flex-direction: column;
      gap: 2rem;
      border-radius: 1rem;
    }
  }

  .content {
    flex: 0 0 42%;
    max-width: 42%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media #{$vpTablet991} {
      width: 30rem;
      padding-right: 1.5rem;
    }

    @media #{$vpMobile600} {
      flex: 0 0 100%;
      max-width: 100%;
      gap: 2rem;
    }

    .underline {
      margin: 0;

      @media #{$vpMobile600} {
        padding-bottom: 0.5rem;
        font-size: 0.875rem;
      }
    }

    .heading2 {
      color: $baselineSecondary;
      margin: 2rem 0;
    }

    .body1 {
      color: $baselineSecondary;

      @media #{$vpMobile600} {
        margin-bottom: 1rem;
        font-size: 1.125rem;
      }

      span {
        @media #{$vpMobile600} {
          font-size: 0.875rem;
          line-height: 1.4;
        }
      }
    }
  }

  .form {
    flex: 0 0 45.8%;
    max-width: 45.8%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media #{$vpTablet991} {
      width: 30rem;
    }

    @media #{$vpMobile600} {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .twoColRow {
      display: flex;
      gap: 2rem;

      @media #{$vpMobile600} {
        flex-direction: column;
      }

      .flexColRow {
        width: 20.5rem !important;

        @media #{$vpMobile600} {
          width: 100% !important;
        }
      }
    }

    .form-fields-row {
      display: flex;
      flex-direction: column;

      @media #{$vpMobile600} {
        flex-direction: column;
      }

      input {
        background: $white;
        border: none;
        padding: 1.5rem 1rem;
        border-radius: 0.5rem;
        width: 100%;
        height: 4.5625rem;
        font-size: 1.125rem;
        line-height: 1.4;
        font-weight: 300;
        border: 0.063rem solid $secondarySimiLight;

        @media #{$vpMobile600} {
          font-size: 1rem;
        }

        &::placeholder {
          font-size: 1.125rem;
          line-height: 1.4;
          font-weight: 300;

          @media #{$vpMobile600} {
            font-size: 1rem;
          }
        }
      }

      input.file-upload-field {
        cursor: pointer;
      }

      .file-upload-wrapper {
        position: relative;
        width: 100%;

        .utility-text {
          font-size: 0.75rem;
          font-weight: 300;
          line-height: 1.4;
          color: #6f7274;
          padding-top: 0.5rem;
        }

        input::-webkit-file-upload-button {
          display: none;
        }

        .search-icon {
          position: absolute;
          right: 3.1rem;
          top: 1.75rem;
          z-index: 2;
          cursor: pointer;
          height: 1rem;
          width: 1rem;

          @media #{$vpMobile600} {
            content: "";
            top: 1.688rem;
            right: 1.25rem;
          }

          path {
            fill: $baselineSecondary;
          }
        }

        &:after {
          content: attr(data-text);
          font-size: 1rem;
          position: absolute;
          top: 0.7rem;
          left: 0.063rem;
          background: $white;
          padding: 0.625rem 0.938rem;
          width: 99%;
          pointer-events: none;
          height: 100%;
          display: flex;
          align-items: center;
          border-radius: 0.5rem;
          color: $neutralGray400;
          height: 3.125rem;
        }

        &:before {
          content: "Browse";
          position: absolute;
          top: 0.813rem;
          right: 1rem;
          height: 3.25rem;
          background: transparent;
          color: $baselineSecondary;
          z-index: 25;
          font-size: 1rem;
          padding: 0.5rem 2.4rem;
          pointer-events: none;
          display: flex;
          align-items: center;
          border: 0.063rem solid $baselinePrimary;
          border-radius: 12.5rem;
          width: 7.9375rem;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.2;
          width: 9.75rem;
          height: 2.875rem;

          @media #{$vpMobile600} {
            content: "Browse";
            height: 1.938rem;
            padding: .8rem 1.5rem;
            right: .813rem;
            top: 1.125rem;
            width: 130px;
            font-size: 0.875rem;
          }
        }
      }
    }

    .form-row-dropdown {
      display: flex;
      flex-direction: column;
      position: relative;

      img {
        position: absolute;
        right: 1.25rem;
        top: 1.563rem;
        pointer-events: none;
        transition: all 0.35s ease-out;
      }

      .dropdown-icon {
        &.rotate {
          transform: rotate(180deg);
        }
      }

      .select2 {
        width: 100% !important;

        .selection {
          .select2-selection {
            display: block;
            height: 100%;
            border: none;
            border-radius: 0.5rem;

            .select2-selection__rendered {
              background: $white;
              border: none;
              padding: 1.5rem 1rem;
              border-radius: 0.5rem;
              width: 100%;
              height: 4.5625rem;
              color: $neutralGray400;
              appearance: none;
              position: relative;
              display: flex;
              align-items: center;
              font-size: 1.125rem;
              line-height: 1.4;
              font-weight: 300;
              border: 0.063rem solid $secondarySimiLight;
              @media #{$vpMobile600} {
                padding: 1rem;
              }
            }

            .select2-selection__arrow {
              display: none;
            }
          }
        }
      }
    }

    .message {
      .message-label {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .body3 {
          @media #{$vpMobile600} {
            font-size: 0.75rem;
          }
        }

        .total-count {
          color: $neutralGray300;
        }

        .counter {
          color: $baselineSecondary;
        }
      }

      textarea {
        width: 100%;
        height: 11.25rem;
        border: none;
        padding: 1.5rem;
        border-radius: 0.5rem;
        font-size: 1.25rem;
        line-height: 1.4;
        font-weight: 300;
        border: 0.063rem solid $secondarySimiLight;

        @media #{$vpMobile600} {
          font-size: 1rem;
          padding: 1rem;
        }

        &::placeholder {
          font-size: 1.25rem;
          line-height: 1.4;
          font-weight: 300;

          @media #{$vpMobile600} {
            font-size: 1rem;
          }
        }

        &.shortTextarea {
          height: 4.5625rem !important;
          overflow: hidden;
        }
      }
    }

    .submit-btn {
      display: flex;
      justify-content: flex-start;

      input {
        background: transparent;
        border-radius: 12.5rem;
        border: 0.063rem solid $baselinePrimary;
        padding: 1.31rem 2rem;
        transition: all 0.4s ease-out;
        font-weight: 300;
        line-height: 1.4;
        width: 10.5rem;
        height: 4rem;

        @media #{$vpMobile600} {
          width: 100%;
          max-width: 100%;
          padding: 1rem;
        }

        &:hover {
          background: $baselinePrimary;
          transition: all 0.4s ease-out;
          color: $white;
        }
      }
    }
  }
}

.select2-dropdown {
  border: none;
  border-radius: 0.5rem;

  .select2-results__option--highlighted[aria-selected] {
    background-color: $baselinePrimary;
  }

  .select2-search {
    display: none;
  }

  .select2-results__option {
    padding: 0.625rem 1.25rem 0.625rem 1.25rem;
  }
}