.editorial-3 {
  &.destination {
    .wrapper {
      .heading {
        width: 43rem;

        @media #{$vpMobile600} {
          width: 100%;
        }
      }
    }
  }

  &.empowering-youth {
    background-color: $baselineSecondaryLight;
  }

  &.work-at-rcu {

    .content-video-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5rem;

      .my-video-dimensions {
        width: 100%;
        height: 59.0625rem;
        border-radius: 1rem;

        @media #{$vpMobile600} {
          height: 12.25rem;
        }

        &.vjs-paused {
          position: relative;

          &::before {
            content: "";
            display: block;
            position: absolute;
            background: linear-gradient(0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)),
              linear-gradient(10.35deg,
                rgba(0, 0, 0, 0.5) 43.98%,
                rgba(0, 0, 0, 0) 94.56%);
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            border-radius: 1rem;
            pointer-events: none;
          }

          &.vjs-playing {
            &::before {
              display: none;
            }
          }

          .vjs-big-play-button {
            z-index: 2;
            border: none;
            background: none;

            @media #{$vpMobile600} {
              position: absolute !important;
              transform: translate(20%);
            }

            .vjs-icon-placeholder {
              &::before {
                content: "";
                background-image: url(../../../assets/img/icon/play-icon.svg);
                width: 6rem;
                height: 6rem;
                background-repeat: no-repeat;
                background-size: cover;

                @media #{$vpMobile600} {
                  width: 3rem;
                  height: 3rem;
                }
              }
            }
          }

          .vjs-control-bar {
            z-index: 3;
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
          }

          video {
            border-radius: 1rem;
            object-fit: cover;
          }
        }
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 1rem;

    @media #{$vpMobile600} {
      flex-direction: column;
      padding-top: 0;
    }

    .heading {
      flex: 0 0 46.37%;
      max-width: 46.37%;

      @media #{$vpMobile600} {
        flex: 0 0 100%;
        max-width: 100%;
        padding-bottom: 1rem;
      }
      .heading2 {
        @media #{$vpMobile600} {
          margin-top: 1rem;
        }
      }
    }

    .content {
      flex: 0 0 49.85%;
      max-width: 49.85%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .body1 {
        @media #{$vpMobile600} {
          margin: 0;
        }
      }

      @media #{$vpMobile600} {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .action-btn-wrapper {
        display: flex;
        gap: 0.625rem;
        padding-top: 2rem;

        .sty-02 {
          display: block;
          @media #{$vpMobile600} {
            margin-top: 1rem;
          }
        }

        @media #{$vpMobile600} {
          display: block;

          &.show {
            display: block;

            button {
              width: 100%;
            }
          }
        }
        .main-primary-btn {
          border: .063rem solid $baselinePrimary;
        }
        .main-secondary-btn {
          @media #{$vpMobile600} {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  &.with-bgImg {
    position: relative;
    height: 40.75rem;
    overflow: hidden;

    @media #{$vpMobile600} {
      height: 37.625rem;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: #00000080;
      z-index: 2;
    }

    .wrapper {
      position: relative;
      z-index: 3;
      padding-top: 12.5rem;
      @media #{$vpTablet991} {
        padding-top: 8rem;
      }
      @media #{$vpMobile600} {
        padding-top: 5rem;
      }
      h2,
      p {
        color: $white;
      }

      .action-btn-wrapper {
        a {
          background-color: $white;
          border: 0.063rem solid $white;

          @media #{$vpMobile600} {
            height: 3rem;
          }

          &:hover {
            background-color: transparent;
            color: $white;
          }
        }
      }
    }

    .bgImage {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  &.sty-01-initiativeDetail {
    background-color: $baselineSecondaryLight;
  }

  &.bg-none {
    background-color: #fff;
    padding-bottom: 5rem;
  }
}

.darkTheme1 {
  .editorial-3 {
    background-color: transparent;

    &.alula-next-chapter {
      height: 100vh;

      .wrapper {
        height: 100%;

        .content-wrapper {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media #{$vpMobile600} {
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }

    .heading {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .body2 {
        margin: 0;
      }

      .heading2 {
        margin: 0;
        padding-top: 1rem;
      }
    }

    &.natural-human-heritage {
      .wrapper {
        .content {
          .action-btn-wrapper {
            @media #{$vpMobile600} {
              display: block;
            }

            button {
              @media #{$vpMobile600} {
                width: 100%;
                background-color: transparent;
                border: 0.063rem solid $white;
                height: 3rem;
                color: $white;

                .btn-text {
                  font-size: 0.875rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

.darkSec1 {
  &.editorial-3 {
    background-color: transparent;

    &.alula-next-chapter {
      height: 100vh;

      .wrapper {
        height: 100%;

        .content-wrapper {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media #{$vpMobile600} {
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }

    .heading {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .body2 {
        margin: 0;
      }

      .heading2 {
        margin: 0;
        padding-top: 1rem;
      }
    }

    &.natural-human-heritage {
      .wrapper {
        .content {
          .action-btn-wrapper {
            @media #{$vpMobile600} {
              display: block;
            }

            button {
              @media #{$vpMobile600} {
                width: 100%;
                background-color: transparent;
                border: 0.063rem solid $white;
                height: 3rem;
                color: $white;

                .btn-text {
                  font-size: 0.875rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

body{
  &.darkTheme{
    .natural-human-heritage{
      &.dark-bg{
        background-color: transparent !important;
      }
    }
  }
}