.faq-search-banner {
  padding-top: 19.75rem;

  @media #{$vpMobile600} {
    padding-top: 5rem;
  }

  .wrapper {
    display: flex;
    flex-direction: column;

    @media #{$vpMobile600} {}

    .content-wrapper {
      display: flex;
      align-items: flex-end;

      .heading1 {
        margin-bottom: 4rem;
        line-height: normal;

        @media #{$vpMobile600} {
          margin-bottom: 5rem;
          font-size: 2.5rem;
        }
      }

      @media #{$vpMobile600} {
        flex-direction: column;
      }
    }

    .image-wrapper {
      img {
        border-radius: 2rem 2rem 0 0;
        height: 33.75rem;
        width: 100%;

        @media #{$vpMobile600} {
          height: 28.125rem;
          ;
        }
      }
    }
  }
}