.highcharts-figure {
  .bar-race-graph-header {
    border: 1px solid $neutralGray300;
    margin: 20px;
    .bar-race-graph-top-heading {
      height: 80px;
      background: $baselineSecondaryLight;
      display: flex;
      justify-content: space-between;
      .bar-race-heading-container-1 {
        border: 0.5px solid lightgrey;
        padding-top: 10px;
        border-radius: 5px;
        margin: 6px;
        text-align: center;
        width: 22%;
        @media #{$vpMobile600} {
          width: 48%;
        }
        .bar-race-heading-text-1 {
          font-weight: bold;
          font-size: 15px;
          margin: 0px;
        }
        .bar-race-heading-text-2 {
          font-weight: bold;
          color: #c39b6a;
          font-size: 15px;
          margin: 0px;
        }
      }
      .bar-race-heading-container-2 {
        border: 0.5px solid lightgrey;
        border-radius: 5px;
        padding-top: 10px;
        margin: 6px;
        text-align: center;
        width: 22%;
        @media #{$vpMobile600} {
          width: 48%;
        }
        .bar-race-heading-text-1 {
          font-weight: bold;
          font-size: 15px;
          margin: 0px;
        }
        .bar-race-heading-text-2 {
          font-weight: bold;
          color: #c39b6a;
          font-size: 15px;
          margin: 0px;
        }
      }
      .bar-race-heading-container-3 {
        border: 0.5px solid lightgrey;
        border-radius: 5px;
        padding-top: 10px;
        margin: 6px;
        text-align: center;
        width: 22%;
        @media #{$vpMobile600} {
          width: 48%;
        }
        .bar-race-heading-text-1 {
          font-weight: bold;
          font-size: 15px;
          margin: 0px;
        }
        .bar-race-heading-text-2 {
          font-weight: bold;
          color: #c39b6a;
          font-size: 15px;
          margin: 0px;
        }
      }
    }
  }
}
