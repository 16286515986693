.job-login {
  .error {
    color: red;
    display: none;
  }
  .invalid-email-error {
    color: red;
    display: none;
  }
  .error-border {
    border: 0.063rem solid red !important;
    border-radius: 0.5rem !important;
  }

  .wrapper {
    display: flex;

    @media #{$vpMobile600} {
      flex-direction: column;
    }

    .left-wrapper {
      width: 50.5rem;
      background-color: $baselineSecondaryLight;

      @media #{$vpMobile600} {
        width: 100%;
      }

      .left-wrapper-container {
        padding: 4rem 2.5rem 2.5rem 7.5rem;
        height: 51.1875rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @media #{$vpMobile600} {
          padding: 2.5rem 1.25rem 1.5rem 1.25rem;
          height: 100%;
        }

        .button-wrapper {
          display: flex;
          justify-content: flex-end;

          @media #{$vpMobile600} {
            display: none;
          }

          .main-primary-btn {
            width: 3.625rem;
            height: 3.625rem;
            padding: 1rem;
            /*rtl:raw:transform: rotate(180deg);*/

            @media #{$vpMobile600} {
              width: 2.75rem;
              height: 2.75rem;
            }
          }

          button {
            padding: 0.875rem 1.5rem;
            justify-content: center;

            img {
              width: 1rem;
              height: 1rem;
              filter: invert(1);
            }
          }
        }
      }
    }

    .right-wrapper {
      width: 69.5rem;

      @media #{$vpMobile600} {
        width: 100%;
      }

      .right-wrapper-container {
        padding: 4rem 7.5rem 0 10.12rem;

        @media #{$vpMobile600} {
          padding: 2.5rem 1.25rem 1.5rem 1.25rem;
        }

        .login-form {
          .form {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            @media #{$vpMobile600} {
              gap: 1.25rem;
            }

            .form-fields-row {
              position: relative;

              input {
                padding: 1.5rem 1rem;
                height: 4.5625rem;
                width: 100%;
                border: 0.063rem solid $secondarySemiLight;
                border-radius: 0.5rem;
                font-size: 1.125rem;
                font-weight: 300;
                line-height: 1.4;
                color: $baselineSecondary;

                &::placeholder {
                  color: $neutralGray400;

                }

                @media #{$vpMobile600} {
                  font-size: 1rem;
                  height: 4.25rem;
                  padding: 1rem 1.5rem 1rem 1rem;
                }


              }

              .icon-Eye {
                position: absolute;
                right: 1rem;
                top: 1.5rem;
                width: 1.5rem;
                height: 1.5rem;
                cursor: pointer;

                &.eye-slash-icon {
                  display: none;
                  fill: $baselinePrimary;
                }
              }
            }

            .submit-btn {
              .main-secondary-btn {
                @media #{$vpMobile600} {
                  height: 3rem;
                  max-width: max-content;
                  .btn-text {
                    font-size: 0.875rem;
                    line-height: 1.4;
                  }
                }
              }

              .main-primary-btn {
                @media #{$vpMobile600} {
                  height: 3rem;
                }

                &.with-ic {
                  @media #{$vpMobile600} {
                    padding: 0;
                    height: 2.75rem;
                    width: 2.75rem;
                    display: flex;
                    .insvg {
                      margin: 0.25rem 0.75rem;
                    }
                  }

                  .btn-text {
                    font-size: 0.875rem;
                    line-height: 1.4;
                  }
                }

              }

              svg {
                width: 1rem;
                height: 1rem;
                  /*rtl:raw:transform: rotate(180deg);*/

                path {
                  fill: $baselineSecondary;
                }
              }

              @media #{$vpMobile600} {
                position: fixed;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                left: 0;
                right: 0;
                padding: 1rem 1.25rem;
                background: $secondarySemiLight;
                flex-direction: row-reverse;
                z-index: 1;
              }

              .main-primary-btn {
                width: 3.625rem;
                justify-content: center;
                display: none;

                @media #{$vpMobile600} {
                  display: block;
                  width: fit-content;
                }

                span {
                  padding: 0;

                  svg {
                    path {
                      fill: $baselineSecondary;
                    }
                  }
                }
              }
            }
          }

          .bottom-content {
            background-color: $baselineSecondaryLight;
            padding: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 0.75rem;
            margin-top: 2.5rem;

            @media #{$vpMobile600} {
              margin-top: 1.25rem;
              margin-bottom: 18.375rem;
              padding: 1rem;
            }

            .main-primary-btn {
              // @media #{$vpMobile600} {
              //   height: 3rem;
              // }

              &.with-ic {
                @media #{$vpMobile600} {
                  padding: 0;
                  height: 2.75rem;
                  width: 2.75rem;
                }

                .btn-text {
                  font-size: 0.875rem;
                  line-height: 1.4;
                }
              }

            }
          }
        }
      }
    }
  }
}