.sec-about-rcu {
  padding: 100px 0;

  .sec-about-rcu-wrapper {
    .underline-title {
      color: $secondaryColor;
    }
  }

  .sec-about-heading-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sec-about-heading-inner {
      min-width: 50%;
    }

    .body2 {
      color: $baselineSecondary;
    }
  }

  .sec-about-slider-contnet-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sec-about-content-inner-left {
      width: 50%;
      background-color: $secondaryLight;
      padding: 20px;
    }
  }
}
