.register {
  .error {
    display: none;
    color: red;
  }
  .error-border .select2-selection__rendered {
    border: 1px solid red !important;
  }
  .error-message {
    color: red;
  }
  .wrapper {
    display: flex;
    align-items: flex-start;
    padding: 80px;
    background: $baselineSecondaryLight;
    justify-content: space-between;
    border-radius: 32px;
    @media #{$vpMobile600} {
      padding: 40px 20px;
      flex-direction: column;
      gap: 20px;
      border-radius: 16px;
    }
  }

  .content {
    flex: 0 0 30%;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    gap: 48px;

    @media #{$vpMobile600} {
      flex: 0 0 100%;
      max-width: 100%;
      gap: 40px;
    }

    .underline-title {
      width: fit-content;
    }
  }

  .register-form {
    flex: 0 0 55%;
    max-width: 55%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .upper-wrapper {
      display: flex;
      gap: 20px;

      @media #{$vpMobile600} {
        flex-direction: column;
      }
    }

    @media #{$vpMobile600} {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .form-fields-row {
      display: flex;
      gap: 20px;

      &.flexColRow {
        flex-direction: column;
        width: 100%;
      }

      @media #{$vpMobile600} {
        flex-direction: column;
      }

      input {
        background: $white;
        border: none;
        padding: 0 20px;
        border-radius: 8px;
        width: 100%;
        height: 70px;
        font-size: 1.125rem;
        line-height: 1.4;
        font-weight: 300;

        &::placeholder {
          font-size: 1.125rem;
          line-height: 1.4;
          font-weight: 300;
        }
      }
    }

    .form-row-dropdown {
      display: flex;
      flex-direction: column;
      position: relative;

      img {
        position: absolute;
        right: 20px;
        top: 25px;
        pointer-events: none;
      }
      .select2 {
        width: 100% !important;
        .selection {
          .select2-selection {
            display: block;
            height: 100%;
            border: none;
            border-radius: 8px;

            .select2-selection__rendered {
              background: $white;
              border: none;
              padding: 0 20px;
              border-radius: 8px;
              width: 100%;
              height: 70px;
              color: $neutralGray400;
              appearance: none;
              position: relative;
              display: flex;
              align-items: center;
            }

            .select2-selection__arrow {
              display: none;
            }
          }
        }
      }
    }

    .checkbox-options {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      padding-top: 10px;

      .option-wrapper {
        width: 45%;
        display: flex;
        gap: 20px;
        align-items: center;

        @media #{$vpMobile600} {
          width: 100%;
        }

        input {
          width: 32px;
          height: 32px;
          outline: none;
          border: none;
          background-color: $white;
          appearance: none;
          position: relative;
          border-radius: 100%;

          &:checked::before {
            content: "";
            background-color: $baselinePrimary;
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            position: absolute;
            top: 6px;
            left: 6px;
          }
        }
      }
    }

    .submit-btn {
      display: flex;
      justify-content: flex-start;
      padding-top: 20px;

      input {
        background: $white;
        border-radius: 200px;
        border: 1px solid $baselinePrimary;
        padding: 15px 60px;
        transition: all 0.4s ease-out;
        font-weight: 300;
        line-height: 1.4;

        @media #{$vpMobile600} {
          width: 100%;
        }

        &:hover {
          background: $baselinePrimary;
          transition: all 0.4s ease-out;
          color: $white;
        }
      }
    }
  }
}

.select2-dropdown {
  border: none;
  border-radius: 8px;
  .select2-results__option--highlighted[aria-selected] {
    background-color: $baselinePrimary;
  }
  .select2-search {
    display: none;
  }
  .select2-results__option {
    padding: 10px 20px 10px 20px;
  }
}
