.carousel {
  &.sec-padded {
    padding-bottom: 3rem;
  }
  .wrapper-box {
    .sec-heading-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0 3.125rem;

      @media #{$vpMobile600} {
        flex-wrap: wrap;
      }

      @media #{$vpMobile600} {
        padding: 1rem 0 1.5rem;
      }

      .sec-heading-inner {
        width: 43rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media #{$vpTablet991} {
          width: 100%;
          gap: 0rem;
        }

        .heading2 {
          margin-top: 1rem;
          @media #{$vpMobile600} {
            margin: 2.5rem 0 1.5rem 0;
          }
        }
      }

      .sec-para-inner {
        width: 51.875rem;

        @media #{$vpTablet991} {
          width: 100%;
        }
      }
    }

    .media-slider-box {
      position: relative;

      @media #{$vpMobile600} {
        margin-bottom: 1.25rem;
      }

      .tab-content-wrapper {
        .tab-content {
          .tab-panel {
            .media-slider {
              max-width: 105rem;
              position: relative;
              height: 100%;
              &::after {
                content: "";
                position: absolute;
                width: 7.5rem;
                height: 100%;
                background-color: $white;
                left: -8rem;
                top: 0;
              }

              .slick-list {
                margin-right: -0.438rem;
                margin-left: -0.438rem;
                overflow: visible;
                height: 100%;
                .slick-track {
                  display: flex;
                  gap: 1.25rem;
                  height: 100%;
                  @media #{$vpMobile600} {
                    gap: 0.625rem;
                  }
                }
              }

              @media #{$vpMobile600} {
                .slick-dots {
                  justify-content: flex-start;
                  width: max-content;
                  padding-top: 1.25rem;
                  margin: 24px auto 0;
                }
              }

              .carousel-items {
                position: relative;

                .wrapper {
                  display: flex;
                  width: 21rem;
                  height: 21rem;
                  background: #f9f7f3;
                  border-radius: 1rem;
                  position: relative;
                  z-index: 10;
                  overflow: hidden;
                  align-items: center;
                  gap: 1rem;
                  flex-direction: column;
                  justify-content: center;
                  cursor: pointer;

                  @media #{$vpMobile600} {
                    height: 14.625rem;
                    width: 21.875rem;
                  }

                  &:hover {
                    .bg-img-box {
                      opacity: 1;
                      height: 100%;
                    }

                    .content-area {
                      color: $white;

                      .top-content {
                        .heading2 {
                          color: $white;
                        }

                        .body1 {
                          color: $white;
                        }

                        .body3 {
                          margin-bottom: 0.625rem;
                        }
                      }

                      .bottom-content {
                        .body1 {
                          color: $white;
                        }
                      }
                    }
                  }

                  .bg-img-box {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    transition: 0.3s all ease-out;
                    opacity: 0;

                    @media #{$vpMobile600} {
                      opacity: 1;
                    }

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }

                    &:before {
                      content: "";
                      display: block;
                      width: 100%;
                      height: 100%;
                      position: absolute;
                      background-color: #00000099;
                    }
                  }

                  .content-area {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;
                    width: 100%;
                    height: 100%;
                    padding: 1.25rem;

                    .top-content {
                      display: flex;
                      align-items: baseline;
                      gap: 0.5rem;

                      h2 {
                        color: $baselinePrimary;
                        margin-bottom: 0;
                        line-height: 1.3;
                        /*rtl:raw:line-height: inherit; */
                        height: 100%;
                        text-align: center;

                        @media #{$vpMobile600} {
                          font-size: 3rem;
                          color: $white;
                        }
                      }

                      span {
                        margin-bottom: 0.7rem;

                        @media #{$vpMobile600} {
                          color: $white;
                        }
                      }

                      .body1 {
                        color: $baselineSecondary;

                        @media #{$vpMobile600} {
                          font-size: 1.25rem;
                          margin-bottom: 1.5rem;
                          color: $white;
                        }
                      }
                    }

                    .bottom-content {
                      .body1 {
                        margin: 0;
                        text-align: center;

                        @media #{$vpMobile600} {
                          font-size: 1.25rem;
                          color: $white;
                        }
                      }
                    }
                  }
                }
              }
            }
            .view-wrapper {
              display: flex;
              margin-top: 3rem;
              position: relative;
              justify-content: flex-start;
              @media #{$vpMobile600} {
                position: absolute;
                right: 0;
                bottom: -1.5rem;
              }
              a {
                display: flex;
                margin-top: 3rem;
              }
              .main-secondary-btn{
                margin-top: 0;
                min-width: 118px;
                @media (max-width: 1440px) {
                  min-width: 90px;
                }
                @media (max-width: 1199px) {
                  min-width: 70px;
                }
                @media (max-width: 991px) {
                  min-width: 90px;
                }
                @media (max-width: 991px) {
                  min-width: 70px;
                }
                @media #{$vpMobile600} {
                  min-width: 100%;
                }
              }
              .pagination--arrow {
                width: 100%;
                // position: absolute;
                // right: 0;
                // bottom: 1.25rem;
                // @media #{$vpTablet991} {
                //   bottom: 0.313rem;
                // }
                .slider-arrow-pagination {
                  padding-top: 0;
                  .arrow-wrap {
                    width: 100%;
                    position: relative;
                    display: flex;
                    right: auto;
                    width: 100%;
                    justify-content: flex-end;
                    // bottom: 0;
                    .slide-arrow{
                      .custom-arrows{
                        width: 2.875rem;
                        height: 2.875rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.dark-bg {
    .wrapper-box {
      .media-slider-box {
        .tab-content-wrapper {
          .tab-content {
            .tab-panel {
              .media-slider {
                .carousel-items {
                  .wrapper {
                    background: rgba($color: #fff, $alpha: 0.1);
                    .content-area {
                      .top-content {
                        .body1 {
                          color: $white;
                        }
                      }
                    }
                  }
                }
                &:after {
                  background-color: #000;
                }
              }
            }
          }
        }
      }
    }
  }
  &.gray-bg {
    .wrapper-box {
      .media-slider-box {
        .tab-content-wrapper {
          .tab-content {
            .tab-panel {
              .media-slider {
                .carousel-items {
                  .wrapper {
                    background: $white;
                  }
                }
                &:after {
                  background-color: #f9f7f3;
                }
              }
            }
          }
        }
      }
    }
  }
}

.darkTheme1 {
  .carousel {
    background-color: #120f14;

    @media #{$vpMobile600} {
      padding: 5rem 0;
    }

    .wrapper-box {
      .sec-heading-box {
        padding: 0 0 3rem;

        h2 {
          margin-top: 1rem !important;
          padding-top: 1rem;
        }
      }

      .media-slider-box {
        .tab-content-wrapper {
          .tab-content {
            .tab-panel {
              .media-slider {
                &::after {
                  background-color: #120f14;
                }

                .slick-dots {
                  @media #{$vpMobile600} {
                    width: 100%;
                    justify-content: center;
                  }
                }

                .carousel-items {
                  .wrapper {
                    background: transparent;

                    &:hover {
                      .content-area {
                        border: none;
                      }
                    }

                    .bg-img-box {
                      &::before {
                        z-index: 1;
                      }

                      .video-js {
                        width: 100%;
                        height: 15.625rem;
                      }

                      video {
                        object-fit: cover;
                        border: 0.063rem solid rgba(0, 0, 0, 0.6);
                        border-radius: 1rem;
                      }
                    }

                    .content-area {
                      background-color: transparent;
                      border-radius: 1rem;
                      border: 0.063rem solid rgba(255, 255, 255, 0.5);
                      z-index: 2;

                      .top-content {
                        .heading2 {
                          color: $white;
                        }
                      }
                    }
                  }
                }
              }

              .view-wrapper {
                position: relative;
                .slider-arrow-pagination {
                  width: 100%;
                  position: absolute;
                  .arrow-wrap {
                    left: 0;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.darkSec1 {
  &.carousel {
    background-color: #120f14;

    @media #{$vpMobile600} {
      padding: 5rem 0;
    }

    .wrapper-box {
      .sec-heading-box {
        padding: 0 0 3rem;

        h2 {
          margin-top: 1rem !important;
          padding-top: 1rem;
        }
      }

      .media-slider-box {
        .tab-content-wrapper {
          .tab-content {
            .tab-panel {
              .media-slider {
                &::after {
                  background-color: #120f14;
                }

                .slick-dots {
                  @media #{$vpMobile600} {
                    width: 100%;
                    justify-content: center;
                  }
                }

                .carousel-items {
                  .wrapper {
                    background: transparent;

                    &:hover {
                      .content-area {
                        border: none;
                      }
                    }

                    .bg-img-box {
                      &::before {
                        z-index: 1;
                      }

                      .video-js {
                        width: 100%;
                        height: 15.625rem;
                      }

                      video {
                        object-fit: cover;
                        border: 0.063rem solid rgba(0, 0, 0, 0.6);
                        border-radius: 1rem;
                      }
                    }

                    .content-area {
                      background-color: transparent;
                      border-radius: 1rem;
                      border: 0.063rem solid rgba(255, 255, 255, 0.5);
                      z-index: 2;

                      .top-content {
                        .heading2 {
                          color: $white;
                        }
                      }
                    }
                  }
                }
              }

              .view-wrapper {
                .slider-arrow-pagination {
                  width: 100%;

                  .arrow-wrap {
                    left: 0;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
body{
  &.darkTheme{
    .carousel{
      .wrapper-box{
        .media-slider-box{
          .tab-content-wrapper{
            .tab-content{
              .tab-panel{
                .media-slider{
                  .slick-list{
                    .slick-track{
                      .carousel-items{
                        .wrapper{
                          background-color: transparent;
                          border: 1px solid $text-white;
                          .content-area{
                            .top-content{
                              .heading2{
                                color: $text-white;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  &::after{
                    background-color: #120f14;
                  }
                }
                .view-wrapper{
                  .main-secondary-btn{
                    color: $text-white;
                    &:hover{
                      color: $baselineSecondary;
                    }
                  }
                }
              }
            }
          }
          @media #{$vpMobile600} {
            margin-bottom: 2.25rem;
          }
        }
      }
    }
  }
}
