.sec-rankings-awards-grid {
  background-color: $white;

  // modification calss for news grid page "sec-new-grid"
  &.sec-new-grid {
    .list-view-item {
      .main-secondary-btn {
        &.with-ic {
          display: none;
        }
      }
    }

    .grid-view-item {
      .icon-primary-btn {
        display: none;
      }
    }
  }

  .container--custom {
    .wrapper-box {
      .rankings-awards-box {
        position: relative;

        .filter-bar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 2.5rem 0;


          @media #{$vpMobile600} {
            flex-direction: column-reverse;
            margin-bottom: 1.25rem;
            margin-top: 2.5rem;
          }

          .left-filter {
            @media #{$vpMobile600} {
              width: 100%;
            }

            .nav-pills {
              display: flex;
              gap: 1rem;

              @media #{$vpMobile600} {
                flex-direction: row;
                overflow: scroll;
                flex-wrap: nowrap;
                padding: 2rem 0 1rem;
                margin-right: -1.25rem;
              }

              .nav-item {
                @media #{$vpMobile600} {
                  flex: 0 0 auto;
                }

                .nav-link {
                  border-radius: 0.25rem;
                  max-width: fit-content;
                  padding: 1rem 2rem;
                  line-height: normal;
                  border: 0.063rem solid $secondarySimiLight;
                  background-color: transparent;
                  color: $baselineSecondary;
                  transition: all 0.4s ease;
                  height: 4.563rem;
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  @media #{$vpMobile600} {
                    flex: 0 0 auto;
                    padding: 1rem 1.5rem;
                    height: 2.75rem;
                  }

                  &:hover {
                    background-color: $secondarySimiLight;
                  }

                  &.active {
                    background-color: $secondarySimiLight;
                    display: flex;
                    align-items: center;
                    gap: 0.75rem;

                    &:after {
                      content: "";
                      width: 0.5rem;
                      height: 0.5rem;
                      display: block;
                      border-radius: 3.125rem;
                      background-color: $baselinePrimary;
                      transition: all 0.4s ease;
                    }
                  }
                }
              }
            }
          }

          .right-filter {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1.25rem;
            width: 53.188rem;

            @media #{$vpMobile600} {
              width: 100%;
              padding-left: 0;
              flex-direction: column;
              gap: 1.5rem;
            }

            .search-box {
              width: 25.3125rem;
              position: relative;

              @media #{$vpMobile600} {
                width: 100%;
              }

              .btn-search {
                position: absolute;
                left: auto;
                top: 50%;
                right: 1.25rem;
                transform: translateY(-50%);
                border: 0;
                background-color: transparent;
                outline: 0;

                @media #{$vpMobile600} {
                  right: 0.5rem;
                }

                .serch-icon {
                  width: 1.5rem;
                  height: 1.5rem;

                  path {
                    fill: $baselineSecondary;
                  }
                }
              }
            }

            .filter-view-type {
              width: 26.625rem;
              display: flex;
              gap: 1.25rem;

              svg {
                width: 1.5rem;
                height: 1.5rem;
                transition: all 0.4s ease-in;
              }

              @media #{$vpMobile600} {
                width: 100%;
              }

              .sort-box {
                width: 16.438rem;
                background: $white;
                border: 0.063rem solid $secondarySimiLight;
                padding: 0 1.25rem;
                border-radius: 0.5rem;
                height: 4.563rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                @media #{$vpMobile600} {
                  width: 13.813rem;
                  height: 3.438rem;
                }

                &.icon-rotate {
                  .sort-icon {
                    transform: rotateX(180deg);
                    transition: all 0.4s ease-in;
                  }
                }
              }

              .view-type {
                width: 8.94rem;
                background: $white;
                border-radius: 0.5rem;
                border: 0.063rem solid $secondarySimiLight;
                height: 4.563rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                overflow: hidden;

                @media #{$vpMobile600} {
                  width: 6.81rem;
                  height: 3.44rem;
                }

                ul {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  li {
                    a {
                      width: 4.5rem;
                      padding: 1.5rem 1rem;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      @media #{$vpMobile600} {
                        width: 3.4375rem;
                        height: 3.4375rem;
                        padding: 1.5rem 1rem;
                      }

                      &:hover {
                        background-color: $secondarySimiLight;
                      }

                      &.active {
                        background-color: $secondarySimiLight;
                      }

                      img {
                        width: 1.5rem;
                        height: 1.5rem;
                      }

                      &.grid-view-action {
                        &.active {
                          .list-view-listing {
                            display: none;
                          }
                        }
                      }

                      &.list-view-action {
                        &.active {
                          .grid-view-listing {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .input-field {
              background: $white;
              border: 0.063rem solid $secondarySimiLight;
              padding: 0 4rem 0 1.25rem;
              border-radius: 0.5rem;
              width: 100%;
              height: 4.563rem;

              @media #{$vpMobile600} {
                height: 3.438rem;
                padding: 1rem 2.5rem 1rem 1.5rem;
              }
            }
          }
        }

        .tab-content-wrapper {
          .tab-content {
            .tab-pane {
              .media-listing {
                &.grid-view-listing,
                &.filterSlider {
                  position: relative;
                  display: grid;
                  flex-wrap: wrap;
                  gap: 1.25rem;
                  grid-template-columns: repeat(4, 1fr);

                  @media #{$vpMobile600} {
                    grid-template-columns: 1fr;
                  }

                  .listingWrapper {
                    .list-view-item {
                      display: none !important;
                    }

                    .grid-view-item {
                      position: relative;
                      border-radius: 0.75rem;
                      overflow: hidden;

                      @media #{$vpMobile600} {
                        width: 100%;
                      }

                      &.rank-box {
                        .main-content {
                          .ranking-img {
                            width: 100%;
                            height: 14.1875rem;

                            @media #{$vpMobile600} {
                              height: 11.5rem;
                            }
                          }

                          .ranking-detail {
                            height: 15.8125rem;

                            @media #{$vpMobile600} {
                              height: 13.0625rem;
                            }
                          }
                        }

                        .action-footer {
                          flex-direction: column;
                          align-items: flex-start !important;
                          height: 15.8125rem;
                          padding: 1.5rem !important;
                          background-color: $secondarySemiLight;
                          bottom: 0 !important;

                          .heading {
                            padding-right: 0rem !important;

                            .heading5 {
                              color: $baselineSecondary;
                              display: -webkit-box;
                              -webkit-line-clamp: 4;
                              -webkit-box-orient: vertical;
                              min-height: 8.438rem;
                              overflow: hidden;
                              line-height: 1.3;
                              margin-bottom: 0;

                              @media #{$vpMobile600} {
                                font-size: 1.25rem;
                                -webkit-line-clamp: 5;
                                min-height: 7.438rem;
                              }
                            }
                          }

                          .btn-box {
                            @media #{$vpMobile600} {
                              max-width: 100% !important;
                              width: 100%;
                            }
                          }
                        }
                      }

                      .main-content {
                        position: relative;
                        height: 100%;

                        picture {
                          &:before {
                            content: "";
                            width: 100%;
                            height: 100%;
                            display: block;
                            background: linear-gradient(180deg,
                                rgba(0, 0, 0, 0.3) 0%,
                                rgba(0, 0, 0, 0) 16.25%),
                              linear-gradient(0deg,
                                rgba(0, 0, 0, 0.3) 0%,
                                rgba(0, 0, 0, 0) 18.44%);
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 2;
                            border-radius: 0.75rem;
                          }

                          .poster-img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            transition: all 0.4s ease;

                            @media #{$vpMobile600} {
                              //height: 24.375rem;
                            }
                          }
                        }

                        .inner-content {
                          position: absolute;
                          top: 0;
                          left: 0;
                          padding: 1.5rem;
                          height: 100%;
                          width: 100%;
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          flex-direction: column;
                          transition: all 0.4s ease;
                          z-index: 3;

                          @media #{$vpMobile600} {
                            padding: 1.25rem;
                          }

                          .cate-date-header {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            margin: 0;
                            text-transform: capitalize;

                            .post-date {
                              color: $white;
                              margin: 0;

                              @media #{$vpMobile600} {
                                font-size: 0.875rem;
                              }
                            }

                            .category {
                              color: $secondaryColor;
                              padding: 0.375rem 1rem;
                              background-color: $secondarySimiLight;
                              border-radius: 0.25rem;
                              margin: 0;

                              @media #{$vpMobile600} {
                                font-size: 0.875rem;
                              }
                            }
                          }

                          .play-icon {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                          }
                        }

                        .action-footer {
                          display: flex;
                          align-items: flex-start;
                          justify-content: space-between;
                          width: 100%;
                          position: absolute;
                          top: auto;
                          bottom: 1.5rem;
                          padding: 0 1.5rem;
                          z-index: 3;

                          @media #{$vpMobile600} {
                            height: 13.0625rem;
                          }

                          .heading {
                            max-width: fit-content;
                            padding-right: 1.875rem;

                            @media #{$vpMobile600} {}

                            .body1 {
                              color: $white;
                              margin: 0;
                              font-size: 1.25rem;
                            }
                          }

                          .btn-box {
                            max-width: 11.563rem;
                            display: flex;
                            align-items: center;
                            gap: 0.875rem;

                            @media #{$vpMobile600} {
                              gap: 0.5rem;
                            }

                            .main-primary-btn {
                              padding: 0.75rem 1.4rem;
                              line-height: 1.375rem;
                              width: 7.9375rem;
                              height: 2.875rem;
                              text-align: center;

                              @media #{$vpMobile600} {
                                padding: 0.5rem 1.125rem;
                                height: 2.25rem;
                                line-height: normal;
                              }

                              &:hover {
                                background-color: $white;
                              }

                              @media #{$vpMobile600} {
                                .btn-text {
                                  font-size: 0.875rem;
                                }
                              }
                            }

                            .icon-primary-btn {
                              width: 2.875rem;
                              height: 2.875rem;
                              display: none;

                              @media #{$vpMobile600} {
                                width: 2.25rem;
                                height: 2.25rem;
                              }

                              img {
                                width: 0.875rem;
                                height: 0.875rem;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }

                &.list-view-listing {
                  display: flex;
                  gap: 2rem;
                  flex-direction: column;

                  @media #{$vpMobile600} {
                    gap: 1rem;
                  }

                  .listingWrapper {
                    .grid-view-item {
                      display: none !important;
                    }

                    .list-view-item {
                      display: flex !important;
                      align-items: center;
                      width: 100%;
                      border: 0.063rem solid $secondarySemiLight;
                      border-radius: 1rem;
                      overflow: hidden;
                      cursor: pointer;

                      @media #{$vpMobile600} {
                        border-radius: 0.5rem;
                        padding: 0.625rem;
                        align-items: flex-start;
                        gap: 1rem;
                      }

                      &.images-cate {
                        .play-icon {
                          display: none;
                        }
                      }

                      .lft-img {
                        width: 34.1875rem;

                        @media #{$vpMobile600} {
                          width: 41%;
                        }

                        .action-cta {
                          position: relative;
                          width: 100%;
                          height: 100%;
                          display: block;

                          .poster-img {
                            width: 100%;
                            height: 18rem;

                            @media #{$vpMobile600} {
                              height: auto;
                              border-radius: 0.25rem;
                            }
                          }

                          .play-icon {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);

                            @media #{$vpMobile600} {
                              .icon-play {
                                width: 2rem;
                              }
                            }
                          }
                        }
                      }

                      .right-content {
                        padding: 2rem 2.5rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 70.8125rem;

                        @media #{$vpMobile600} {
                          padding: 0;
                          width: 59%;
                        }

                        .inner-left {
                          width: 50.625rem;
                          display: flex;
                          flex-direction: column;
                          gap: 1.5rem;

                          @media #{$vpMobile600} {
                            gap: 1rem;

                            .heading3 {
                              font-size: 0.875rem;
                              line-height: 1.225rem;
                              margin-bottom: 0;
                            }
                          }

                          .cate-date-header {
                            display: flex;
                            align-items: flex-start;
                            justify-content: center;
                            width: 100%;
                            margin: 0;
                            flex-direction: column;
                            gap: 1.5rem;
                            text-transform: capitalize;

                            @media #{$vpMobile600} {
                              flex-direction: row;
                              align-items: center;
                              justify-content: flex-start;
                              gap: 1rem;
                            }

                            .post-date {
                              color: $secondaryColor;
                              margin: 0;

                              @media #{$vpMobile600} {
                                font-size: 0.75rem;
                                padding: 0;
                              }
                            }

                            .category {
                              color: $secondaryColor;
                              padding: 0.375rem 1rem;
                              background-color: $secondarySimiLight;
                              border-radius: 0.25rem;
                              margin: 0;

                              @media #{$vpMobile600} {
                                font-size: 0.75rem;
                                padding: 0.25rem 0.75rem;
                              }
                            }
                          }
                        }

                        .inner-right {
                          @media #{$vpMobile600} {
                            display: none;
                          }

                          .btn-box {
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            flex-direction: column;

                            .main-secondary-btn {
                              min-width: 10rem;
                              outline: none;
                              text-align: center;

                              &.with-ic {
                                display: none;
                              }
                            }
                          }
                        }
                      }

                      &.grid-view-item {
                        display: none !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .heading-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .wrapper-heading {
          min-width: 50%;
        }
      }
    }
  }
}