.display-font {
  font-size: 10rem;
  line-height: 1.2; // in ar design its 1.3
  /*rtl:raw:line-height: 1.3; */
  font-weight: 300;

  @media #{$vpDesktop1680} {
    font-size: 100px;
  }

  @media #{$vpDesktop1440} {
    font-size: 100px;
  }

  @media #{$vpTablet1024} {
    font-size: 80px;
  }

  @media #{$vpMobile600} {
    font-size: $font34;
  }

  &.white {
    color: $text-white;
  }
}

h1,
.heading1 {
  font-size: 6.25rem;
  line-height: 1.1; // in ar design its 1.3
  /*rtl:raw:line-height: 1.8; */
  font-weight: 300;
  margin: 0;
  @media #{$vpTablet991} {
    font-size: 3.75rem;
  }
  @media #{$vpMobile600} {
    font-size: 3rem;
  }

  &.white {
    color: $text-white;
  }
}

h2,
.heading2 {
  font-size: 3.75rem;
  line-height: 1.3; // in ar design its 2
  /*rtl:raw:line-height: 1.8; */
  font-weight: 300;
  margin: 0;
  @media #{$vpTablet991} {
    font-size: 2.5rem;
  }
  @media #{$vpMobile600} {
    font-size: 2rem;
  }

  &.white {
    color: $text-white;
  }
}

h3,
.heading3 {
  font-size: 2.5rem;
  line-height: 1.3; // in ar design its 2
  /*rtl:raw:line-height: 1.6; */
  font-weight: 300;
  margin: 0;
  @media #{$vpTablet991} {
    font-size: 2rem;
  }
  @media #{$vpMobile600} {
    font-size: 1.75rem;
  }

  &.white {
    color: $text-white;
  }
}

h4,
.heading4 {
  font-size: 2rem;
  line-height: 1.2; // in ar design its 1.8
  /*rtl:raw:line-height: 1.6; */
  font-weight: 300;
  margin: 0;
  @media #{$vpTablet991} {
    font-size: 1.75rem;
  }
  @media #{$vpMobile600} {
    font-size: 1.25rem;
  }

  &.white {
    color: $text-white;
  }
}

h5,
.heading5 {
  font-size: 1.75rem;
  line-height: 1.2; // in ar design its 1.8
  /*rtl:raw:line-height: 1.8; */
  font-weight: 300;
  margin: 0;
  @media #{$vpTablet991} {
    font-size: 1.5rem;
  }
  @media #{$vpMobile600} {
    font-size: 1.375rem;
  }

  &.white {
    color: $text-white;
  }
}

.body1 {
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 300;

  /*rtl:raw:line-height: 1.7; */
  @media #{$vpMobile600} {
    font-size: 1rem;
  }

  &.white {
    color: $white;
  }
}

.body2 {
  font-size: 1.125rem;
  line-height: 1.4;
  font-weight: 300;


  /*rtl:raw:line-height: 1.7; */

  @media #{$vpMobile600} {
    font-size: 1rem;
  }

  &.white {
    color: $white;
  }

  span {
    position: relative;
    color: $baselinePrimary;
    font-size: 1.125rem;
    line-height: 1.4;
    font-weight: 300;

    @media #{$vpMobile600} {
      font-size: 1rem;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.063rem;
      background-color: #9f8332;
      left: 0;
      bottom: -1rem;
    }
  }
}

.darkSec {
  .body2 {
    font-size: 1.125rem;
    line-height: 1.4;
    font-weight: 300;

    margin: 0;

    /*rtl:raw:line-height: 1.6; */

    @media #{$vpMobile600} {
      font-size: 1rem;
    }

    &.white {
      color: $white;
    }

    span {
      position: relative;
      color: $white;
      font-size: 1.125rem;
      line-height: 1.4;
      font-weight: 300;

      @media #{$vpMobile600} {
        font-size: 1rem;
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0.063rem;
        background-color: $white;
        left: 0;
        bottom: -1rem;

        @media #{$vpMobile600} {
          bottom: -0.88rem;
        }
      }
    }
  }
}

.body3 {
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 300;
  @media #{$vpMobile600} {
    font-size: 0.875rem;
  }
  /*rtl:raw:line-height: 1.8; */

  &.white {
    color: $white;
  }



  &.white {
    color: $white;
  }
}

.body4 {
  font-size: 0.88rem;
  line-height: 1.4;
  font-weight: 300;


  /*rtl:raw:line-height: 1.6; */
  &.white {
    color: $white;
  }
}

.body5 {
  font-size: 0.75rem;
  line-height: 1.4;
  font-weight: 300;
  /*rtl:raw:line-height: 1.6; */

  &.white {
    color: $white;
  }
}

.main-secondary-btn {
  &.with-ic {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$vpTablet767} {
      justify-content: center;
    }

    span {
      &:last-child {
        padding-left: 10px;
      }
    }
  }

  &.small-btn {
    padding: 0.75rem 1.5rem;
  }
}

.main-primary-btn {
  &.with-ic {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media #{$vpTablet767} {
      justify-content: center;
    }

    span {
      &:last-child {
        padding-left: 10px;
      }
    }
  }

  // &.small-btn {
  //   padding: 12px 24px;
  // }
}
.dark-bg {
  .heading3
  {
    color: $white;
  }
}