.sec-media-resources {
  @media #{$vpMobile600} {
    padding-bottom: 4.5rem;
  }
  .wrapper-box {
    .para {
      color: $baselinePrimary;
      margin-bottom: 2rem;
    }

    .heading-col {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media #{$vpMobile600} {
        flex-direction: column;
        gap: 3rem;
      }

      .left-col {
        width: 50%;

        @media #{$vpMobile600} {
          width: 100%;
        }
      }

      .right-col {
        width: 50%;

        @media #{$vpMobile600} {
          width: 100%;
        }
      }
    }

    .media-slider-box {
      position: relative;

      @media #{$vpMobile600} {
        margin-bottom: 1.25rem;
      }

      .filter {
        .nav-pills {
          display: flex;
          gap: 1rem;
          margin: 3rem 0;

          @media #{$vpMobile600} {
            flex-direction: row;
            overflow: scroll;
            flex-wrap: nowrap;
            padding: 0 0 0.938rem 0;
            margin-right: -1.25rem;
          }

          .nav-item {
            @media #{$vpMobile600} {
              flex: 0 0 auto;
            }

            .nav-link {
              border-radius: 0.25rem;
              max-width: fit-content;
              padding: 0.813rem 1.5rem;
              line-height: normal;
              border: 0.063rem solid $secondarySimiLight;
              background-color: transparent;
              color: $baselineSecondary;
              transition: all 0.4s ease;

              @media #{$vpMobile600} {
                flex: 0 0 auto;
              }

              &:hover {
                background-color: $secondarySimiLight;
              }

              &.active {
                background-color: $secondarySimiLight;
                display: flex;
                align-items: center;
                gap: 0.75rem;

                &:after {
                  content: "";
                  width: 0.5rem;
                  height: 0.5rem;
                  display: block;
                  border-radius: 3.125rem;
                  background-color: $baselinePrimary;
                  transition: all 0.4s ease;
                }
              }
            }
          }
        }
      }

      .tab-content-wrapper {
        .tab-content {
          .tab-pane {
            .media-slider {
              max-width: 88.125rem;
              position: relative;

              &::after {
                content: "";
                position: absolute;
                width: 7.5rem;
                height: 100%;
                background-color: $baselineSecondaryLight;
                left: -8.125rem;
                top: 0;
              }

              .slick-list {
                margin-right: -0.438rem;
                margin-left: -0.438rem;
                overflow: visible;

                .slick-track {
                  display: flex;
                  gap: 1.25rem;

                  @media #{$vpMobile600} {
                    gap: 0.625rem;
                  }
                }
              }

              @media #{$vpMobile600} {
                .slick-dots {
                  justify-content: flex-start;
                  width: max-content;
                  padding-top: 1.25rem;
                }
              }

              .grid-view-item {
                position: relative;
                border-radius: 1rem;
                overflow: hidden;
                width: 28.125rem;
                height: 30rem;

                @media #{$vpMobile600} {
                  width: 21.875rem;
                  height: 24.375rem;
                }

                &.podcast-item {
                  .main-content {
                    .podcast-img {
                      width: 100%;
                      height: 15rem;

                      @media #{$vpMobile600} {
                        height: 12.188rem;
                      }

                      .poster-img {
                        width: 100%;
                        height: 15rem;

                        @media #{$vpMobile600} {
                          height: 12.188rem;
                        }
                      }
                    }

                    .podcast-detail {
                      height: 15rem;

                      @media #{$vpMobile600} {
                        height: 12.188rem;
                      }

                      .inner-content {
                        height: 50%;
                      }
                    }
                  }

                  .action-footer {
                    flex-direction: column;
                    align-items: flex-end !important;
                    height: 50%;
                    padding: 1.5rem !important;
                    background-color: $secondarySemiLight;
                    bottom: 0 !important;

                    .heading {
                      padding-right: 0rem !important;

                      .heading5 {
                        color: $baselineSecondary;

                        @media #{$vpMobile600} {
                          font-size: 1.25rem;
                        }
                      }
                    }

                    .btn-box {
                      margin-right: auto;
                      margin-left: 0 !important;

                      @media #{$vpMobile600} {
                        max-width: 100% !important;
                        width: 100%;
                      }
                    }
                  }
                }

                .main-content {
                  position: relative;
                  height: 100%;

                  picture {
                    &:before {
                      content: "";
                      width: 100%;
                      height: 100%;
                      display: block;
                      background: linear-gradient(180deg,
                          rgba(0, 0, 0, 0.3) 0%,
                          rgba(0, 0, 0, 0) 16.25%),
                        linear-gradient(0deg,
                          rgba(0, 0, 0, 0.3) 0%,
                          rgba(0, 0, 0, 0) 18.44%);
                      position: absolute;
                      top: 0;
                      left: 0;
                      z-index: 2;
                      border-radius: 0.75rem;
                    }

                    .poster-img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      transition: all 0.4s ease;

                      @media #{$vpMobile600} {
                        height: 24.375rem;
                      }
                    }
                  }

                  .inner-content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 1.5rem;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    transition: all 0.4s ease;

                    @media #{$vpMobile600} {
                      padding: 1.25rem;
                    }

                    .cate-date-header {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      width: 100%;
                      margin: 0;
                      text-transform: capitalize;

                      .post-date {
                        color: $white;
                        margin: 0;

                        @media #{$vpMobile600} {
                          font-size: 0.875rem;
                        }
                      }

                      .category {
                        color: $secondaryColor;
                        padding: 0.375rem 1rem;
                        background-color: $secondarySimiLight;
                        border-radius: 0.25rem;
                        margin: 0;

                        @media #{$vpMobile600} {
                          font-size: 0.875rem;
                        }
                      }
                    }

                    .play-icon {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    }
                  }

                  .action-footer {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    position: absolute;
                    top: auto;
                    bottom: 1.5rem;
                    padding: 0 1.5rem;
                    z-index: 3;

                    @media #{$vpMobile600} {
                      bottom: 1.25rem;
                      padding: 0 1.25rem;
                    }

                    .heading {
                      max-width: fit-content;
                      padding-right: 1.875rem;

                      @media #{$vpMobile600} {}

                      .body1 {
                        color: $white;
                        margin: 0;
                        font-size: 1.25rem;
                      }
                    }

                    .btn-box {
                      max-width: 12rem;
                      display: flex;
                      align-items: center;
                      gap: 0.875rem;
                      margin-left: 0.875rem;

                      @media #{$vpMobile600} {
                        gap: 0.5rem;
                      }

                      .main-primary-btn {
                        padding: 0.75rem 1.4rem;
                        line-height: 1.375rem;
                        width: 7.9375rem;
                        height: 2.875rem;
                        text-align: center;

                        @media #{$vpMobile600} {
                          padding: 0.5rem 1.125rem;
                          height: 2.25rem;
                          line-height: normal;
                        }

                        &:hover {
                          background-color: $white;
                        }

                        @media #{$vpMobile600} {
                          .btn-text {
                            font-size: 0.875rem;
                          }
                        }
                      }

                      .icon-primary-btn {
                        width: 2.875rem;
                        height: 2.875rem;
                        transition: all .35s ease;
                        border: 0.063rem solid $white;

                        &:hover,
                        &:focus {
                          border: 0.063rem solid $baselinePrimary;
                          transition: all .35s ease;
                        }

                        .insvg {
                          width: 0.875rem;
                          height: 0.875rem;
                        }

                        @media #{$vpMobile600} {
                          width: 2.25rem;
                          height: 2.25rem;
                        }

                        img {
                          width: 0.875rem;
                          height: 0.875rem;
                        }
                      }
                    }
                  }
                }
              }
            }

            .view-wrapper {
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: 3rem;

              @media #{$vpMobile600} {
                position: absolute;
                top: auto;
                bottom: -1.25rem;
                justify-content: flex-end;
                width: auto;
                right: 0;
              }

              .main-secondary-btn {
                @media #{$vpMobile600} {
                  .btn-text {
                    font-size: 0.875rem;
                  }
                }

                &.with-ic {
                  span {
                    &:last-child {
                      display: none;
                    }
                  }

                  @media #{$vpMobile600} {
                    padding: 0.875rem 1.5rem;
                    height: 3rem;

                    span {
                      &:last-child {
                        display: none;
                      }
                    }
                  }

                  img {
                    width: 1rem;
                    height: 1rem;
                  }
                }
              }

              .arrow-wrap {
                bottom: 0;
                top: 2.063rem;
              }
            }
          }
          // .media-listing {
          //   &.filterSlider {
          //     .listingWrapper {
          //       &.element-item {
          //         .grid-view-item {
          //           .main-content {
          //             &:hover {
          //               .light-box-triger {
          //                 picture {
          //                   .poster-img {
          //                     transform: scale(1.25);
          //                   }
          //                 }
          //               }
          //             }
          //           }
          //         }
          //       }
          //     }
          //   }
          // }
        }
      }
    }
  }
  &.no-bg {
    .wrapper-box {
      .media-slider-box {
        .tab-content-wrapper {
          .tab-content {
            .tab-pane {
              .media-slider {
                &:after {
                  background-color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
  &.dark-bg {
    .wrapper-box {
      .media-slider-box {
        .tab-content-wrapper {
          .tab-content {
            .tab-pane {
              .media-slider {
                &:after {
                  background-color: #000000;
                }
              }
            }
          }
        }
      }
    }
  }

  .heading-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .wrapper-heading {
      min-width: 50%;

      .heading2 {
        margin-bottom: 0;
      }
    }
  }
}
.filterSlider {
  position: relative;
  &.js-filter-slider {
    .slick-list {
      overflow: visible;
      .slick-track {
        margin-left: 0 !important;
      }
    }
    .listingWrapper {
      max-width: 100% !important;
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: 12.5rem;
    height: 100%;
    background-color: #f9f7f3;
    left: -13.125rem;
    top: 0;
  }
  &.noSlider {
    display: flex;
  }
}
// .arrow-wrap {
//   &.noSlider {
//     display: none;
//   }
// }  
html[lang="ar"] {
  .sec-e-services {
    .sec-wrapper {
      .slider-wrapper {
        .sec-post-content-box {
          .sec-post-content-info {
            .heading5 {
              line-height: 1.75;
            }
          }
        }
      }
    }
  }
}