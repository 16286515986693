.styleguide-heading {
  background-color: $baselineSecondary;
  color: $text-white;
  text-align: center;
  padding-block: 10px;
  margin-block: 20px;
}

.sb-wrapper {
  max-width: 800px;
  margin-inline: auto;
}

.sbw-box {
  display: flex;
  gap: 50px;
  padding-block: 20px;
  align-items: center;
  margin-bottom: 20px;

  &.sbw-box-white {
    background-color: $secondaryColor;
    padding-inline: 20px;
  }

  &.mt-20 {
    margin-top: 20px;
  }
}

.main-primary-btn {
  color: $baselineSecondary;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 300;
  background: $white;
  padding: 1.31rem 2rem;
  width: auto;
  max-width: max-content;
  border-radius: 12.5rem;
  border: 0.063rem solid transparent;
  transition: all 0.4s ease-out;

  .btn-text {
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 300;

    @media #{$vpMobile600} {
      font-size: 0.875rem;
      line-height: 1.225rem;
    }
  }

  @media #{$vpTablet991} {
    padding: 0.875rem 1.5rem;
  }

  @media #{$vpMobile600} {
    font-size: 0.875rem;
    line-height: 1.225rem;
    padding: 0.71rem 1.12rem;
  }

  &:hover {
    background: $secondarySimiLight;
    color: $baselineSecondary;
    transition: all 0.4s ease-out;
  }
  &.pressed {
    background: $secondarySimiLight;
    color: $baselineSecondary;
    transition: all 0.4s ease-out;
  }

  &:focus {
    outline: $primaryLight solid 0.094rem !important;
    background: $secondarySimiLight;
    border: 0.094rem solid $white;
  }

  &.white {
    color: $secondaryColor;
    background: $text-white;
    border-color: $secondaryColor;

    &:hover,
    &.active {
      background-color: $text-color;
      color: $text-white;
      border-color: $text-white;
    }

    &.disabled {
      opacity: 0.5;
      color: $secondaryColor;
      background: $text-white;
      border-color: $secondaryColor;
    }
  }

  &.small-btn {
    padding: 0.75rem 1.5rem;
  }

  &.disabled,
  &[disabled] {
    opacity: 0.5;
    color: $text-white;
    background: $secondaryColor;
    border-color: $text-white;
  }
}

.darkSec {
  .main-secondary-btn {
    border: none;
    background: $white;
    transition: all 0.4s ease-out;
    color: $baselineSecondary;

    &:hover {
      color: $white;
      border: 0.063rem solid $white;
      background: transparent;
      transition: all 0.4s ease-out;
    }
  }
}

.main-secondary-btn {
  color: $baselineSecondary;
  background: transparent;
  padding: 1.31rem 2rem;
  width: auto;
  max-width: max-content;
  border-radius: 12.5rem;
  border: 0.063rem solid $baselinePrimary;
  font-weight: 300;
  line-height: 1.4;
  transition: all 0.4s ease-out;

  &.with-ic {
    .btn-text {
      line-height: normal;
    }
  }

  .btn-text {
    font-size: 1rem;
    line-height: 1.4;
    font-weight: 300;

    @media #{$vpMobile600} {
      font-size: 0.875rem;
      line-height: 1.225rem;
    }
  }

  @media #{$vpTablet991} {
    padding: 0.875rem 1.5rem;
  }

  @media #{$vpMobile600} {
    font-size: 0.875rem;
    max-width: 100%;
    width: 100%;
    display: block;
    text-align: center;
    padding: 0.71rem 1.12rem;
  }

  &:hover,
  &.active {
    background: $baselineSecondaryLight;
    color: #2c2e34;
    transition: all 0.4s ease-out;
  }

  &:focus,
  &.pressed
   {
    border: 0.094rem solid $primaryLight;
    outline: none;
  }
}

.icon-primary-btn {
  color: $baselineSecondary;
  background: $white;
  border-radius: 100px;
  border: none;
  width: 2.875rem;
  height: 2.875rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  font-weight: 300;
  line-height: 1.4;

  @media #{$vpTablet991} {
    padding: 12px;
  }

  img {
    width: 24px;
    height: 24px;
  }

  svg {
    path {
      fill: $baselineSecondary;
    }
  }
}

.nav-btn-main {
  background: transparent;
  border: .063rem solid #9f8332;
  border-radius: 0.25rem;
  color: #2c2e34;
  font-weight: 300;
  line-height: 1.4;
  max-width: 100%;
  padding: 0.688rem 2.75rem;
  transition: all .4s ease-out;
  width: max-content;
  display: flex;
  align-items: center;
  &::after {
    position: absolute;
    right: 1.125rem;
    background-color: #9f8332;
    border-radius: 3.125rem;
    content: "";
    display: none;
    height: .5rem;
    transition: all .4s ease;
    width: .5rem;
  }
  &.active {
    align-items: center;
    background-color: #e5e0d0;
    display: flex;
    gap: .75rem;
    &::after {
      display: block;
    }
  }
  &:focus {
    outline: none;
  }
}


.wizard-content-left {
  background-blend-mode: darken;
  background-color: rgba(0, 0, 0, 0.45);
  background-image: url("https://i.ibb.co/X292hJF/form-wizard-bg-2.jpg");
  background-position: center center;
  background-size: cover;
  height: 100vh;
  padding: 30px;
}
.wizard-content-left h1 {
  color: #ffffff;
  font-size: 38px;
  font-weight: 600;
  padding: 12px 20px;
  text-align: center;
}

// .form-wizard {
//   color: #888888;
//   padding: 30px;
// }
.form-wizard .wizard-form-radio {
  display: inline-block;
  margin-left: 5px;
  position: relative;
}
.form-wizard .wizard-form-radio input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #dddddd;
  height: 25px;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.form-wizard .wizard-form-radio input[type="radio"]:focus {
  outline: 0;
}
.form-wizard .wizard-form-radio input[type="radio"]:checked {
  background-color: #fb1647;
}
.form-wizard .wizard-form-radio input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 50%;
  left: 1px;
  right: 0;
  margin: 0 auto;
  top: 8px;
}
.form-wizard .wizard-form-radio input[type="radio"]:checked::after {
  content: "";
  display: inline-block;
  webkit-animation: click-radio-wave 0.65s;
  -moz-animation: click-radio-wave 0.65s;
  animation: click-radio-wave 0.65s;
  background: #000000;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
  border-radius: 50%;
}
.form-wizard .wizard-form-radio input[type="radio"] ~ label {
  padding-left: 10px;
  cursor: pointer;
}
.form-wizard .form-wizard-header {
  text-align: center;
}
// .form-wizard .form-wizard-next-btn, .form-wizard .form-wizard-previous-btn, .form-wizard .form-wizard-submit {
//   background-color: #d65470;
//   color: #ffffff;
//   display: inline-block;
//   min-width: 100px;
//   min-width: 120px;
//   padding: 10px;
//   text-align: center;
// }
// .form-wizard .form-wizard-next-btn:hover, .form-wizard .form-wizard-next-btn:focus, .form-wizard .form-wizard-previous-btn:hover, .form-wizard .form-wizard-previous-btn:focus, .form-wizard .form-wizard-submit:hover, .form-wizard .form-wizard-submit:focus {
//   color: #ffffff;
//   opacity: 0.6;
//   text-decoration: none;
// }
.form-wizard .wizard-fieldset {
  display: none;
}
.form-wizard .wizard-fieldset.active {
  display: block;
}
// .form-wizard .wizard-form-error {
//   display: none;
//   background-color: #d70b0b;
//   position: absolute;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   height: 2px;
//   width: 100%;
// }
// .form-wizard .form-wizard-previous-btn {
//   background-color: #fb1647;
// }
// .form-wizard .form-control {
//   font-weight: 300;
//   height: auto !important;
//   padding: 15px;
//   color: #888888;
//   background-color: #f1f1f1;
//   border: none;
// }
// .form-wizard .form-control:focus {
//   box-shadow: none;
// }
// .form-wizard .form-group {
//   display: flex;
// }
// .form-wizard .wizard-form-text-label {
//   position: absolute;
//   left: 10px;
//   top: 16px;
//   transition: 0.2s linear all;
// }
// .form-wizard .focus-input .wizard-form-text-label {
//   color: #d65470;
//   top: -18px;
//   transition: 0.2s linear all;
//   font-size: 12px;
// }
// .form-wizard .form-wizard-steps {
//   margin: 30px 0;
// }
// .form-wizard .form-wizard-steps li {
//   width: 25%;
//   float: left;
//   position: relative;
// }
// .form-wizard .form-wizard-steps li::after {
//   background-color: #f3f3f3;
//   content: "";
//   height: 5px;
//   left: 0;
//   position: absolute;
//   right: 0;
//   top: 50%;
//   transform: translateY(-50%);
//   width: 100%;
//   border-bottom: 1px solid #dddddd;
//   border-top: 1px solid #dddddd;
// }
// .form-wizard .form-wizard-steps li span {
//   background-color: #dddddd;
//   border-radius: 50%;
//   display: inline-block;
//   height: 40px;
//   line-height: 40px;
//   position: relative;
//   text-align: center;
//   width: 40px;
//   z-index: 1;
// }
// .form-wizard .form-wizard-steps li:last-child::after {
//   width: 50%;
// }
// .form-wizard .form-wizard-steps li.active span, .form-wizard .form-wizard-steps li.activated span {
//   background-color: #d65470;
//   color: #ffffff;
// }
// .form-wizard .form-wizard-steps li.active::after, .form-wizard .form-wizard-steps li.activated::after {
//   background-color: #d65470;
//   left: 50%;
//   width: 50%;
//   border-color: #d65470;
// }
// .form-wizard .form-wizard-steps li.activated::after {
//   width: 100%;
//   border-color: #d65470;
// }
// .form-wizard .form-wizard-steps li:last-child::after {
//   left: 0;
// }
// .form-wizard .wizard-password-eye {
//   position: absolute;
//   right: 32px;
//   top: 50%;
//   transform: translateY(-50%);
//   cursor: pointer;
// }
@keyframes click-radio-wave {
  0% {
    width: 25px;
    height: 25px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    width: 60px;
    height: 60px;
    margin-left: -15px;
    margin-top: -15px;
    opacity: 0.0;
  }
}
@media screen and (max-width: 767px) {
  .wizard-content-left {
    height: auto;
  }
}