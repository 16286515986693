.c-sec-map {
    height: 100vh;
}

.interactiveMap {
    display: block;
    font-size: 0;
    position: relative;
    background-color: #FFF;
    overflow: hidden;
    height: 100%;
    .sec-alula-map {
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
        height: 100%;
        z-index: 3;
        @media #{$vpTablet991} {
          width: 50%;
        }
        @media #{$vpMobile600} {
            width: 100%;
            height: 30%;
            top: auto;
            bottom: 1rem;
          }
          &.height--modify {
            @media #{$vpMobile600} {
              height: 100%;
            }
          }
    }
    h2 {
        position: absolute;
        z-index: 3;
        top: 5rem;
        left: 7.5rem;
        color: $baselineSecondary;
        width: 25rem;
        @media #{$vpTablet991} {
          left: 3.5rem;
        }
  
        @media #{$vpMobile600} {
          top: 5rem;
          left: 1.25rem;
        }
      }
    .btn-wrapper {
        position: absolute;
        z-index: 5;
        top: 13.125rem;
        left: 7.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media #{$vpTablet991} {
          left: 3.5rem;
        }

        @media #{$vpMobile600} {
          left: 0;
          top: 1rem;
          flex-direction: row;
          width: 100%;
          overflow: scroll;
          padding-left: 1.25rem;
          &::-webkit-scrollbar {
            display: none;
          }
        }

        .tab-button {
          margin-bottom: 1.5rem;
          background-color: transparent;
          color: $baselineSecondary;
          opacity: 0.4;
          outline: 0;
          border: 0;
          position: relative;
          padding: 0;
          @media #{$vpMobile600} {
            font-size: .875rem;
            border: .063rem solid $baselineSecondary;
            border-radius: .25rem;
            color: #2c2e34;
            padding: 0.75rem 1rem;
            position: relative;
            transition: all .35s ease;
            width: max-content;
            margin-bottom: 0;
            margin-right: 0.75rem;
            flex: 0 0 auto;
          }

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $baselineSecondary;
            left: 0;
            bottom: 0;
            display: none;
          }

          &.active {
            opacity: 1;
            color: $baselineSecondary;

            @media #{$vpMobile600} {
              background-color: #e5e0d0;
              padding-right: 2.75rem;
              border: 0;
            }

            &::after {
              display: block;
              opacity: 1;

              @media #{$vpMobile600} {
                background-color: #9f8332;
                border-radius: 50%;
                content: "";
                height: .5rem;
                opacity: 0;
                position: absolute;
                right: 1.25rem;
                top: 50%;
                transform: translateY(-50%);
                width: .5rem;
                display: block;
                opacity: 1;
                left: auto;
                bottom: auto;
              }
            }
          }
        }

        &.color--modify {
          .tab-button:nth-child(1) {
            color: $white;
            opacity: 0.4;

            @media #{$vpMobile600} {
              color: $white;
              border: .063rem solid $secondarySemiLight;
            }
          }

          .tab-button:nth-child(2) {
            border: 0;
            color: #fff;

            &::after {
              background-color: $white;

              @media #{$vpMobile600} {
                background-color: $baselinePrimary;
              }
            }
          }

          .tab-button:nth-child(3) {
            color: $white;
            opacity: 0.4;

            @media #{$vpMobile600} {
              color: $white;
              border: .063rem solid $secondarySemiLight;
            }
          }

          .tab-button {
            &.active {
              border: 0;

              @media #{$vpMobile600} {
                color: $baselineSecondary;
              }
            }
          }
        }
      }
}
.your-css-class {
    background-color: #5e5e5e;
}

.map-container {
    position: relative;
    height: 100%;
    @media(max-width: 767px) {
        height: 100%;
        min-height: 350px;
    }
}

.map {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 100%;
    .map-elem {
        width: 100%;
        height: 100%;
        display: block;
        // .gm-style div> img {
        //     background-color: #fff;
        // }
    }
}

.markers-list {
    position: relative;
    padding: 20px 0 15px 0;
    max-width: 1340px;
    margin: auto;
    ul {
        display: none;
        height: 0;
        width: 0;
        list-style: none;
        padding: 0;
        text-align: left;
        margin: 0;
        justify-content: space-between;
        li {
            position: relative;
            color: #5e5e5e;
            font-size: 16px;
            text-align: center;
            max-width: 164px;
            a {
                display: inline-block;
                position: relative;
            }
            img {
                height: 73px;
                margin-bottom: 15px;
            }
            span {
                display: block;
                font-size: 12px;
                color: $iPrimary;
                line-height: normal;
                text-transform: uppercase;
                font-weight: 400;
            }
        }
    }
    @media(max-width:767px) {
        height: 270px;
        position: relative;
        padding-bottom: 0;
        &:after {
            content: "";
            width: 100%;
            position: absolute;
            height: 70px;
            bottom: 0;
            left: 0;
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        }
        ul {
            flex-direction: column;
            overflow-y: auto;
            height: 100%;
            padding-bottom: 70px;
            li {
                max-width: 100%;
                margin-top: 12px;
                padding-top: 12px;
                border-top: 1px solid #f2f2f2;
                a {
                    display: flex;
                    align-items: center;
                }
                img {
                    margin-right: 10px;
                    margin-bottom: 0;
                    height: 44px;
                }
                span {
                    text-align: left;
                    font-weight: 300;
                }
                &:first-child {
                    margin-top: 0;
                    padding-top: 0;
                    border-top: 0;
                }
            }
        }
    }
}

.gm-style-pbc {
    transition: opacity ease-in-out;
    background-color: rgba(0, 0, 0, 0.45);
    text-align: center
}

.gm-style-pbt {
    font-size: 22px;
    color: white;
    font-family: Roboto, Arial, sans-serif;
    position: relative;
    margin: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

.gm-style .gm-style-iw {
    font-weight: 300;
    font-size: 13px;
    overflow: hidden;
}

.gm-style-iw {
    max-width: 220px !important;
    background-color: #fff;
    -webkit-box-shadow: 0 3px 16px rgba(178, 178, 178, 0.8);
    box-shadow: 0 3px 16px rgba(178, 178, 178, 0.8);
    border: none;
    border-radius: 3px;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 0 0 60px 0;
    /* padding-bottom: 20px; */
    /* transform: translate(110px, 75px); */
    /* top: 70px !important; */
    /* left: 0px !important; */
    /* right: auto !important; */
}

.gm-style-iw img {
    display: block;
    width: 100%;
    border-radius: 3px 3px 25px 3px;
}

.gm-style img {
    margin: 0!important;
}

.gm-style-iw_prev {
    top: -5px!important;
}