.contact-rcu {
  @media #{$vpMobile600} {
    padding: 2.5rem 0 5rem;
  }

  &.sticky {
    margin-top: -3.524rem;
    &.active {
      overflow: visible;
    }
    .wrapper {
     .content {
       position: sticky;
       top: 8.524rem;
     }
     form {
      padding-top: 3.524rem;
     }
    }
  }

  .error {
    color: red;
    display: none;
  }

  .error-border {
    border: 0.063rem solid red !important;
    border-radius: 0.5rem !important;

    .select2-selection__rendered {
      border: none !important;
    }
  }

  .wrapper {
    display: flex;
    align-items: flex-start;
    padding: 5rem;
    // background: $baselineSecondaryLight;
    justify-content: space-between;
    border-radius: 2rem;
    @media #{$vpTablet991} {
      // padding: 3rem;
      padding: 0;
      flex-direction: column;
      border-radius: 1rem;
      gap: 2.5rem;
    }
    // @media #{$vpMobile600} {
    //   padding: 0;
    //   flex-direction: column;
    //   border-radius: 1rem;
    //   gap: 2.5rem;
    // }

    .content {
      width: 39.125rem;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      @media #{$vpTablet991} {
        // width: 30rem;
        // padding-right: 1.5rem;
        width: 100%;
        gap: 1rem;
      }
      // @media #{$vpMobile600} {
      //   width: 100%;
      //   gap: 1rem;
      // }

      .underline {
        margin: 0;

        @media #{$vpMobile600} {
          padding-bottom: 2rem;
        }

        span {
          @media #{$vpMobile600} {
            font-size: 0.875rem;
          }
        }
      }

      .body2 {
        margin: 0;

        @media #{$vpMobile600} {
          padding-bottom: 2rem;
        }

        span {
          @media #{$vpMobile600} {
            font-size: 0.875rem;
          }
        }
      }

      .simple {
        @media #{$vpMobile600} {
          font-size: 1.25rem;
        }
      }
    }
  }

  form { // .contact-rcu-form
    width: 43rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media #{$vpTablet991} {
      //width: 30rem;
      width: 100%;
    }
    .radio-wrapper {
      width: 24.0625rem;
      gap: 1rem;
      display: flex;
      flex-direction: column;

      @media #{$vpMobile600} {
        width: 100%;
      }

      .radio-options {
        display: flex;
        gap: 1.5rem;

        .option-wrapper {
          display: flex;
          gap: 1rem;
          align-items: center;
          .radio-btn {
            border: 1px solid #000;
          }

          input {
            width: 2rem;
            height: 2rem;
            outline: none;
            border: none;
            background-color: $white;
            appearance: none;
            position: relative;
            border-radius: 100%;

            &:checked::before {
              content: "";
              background-color: $baselinePrimary;
              display: block;
              width: 1.25rem;
              height: 1.25rem;
              border-radius: 100%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }

    input.file-upload-field {
      cursor: pointer;
    }

    .file-upload-wrapper {
      position: relative;
      width: 100%;

      .utility-text {
        font-size: 0.75rem;
        font-weight: 300;
        line-height: 1.4;
        color: #6f7274;
        padding-top: 0.5rem;
      }

      input::-webkit-file-upload-button {
        display: none;
      }

      .search-icon {
        position: absolute;
        right: 3.1rem;
        top: 1.75rem;
        z-index: 2;
        cursor: pointer;
        height: 1rem;
        width: 1rem;

        path {
          fill: $baselineSecondary;
        }
      }

      &:after {
        content: attr(data-text)  !important;
        font-size: 1rem !important;
        position: absolute !important;
        top: 0.7rem !important;
        left: 0.063rem !important;
        background: $white;
        padding: 0.625rem 0.938rem !important;
        width: 99% !important;
        pointer-events: none !important;
        height: 100% !important;
        display: flex !important;
        align-items: center !important;
        border-radius: 0.5rem !important;
        color: $neutralGray400 !important;
        height: 3.125rem !important;
      }

      &:before {
        content: "Browse" !important;
        position: absolute !important;
        top: 0.7rem !important;
        right: 1rem !important;
        height: 3.25rem !important;
        background: transparent !important;
        color: $baselineSecondary !important;
        z-index: 25 !important;
        font-size: 1rem !important;
        padding: 0.5rem 2.4rem !important;
        pointer-events: none !important;
        display: flex !important;
        align-items: center !important;
        border: 0.063rem solid $baselinePrimary !important; 
        border-radius: 12.5rem !important;
        width: 7.9375rem !important;
        font-size: 1rem !important;
        font-weight: 400 !important;
        line-height: 1.2 !important;
        width: 9.75rem !important;
        height: 2.875rem !important;
      }

      &.ktc-file-component-wrapper {
        &:before {
          top: 2.9rem !important;
        }
      }
    }

    .icon-Eye {
      position: absolute;
      right: 1rem;
      top: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;

      &.eye-slash-icon {
        display: none;
        fill: $baselinePrimary;
      }
    }

    .checkbox-options {
      display: flex;
      flex-wrap: wrap;
      gap: 1.25rem;

      .option-wrapper {
        width: 100%;
        display: flex;
        gap: 1.25rem;
        align-items: center;

        @media #{$vpMobile600} {
          width: 100%;
        }

        input {
          width: 2rem;
          height: 2rem;
          outline: none;
          border: none;
          background-color: $white;
          appearance: none;
          position: relative;
          border-radius: 100%;
          border: 0.063rem solid $secondarySimiLight;

          &::before {
            width: 2rem;
            height: 2rem;
            border-radius: 100%;
          }

          &:checked::before {
            content: "\2713";
            background-color: $baselinePrimary;
            display: block;
            position: absolute;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .body3 {
          color: $baselineSecondary;
        }
      }
    }

    @media #{$vpMobile600} {
      width: 100%;
    }

    .twoColRow {
      display: flex;
      gap: 1.25rem;
      justify-content: space-between;

      @media #{$vpMobile600} {
        flex-direction: column;
        gap: 2rem;
      }

      .flexColRow {
        //width: 20.5rem;
        width: 50%;

        @media #{$vpMobile600} {
          width: 100% !important;
        }
      }
    }

    .form-fields-row {
      display: flex;

      &.flexColRow {
        flex-direction: column;
      }

      @media #{$vpMobile600} {
        flex-direction: column;
      }

      input {
        background: $white;
        border: none;
        padding: 1.5rem 1rem;
        border-radius: 0.5rem;
        width: 100%;
        height: 4.5625rem;
        font-size: 1.125rem;
        line-height: 1.4;
        font-weight: 300;
        border: 0.063rem solid $secondarySimiLight;
        color: #5b5b5b;
        @media #{$vpMobile600} {
          font-size: 1rem;
        }

        input[type="date"]::-webkit-calendar-picker-indicator {
          display: none;
        }

        &::placeholder {
          font-size: 1.125rem;
          line-height: 1.4;
          font-weight: 300;
          color: #5b5b5b;
          @media #{$vpMobile600} {
            font-size: 1rem;
          }
        }
      }

      input.file-upload-field {
        cursor: pointer;
      }

      .file-upload-wrapper {
        position: relative;
        width: 100%;

        input::-webkit-file-upload-button {
          display: none;
        }

        img {
          position: absolute;
          right: 1.875rem;
          top: 20.5rem;
          z-index: 2;
          cursor: pointer;
          filter: brightness(0) saturate(100%) invert(14%) sepia(7%)
            saturate(896%) hue-rotate(187deg) brightness(103%) contrast(91%);
          height: 10.5rem;
          width: 10.5rem;
        }

        &:after {
          content: attr(data-text);
          font-size: 1rem;
          position: absolute;
          top: 0.313rem;
          left: 0.313rem;
          background: $white;
          padding: 0.625rem 10.313rem;
          width: 100%;
          pointer-events: none;
          height: 100%;
          display: flex;
          align-items: center;
          border-radius: 0.5rem 12.5rem 12.5rem 0.5rem;
          color: $neutralGray400;
          height: 3.75rem;
        }

        &:before {
          content: "Browse";
          position: absolute;
          top: 0;
          right: 0.125rem;
          height: 4.375rem;
          background: transparent;
          color: $baselineSecondary;
          z-index: 25;
          font-size: 1rem;
          padding: 0 3.75rem 0 40.313rem;
          pointer-events: none;
          display: flex;
          align-items: center;
          border: 0.063rem solid $baselinePrimary;
          border-radius: 12.5rem;
          &:hover {
            background-color: $baselineSecondaryLight;
          }
        }
      }
    }

    .form-row-dropdown {
      display: flex;
      flex-direction: column;
      position: relative;

      img {
        position: absolute;
        right: 1.25rem;
        top: 1.6rem;
        pointer-events: none;
        transition: all 0.35s ease-out;
      }

      .dropdown-icon {
        &.rotate {
          transform: rotate(180deg);
        }
      }

      .select2 {
        width: 100% !important;

        .selection {
          .select2-selection {
            display: block;
            height: 100%;
            border: none;
            border-radius: 0.5rem;

            .select2-selection__rendered {
              background: $white;
              border: none;
              padding: 1.5rem 1rem;
              border-radius: 0.5rem;
              width: 100%;
              height: 4.5625rem;
              color: $neutralGray400;
              appearance: none;
              position: relative;
              display: flex;
              align-items: center;
              font-size: 1.125rem;
              line-height: 1.4;
              font-weight: 300;
              border: 0.063rem solid $secondarySimiLight;
            }

            .select2-selection__arrow {
              display: none;
            }
          }
        }
      }
    }

    .message {
      .message-label {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .body3 {
          @media #{$vpMobile600} {
            font-size: 0.75rem;
          }
        }

        .total-count {
          color: $neutralGray300;
        }

        .counter {
          color: $baselineSecondary;
        }
      }

      textarea {
        width: 100%;
        height: 11.25rem;
        border: none;
        padding: 1.5rem;
        border-radius: 0.5rem;
        font-size: 1.25rem;
        line-height: 1.4;
        font-weight: 300;
        border: 0.063rem solid $secondarySimiLight;

        @media #{$vpMobile600} {
          font-size: 1.25rem;
        }

        &::placeholder {
          font-size: 1.25rem;
          line-height: 1.4;
          font-weight: 300;

          @media #{$vpMobile600} {
            font-size: 1.25rem;
          }
        }

        &.shortTextarea {
          height: 4.5625rem !important;
          overflow: hidden;
        }
      }
    }

    .submit-btn {
      display: flex;
      justify-content: flex-start;

      @media #{$vpMobile600} {
        padding-top: 0.5rem;
      }

      input {
        background: transparent;
        border-radius: 12.5rem;
        border: 0.063rem solid $baselinePrimary;
        padding: 1.3125rem 2rem;
        transition: all 0.4s ease-out;
        font-weight: 300;
        line-height: 1.4;

        @media #{$vpMobile600} {
          width: 100%;
          max-width: 100%;
        }

        &:hover {
          background: $baselinePrimary;
          transition: all 0.4s ease-out;
          color: $white;
        }
      }
    }
  }
  &.step-form {
    &.no-bg {
      .wrapper {
        background-color: #f9f7f3 !important;
      }
    }
    .wrapper {
      padding: 4rem 0 0 7.5rem;
      border-radius: 0;
      @media #{$vpTablet991} {
        padding: 4rem 0 0 3.5rem;
      }
      @media #{$vpMobile600} {
        padding: 0;
      }
      .left-wrapper {
        flex: 0 0 30.68%;
        max-width: 30.68%;
        @media #{$vpTablet991} {
          padding-right: 1.5rem;
        }
        @media #{$vpMobile600} {
          padding-right: 0;
          flex: 0 0 100%;
          max-width: 100%;
        }
        .heading-wrapper {
          margin-bottom: 2rem;
          @media #{$vpMobile600} {
            padding: 2rem 2rem 0 2rem;
          }
        }
        .list-unstyled {
          display: flex;
          flex-direction: column;
          @media #{$vpMobile600} {
           flex-direction: row;
           justify-content: space-between;
           padding: 0 2rem;
          }
          .nav-btn-main {
            margin-bottom: 0.75rem;
            cursor: pointer;
            @media #{$vpMobile600} {
            // margin-right: 1.5rem;
            // padding: .688rem 0.75rem;
            // max-width: 75%;
            // flex: 0 0 auto;
            display: none;
            }
            &.disabled {
              pointer-events: none;
            }
          }
        }
        .button-wrapper {
          display: none;
        }
      }
      .right-wrapper {
        flex: 0 0 61.8%;
        max-width: 61.8%;
        background-color: #fff;
        padding: 4rem 7.5rem 10.625rem 10rem;
        @media #{$vpTablet991} {
          padding: 2rem 4rem;
          flex: 0 0 70%;
          max-width: 70%;
        }
        @media #{$vpMobile600} {
          padding: 2rem;
          flex: 0 0 100%;
          max-width: 100%;
        }
        form { //  .contact-rcu-form
          width: 100%;
          h5 {
            margin-bottom: 2.5rem;
          }
          .twoColRow {
            @media #{$vpMobile600} {
              gap: 0;
            }
            .flexColRow {
              //width: 25rem;
              width: 50%;
            }
          }
          .message-area {
            margin-bottom: 2rem;
            textarea {
              height: 4.563rem;
            }
          }
          .form-fields-row {
            margin-bottom: 2rem;
            position: relative;
            input {
              @media #{$vpMobile600} {
                font-size: 0.875rem;
                height: auto;
              }
            }
            label {
              position: absolute;
              left: 0;
              font-size: 1.125rem;
              font-weight: 300;
              padding-left: 1rem;
              display: none;
              transform: translateY(30px);
              top: 0;
              transition: all 0.35s ease;
              @media #{$vpMobile600} {
                font-size: 0.875rem;
              }
            }
            .file-upload-wrapper {
              &::before {
                @media #{$vpMobile600} {
                  padding: 1rem !important;
                  width: 6.75rem !important;
                }
              }
              .search-icon {
                right: 2rem;
              }
            }
            &.focused {
              .label {
                display: block;
                top: -1.563rem;
                transform: translateY(0);
              }
              input {
                &::placeholder {
                  color: transparent;
                }
              }
            }
            .file-upload-wrapper {
              &::after {
                @media #{$vpTablet991} {
                  width: 65% !important;
                }
                @media #{$vpMobile600} {
                  content: attr(data-text);
                  font-size: 0.875rem !important;
                }
              }
          }
          }
          .radio-wrapper {
            margin-bottom: 2.5rem;
            .radio-options {
              flex-direction: column;
            }
          }
          .form-group {
            display: flex;
          }
          .checkbox-wrapper {
            &:last-child {
              margin-bottom: 2.5rem;
            }
          }
        }
        .location-description {
          margin-bottom: 2.5rem;
        }
      }
    }
  }
}

.select2-dropdown {
  border: none;
  border-radius: 0.5rem;

  .select2-results__option--highlighted[aria-selected] {
    background-color: $baselinePrimary;
  }

  .select2-search {
    display: none;
  }

  .select2-results__option {
    padding: 0.625rem 1.25rem 0.625rem 1.25rem;
  }
}


.darkTheme {
  .contact-rcu {
    &.imgSec {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 67.5rem;
      .bgWrapper {
        height: 100%;
        display: flex;
        align-items: center;
        .wrapper {
          background: rgba(14, 12, 16, 0.5);
          z-index: 2;
          position: relative;
          width: 100%;

          form { //  .contact-rcu-form
            .form-fields-row {
              input {
                border: 0.063rem solid $neutralGray400;
                background: #181818;
                &::placeholder {
                  color: $white;
                }
              }
            }
            .form-row-dropdown {
              .select2 {
                .selection {
                  .select2-selection {
                    .select2-selection__rendered {
                      border: 0.063rem solid $neutralGray400;
                      background: #181818;
                      color: $white;
                    }
                  }
                }
              }

              img {
                filter: invert(1);
              }
            }

            .submit-btn {
              input {
                border: 0.063rem solid $white;
                color: $white;

                &:hover {
                  color: #1d1e20;
                  background-color: $white;
                }
              }
            }
          }
        }
      }

      .form-bgImage {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;

        img {
          width: 100%;
          height: 100%;

          @media #{$vpMobile600} {
            object-fit: cover;
          }
        }
      }
    }
  }

  .select2-results {
    background: #181818 !important;
  }

  .select2-dropdown {
    .select2-results__option--highlighted[aria-selected] {
      background-color: $white !important;
      color: #1d1e20;
    }
  }
}

.imgSec {
  &.contact-rcu {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 67.5rem;
    .bgWrapper {
      height: 100%;
      display: flex;
      align-items: center;
      .wrapper {
        background: rgba(14, 12, 16, 0.5);
        z-index: 2;
        position: relative;
        width: 100%;

        form { //  .contact-rcu-form
          .form-fields-row {
            input {
              border: 0.063rem solid $neutralGray400;
              background: #181818;
              &::placeholder {
                color: $white;
              }
            }
          }
          .form-row-dropdown {
            .select2 {
              .selection {
                .select2-selection {
                  .select2-selection__rendered {
                    border: 0.063rem solid $neutralGray400;
                    background: #181818;
                    color: $white;
                  }
                }
              }
            }

            img {
              filter: invert(1);
            }
          }

          .submit-btn {
            input {
              border: 0.063rem solid $white;
              color: $white;

              &:hover {
                color: #1d1e20;
                background-color: $white;
              }
            }
          }
        }
      }
    }
    .form-bgImage {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;

        @media #{$vpMobile600} {
          object-fit: cover;
        }
      }
    }
  }

  .select2-results {
    background: #181818 !important;
  }

  .select2-dropdown {
    .select2-results__option--highlighted[aria-selected] {
      background-color: $white !important;
      color: #1d1e20;
    }
  }
}

.ktc-file-component-wrapper {
  &.file-upload-wrapper {
    position: relative;
    width: 100%;
  
    .utility-text {
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 1.4;
      color: #6f7274;
      padding-top: 0.5rem;
    }
  
    input::-webkit-file-upload-button {
      display: none;
    }
  
    .search-icon {
      position: absolute;
      right: 3.1rem;
      top: 1.75rem;
      z-index: 2;
      cursor: pointer;
      height: 1rem;
      width: 1rem;
  
      path {
        fill: $baselineSecondary;
      }
    }
  
    &:after {
      content: attr(data-text)  !important;
      font-size: 1rem !important;
      position: absolute !important;
      top: 2.9rem !important;
      left: 0.063rem !important;
      background: $white;
      padding: 0.625rem 0.938rem !important;
      width: 99% !important;
      pointer-events: none !important;
      display: flex !important;
      align-items: center !important;
      border-radius: 0.5rem !important;
      color: $neutralGray400 !important;
      height: 3.125rem !important;
      background: transparent !important;
    }
  
    &:before {
      content: "Browse" !important;
      position: absolute !important;
      top: 2.9rem !important;
      right: 1rem !important;
      height: 3.25rem !important;
      background: transparent !important;
      color: $baselineSecondary !important;
      z-index: 25 !important;
      font-size: 1rem !important;
      padding: 0.5rem 2.4rem !important;
      pointer-events: none !important;
      display: flex !important;
      align-items: center !important;
      border: 0.063rem solid $baselinePrimary !important; 
      border-radius: 12.5rem !important;
      width: 7.9375rem !important;
      font-size: 1rem !important;
      font-weight: 400 !important;
      line-height: 1.2 !important;
      width: 9.75rem !important;
      height: 2.875rem !important;
      background: transparent !important;
    }
  }
}

.form-wrapper{
  height: 100%;
  .form{
    .iti__flag{
      background-image: url("../img/flags.png");
    }
    
    
    .phone-number {
      margin-bottom: 2rem;
    
      .iti {
          width: 100%;
          display: flex;
          /*rtl:raw: flex-direction: row-reverse;*/
    
          .iti__flag-container {
              position: static;
              padding: 0;
    
              .iti__flag {
                  // width: 1.5rem;
                  // height: 1rem;
              }
    
              .iti__selected-flag {
                  padding: 0 1rem;
                  background-color: transparent;
                  border: 1px solid $secondarySemiLight;
                  border-right: 0;
                  outline: none;
                  border-radius: 0.5rem 0 0 0.5rem;
                  /*rtl:raw: border-left: 1px solid $secondaryColor; border-right: 0; border-radius: 0.5rem 0 0 .5rem;*/
    
                  &:focus {
                      border-style: solid;
                      border-width: 0.125rem 0.063rem 0.125rem 0.125rem;
                      border-color: $secondarySemiLight;
                      /*rtl:raw: border-width: 2px 0.063rem 2px 2px; */
                  }
    
                  &[aria-expanded="true"] {
                      border-bottom-width: 0.063rem;
    
                  }
    
                  .iti__selected-dial-code {
                      margin: 0 0.625rem;
                      min-width: 2.5rem;
                      display: flex;
                      justify-content: center;
    
                  }
    
                  .iti__arrow {
                      background-image: url(../../../assets/img/icon/chevron-down.png);
                      background-color: transparent;
                      background-size: contain;
                      border: 0;
                      height: 1.5rem;
                      width: 1.5rem;
                      margin: 0;
                      transition: transform 0.3s ease;
    
                      &.iti__arrow--up {
                          transform: rotate(180deg);
                      }
                  }
              }
    
              .iti__country-list {
                  max-height: 19.063rem;
                  margin: 0;
                  box-shadow: none;
                  border-style: solid;
                  border-width: 0.063rem 0.125rem 0.125rem 0.125rem;
                  width: 100%;
                  left: 0;
                  .iti__divider {
                      display: none;
                  }
                  &.iti__country-list--dropup{
                      border-top-width: 0.125rem;
                  }
                  .iti__country {
                    padding: 1rem 0px 1rem 0.875rem;
                      // padding: 1rem 0.875rem;
                      /*rtl:raw:  direction: ltr; padding: 1rem 0.875rem 1rem 0.875rem;*/
    
                  
                      .iti__flag-box {
                          // margin: 0;
                          // width: 1.5rem;
                      }
    
    
                      .iti__country-name {
                          margin-right: 0.5rem;
                          margin-left: 0.75rem;
                      }
    
                      &.iti__highlight {
                          background-color: $baselinePrimary;
                          color: $baselineSecondary;

                          &:hover{
                            color: $white;
                          }
                      }
    
                      &.iti__active {
                        background-color: $baselinePrimary;
                        color: $white;
                      }
    
                      .iti__dial-code {
                          color: $white;
                      }
    
                      &:hover {
                          background-color: $baselinePrimary;
                          color: $baselineSecondary;
                      }
    
                      &.iti__preferred {
                          display: none;
    
                      }
    
                  }
              }
          }
    
          .phone-number-input {
              padding-left: 1rem !important;
              border-radius: 0 0.5rem 0.5rem 0;
              border-color: $secondarySemiLight;
              /*rtl:raw: padding-left: 1rem !important; border-radius: 0 0.5rem 0.5rem 0; direction: rtl;*/
    
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
              }
    
    
          }
    
    
      }
    
    }
    
    .phone-number {
      .iti {
          .iti__flag-container {
    
    
              .iti__selected-flag {
                  border: 0.063rem solid $baselinePrimary;
                  border-right: 0;
                  /*rtl:raw: border-right: 0; border-left: 1px solid $errorColor*/
    
                  .iti__arrow {
                      background-image: url(../../../assets/img/icon/alert-chevron-down.png);
    
                  }
              }

          }
          .form-control{
            &:focus{
              box-shadow: none;
            }
          }
      }
    }
    .phone-number .iti .iti__flag-container .iti__country-list .iti__country {
      padding: 1rem 0.875rem;
    }
  }
}

.flatpickr-day{
  &.today{
    border-color: $baselinePrimary;
  }
}