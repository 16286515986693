.sec-discovering-alula {
  background-color: $white;

  .box-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media #{$vpMobile600} {
      flex-direction: column;
    }

    .content-left {
      width: 50%;

      @media #{$vpMobile600} {
        width: 100%;
      }

      .heading2 {
        color: $baselineSecondary;
      }
    }

    .contnet-right {
      width: 50%;

      @media #{$vpMobile600} {
        width: 100%;
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .action-box {
          display: flex;
          gap: 1rem;
          margin-top: 2.5rem;

          @media #{$vpMobile600} {
            flex-direction: column;
          }
        }
      }
    }
  }
}

.sec-discovering-slider {
  @media #{$vpDesktop1440} {
    padding: 5rem 0;
  }
  @media #{$vpMobile600} {
    padding: 0;
    }
  &.horizontal-scroll {
    .horizontal-scroll-wrapper {
      overflow: hidden;
      height: auto;

      .horizontal {
        display: flex;
        height: 100%;
        gap: 2.5rem;
        margin-left: -25%;

        .img-item {
          overflow: hidden;
          border-radius: 1rem;

          img {
            width: 100%;
            object-fit: cover;
            border-radius: 1rem;
            max-width: 47.313rem;
            transition: all 0.35s ease;
            @media #{$vpMobile600} {
              max-width: 17rem;
              max-height: 17rem;
              min-height: 17rem;
              transform: scale(0.8);
              transform-origin: bottom;
            }
          }
          .slick-slide {
            &.slick-active {
              img {
                max-width: 18rem;
                max-height: 18rem;
              }
            }
          }
        }
        &>div {
          display: flex;
          flex-shrink: 0;
          align-items: end;
          &:nth-child(odd){
            .img-item {
              img{
                height: 30.313rem;
                @media #{$vpMobile600} {
                  height: auto;
                }
              }
            }
          }
          &:nth-child(even){
            .img-item {
              img{
                height: 38.75rem;
                @media #{$vpMobile600} {
                  height: auto;
                }
              }
            }
          }
        }

        &.ada-slide {
            @media #{$vpMobile600} {
            width: 83%;
            margin-left: 4rem;
            margin-top: 2rem;
            .slick-list {
              overflow: visible;

              .slick-track {
                display: flex;
                align-items: flex-end;
                gap: 0.5rem;

                .slider-item {
                  margin-left: 1.75rem;
                }
                .img-item {
                  img {
                    width: 100%;
                    border-radius: 1rem;
                    // min-height: 12.5rem;
                    object-fit: cover;
                    // transform: scale(1);
                  }
                }
                .slick-slide {
                  &.slick-active {
                    .img-item {
                      img {
                        // max-width: 18rem;
                        // max-height: 18rem;
                        transform: scale(1);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
