.sec-breadcrumb {
  padding: 2rem 0;

  &.bgColor-breadcrumb {
    background: $baselineSecondaryLight;
  }

  @media #{$vpMobile600} {
    padding: 1.25rem 0;
  }
  .breadcrumb-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @media #{$vpMobile600} {
      flex-direction: column;
    }
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      li {
        position: relative;
        font-size: 1rem;
        letter-spacing: -0.01rem;
        font-weight: 300;
        padding: 0 0.75rem;
        color: #2C2E34;
        line-height: 1.4;
        @media #{$vpMobile600} {
          font-size: 0.75rem;
          letter-spacing: -0.008rem;
        }
        &::before {
          display: none;
        }
        a {
          position: relative;
          font-size: 1rem;
          letter-spacing: -0.01rem;
          font-weight: 300;
          color: #2C2E34;
          width: max-content;

          @media #{$vpMobile600} {
            font-size: 0.75rem;
            letter-spacing: -0.008rem;
          }
        }

        &:after {
          content: "";
          position: absolute;
          font-size: 0.875rem;
          font-weight: 300;
          left: -0.125rem;
          top: 0;
          background-image: url("../img/icon/breadcrumb-icon.svg");
          width: 0.375rem;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center;
          /*rtl:raw:transform: rotate(180deg);*/
        }
        &:first-child {
          padding-left: 0;
          &::after {
            display: none;
          }
        }
        &:last-child {
          opacity: 0.5;
        }
      }
    }
  }
  &.dark-bg {
    .breadcrumb-wrapper {
      ul {
        li {
          color: $white;
          a {
            color: $white;
          }
        }
      }
    }
  }
}

.header-light {
  .sec-breadcrumb {
    background: $baselineSecondaryLight;
  }
}

.darkTheme {
  .sec-breadcrumb {
    .breadcrumb-wrapper {
      ul {
        li {
          color: $white;
          opacity: 0.5;
          
          &.breadcrumb-link {
            opacity: 1;
          }

          &::after {
            filter: invert(1);
          }
          a {
            color: $white;
          }
        }
      }
    }
  }
}
