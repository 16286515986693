.open-application {
  .error {
    color: red;
    display: none;
  }

  .error-border {
    border: 1px solid red;
    border-radius: 8px;
  }
  .wrapper {
    display: flex;
    align-items: flex-start;
    padding: 5rem;
    background: $baselineSecondaryLight;
    justify-content: space-between;
    border-radius:2rem;
    @media #{$vpTablet991} {
      padding: 1.875rem;
    }
    @media #{$vpMobile600} {
      padding: 2.5rem 1.875rem;
      flex-direction: column;
      gap: 20px;
      border-radius: 16px;
    }
  }

  .content {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media #{$vpMobile600} {
      width: 100%;
      gap: 10px;
    }
  }

  .application-form {
    max-width: 55%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media #{$vpTablet991} {
      max-width: 65%;
    }
    @media #{$vpMobile600} {
      width: 100%;
      max-width: 100%;
    }

    .twoColRow {
      display: flex;
      gap: 20px;
      @media #{$vpMobile600} {
        flex-direction: column;
      }
      .flexColRow {
        max-width: 50%;
        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }

    .form-fields-row {
      display: flex;
      gap: 20px;

      &.flexColRow {
        flex-direction: column;
      }

      @media #{$vpMobile600} {
        flex-direction: column;
      }

      input {
        background: $white;
        border: none;
        padding: 0 20px;
        border-radius: 8px;
        width: 100%;
        height: 70px;
        font-size: 1.125rem;
        line-height: 1.4;
        font-weight: 300;

        &::placeholder {
          font-size: 1.125rem;
          line-height: 1.4;
          font-weight: 300;
        }
      }

      input.file-upload-field {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        cursor: pointer;
      }

      .file-upload-wrapper {
        position: relative;
        width: 100%;

        input::-webkit-file-upload-button {
          display: none;
        }

        img {
          position: absolute;
          right: 30px;
          top: 28px;
          z-index: 2;
          cursor: pointer;
          filter: brightness(0) saturate(100%) invert(14%) sepia(7%)
            saturate(896%) hue-rotate(187deg) brightness(103%) contrast(91%);
          height: 18px;
          width: 18px;
        }

        &:after {
          content: attr(data-text);
          font-size: 1.125rem;
          position: absolute;
          top: 0;
          left: 1.25rem;
          background: $white;
          // padding: 10px 15px;
          width: 90%;
          pointer-events: none;
          height: 100%;
          display: flex;
          align-items: center;
          border-radius: 8px 200px 200px 8px;
          color: $neutralGray400;
          height: 60px;
        }

        &:before {
          content: "Browse";
          position: absolute;
          top: 0;
          right: -2px;
          height: 70px;
          background: transparent;
          color: $baselineSecondary;
          z-index: 25;
          font-size: 1.125rem;
          padding: 0 3.75rem 0 2.813rem;
          pointer-events: none;
          display: flex;
          align-items: center;
          border: 1px solid $baselinePrimary;
          border-radius: 200px;
          max-width: 10rem;
        }
      }
    }

    .form-row-dropdown {
      display: flex;
      flex-direction: column;
      position: relative;

      img {
        position: absolute;
        right: 20px;
        top: 25px;
        pointer-events: none;
      }

      .select2 {
        width: 100% !important;
        .selection {
          .select2-selection {
            display: block;
            height: 100%;
            border: none;
            border-radius: 8px;

            .select2-selection__rendered {
              background: $white;
              border: none;
              padding: 0 20px;
              border-radius: 8px;
              width: 100%;
              height: 70px;
              color: $neutralGray400;
              appearance: none;
              position: relative;
              display: flex;
              align-items: center;
            }

            .select2-selection__arrow {
              display: none;
            }
          }
        }
      }
    }

    .message {
      .message-label {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .total-count {
          color: $neutralGray300;
        }

        .counter {
          color: $baselineSecondary;
        }
      }

      textarea {
        width: 100%;
        height: 200px;
        border: none;
        padding: 24px;
        border-radius: 8px;
        font-size: 1.125rem;
        line-height: 1.4;
        font-weight: 300;
        &::placeholder {
          font-size: 1.125rem;
          line-height: 1.4;
          font-weight: 300;
        }
      }
    }

    .submit-btn {
      display: flex;
      justify-content: flex-start;
      padding-top: 20px;

      input {
        background: $white;
        border-radius: 200px;
        border: 1px solid $baselinePrimary;
        padding: 15px 60px;
        transition: all 0.4s ease-out;
        font-weight: 300;
        line-height: 1.4;

        @media #{$vpMobile600} {
          width: 100%;
        }

        &:hover {
          background: $baselinePrimary;
          transition: all 0.4s ease-out;
          color: $white;
        }
      }
    }
  }
  &.dark-bg {
    .wrapper {
      background: #1D1E20;
      .application-form {
        .form-fields-row {
          input {
            background: rgba($color: #fff, $alpha: 0.1);
          }
          .file-upload-wrapper {
            input {
              background: rgba($color: #fff, $alpha: 0.1);
            }
            &::after {
              background: rgba($color: #fff, $alpha: 0.1);
            }
          }
        }
        .form-row-dropdown {
          .select2 .selection .select2-selection .select2-selection__rendered {
            background: rgba($color: #fff, $alpha: 0.1);
          }
          .select2-container--default .select2-selection--single {
            background-color: rgba($color: #fff, $alpha: 0.1);
          }
          .select2-container .select2-dropdown {
            background-color: rgba($color: #fff, $alpha: 0.1);
          }
        }
      }
    }
  }
}

// .select2-dropdown {
//   border: none;
//   border-radius: 8px;
//   .select2-results__option--highlighted[aria-selected] {
//     background-color: $baselinePrimary;
//   }
//   .select2-search {
//     display: none;
//   }
//   .select2-results__option {
//     padding: 10px 20px 10px 20px;
//   }
// }
