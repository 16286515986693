.section-content-page {
  background-color: $baselineSecondaryLight;

  &.detailed-podcast {
    .heading2 {
      padding-bottom: 4rem;
      margin: 0;
    }

    .button-wrapper {
      padding-top: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .max-content-wrapper {
    max-width: 100%;
    padding: 0 17.69rem;
    @media #{$vpTablet991} {
      padding: 5rem;
    }
    @media #{$vpMobile600} {
      padding: 0;
    }

    h4 {
      margin: 0;
      padding-bottom: 4rem;
      font-size: 2rem;
      color: $baselineSecondary;
      line-height: 1.3;

      @media #{$vpTablet767} {
        padding-bottom: 1.88rem;
      }
    }

    h2 {
      padding-bottom: 4rem;
      margin: 0;

      @media #{$vpTablet767} {
        padding-bottom: 2rem;
      }
    }

    p {
      padding-bottom: 2.375rem;
      margin: 0;
      font-size: 1.25rem;
      color: $baselineSecondary;
      line-height: 1.4;
      font-weight: 300;

      &:last-child {
        padding-bottom: 0;
      }

      &.mbl-padding{
        padding-bottom: 4rem;
        @media #{$vpMobile600} {
          padding-bottom: 2rem;
        }
      }

      @media #{$vpTablet767} {
        font-size: 1rem;
        padding-bottom: 1.75rem;
      }
    }

    ul, ol {
      padding: 0 0 1.5rem;
      margin: 0;
      li {
        list-style: initial;
        margin-left: 1.5rem;
        padding-bottom: 0;
        font-size: 1.25rem;
        color: $baselineSecondary;
        font-weight: 300;
        padding-left: 0;
        @media #{$vpMobile600} {
          font-size: 1rem;
        }
        &::before {
          display: none;
        }
      }
    }

    .img-wrapper {
      margin: 4rem 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media #{$vpTablet767} {
        margin: 1.88rem 0;
      }
    }

    .video-block {
      padding-bottom: 4rem;

      @media #{$vpMobile600} {
        padding-bottom: 2rem;
      }
      .my-video-dimensions {
        width: 100%;
        height: 34.1875rem;

        @media #{$vpMobile600} {
          height: 12.25rem;
        }

        &.vjs-paused {
          position: relative;

          &::before {
            content: "";
            display: block;
            position: absolute;
            background: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0.6) 15.18%,
              rgba(0, 0, 0, 0) 47.21%
            );
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            pointer-events: none;
          }

          &.vjs-playing {
            &::before {
              display: none;
            }
          }

          .vjs-big-play-button {
            z-index: 2;
            border: none;
            background: none;

            @media #{$vpMobile600} {
              position: absolute !important;
              transform: translate(20%);
            }

            .vjs-icon-placeholder {
              &::before {
                content: "";
                background-image: url(../../../assets/img/icon/play-icon.svg);
                width: 3.475rem;
                height: 3.475rem;
                background-repeat: no-repeat;
                background-size: cover;

                @media #{$vpMobile600} {
                  width: 3rem;
                  height: 3rem;
                }
              }
            }
          }

          .vjs-control-bar {
            z-index: 3;
          }

          video {
            object-fit: cover;
          }
        }
      }
    }
    a{
      color: $baselinePrimary;
    }
  }

  &.financial-times-news-detail {
    .max-content-wrapper {
      h4 {
        padding-bottom: 0;
      }
      .bottom-heading {
        @media #{$vpMobile600} {
          font-size: 1.5rem;
        }
      }
    }
  }
  &.dark-bg {
    .max-content-wrapper {
      h4 {
        color: $white;
      }
      p {
        color: $white;
      }
      ul, ol {
        li {
          color: $white;
        }
      }
      a{
        color: $white;
      }
    }
  }
}
