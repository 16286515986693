html {
  font-family: $primaryFont;
  color: $iBlack;
  background: $white;
  font-weight: 300;
  height: 100%;
  line-height: 1.4;
  background-color: #fafbfe;
  direction: ltr;
  width: 100%;
  font-size: 0.8333333333vw;

  /*rtl:raw:font-family: "HelveticaNeueLT-Arabic"*/
  @media #{$vpTablet991} {
    font-size: 1.3333334vw;
  }

  @media #{$vpMobile600} {
    font-size: 4.102564103vw;
  }

  &.browserIos {
    overflow-x: hidden;
  }
}

body {
  font-family: $primaryFont;
  min-height: 100vh;
  width: 100%;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: #fff;
  color: #2c2e34;
  /*rtl:raw:font-family: "HelveticaNeueLT-Arabic";*/
}

body {
  &.darkTheme {
    background: #120f14 url(../../../assets/img/bg/zig-zag-lines.png);
    background-repeat: repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    color: $white;

    @media #{$vpMobile600} {
      background-repeat: space;
    }
  }

  .darkSec {
    color: $white;
  }
}

.sec-padded {
  padding: 6.25rem 0;

  @media #{$vpTablet991} {
    padding: 4rem 0;
  }

  @media #{$vpMobile600} {
    padding: 2.5rem 0;
  }
}
.e-services-listing.data-library ~ .sec-pagination {
  padding-top: 0;
  @media #{$vpTablet991} {
    padding-top: 0;
  }

  @media #{$vpMobile600} {
    padding-top: 0;
  }
}
.sec-banner.banner-home ~ .sec-royal-commission {
  padding-top: 6.25rem;
  @media #{$vpTablet991} {
    padding-top: 4rem;
  }

  @media #{$vpMobile600} {
    padding-top: 2.5rem;
  }
}


.sec-padding--top {
  padding-top: 6.25rem;

  @media #{$vpTablet991} {
    padding-top: 4rem;
  }

  @media #{$vpMobile600} {
    padding-top: 2.5rem;
  }
}

.sec-padding--bottom {
  padding-bottom: 6.25rem;

  @media #{$vpTablet991} {
    padding-bottom: 4rem;
  }

  @media #{$vpMobile600} {
    padding-bottom: 2.5rem;
  }
}

.padding-top-variant {
  padding-top: 6.25rem;

  @media #{$vpMobile600} {
    padding-top: 2.5rem;
  }
}

.padding-bottom-variant {
  padding-bottom: 6.25rem;

  @media #{$vpMobile600} {
    padding-bottom: 2.5rem;
  }
}

:root {
  --max-width: 100%;
  --padding-large: 7.5rem;
  --padding-medium: 3.75rem;
  --padding-small: 1.25rem;
  --width-milestone-wrapper: auto;
}

::-moz-selection {
  background: $baselinePrimary;
  color: $text-white;
  text-shadow: none;
}

::selection {
  background: $baselinePrimary;
  color: $text-white;
  text-shadow: none;
}

hr {
  display: block;
  height: 0.063rem;
  border: 0;
  border-top: 0.063rem solid #ccc;
  margin: 1em 0;
  padding: 0;
}

*,
*:after,
*:before {
  outline: none;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

.container--custom {
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 0 7.5rem;
  margin: 0 auto;

  @media #{$vpTablet991} {
    padding: 0 4.5rem;

    &--left {
      padding-left: 4.5rem;
    }

    &--right {
      padding-right: 4.5rem;
    }
  }

  @media #{$vpMobile600} {
    padding: 0 1.25rem;

    &--left {
      padding-left: 1.25rem;
    }

    &--right {
      padding-right: 1.25rem;
    }
  }

  &--left {
    padding-left: 7.5rem;
  }

  &--right {
    padding-right: 7.5rem;
  }
}

.container--fluid {
  max-width: var(--max-width);
  width: 100%;
  padding: 0 var(--padding-large) 0 0;
  margin-left: 0;
  margin-right: auto;

  @media (max-width: 1600px) {
    padding: 0 var(--padding-medium) 0 0;
  }

  @media (max-width: 991px) {
    padding: 0 24px 0 0;
  }

  @media (max-width: 600px) {
    padding: 0;
  }
}

.container--header {
  padding: 2.5rem 3.75rem 2rem;

  @media #{$vpMobile600} {
    padding: 1rem 1.25rem;
  }
}

a {
  text-decoration: none !important;
  outline: none !important;
  color: $white;
  transition: all 0.35s ease;

  &:hover {
    color: $neutralGray300;
  }
}

p {
  line-height: 1.6;
  margin: 10px 0;
  font-size: 1rem;

  i {
    font-style: italic;
  }
}

svg {
  transform: translateZ(0);
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix:after {
  clear: both;
}

.o-hidden {
  overflow: hidden;
  height: 100%;
}

.u-hide {
  display: none !important;
}

.dnone {
  display: none !important;
}

.u-pos-s {
  position: static;
}

.u-pos-r {
  position: relative;
}

.pl {
  &-20 {
    padding-left: 20px;
  }

  &-25 {
    padding-left: 25px;
  }

  &-40 {
    padding-left: 40px;
  }

  &-50 {
    padding-left: 50px;
  }
}

.pr {
  &-20 {
    padding-right: 20px;
  }

  &-25 {
    padding-right: 25px;
  }

  &-40 {
    padding-right: 40px;
  }

  &-50 {
    padding-right: 50px;
  }
}

.pt {
  &-20 {
    padding-top: 20px;
  }

  &-25 {
    padding-top: 25px;
  }

  &-40 {
    padding-top: 40px;
  }

  &-50 {
    padding-top: 50px;
  }
}

.pb {
  &-20 {
    padding-bottom: 20px;
  }

  &-25 {
    padding-bottom: 25px;
  }

  &-40 {
    padding-bottom: 40px;
  }

  &-50 {
    padding-bottom: 50px;
  }
}

.ml {
  &-20 {
    margin-left: 20px;
  }

  &-25 {
    margin-left: 25px;
  }

  &-40 {
    margin-left: 40px;
  }

  &-50 {
    margin-left: 50px;
  }
}

.mr {
  &-20 {
    margin-right: 20px;
  }

  &-25 {
    margin-right: 25px;
  }

  &-40 {
    margin-right: 40px;
  }

  &-50 {
    margin-right: 50px;
  }
}

.mt {
  &-20 {
    margin-top: 20px;
  }

  &-25 {
    margin-top: 25px;
  }

  &-40 {
    margin-top: 40px;
  }

  &-50 {
    margin-top: 50px;
  }
}

.mb {
  &-20 {
    margin-bottom: 20px;
  }

  &-25 {
    margin-bottom: 25px;
  }

  &-40 {
    margin-bottom: 40px;
  }

  &-50 {
    margin-bottom: 50px;
  }
}

.toSvg {
  max-width: 100%;
  max-height: 100%;

  polygon,
  path {
    transition: fill 0.35s linear;
  }
}

input::-ms-clear {
  display: none;
}

.primaryfont {
  font-family: $primaryFont;
}

.for--mobilehide {
  @media #{$vpTablet767} {
    display: none !important;
  }
}

.for--mobileshow {
  @media (min-width: 768px) {
    display: none !important;
  }
}

.for--tablethide {
  @media #{$vpTablet991} {
    display: none !important;
  }
}

.for--tabletshow {
  @media (min-width: 992px) {
    display: none !important;
  }
}

.grayScale {
  >img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.6;
    transition: all 0.5s ease;
  }

  &:hover {
    >img {
      -webkit-filter: none;
      filter: none;
      opacity: 1;
    }
  }

  @media #{$vpTablet991} {
    >img {
      -webkit-filter: none;
      filter: none;
      opacity: 1;
    }
  }
}

.bdCenter {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    width: 1px;
    height: 100%;
    background-color: #e3e4e7;
  }
}

.nopdlr--mobile {
  @media #{$vpTablet767} {
    padding-left: 0;
    padding-right: 0;
  }
}

[class^="box-"] {
  display: none;

  &.showfirst {
    display: block;
  }
}

.gridView {
  >ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 -15px;
    flex-wrap: wrap;

    >li {
      padding: 15px;
      flex: 0 0 25%;

      .programmeExperience {
        width: 100%;
      }

      .IE & {
        flex: 0 0 23%;
      }
    }
  }

  @media (max-width: 1550px) {
    >ul {
      >li {
        flex: 0 0 33.33%;
      }
    }
  }

  @media (max-width: 1275px) {
    >ul {
      >li {
        flex: 0 0 50%;
      }
    }
  }

  @media #{$vpTablet991} {
    >ul {
      >li {
        flex: 0 0 50%;
      }
    }
  }

  @media #{$vpTablet767} {
    >ul {
      >li {
        flex: 0 0 100%;
      }
    }
  }
}

.IE {
  .gridView {
    >ul {
      >li {
        flex: 0 0 23%;
      }
    }
  }
}

.popup-is-active {
  overflow: hidden;
}

.loadingDv {
  text-align: center;
  padding: 50px 0 25px 0;
  position: relative;
  min-height: 134px;

  span {
    font-size: 9px;
    font-weight: 300;
    text-transform: uppercase;
    color: $iBlack;
    margin-top: 7px;
    display: block;
  }

  >span {
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 106px;
    transition: all 0.5s ease;
    height: 30px;
  }

  .loadmorebtn {
    display: none;

    >img {
      width: 49px;
    }
  }
}

.dropdown-wrap {
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border-radius: 0 0 5px 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-top: none;
    border-bottom: none;
    list-style: none;
    transition: all 0.3s ease-out;
    max-height: 0;
    overflow: hidden;
    z-index: 4;
    min-width: 207px;

    li {
      padding: 0 10px;
      display: block;
      margin: 0;

      a {
        display: block;
        text-decoration: none;
        color: $iBlack;
        padding: 10px 0;
        transition: all 0.3s ease-out;
        border-bottom: 1px solid #e6e8ea;
        font-size: 12px;
      }

      &:last-of-type a {
        border: none;
      }

      &:hover a {
        color: $iMagenta;
      }
    }
  }

  &.is--active {
    .filterBtn {
      background-color: $iMagenta;
    }

    .dropdown {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      max-height: 400px;
    }
  }
}

.row {
  &.pd_10 {
    margin-left: -10px;
    margin-right: -10px;

    [class*="col-"] {
      padding: 0 10px;
    }
  }
}

.locationBg {
  position: relative;

  @media #{$vpTablet767} {
    min-height: 610px;
  }
}

.bgImg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  transition: all 0.5s ease-in-out;
  top: 0;
  left: 0;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    opacity: 1;
    transition: all 0.5s ease-in-out;
    background: -webkit-repeating-linear-gradient(145deg,
        rgba(64, 64, 64, 0.5),
        rgba(64, 64, 64, 0.5) 2px,
        hsla(0, 0%, 100%, 0.1) 0,
        hsla(0, 0%, 100%, 0.1) 7px);
    background: -o-repeating-linear-gradient(145deg,
        rgba(64, 64, 64, 0.5),
        rgba(64, 64, 64, 0.5) 2px,
        hsla(0, 0%, 100%, 0.1) 0,
        hsla(0, 0%, 100%, 0.1) 7px);
    background: repeating-linear-gradient(-55deg,
        rgba(64, 64, 64, 0.5),
        rgba(64, 64, 64, 0.5) 2px,
        hsla(0, 0%, 100%, 0.1) 0,
        hsla(0, 0%, 100%, 0.1) 7px);
  }

  .bg1 {

    &:before,
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transition: all 0.5s ease-in-out;
    }

    &:before {
      z-index: 2;
      background-color: #242424;
      opacity: 0.97;
    }

    &:after {
      z-index: 3;
      background: #6b89b7;
      background: -webkit-linear-gradient(320deg, #6b89b7 40%, #ba287f);
      background: -o-linear-gradient(320deg, #6b89b7 40%, #ba287f);
      background: linear-gradient(130deg, #6b89b7 40%, #ba287f);
      opacity: 0.5;
    }
  }

  &.style1 {
    z-index: 1;

    &:before {
      background: -webkit-repeating-linear-gradient(145deg,
          rgba(144, 144, 144, 0.5),
          rgba(144, 144, 144, 0.5) 2px,
          hsla(0, 0%, 100%, 0.1) 0,
          hsla(0, 0%, 100%, 0.1) 7px);
      background: -o-repeating-linear-gradient(145deg,
          rgba(144, 144, 144, 0.5),
          rgba(144, 144, 144, 0.5) 2px,
          hsla(0, 0%, 100%, 0.1) 0,
          hsla(0, 0%, 100%, 0.1) 7px);
      background: repeating-linear-gradient(-55deg,
          rgba(144, 144, 144, 0.1),
          rgba(144, 144, 144, 0.1) 2px,
          hsla(0, 0%, 100%, 0.05) 0,
          hsla(0, 0%, 100%, 0.05) 3.4px);
    }
  }
}

.mouseanimateDv {
  display: inline-block;
  text-align: center;
  padding: 20px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all 1.7s ease-in-out;
  opacity: 0;
  z-index: 1;
  visibility: hidden;

  .toSvg {
    width: 28px;
    height: 47px;
    display: inline-block;
    transition: all 1s ease-in-out;
    margin-bottom: 13px;
    position: absolute;
    left: 50%;
    top: -100%;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -50%);

    .anim-path1,
    .anim-path2,
    .anim-path3,
    .anim-path4 {
      animation: mouseanimate 1s infinite;
    }

    .anim-path1,
    .anim-path3 {
      animation-delay: 0.1s;
      animation-direction: alternate;
    }

    .anim-path2,
    .anim-path4 {
      opacity: 0;
      animation-delay: 0.3s;
      animation-direction: alternate;
    }

    &.touchIcon {
      display: none;
    }

    @media #{$vpTablet991} {
      &.touchIcon {
        display: inline-block;
      }

      &.mouseIcon {
        display: none;
      }
    }
  }

  .blurFont {
    opacity: 0;
    transition: all 1s ease-in-out;
    visibility: hidden;
  }

  span {
    padding-top: 11px;
    display: block;
    line-height: 9px;
    text-transform: uppercase;
    color: $white;
    font-weight: 300;
    font-size: 12px;
    transition: all 1s ease-in-out;
    position: absolute;
    opacity: 0;
    visibility: hidden;

    &.slideMRight {
      top: calc(50% + 47px);
      transform: translate(50%, -50%);
    }

    &.slideMLeft {
      top: calc(50% + 67px);
      transform: translate(-50%, -50%);
    }
  }

  .bgImg {
    opacity: 0;
    transition: all 1.5s ease-in-out;
    visibility: hidden;
  }

  .slideMTop {
    top: -100%;
    opacity: 0;
    visibility: hidden;
  }

  .slideMLeft {
    left: -100%;
  }

  .slideMRight {
    right: -100%;
  }

  &.showMAnimate {
    opacity: 1;
    visibility: visible;

    .bgImg {
      opacity: 1;
      visibility: visible;
    }

    .slideMTop {
      top: 50%;
      opacity: 1;
      visibility: visible;
    }

    .blurFont {
      opacity: 0.04;
      visibility: visible;

      .IE & {
        opacity: 0.02;
      }
    }

    .slideMLeft {
      left: 50%;
      opacity: 1;
      visibility: visible;
    }

    .slideMRight {
      right: 50%;
      opacity: 1;
      visibility: visible;
    }
  }
}

@-webkit-keyframes mouseanimate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes mouseanimate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes mouseanimate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes mouseanimate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.plyr--full-ui {
  input[type="range"] {
    color: $white;
  }

  .plyr__controls {

    .plyr__menu,
    .plyr__time {
      display: none;
    }

    .plyr__control {
      width: 42px;
      height: 34px;
      padding: 0;

      // svg.icon--not-pressed {
      //     use {
      //         opacity: 0;
      //     }
      // }
      &[data-plyr="play"] {
        svg {
          height: 20px;
          width: 17px;
          margin: auto;

          use {
            opacity: 0;
          }
        }

        svg.icon--pressed {
          background: url(../img/icons/ic-pause.svg) no-repeat 0 0 scroll;
        }

        svg.icon--not-pressed {
          background: url(../img/icons/ic-play.svg) no-repeat 0 0 scroll;
          width: 20px;
        }
      }

      &[data-plyr="fullscreen"] {
        margin-left: 10px;

        svg {
          margin: auto;
        }

        svg.icon--pressed {}

        svg.icon--not-pressed {
          background: url(../img/icons/ic-fullscreen.svg) no-repeat 0 0 scroll;
          height: 26px;
          width: 34px;

          use {
            opacity: 0;
          }
        }
      }

      &[data-plyr="mute"] {
        svg {
          height: 26px;
          width: 25px;
          margin: auto;

          use {
            opacity: 0;
          }
        }

        svg.icon--pressed {
          background: url(../img/icons/ic-mute.svg) no-repeat 0 0 scroll;
        }

        svg.icon--not-pressed {
          background: url(../img/icons/ic-volume.svg) no-repeat 0 0 scroll;
        }
      }
    }

    .plyr__volume {
      margin-left: 10px;
    }
  }
}

.plyr--video {
  .plyr__controls {
    .plyr__control {

      &.plyr__tab-focus,
      &:hover,
      &[aria-expanded="true"] {
        background: $iGreen;
      }
    }
  }
}

.plyr__control--overlaid {
  background: $iGreen;

  &:hover,
  &:focus {
    background: $iGreen;
  }
}

.css-scrollable {
  .mCSB_scrollTools {
    .mCSB_draggerRail {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .mCSB_dragger {
      .mCSB_dragger_bar {
        background-color: rgba(60, 63, 64, 0.4);
      }
    }
  }
}

.underline-title {
  border-bottom: 1px solid $baselinePrimary;
  padding-bottom: 14px;
  display: inline-block;
  color: $baselinePrimary;
}

.slick-dots {
  display: flex;
  justify-content: center;
  margin-top: 24px;

  .custom-pagination-dot {
    width: 10px;
    height: 10px;
    margin-right: 0.31rem;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid $baselinePrimary;
    cursor: pointer;
  }

  .slick-active {
    .custom-pagination-dot {
      background-color: $baselinePrimary;
    }
  }
  li {
    padding-left: 0;
    &::before {
      display: none;
    }
  }
}

.darkSec {
  .slick-dots {
    justify-content: flex-start;

    .slick-active {
      .custom-pagination-dot {
        background-color: $white;
      }
    }

    .custom-pagination-dot {
      border: 0.063rem solid $white;
    }
  }
}

.slider-arrow-pagination {
  position: relative;
  

  @media #{$vpMobile600} {
    display: none;
  }

  .arrow-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 300px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    [dir="rtl"] & {
      .slide-arrow {
        // flex-direction: row-reverse;
      }
    }

    .slide-arrow {
      display: flex;
      justify-content: space-between;
      /*rtl:raw:flex-direction: row-reverse; */

      .custom-arrows {
        // width: 44px;
        // height: 44px;
        width: 2.875rem;
        height: 2.875rem;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid $baselinePrimary;
        border-radius: 50%;
        z-index: 1;
        position: relative;
        transition: all 0.35s ease 0s;
        opacity: 1;
        visibility: visible;
        @media #{$vpMobile600} {
          width: 2.5rem;
          height: 2.5rem;
        }
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        &.next {
          right: 0;
          margin-left: 1rem;
          transform: translatex(0px);
        }

        &.prev {
          left: 0;
          margin-left: 1rem;
          transform: translatex(0px);
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          /* rtl:ignore */
          width: 1rem;
          height: 1rem;
        }

        &:hover {
          background-color: $secondaryLight !important;
          @media #{$vpTablet1024} {
            background-color: transparent !important;
          }
        }
      }
    }

    .pagination-wrap {
      transition: all 0.35s ease 0s;

      .custom-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .custom-pagination-label {
        margin: 0 10px;
        color: $baselineSecondary;
        font-weight: 500;
        line-height: 1.5;

        .current-slide {
          color: $baselineSecondary;
        }

        .total-slides,
        .seperator {
          color: $neutralGray300;
        }
      }
    }

    // &.hidePrevArrow {
    //   .pagination-wrap {
    //     transform: translatex(62px);
    //   }

    //   .slide-arrow {
    //     .custom-arrows {
    //       &.prev {
    //         transform: translatex(62px);
    //         opacity: 0;
    //         visibility: hidden;
    //       }
    //     }
    //   }
    // }

    // &.hideNextArrow {
    //   .pagination-wrap {
    //     transform: translatex(62px);
    //   }

    //   .slide-arrow {
    //     .custom-arrows {
    //       &.prev {
    //         transform: translatex(62px);
    //       }

    //       &.next {
    //         transform: translatex(62px);
    //         opacity: 0;
    //         visibility: hidden;
    //       }
    //     }
    //   }
    // }
  }
}

.darkSec {
  .slider-arrow-pagination {
    .arrow-wrap {
      .slide-arrow {
        .custom-arrows {
          border: 0.063rem solid $secondarySemiLight;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.4s ease-out;

          &:hover {
            svg {
              path {
                fill: #120f14;
                transition: all 0.4s ease-out;
              }
            }
          }

          svg {
            width: 1rem;

            path {
              fill: $white;
            }
          }
        }
      }

      .pagination-wrap {
        .custom-pagination-label {
          color: $white;
        }
      }
    }
  }
}

.wrapper {
  .content {
    .title-wrap {
      .body3 {
        @media #{$vpMobile600} {
          font-size: 0.88rem;
        }
      }
    }
  }
}

.mbl-font-14 {
  font-size: 0.88rem;
}

// Image Hover Effect

img.imgHoverEffect {
  transition: all 0.7s ease-in-out;

  &:hover {
    transform: scale(1.25);
    transition: all 0.7s ease-in-out;
  }

  @media #{$vpTablet1024} {
    &:hover {
      transform: scale(1);
    }
  }
}

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: $baselinePrimary !important;
  width: 4px !important;
  border-radius: 0.25rem;
}
.mCSB_inside>.mCSB_container {
  margin-right: 12px;
}

.theme--dark {
  .slider-arrow-pagination {
    .arrow-wrap {
      .pagination-wrap {
        .custom-pagination-label {

          .current-slide,
          .seperator,
          .total-slides {
            color: $white;
          }
        }
      }

      .slide-arrow {
        .custom-arrows {
          border: 1px solid $white;

          .insvg {
            path {
              fill: $white;
            }
          }
          &:hover{
            .insvg {
              path {
                fill: $baselinePrimary;
              }
            }
          }
        }
      }
    }
  }
}

.page--wrapper {
  position: relative;
  ul {
    li {
      position: relative;
      padding-left: 20px;
      &::before {
        content: '';
        position: absolute;
        border-radius: 50%;
        width: 0.375rem;
        height: 0.375rem;
        background-color: #000000;
        left: 0;
        top: 0.625rem;
      }
    }
  }
  ol {
    padding: 0;
    padding-left: inherit;
  }
}

.gray-bg {
  background-color: #f9f7f3 !important;
}

.no-bg {
  background-color: transparent !important;
  .main-primary-btn {
    border: .063rem solid #9f8332;
  }
  .main-secondary-btn {
    border: .063rem solid #9f8332;
    border-color: #9f8332;
  }
}

.dark-bg {
  background-color: #000000 !important;
  color: $white;

  .insvg {
    path {
      fill: $white;
    }
  }

  .main-secondary-btn {
    color: $white;
  }

  .slider-arrow-pagination {
    .arrow-wrap {
      .slide-arrow {
        .arrow-wrap {
          &.noSlider--pagination {
            display: none !important;
          }
        }
        .custom-arrows {
          &:hover {
            svg {
              path {
                fill: #120f14;
                transition: all 0.4s ease-out;
              }
            }
          }

          svg {
            path {
              fill: $white;
            }
          }
        }
      }

      .pagination-wrap {
        .custom-pagination-label {
          color: $white;
        }
      }
    }
  }

  input,
  .select2 .selection .select2-selection .select2-selection__rendered,
  .select2-container--default .select2-selection--single,
  textarea,
  .select2-container .select2-dropdown,
  .file-upload-wrapper::after {
    background: #1a1a1a !important;
    color: #fff;
  }

  .Formsubmit {
    background-color: transparent !important;
    color: #fff;
    transition: all 0.35s ease;

    &:hover {
      background-color: #9f8332 !important;
    }
  }
}

.form-field {
  margin-bottom: 1rem;
  .editing-form-control-nested-control{
    .ktc-radio-list{
      @media #{$vpMobile600} {
        display: flex;
        padding-top: 1.25rem;
      }
      .ktc-radio{
        @media #{$vpMobile600} {
          padding-left: 1.875rem;
          &:last-child{
            padding-left: 0;
          }
        }
      }
    }
  }

  .explanation-text {
    color: #6f7274;
    font-size: .75rem;
    font-weight: 300;
    line-height: 1.4;
    padding-top: .5rem;
  }

  select {
    background: transparent;
    border: .063rem solid #e5e0d0;
    border-radius: .5rem;
    font-size: 1.125rem;
    font-weight: 300;
    height: 4.5625rem;
    line-height: 1.4;
    padding: 0.5rem 1rem;
    width: 100%;
    -moz-appearance:none;
    -webkit-appearance:none;
    appearance:none;
  }

  .select2 {
    width: 100% !important;

    .selection {
      .select2-selection {
        display: block;
        height: 100%;
        border: none;
        border-radius: 0.5rem;

        .select2-selection__rendered {
          background: $white;
          border: none;
          padding: 1.5rem 1rem;
          border-radius: 0.5rem;
          width: 100%;
          height: 4.5625rem;
          color: $neutralGray400;
          appearance: none;
          position: relative;
          display: flex;
          align-items: center;
          font-size: 1.125rem;
          line-height: 1.4;
          font-weight: 300;
          border: 0.063rem solid $secondarySimiLight;
        }

        .select2-selection__arrow {
          display: none;
        }
      }
    }
  }

  input {
    background: transparent;
    border: .063rem solid #e5e0d0;
    border-radius: .5rem;
    font-size: 1.125rem;
    font-weight: 300;
    height: 4.5625rem;
    line-height: 1.4;
    padding: 1.5rem 4rem 1.5rem 1.5rem;
    width: 100%;
    @media #{$vpMobile600} {
      font-size: 1rem;
      }
    &:focus {
      box-shadow: none;
      border-color: $baselinePrimary;
    }
    .disabled,
    &:disabled {
      pointer-events: none;
      background: rgba($secondarySemiLight, 0.2);
      border-color: rgba($secondarySemiLight, 0.5);
    }
  }
  .input-validation-error {
    border-color: $errorColor;
  }
  .field-validation-error{
    color: $errorColor;
    position: absolute;
  }

  textarea {
    border: .063rem solid #e5e0d0;
    border-radius: .5rem;
    font-size: 1.25rem;
    font-weight: 300;
    height: 11.25rem;
    line-height: 1.4;
    padding: 1.5rem;
    width: 100%;

    &:focus {
      box-shadow: none;
      border-color: $baselinePrimary;
    }
    .disabled,
    &:disabled {
      pointer-events: none;
      background: rgba($secondarySemiLight, 0.2);
      border-color: rgba($secondarySemiLight, 0.5);
    }
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: .063rem solid #e5e0d0;
    border-radius: 100%;
    height: 2rem;
    outline: none;
    position: relative;
    max-width: 2rem;
    background-color: transparent !important;
    padding: 0;

    &:checked:before {
      content: "✓";
      align-items: center;
      background-color: #9f8332;
      color: #fff;
      display: block;
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }

    &.invalid {
      &+label {
        border-color: #f36e7f !important;
        color: #f36e7f;
      }
    }
  }

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: .063rem solid #e5e0d0;
    border-radius: 100%;
    height: 2rem;
    outline: none;
    position: relative;
    width: 2rem;
    background-color: transparent !important;
    padding: 0;

    &:checked:before {
      background-color: #9f8332;
      border-radius: 100%;
      content: "";
      display: block;
      height: 1.25rem;
      left: 50%;
      position: absolute;
      top: 50%;
      width: 1.25rem;
      transform: translate(-50%, -50%);
    }
  }

  .ktc-checkbox,
  .ktc-radio {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;

    label {
      margin-bottom: 0;
      margin-left: 1rem;
    }
  }
}

input[type="submit"] {
  background: transparent;
  border: .063rem solid #9f8332;
  border-radius: 12.5rem;
  color: #2c2e34;
  font-weight: 300;
  line-height: 1.4;
  max-width: -moz-max-content;
  max-width: max-content;
  padding: 1.31rem 2rem;
  transition: all .4s ease-out;
  width: auto;
  &:hover {
    background-color: #e5e0d0;
  }
}

section {
  overflow: hidden;
}

.noSlider {
  display: flex;
  column-gap: 1.5rem;
}
.noSlider--pagination {
  display: none !important;
}

.custom-arrows,
.main-secondary-btn,
.main-primary-btn {
  &:hover {
    @media #{$vpTablet1024} {
      background-color: transparent !important;
    }
  }
}
.main-primary-btn {
  &:hover {
    @media #{$vpTablet1024} {
      background-color: #fff !important;
    }
  }
}
.error {
  color: red;
  display: none;
}
.invalid-email-error {
  color: red;
  display: none;
}
.invalid-number-error {
  color: red;
  display: none;
}
.invalid-file-type-error {
  color: red;
  display: none;
}
.invalid-file-size-error {
  color: red;
  display: none;
}

.custom-arrows {
  @media #{$vpTablet1024} {
  height: 3.125rem;
  width: 3.125rem;;
  svg,
  img {
      width: 10px;
      height: 10px;
    }
  }
}

.icon {
  .icon-arrow-right {
    /*rtl:raw:transform: rotate(180deg);*/
  }
}
.arrow-icon {
    /*rtl:raw:transform: rotate(180deg);*/
}


::placeholder {
  overflow: visible;
}

::-ms-input-placeholder { /* Edge 12 -18 */
  overflow: visible;
}

body {
  &.Safari {
    .contact-rcu {
      .contact-rcu-form {
        .form-fields-row {
          input[type="date"] {
            position: relative;
            &::after {
              position: absolute;
              content: "";
              background-image: url(../img/icon/calendar.png);
              width: 24px;
              height: 24px;
              right: 0.938rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
    }
  }
}

.form-control {
  &:focus {
    box-shadow: none !important;
    background-color: transparent;
    box-shadow: none !important;
  }
}

.formwidget-submit-text {
  color: #fff;
}

.grecaptcha-badge{
  @media #{$vpMobile600} {
    bottom: -130px !important;
  }
  @media #{$vpMobile480} {
    bottom: -95px !important;
  }
}
:lang(ar) {
  .lang-link a{
    font-family: "HelveticaNeueLT-Arabic";
  }
}
:lang(en) {
  .lang-link a{
    font-family: $primaryFont;
  }
}

.is-iframe .form-control {
  height: auto !important;
}

.page--wrapper form{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 39.125rem;
  @media #{$vpTablet991} {
    width: 100%;
  }
}

.footer-menu {
  .news-letter-info {
    .news-letter-inner {
      .form-field {
        input {
          color: #fff !important;
        }
      }
    }
  }
}



.is-iframe {
  label {
    margin: 0;
    @media #{$vpDesktop1440} {
      font-size: .844rem;
    }
    @media #{$vpTablet1280} {
      font-size: .75rem;
    }
    @media #{$vpTablet991} {
      font-size: 0.929rem;
    }
    @media #{$vpMobile480} {
      font-size: 0.85rem;
    }
  }

  .form-control {
    @media #{$vpDesktop1440} {
      min-height: 3.422rem !important;
      font-size: .844rem !important;
      padding: 1.125rem .75rem !important;
    }
    @media #{$vpTablet1280} {
      min-height: 3.041rem !important;
      font-size: .75rem !important;
      padding: 1rem .667rem !important;
    }
    @media #{$vpTablet991} {
      min-height: 3.768rem !important;
      padding: 1.239rem 0.826rem !important;
      font-size: 0.929rem !important;
    }
    @media #{$vpMobile480} {
      font-size: 0.85rem !important;
    }
    option {
      &:active,
      &:focus,
      &:hover {
        background-color: $baselinePrimary;
      }
    }
  }

  div:has(> input[type="file"]) {
    &:before {
      top: 0.85rem !important;
      @media #{$vpDesktop1440} {
        font-size: .95rem !important;
        height: 2.5rem !important;
        top: 0.55rem !important;
      }
      @media #{$vpTablet1280} {
        font-size: 0.85rem !important;
        height: 2rem !important;
        width: 6.9375rem !important;
      }
      @media #{$vpTablet991} {
        height: 2.5rem !important;
        top: .6rem !important;
      }
      @media #{$vpMobile480} {
        height: 2rem !important;
        width: 5.9375rem !important;
        top: 0.85rem !important;
      }
    }

    input[type="file"] {
      @media #{$vpDesktop1440} {
        height: 3.51rem !important;
      }
      @media #{$vpTablet1280} {
        height: 3.041rem !important;
      }
      @media #{$vpTablet991} {
        height: 3.768rem !important;
      }
    }

    &:after {
      content: '' !important;
      top: 0.85rem !important;
      @media #{$vpDesktop1440} {
        top: 0.3rem !important;
      }
      @media #{$vpTablet1280}  {
        height: 2.3rem !important;
      }
      @media #{$vpTablet991} {
        height: 3.2rem !important;
      }
    }
  }

  input[type="radio"] {
    height: 1.5rem;
    width: 1.5rem;
    @media #{$vpDesktop1440} {
      height: 1.2rem;
      width: 1.2rem;
    }
    @media #{$vpTablet1280} {
      height: 1rem;
      width: 1rem;
    }
    @media #{$vpTablet991} {
      top: -.12rem;
    }
    &:checked {
      &:before {
        height: 1.5rem;
        width: 1.5rem;
        @media #{$vpDesktop1440} {
          height: 1.2rem;
          width: 1.2rem;
        }
        @media #{$vpTablet1280} {
          height: 1rem;
          width: 1rem;
        }
      }
    }
  }

  div:has(> input[type="checkbox"]) {
    gap: 1rem;
    @media #{$vpTablet991} {
      align-items: flex-start;
    }
  }

  input[type="checkbox"] {
    height: 1.5rem;
    width: 1.5rem;
    @media #{$vpTablet1280} {
      height: 1.2rem;
      width: 1.2rem;
    }
    &:checked{
      &:before {
        height: 1.5rem;
        width: 1.5rem;
        padding-top: .3rem;
        @media #{$vpTablet1280} {
          height: 1.2rem;
          width: 1.2rem;
        }
      }
    }
  }

  .btn{
    &.btn-primary,
    &.primary {
      @media #{$vpDesktop1440} {
        padding: .8rem 2rem !important;
        font-size: .844rem !important;
      }
      @media #{$vpMobile600} {
        font-size: .9rem !important;
        justify-content: center !important;
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
}

.ktc-default-section {
  .form-field {
    margin-bottom: 1rem;
  }
}

.form-group {
  margin-bottom: 2rem !important;
  @media #{$vpDesktop1440} {
    margin-bottom: 1.3rem;
  }
  @media #{$vpTablet991} {
    margin-bottom: 1.5rem;
  }
  .radioBlock {
    display: flex;
    column-gap: 1.5rem;
    margin-top: .5rem;
    @media #{$vpDesktop1440} {
      font-size: .844rem;
    }
    @media #{$vpTablet1280} {
      font-size: .75rem;
    }
    @media #{$vpTablet991} {
      font-size: 0.929rem;
    }
    @media #{$vpMobile480} {
      font-size: 0.85rem;
    }
  }
}

.content-para,
.content-box {
  b, strong {
    font-weight: bold;
  }
  a {
    color: $baselinePrimary;
    &:hover {
      color: $baselineSecondary;
    }
  }
}

.page--wrapper {
  .formwidget-submit-text {
    width: 43rem;
    text-align: center;
    color: $baselineSecondary;
    font-size: 1.75rem;
    line-height: 1.2;
    font-weight: 300;
    padding-top: 4rem;
    @media #{$vpTablet991} {
      font-size: 1.5rem;
      width: 100%;
      padding-top: 0;
    }
    @media #{$vpMobile600} {
      font-size: 1.375rem;
    }
  }
  .fr-view {
    max-width: 100%;
    width: 100%;
    height: 100%;
    padding: 0 7.5rem;
    margin: 0 auto;
    @media #{$vpTablet991} {
      padding: 0 4.5rem;
    }
    @media #{$vpMobile600} {
      padding: 0 1.25rem;
    }
  }
}