.service-form {
  &.complated-services {
    .wrapper {
      .left-wrapper {
        .left-wrapper-container {
          justify-content: flex-start;
          gap: 2.5rem;

          @media #{$vpMobile600} {
            gap: 2rem;
          }

          .heading-wrapper {
            .heading3 {
              margin-bottom: 0;
            }
          }

          .date-trans-wrapper {
            display: flex;
            gap: 1rem;
            flex-direction: column;

            .transaction-txt {
              .body3 {
                margin: 0;
              }
            }
          }
        }
      }

      .right-wrapper {
        .right-wrapper-container {
          .sentFeedback {
            width: 25.3125rem;

            @media #{$vpMobile600} {
              width: 100%;
            }

            .heading4 {
              margin-bottom: 2.5rem;

              @media #{$vpMobile600} {
                font-size: 1.5rem;
                margin-bottom: 2rem;
              }
            }

            .content-feedback {
              .heading5 {
                margin-bottom: 1.5rem;

                @media #{$vpMobile600} {
                  font-size: 1.25rem;
                }
              }

              .innet-conten-item {
                display: flex;
                align-items: flex-start;
                gap: 1.5rem;
                margin-bottom: 2rem;

                &:last-child {
                  margin-bottom: 0;
                }

                .numText {
                  color: $baselinePrimary;
                }

                .right-text {
                  color: $baselineSecondary;
                  display: flex;
                  align-items: flex-start;
                  justify-content: center;
                  flex-direction: column;
                  gap: 1.125rem;

                  p {
                    margin: 0;
                  }

                  .main-secondary-btn {
                    height: 2.875rem;
                    padding: 0.75rem 1.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }


  }

  .error {
    color: red;
    display: none;
  }

  .error-border {
    border: 0.063rem solid red !important;
    border-radius: 0.5rem !important;
  }

  .wrapper {
    display: flex;

    @media #{$vpMobile600} {
      flex-direction: column;
    }

    .left-wrapper {
      width: 50.5rem;
      height: 51.1875rem;
      position: relative;

      @media #{$vpMobile600} {
        height: 100%;
      }

      &::before {
        content: "";
        background-color: $baselineSecondaryLight;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
      }

      @media #{$vpMobile600} {
        width: 100%;
      }

      .left-wrapper-container {
        padding: 4rem 8.81rem 2.5rem 7.5rem;
        height: 51.1875rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        z-index: 1;
        position: relative;

        @media #{$vpMobile600} {
          padding: 2.5rem 1.25rem;
          height: 100%;
        }

        .heading3 {
          color: $baselineSecondary;
        }

        .button-wrapper {
          display: flex;
          justify-content: flex-end;

          @media #{$vpMobile600} {
            display: none;
          }

          button {
            padding: 0.875rem 1.5rem;
            justify-content: center;

            img {
              width: 1rem;
              height: 1rem;
              filter: invert(1);
            }
          }
        }
      }
    }

    .right-wrapper {
      width: 69.5rem;

      @media #{$vpMobile600} {
        width: 100%;
      }

      .right-wrapper-container {
        padding: 4rem 7.5rem 4rem 10.12rem;

        @media #{$vpMobile600} {
          padding: 2.5rem 1.25rem;
        }

        .login-form {
          .heading4 {
            margin-bottom: 2.5rem;
            color: $baselineSecondary;


            @media #{$vpMobile600} {
              font-size: 1.5rem;
              margin-bottom: 2rem;
            }
          }

          .contact-rcu-form {
            width: 43rem;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            @media #{$vpMobile600} {
              width: 100%;
              gap: 1.5rem;
            }

            .twoColRow {
              display: flex;
              gap: 1.25rem;
              justify-content: space-between;

              @media #{$vpMobile600} {
                flex-direction: column;
                gap: 1.5rem;
              }

              .flexColRow {
                width: 20.5rem;

                @media #{$vpMobile600} {
                  width: 100% !important;
                }
              }
            }

            .form-fields-row {
              display: flex;

              &.flexColRow {
                flex-direction: column;
              }

              @media #{$vpMobile600} {
                flex-direction: column;
              }

              input {
                background: $white;
                border: none;
                padding: 1.5rem 1rem;
                border-radius: 0.5rem;
                width: 100%;
                height: 4.5625rem;
                font-size: 1.125rem;
                line-height: 1.4;
                font-weight: 300;
                border: 0.063rem solid $secondarySemiLight;

                @media #{$vpMobile600} {
                  font-size: 1rem;
                }

                &::placeholder {
                  font-size: 1.125rem;
                  line-height: 1.4;
                  font-weight: 300;

                  @media #{$vpMobile600} {
                    font-size: 1rem;
                  }
                }
              }

              input.file-upload-field {
                border-top-right-radius: 1.875rem;
                border-bottom-right-radius: 1.875rem;
                cursor: pointer;
              }

              .file-upload-wrapper {
                position: relative;
                width: 100%;

                input::-webkit-file-upload-button {
                  display: none;
                }

                img {
                  position: absolute;
                  right: 1.875rem;
                  top: 20.5rem;
                  z-index: 2;
                  cursor: pointer;
                  filter: brightness(0) saturate(100%) invert(14%) sepia(7%) saturate(896%) hue-rotate(187deg) brightness(103%) contrast(91%);
                  height: 10.5rem;
                  width: 10.5rem;
                }

                &:after {
                  content: attr(data-text);
                  font-size: 1rem;
                  position: absolute;
                  top: 0.313rem;
                  left: 0.313rem;
                  background: $white;
                  padding: 0.625rem 10.313rem;
                  width: 100%;
                  pointer-events: none;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  border-radius: 0.5rem 12.5rem 12.5rem 0.5rem;
                  color: $neutralGray400;
                  height: 3.75rem;
                }

                &:before {
                  content: "Browse";
                  position: absolute;
                  top: 0;
                  right: 0.125rem;
                  height: 4.375rem;
                  background: transparent;
                  color: $baselineSecondary;
                  z-index: 25;
                  font-size: 1rem;
                  padding: 0 3.75rem 0 40.313rem;
                  pointer-events: none;
                  display: flex;
                  align-items: center;
                  border: 0.063rem solid $baselinePrimary;
                  border-radius: 12.5rem;
                }
              }
            }

            .form-row-dropdown {
              display: flex;
              flex-direction: column;
              position: relative;

              img {
                position: absolute;
                right: 1.25rem;
                top: 1.6rem;
                pointer-events: none;
                transition: all 0.35s ease-out;
              }

              .dropdown-icon {
                &.rotate {
                  transform: rotate(180deg);
                }
              }

              .select2 {
                width: 100% !important;

                .selection {
                  .select2-selection {
                    display: block;
                    height: 100%;
                    border: none;
                    border-radius: 0.5rem;

                    .select2-selection__rendered {
                      background: $white;
                      border: none;
                      padding: 1.5rem 1rem;
                      border-radius: 0.5rem;
                      width: 100%;
                      height: auto;
                      color: $neutralGray400;
                      appearance: none;
                      position: relative;
                      display: flex;
                      align-items: center;
                      font-size: 1.125rem;
                      line-height: 1.4;
                      font-weight: 300;
                      border: 0.063rem solid $secondarySemiLight;
                    }

                    .select2-selection__arrow {
                      display: none;
                    }
                  }
                }
              }
            }

            .message {
              .message-label {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .body3 {
                  @media #{$vpMobile600} {
                    font-size: 0.75rem;
                  }
                }

                .total-count {
                  color: $neutralGray300;
                }

                .counter {
                  color: $baselineSecondary;
                }
              }

              textarea {
                width: 100%;
                height: 11.25rem;
                border: none;
                padding: 1.5rem;
                border-radius: 0.5rem;
                font-size: 1.25rem;
                line-height: 1.4;
                font-weight: 300;
                border: 0.063rem solid $secondarySemiLight;

                @media #{$vpMobile600} {
                  font-size: 1rem;
                  height: 10.625rem;
                  padding: 1rem;
                }

                &::placeholder {
                  font-size: 1.125rem;
                  line-height: 1.4;
                  font-weight: 300;

                  @media #{$vpMobile600} {
                    font-size: 1rem;
                  }
                }

                &.shortTextarea {
                  height: 4.5625rem !important;
                  overflow: hidden;
                }
              }
            }

            .submit-btn {
              display: flex;
              justify-content: flex-start;
              padding-top: 1.25rem;

              .main-secondary-btn.with-ic {
                span {
                  .icon-arrow {
                    width: 1rem;
                    height: 1rem;
/*rtl:raw:transform: rotate(180deg);*/
                    path {
                      fill: $baselineSecondary;
                    }
                  }
                }
              }

              @media #{$vpMobile600} {
                padding-top: 0;

                .main-secondary-btn.with-ic {
                  width: 100%;
                  padding: 0.875rem 1.5rem;
                  height: 3rem;

                  .btn-text {
                    font-size: 0.875rem;
                  }
                }
              }

              input {
                background: $white;
                border-radius: 12.5rem;
                border: 0.063rem solid $baselinePrimary;
                padding: 1.3125rem 2rem;
                transition: all 0.4s ease-out;
                font-weight: 300;
                line-height: 1.4;

                @media #{$vpMobile600} {
                  width: 100%;
                }

                &:hover {
                  background: $baselinePrimary;
                  transition: all 0.4s ease-out;
                  color: $white;
                }
              }
            }
          }

          .bottom-content {
            background-color: $baselineSecondaryLight;
            padding: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 0.75rem;
            margin-top: 2.5rem;

            .body3 {
              @media #{$vpMobile600} {
                width: 12rem;
              }
            }
          }
        }
      }
    }
  }
}