.sec-filter-drawer {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  top: 0;
  transform: translateX(100%);
  transition: all 0.5s ease-out;

  .filter-main {
    background-color: $baselineSecondaryLight;
    padding: 7.5rem 4rem;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 50.5rem;
    margin-left: auto;

    @media #{$vpMobile600} {
      padding: 4rem 1.25rem 2.5rem;
      width: 100%;
      gap: 2rem;
      overflow-y: scroll;
    }

    .close {
      position: relative;
      width: 3.625rem;
      min-height: 3.625rem;
      //padding: 1.25rem;
      border: 1px solid $baselinePrimary;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      opacity: 1;
      cursor: pointer;

      @media #{$vpMobile600} {
        width: 2.75rem;
        min-height: 2.75rem;
        margin-left: auto;
      }

      .close-btn {
        width: 1rem;
        height: 1rem;
      }
    }

    .heading-filter {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .heading4 {
        margin-bottom: 0;
        line-height: 1.3;

        @media #{$vpMobile600} {
          font-size: 1.5rem;
        }
      }

      .clear-btn {
        padding: 0.75rem 1.5rem;
        height: 2.25rem;
        outline: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media #{$vpMobile600} {
          padding: 0.5rem 1.125rem;
        }
      }
    }

    .tag-main {
      display: inline-block;

      .body1 {
        margin: 0 0 1.5rem 0px;

        @media #{$vpMobile600} {
          margin: 0 0 1rem 0px;
        }
      }

      .tag-inner {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        @media #{$vpMobile600} {
          gap: 0.5rem;
        }

        .tag-btn {
          input {
            appearance: none;
            display: none;
          }

          input[type=checkbox]:checked+label {
            background-color: $secondarySemiLight;
            padding-right: 2.75rem;

            @media #{$vpMobile600} {
              padding-right: 1.75rem;
            }

            &::after {
              opacity: 1;
            }
          }

          label {
            border-radius: 0.25rem;
            max-width: fit-content;
            padding: 1rem 2rem;
            line-height: normal;
            border: 0.063rem solid $secondarySimiLight;
            background-color: transparent;
            color: $baselineSecondary;
            transition: all 0.4s ease;
            height: 3.357rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 0;
            position: relative;
            font-weight: 300;

            @media #{$vpMobile600} {
              padding: 1rem;
              height: 3.25rem;

              .body3 {
                font-size: 0.875rem;
              }
            }

            &:after {
              content: '';
              width: 0.5rem;
              height: 0.5rem;
              display: block;
              border-radius: 3.125rem;
              background-color: $baselinePrimary;
              transition: all 0.4s ease;
              opacity: 0;
              position: absolute;
              right: 1.5rem;

              @media #{$vpMobile600} {
                right: 0.8rem;
              }
            }

          }
        }

      }
    }

    .sort-tag {
      .body1 {
        margin: 0 0 2rem;
      }
      .tag-inner {
        .tag-btn {
          position: relative;
          width: fit-content;
          padding-left: 2.5rem;
          margin: 0rem 0 .5rem .5rem;

          &:not(:last-of-type) {
            margin-bottom: 2rem;
          }

          input[type=radio] {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;

            &:checked {
              &~ .custom-radio-btn {
                background-color: $baselinePrimary;
              }
            }
          }

          .custom-radio-btn {
            position: absolute;
            top: .125rem;
            left: 0;
            height: 1rem;
            width: 1rem;
            border-radius: 50%;
            background-color: #d3d3d3;
          }

          .body3 {
            margin: 0;
          }
        }
      }
    }

    .filter-action {
      margin-top: auto;

      .filter-btn {
        padding: 1.3125rem 2rem;
        width: 100%;
        outline: none;
        max-width: 100%;

        @media #{$vpMobile600} {
          padding: 0.875rem 1.5rem;
          height: 3rem;
        }
      }
    }
  }
  &.dark-bg {
    .filter-main {
      background-color: rgba($color: #fff, $alpha: 0.1);
      .tag-main {
        .tag-inner {
          .tag-btn {
            label {
              color: rgba($color: #fff, $alpha: 0.4);
            }
            input[type=checkbox]:checked+label {
              background-color: rgba($color: #fff, $alpha: 0.1);
              color: $white;
            }
          }
        }
      }
    }
  }
  &.gray-bg {
    .filter-main {
      background-color: $white;
    }
  }
  &.no-bg {
    .filter-main {
      background-color: #f9f7f3;
    }
  }
}

.no-scroll {
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background-color: #00000080;
    position: absolute;
    z-index: 999;
    transition: all 0.5s ease-out;
  }

  .sec-filter-drawer {
    transform: translateX(0%);
    transition: all 0.5s ease-out;
  }
}

html[lang="ar"] {
  .sec-filter-drawer {
    .filter-main {
      .sort-tag {
        .tag-inner {
          .tag-btn {
            .custom-radio-btn {
              top: .5rem;
            }
          }
        }
      }
    }
  }
}