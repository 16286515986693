// Custom Variables //
$primaryFont: 'helvetica';
$secondaryFont: 'HelveticaNeue';


// Set Colors
$primaryColor: #aa9100;
$primaryDark: #665700;
$primaryLight: #443a00;
$baselineSecondary: #2c2e34;
$baselineSecondaryLight: #f9f7f3;
$baselinePrimary: #9f8332;
$secondaryColor: #212529;
$secondarySemiLight: #e5e0d0;
$secondaryLight: #f2efe7;
$neutralGray100: #f8f8f8;
$neutralGray200: #dddddd;
$neutralGray300: #a3a3a3;
$neutralGray400: #5b5b5b;
$neutralGray500: #e8ddda;
$tertiaryColor: #2B2100;
$text-color: #12272B;
$text-white: #FFFFFF;
$errorColor: #C20618;
$black: #000000;
$white: #ffffff;
$iPrimary: #100449;
$iGray: #8f8f8f;
$iBlack: #3c3f40;
$iYellow: #f5c200;
$iGreen: #007018;
$Red: #c20618;
$iBlue: #009fdc;
$iMagenta: #b9287e;
$lightGray: #E6E3DA;
$secondarySimiLight: #E5E0D0;
$darkGold: #2B2100;
$netureGray: #5B5B5B;

$font100: 6.25rem;
$font80: 5rem;
$font72: 4.5rem;
$font70: 4.375rem;
$font50: 3.125rem;
$font60: 3.75rem;
$font48: 3rem;
$font42: 2.625rem;
$font40: 2.5rem;
$font34: 2.125rem;
$font32: 2rem;
$font30: 1.875rem;
$font28: 1.75rem;
$font26: 1.625rem;
$font24: 1.5rem;
$font22: 1.375rem;
$font21: 1.313rem;
$font20: 1.25rem;
$font18: 1.125rem;
$font16: 1rem;
$font14: 0.875rem;
$font12: 0.75rem;

// Media Queries
$vpDesktop1920: '(max-width:1920px)';
$vpDesktop1680: '(max-width:1680px)';
$vpDesktop1600: '(max-width:1600px)';
$vpDesktop1440: '(max-width:1440px)';
$vpTablet1280: '(max-width:1280px)';
$vpTablet1229: '(max-width:1229px)';
$vpTablet1024: '(max-width:1024px)';
$vpTablet991: '(max-width:991px)';
$vpTablet767: '(max-width:767px)';
$vphMobile650: '(max-height:650px)';
$vpMobile600: '(max-width:600px)';
$vpMobile480: '(max-width:480px)';
$vpMobile390: '(max-width:390px)';
$vpMobile359: '(max-width:359px)';