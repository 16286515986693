.feedback-suggestions {
  .error {
    color: red;
    display: none;
  }

  .error-border {
    border: 0.063rem solid red !important;
    border-radius: 0.5rem !important;
  }
  .wrapper {
    display: flex;
    align-items: flex-start;
    padding: 5rem;
    background: $baselineSecondaryLight;
    justify-content: space-between;
    border-radius: 2rem;
    flex-wrap: wrap;
    @media #{$vpTablet991} {
      padding: 3rem;
    }
    @media #{$vpMobile600} {
      padding: 2.5rem 1.25rem;
      flex-direction: column;
      gap: 2.5rem;
      border-radius: 1rem;
    }
  }

  .content {
    max-width: 27.4375rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    @media #{$vpTablet991} {
      padding-bottom: 2rem;
      max-width: 100%;
      width: 100%;
    }
    @media #{$vpMobile600} {
      width: 100%;
      gap: 1rem;
      padding-bottom: 0;
    }

    .underline-title {
      width: fit-content;
    }
  }

  .feedback-form {
    max-width: 55.75rem;
    width: 100%;
    @media #{$vpMobile600} {
      width: 100%;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      .top,
      .middle {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media #{$vpMobile600} {
          flex-direction: column;
          gap: 2.5rem;
        }

        .input-field {
          width: 29.1875rem;

          @media #{$vpMobile600} {
            width: 100%;
          }

          .error{
            color: red;
          }

          input {
            background: $white;
            border: none;
            padding: 1.5rem 1rem;
            border-radius: 0.5rem;
            width: 29.1875rem;
            height: 4.5625rem;
            font-size: 1.125rem;
            line-height: 1.4;
            font-weight: 300;
            border: 0.063rem solid $secondarySimiLight;

            &::placeholder {
              font-size: 1.125rem;
              line-height: 1.4;
              font-weight: 300;
            }

            @media #{$vpMobile600} {
              width: 100%;
            }
          }
        }

        .radio-wrapper {
          width: 24.0625rem;
          gap: 1rem;
          display: flex;
          flex-direction: column;

          @media #{$vpMobile600} {
            width: 100%;
          }

          .radio-options {
            display: flex;
            gap: 1.5rem;

            .option-wrapper {
              display: flex;
              gap: 1rem;
              align-items: center;

              input {
                width: 2rem;
                height: 2rem;
                outline: none;
                border: none;
                background-color: $white;
                appearance: none;
                position: relative;
                border-radius: 100%;

                &:checked::before {
                  content: "";
                  background-color: $baselinePrimary;
                  display: block;
                  width: 1rem;
                  height: 1rem;
                  border-radius: 100%;
                  position: absolute;
                  top: 0.4rem;
                  left: 0.4rem;
                }
              }
            }
          }
        }
      }

      .bottom {
        display: flex;
        flex-direction: column;

        .message-label {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .total-count {
            color: $neutralGray300;
          }

          .counter {
            color: $baselineSecondary;
          }
        }

        textarea {
          width: 100%;
          height: 11.25rem;
          border: none;
          padding: 1.5rem;
          border-radius: 0.5rem;
          font-size: 1.125rem;
          line-height: 1.4;
          font-weight: 300;
          border: 0.063rem solid $secondarySimiLight;
          &::placeholder {
            font-size: 1.125rem;
            line-height: 1.4;
            font-weight: 300;
          }
        }
      }

      .submit-btn {
        display: flex;
        justify-content: flex-end;

        input {
          background: transparent;
          border-radius: 12.5rem;
          border: 0.063rem solid $baselinePrimary;
          padding: 1.31rem 2rem;
          transition: all 0.4s ease-out;
          font-weight: 300;
          line-height: 1.4;

          @media #{$vpMobile600} {
            width: 100%;
            max-width: 100%;
          }

          &:hover {
            background: $baselinePrimary;
            transition: all 0.4s ease-out;
            color: $white;
          }
        }
      }
    }
  }
}
