.sec-faq {
  background-color: $baselineSecondaryLight;

  .wrapper-box {
    .underline-title {
      color: $baselinePrimary;
      margin-bottom: 2rem;

      @media #{$vpMobile600} {
        margin-bottom: 1rem;
      }
    }
  }

  .heading-box {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;

    .wrapper-heading {
      width: 88.5rem;

      .heading2 {
        width: 100%;
        margin: 0;
      }

      @media #{$vpMobile600} {
        width: 100%;
      }
    }

    .btn-wraper {
      @media #{$vpTablet991} {
        margin-top: 2rem;
      }
      &.desk-btn {
        @media #{$vpMobile600} {
          display: none;
        }
      }

      .main-primary-btn {
        padding: 1.313rem 1.875rem;

        @media #{$vpMobile600} {
          padding: 0.75rem;
        }
      }
    }
  }

  .accordion-box {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    .accordion {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 1.5rem;
      margin-top: 5rem;

      @media #{$vpMobile600} {
        gap: 0;
        margin-top: 2.5rem;
        flex-direction: column;
      }
        @media #{$vpMobile600} {
          width: 100%;
        }
        .card {
          padding: 2.5rem;
          border-radius: 1rem;
          background-color: $white;
          width: 100%;
          border: 0;
         
          justify-content: center;

          @media #{$vpMobile600} {
            margin-bottom: 1rem;
            padding: 1.25rem;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .card-header {
            background-color: transparent;
            border-bottom: 0;
            padding: 0;

            a {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .heading4 {
                color: $baselineSecondary;
                width: calc(100% - 5rem);
                margin-bottom: 0;

                @media #{$vpMobile600} {
                  line-height: 1.938rem;
                }
              }

              .icon {
                width: 2.875rem;
                height: 2.875rem;
                border: 0.063rem solid $baselinePrimary;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .plus-icon,
                .minus-icon {
                  width: 16px;
                  height: 16px;
                }
              }

              &[aria-expanded="true"] .plus-icon {
                display: none;
              }

              &[aria-expanded="true"] .plus-icon {
                display: none;
              }

              &[aria-expanded="false"] .minus-icon {
                display: none;
              }

              &[aria-expanded="false"] .plus-icon {
                display: block;
              }
            }
          }

          .card-body {
            padding: 0;
            margin-top: 2.188rem;

            @media #{$vpMobile600} {
              margin-top: 1rem;
            }

            p {
              @media #{$vpMobile600} {
                font-size: 0.875rem;
                line-height: 1.225rem;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
    }
    &.two--col {
      .accordion {
        flex-wrap: wrap;
        .card {
          width: 51rem;
          @media #{$vpTablet991} {
            width: 32rem;
          }
          @media #{$vpMobile600} {
            width: 100%;
          }
        }
      }
    }

    .bottom-row {
      display: flex;
      align-items: center;
      border-top: 0.063rem solid $baselinePrimary;
      justify-content: space-between;
      padding: 2.5rem 0;

      .heading4 {
        @media #{$vpMobile600} {
          line-height: 1.95rem;
          font-size: 1.5rem;
        }
      }

      @media #{$vpMobile600} {
        flex-direction: column;
        gap: 1rem;
      }

      .main-primary-btn {
        @media #{$vpMobile600} {
          width: 100%;
          max-width: 100%;
          display: block;
          text-align: center;
          padding: .875rem 1.5rem;
          margin-top: 0.313rem;
        }
      }
    }

    .btn-wraper {
      display: none;

      &.mobl-btn {
        @media #{$vpMobile600} {
          display: block;
          width: 100%;
          margin-top: 0.5rem;

          .main-primary-btn {
            width: 100%;
            max-width: 100%;
            display: block;
            padding: .875rem 1.5rem;
            text-align: center;
          }
        }
      }
    }
  }
  &.form--faq {
    background-color: transparent;
    width: 51.875rem;
    @media #{$vpMobile600} {
      width: 100%;
    }
    .heading-box {
      .wrapper-heading {
        .heading2 {
          margin-bottom: 2rem;
        }
      }
      .btn-wraper {
        a {
          display: block;
        }
      }
    }
    .accordion-box {
      .accordion {
        display: block;
        margin-top: 0;
        
          .card {
            background-color: #f9f7f3;
            &:last-child {
              @media #{$vpMobile600} {
                margin-bottom: 1rem;
              }
            }
          }
      }
    }
  }
  &.dark-bg {
    .accordion-box {
      .accordion {
        .card {
          background-color: rgba($color: #fff, $alpha: 0.1);
          .card-header {
            a {
              .heading4 {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
  &.no-bg {
    .accordion-box {
      .accordion {
        .card {
          background-color: #f9f7f3;
        }
      }
      .bottom-row {
        .main-primary-btn {
          border: .063rem solid #9f8332;
        }
      }
    }
    .heading-box {
      .btn-wraper {
        .main-primary-btn {
          border: .063rem solid #9f8332;
        }
      }
    }
  }
}

.darkTheme1 {
  .sec-faq {
    &.darkSec {
      background-color: transparent;

      .body2 {
        padding-bottom: 1rem;
      }

      .heading-box {
        padding-top: 1rem;
      }

      .accordion-box {

       
          .card {
            background-color: #1d1e20;

            .card-header {
              a {
                .heading4 {
                  color: $white;
                }

                .icon {
                  border: 0.063rem solid $white;

                  svg {
                    path {
                      fill: $white;
                    }
                  }
                }
              }
            }
          }

        .bottom-row {
          border-top: 0.063rem solid $white;
        }
      }
    }
  }
}

.darkSec1 {
  &.sec-faq {
    background-color: transparent;

    .body2 {
      padding-bottom: 1rem;
    }

    .heading-box {
      padding-top: 1rem;
    }

    .accordion-box {

      
        .card {
          background-color: #1d1e20;

          .card-header {
            a {
              .heading4 {
                color: $white;
              }

              .icon {
                border: 0.063rem solid $white;

                svg {
                  path {
                    fill: $white;
                  }
                }
              }
            }
          }
        }

      .bottom-row {
        border-top: 0.063rem solid $white;
      }
    }
  }
}

body{
  &.darkTheme{
    .sec-faq{
      background-color: transparent;
      .heading-box{
        .wrapper-heading{
          padding-top: 2rem;
        }
        .btn-wraper{
          .main-primary-btn{
            color: $text-white;
            border-color: $baselinePrimary;
            background-color: transparent;
            &:hover{
              background-color: $secondaryLight;
              color: $baselineSecondary;
              transition: all .4s ease-out;
            }
          }
        }
      }
      .accordion-box{
        .accordion{
          .card{
            background-color: #1D1E20;
            border: 0.063rem solid $text-white;
            .card-header{
              a{
                .heading4{
                  color: $text-white;
                }
                .icon{
                  border-color: $text-white;
                  svg{
                    path{
                      fill: $text-white;
                    }
                  }
                }
              }
            }
          }
        }
        .bottom-row{
          .main-primary-btn{
            color: $text-white;
            border-color: $baselinePrimary;
            background-color: transparent;
            &:hover{
              background-color: $secondaryLight;
              color: $baselineSecondary;
              transition: all .4s ease-out;
            }
          }
        }
      }
    }
  }
}