.sec-interactive-width {
  .comp-wrapper {
    overflow: hidden;

    .content-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 5rem;

      @media #{$vpMobile600} {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 1.5rem;
      }

      .heading2 {
        max-width: 40%;
        flex: 0 0 40%;
        margin: 0;

        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      .body1 {
        max-width: 60%;
        flex: 0 0 60%;

        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
          font-size: 1.25rem;
          line-height: 1.75rem;
          margin-top: 1rem;
        }
      }
    }

    .img-gridBox {
      display: flex;
      justify-content: space-between;
      padding-top: 5rem;

      @media #{$vpMobile600} {
        flex-direction: column;
        padding-top: 1.5rem;
      }

      .img-grid {
        max-width: 50%;
        flex: 0 0 50%;
        position: relative;
        border-right: 0.125rem solid $text-white;
        transition: all 0.8s ease;

        @media #{$vpMobile600} {
          max-width: 100% !important;
          flex: 0 0 100% !important;
          border-right: 0;
          border-bottom: 0.125rem solid $text-white;
        }

        .img-wrapper {
          height: 40rem;
          transition: all 0.8s ease;
          @media #{$vpTablet991} {
            height: 30rem;
          }
          @media #{$vpMobile600} {
            height: 15rem;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .icon-box {
            display: none;
            width: auto;
            height: auto;
            position: absolute;
            top: 1.25rem;
            right: 1.25rem;
            z-index: 1;
            @media #{$vpTablet991} {
             display: block;
            }
            img {
              width: 100% !important;
              height: 100% !important;
            }
          }
        }

        .content-wrapper {
          position: absolute;
          bottom: 0;
          padding: 0 0 4.125rem 4rem;
          max-width: 36rem;
          color: $text-white;
          z-index: 1;
          transform: translateY(1.875rem);
          transition: all 0.8s ease;

          @media #{$vpTablet991} {
            padding: 0 1.25rem 1.25rem 1.25rem;
          }

          .heading3 {
            padding-bottom: 2.5rem;
            margin: 0;

            @media #{$vpMobile600} {
              padding-bottom: 1.5rem;
            }
          }

          .inner-content-wrapper {
            opacity: 0;
            height: 0;
            width: 0;

            .btn-box {
              button, 
              .main-secondary-btn {
                padding: 0.75rem 1.5rem;
                height: 2.875rem;

                @media #{$vpMobile600} {
                  padding: 0.5rem 1.125rem;
                  height: 2.25rem;
                }

                .btn-text {
                  font-size: 0.875rem;
                }

                img {
                  width: 0.875rem;
                  height: 0.875rem;
                }
              }
            }

            .body3 {
              padding-bottom: 2.5rem;
              margin: 0;

              @media #{$vpMobile600} {
                padding-bottom: 1.5rem;
                font-size: 0.875rem;
                line-height: 1.225rem;
              }
            }
            .btn-box {
              .main-secondary-btn {
                @media #{$vpMobile600} {
                  width: max-content;
                }
              }
            }
          }
        }

        &:first-child {
          img {
            border-radius: 2rem 0 0 2rem;

            @media #{$vpMobile600} {
              border-radius: 1rem 1rem 0 0;
            }
          }

          &:hover {
            &::after {
              border-radius: 2rem 0 0 2rem;

              @media #{$vpMobile600} {
                border-radius: 1rem 1rem 0 0;
              }
            }
          }
        }

        &:last-child {
          border-right: 0;

          @media #{$vpMobile600} {
            border-bottom: 0;
          }

          img {
            border-radius: 0 2rem 2rem 0;

            @media #{$vpMobile600} {
              border-radius: 0 0 1rem 1rem;
            }
          }

          &:hover {
            &::after {
              border-radius: 0 2rem 2rem 0;

              @media #{$vpMobile600} {
                border-radius: 0 0 1rem 1rem;
              }
            }
          }
        }

        &:hover {
          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(0deg,
                rgba(0, 0, 0, 0.6) 0%,
                rgba(0, 0, 0, 0.6) 100%),
              linear-gradient(180deg,
                rgba(0, 0, 0, 0) 61.72%,
                rgba(0, 0, 0, 0.6) 100%);
            width: 100%;
            height: 100%;
          }

          .content-wrapper {
            transform: translateY(0);

            .inner-content-wrapper {
              height: 100%;
              width: 100%;
              opacity: 1;
              transition: opacity 1.5s ease-in-out;

              @media #{$vpMobile600} {
                width: 100%;
              }

              .main-secondary-btn {
                span {
                  color: $white;
                }

                img {
                  filter: invert(1);
                }

                &:hover {
                  span {
                    color: $baselineSecondary;
                  }

                  img {
                    filter: invert(0);
                  }
                }
              }
            }
          }
        }

        &.expand {
          max-width: 58.5%;
          flex: 0 0 58.5%;

          .img-wrapper {
            @media #{$vpMobile600} {
              height: 21.875rem;
            }

            .icon-box {
              @media #{$vpTablet991} {
                display: block;
              }
              .insvg {
                path {
                  &.st1 {
                    display: none;
                  }
                }
              }
            }
          }

          @media #{$vpMobile600} {
            max-width: 100%;
            flex: 0 0 100%;
          }
        }

        &.contract {
          max-width: 41.5%;
          flex: 0 0 41.5%;

          @media #{$vpMobile600} {
            max-width: 100%;
            flex: 0 0 100%;
          }
          .img-wrapper {
            .icon-box {
              @media #{$vpTablet991} {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  &.partner-sec {
    .comp-wrapper {
      .img-gridBox {
        .img-grid {
          max-width: 34rem;
          flex: 0 0 34rem;

          &.expand {
            max-width: 46rem;
            flex: 0 0 46rem;

            .img-wrapper {
              @media #{$vpMobile600} {
                height: 21.875rem;
              }

              .icon-box {
                @media #{$vpTablet991} {
                  display: block;
                }
              }
            }

            @media #{$vpMobile600} {
              max-width: 100%;
              flex: 0 0 100%;
            }
          }

          &.contract {
            max-width: 26rem;
            flex: 0 0 26rem;

            @media #{$vpMobile600} {
              max-width: 100%;
              flex: 0 0 100%;
            }
            .img-wrapper {
              .icon-box {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  &.three-col-drawer {
    // background-color: $baselineSecondaryLight;

    .comp-wrapper {
      .img-gridBox {
        padding-top: 0;
        overflow: hidden;
        border-radius: 2rem;

        .img-grid {
          max-width: 33.33%;
          flex: 0 0 33.33%;
          border-right: 0.125rem solid $white;
          .content-wrapper{
            @media #{$vpTablet991} {
              max-width: 23rem;
              bottom: 1.438rem
              
            }
          }

          &.expand {
            max-width: 50rem;
            flex: 0 0 50rem;
            @media #{$vpTablet991} {
              max-width: 45%;
              flex: 0 0 45%;
            }
          }

          &.contract {
            max-width: 27.5rem;
            flex: 0 0 27.5rem;
            @media #{$vpTablet991} {
              max-width: 20.5rem;
              flex: 0 0 20.5rem;
            }
          }
          @media #{$vpMobile600} {
            border-right: 0;
          }
        }
      }
    }
  }

  // &.initiativeDetails {
  //   background-color: #fff;
  // }
}

.darkTheme {
  .sec-interactive-width {
    &.darkSec {
      background-color: transparent;

      .comp-wrapper {
        .img-gridBox {
          .img-grid {
            max-width: 33.33%;
            flex: 0 0 33.33%;
            border-right: 0.125rem solid #1d1e20;

            @media #{$vpMobile600} {
              border-bottom: 0.125rem solid #1d1e20;
            }

            &.expand {
              max-width: 50rem;
              flex: 0 0 50rem;

              .img-wrapper {
                .icon-box {
                  &.plus {
                    @media #{$vpTablet991} {
                      display: none;
                    }
                  }

                  &.minus {
                    @media #{$vpTablet991} {
                      display: block;
                    }
                  }
                }
              }
            }

            &.contract {
              max-width: 27.5rem;
              flex: 0 0 27.5rem;
            }

            .content-wrapper {
              .inner-content-wrapper {
                .btn-box {
                  button {
                    span {
                      color: $baselineSecondary;
                      padding: 0;
                    }

                    &:hover {
                      span {
                        color: $white;
                      }
                    }
                  }
                }
              }
            }

            .img-wrapper {
              .icon-box {
                &.plus {
                  @media #{$vpTablet991} {
                    display: block;
                  }
                }

                &.minus {
                  @media #{$vpTablet991} {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.sec--wrapper {
  &.sec-interactive-width {
    background-color: transparent;

    .comp-wrapper {
      .img-gridBox {
        .img-grid {
          max-width: 33.33%;
          flex: 0 0 33.33%;
          border-right: 0.125rem solid #1d1e20;

          @media #{$vpMobile600} {
            border-bottom: 0.125rem solid #1d1e20;
          }

          &.expand {
            max-width: 50rem;
            flex: 0 0 50rem;

            .img-wrapper {
              .icon-box {
                &.plus {
                  @media #{$vpTablet991} {
                    display: none;
                  }
                }

                &.minus {
                  @media #{$vpTablet991} {
                    display: block;
                  }
                }
              }
            }
          }

          &.contract {
            max-width: 27.5rem;
            flex: 0 0 27.5rem;
          }

          .content-wrapper {
            .inner-content-wrapper {
              .btn-box {
                button {
                  span {
                    color: $baselineSecondary;
                    padding: 0;
                  }

                  &:hover {
                    span {
                      color: $white;
                    }
                  }
                }
              }
            }
          }

          .img-wrapper {
            .icon-box {
              &.plus {
                @media #{$vpTablet991} {
                  display: block;
                }
              }

              &.minus {
                @media #{$vpTablet991} {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}


body{
  &.darkTheme{
    .sec-interactive-width{
      &.three-col-drawer{
        .comp-wrapper{
          .content-box{
            .img-gridBox{
              .img-grid{
                border-right: 0.125rem solid #1D1E20;
              }
            }
          }
        }
      }
    }
  }
}