.quote {
  padding: 22.5rem 0;
  position: relative;
  @media #{$vpMobile600} {
    padding: 5rem 0 0;
  }

  .wrapper {
    .quote-content {
      .inner-content {
        max-width: 65%;
        flex: 0 0 65%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        color: $text-white;
        position: relative;

        .heading3 {
          padding-bottom: 3rem;
          position: relative;
          margin: 0;

          span {
            &:first-child {
              padding-right: 2.5rem;
              /*rtl:raw:padding-right: 0rem !important; */
              /*rtl:raw:padding-left: 0rem !important; */

              @media #{$vpMobile600} {
                padding-right: 0.625rem;
              }

              img {
                margin-bottom: 1.25rem;
                width: 2rem;
                height: 2rem;
                /*rtl:raw:transform: scaleX(-1); */

                @media #{$vpDesktop1440} {
                  margin-bottom: 0;
                }

                @media #{$vpMobile600} {
                  width: 2.188rem;
                  height: 2.188rem;
                }
              }
            }

            &:last-child {
              padding-left: 2.5rem;
              /*rtl:raw:padding-right: 0rem !important; */
              /*rtl:raw:padding-left: 0rem !important; */

              @media #{$vpMobile600} {
                padding-left: 0;
              }

              img {
                position: absolute;
                bottom: 3.4rem;
                width: 2rem;
                height: 2rem;
                /*rtl:raw:transform: scaleX(-1); */

                @media #{$vpMobile600} {
                  bottom: 2.4rem;
                  left: 13rem;
                  width: 2.188rem;
                  height: 2.188rem;
                }
              }
            }
          }
        }

        span {
          font-weight: 300;
          font-size: 1rem;
        }

        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }

  .quote-image-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;

    @media #{$vpMobile600} {
      position: relative;
      padding-top: 2rem;
      margin: 0 auto;
      text-align: right;
    }

    img {
      height: 60.5rem;
      @media #{$vpMobile600} {
        height: 100%;
        width: 65%;
      }
    }
  }
}
