.pageNotfound-sec {
  .wrapper {
    position: relative;

    .img-wrapper {
      height: 100vh;

      img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
      }

      .video-wrapper {
        height: 100vh;

        video {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .content {
      position: absolute;
      bottom: 0;


      .title-wrap {
        .heading1 {
          font-size: 25rem;
          line-height: 27.5rem;
          color: $text-white;
          text-align: center;
          margin: 0;

          @media #{$vpMobile600} {
            font-size: 10.25rem;
            line-height: 13.313rem;
          }
        }

        .inner-wrapper {
          margin: 3.188rem 7.5rem 0 7.5rem;
          padding: 5rem 5rem 5.625rem 5rem;
          border-radius: 2rem 2rem 0 0;
          display: flex;
          background-color: $white;
          justify-content: space-between;

          .title {
            max-width: 52%;
            flex: 0 0 52%;

            .heading2 {
              margin: 0;
            }

            @media #{$vpMobile600} {
              max-width: 100%;
              flex: 0 0 100%;
            }
          }

          .para-sec {
            max-width: 37%;
            flex: 0 0 37%;

            .body3 {
              max-width: 70%;
              margin: 0;

              @media #{$vpMobile600} {
                max-width: 100%;

              }

            }

            .btn-wrapper {
              display: flex;
              margin-top: 2.5rem;

              .sty-1 {
                margin-right: 1.25rem;

                @media #{$vpMobile600} {
                  margin-right: 0;
                  margin-bottom: 1rem;
                }
              }

              @media #{$vpMobile600} {
                flex-direction: column;
              }
            }

            @media #{$vpMobile600} {
              max-width: 100%;
              flex: 0 0 100%;
              padding-top: 1.5rem;
            }
          }

          @media #{$vpMobile600} {
            flex-direction: column;
            margin: 4.25rem 1.25rem 0 1.25rem;
            padding: 1.25rem 1.25rem 2.5rem 1.25rem;
          }
        }
      }
    }
  }

  &.varient2 {
    .wrapper {
      .content-wrapper {
        padding-top: 4.5rem;
        text-align: center;
        @media #{$vpMobile600} {
          padding-top: 0;
          margin-bottom: 0.5rem;
          .sec-padding--top {
            padding-top: 2.5rem;
        }
        }
        .title-wrap {
          .heading1 {
            color: $baselinePrimary;

          }

          .inner-wrapper {
            max-width: 50%;
            margin: 0 auto;
            padding-top: 2rem;

            .title {
              @media #{$vpMobile600} {
                padding: 0 1.25rem;
              }
            }

            .btn-wrapper {
              padding-top: 3.5rem;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              .main-secondary-btn {
                margin-top: 1rem;
                margin-right: 0.5rem;
                margin-left: 0.5rem;
                padding: 0.7rem 1.5rem;
                @media #{$vpMobile600} {

                  padding: 0.5rem 1.125rem;
                  max-width: max-content;
                }
              }

              @media #{$vpMobile600} {

                padding-top: 2.438rem;
              }


            }

            @media #{$vpMobile600} {
              max-width: 100%;
            }
          }
        }
      }

      .sec-discovering-slider {
        padding-top: 4.5rem;

        @media #{$vpMobile600} {
          padding-top: 0rem;
        }
      }
    }
  }
  &.dark-bg {
    .wrapper {
      .content {
        .title-wrap {
          .inner-wrapper {
            background-color: #1D1E20;
          }
        }
}
    }}

// thankyou page css

.thankyou--page {
  .wrapper {
    align-items: center;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    display: flex;
    height: 60vh;
    position: relative;
    width: 100%;
    z-index: 1;
    .hero-content {
      position: absolute;
      z-index: 2;
      bottom: 4.063rem;
    }
    img {
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
    }
  }
  .content {
    .content-wrap {
      padding-top: 4rem;
      .heading2 {
        margin-bottom: 2rem;
      }
      p {
        margin-bottom: 3rem;
      }
    }
  }
}}