.sec-header-search-drawer {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999;
  top: 8rem;
  transform: translateY(-160%);
  transition: all 0.5s ease-out;
  opacity: 0;

  @media #{$vpMobile600} {
    top: 0;
  }


  &.sec-search-results {
    opacity: 1;
    height: 100%;
    position: unset;
    transform: translateY(0);

    .filter-main {
      background-color: $white;
      padding-bottom: 12.5rem;

      @media #{$vpMobile600} {
        padding-bottom: 5rem;
      }

      .tag-main {
        .search-tabs-sticky {
          transition: all .5s ease;
          background-color: $white;

          &.sticky {
            position: sticky;
            top: 8.375rem;
            padding: 1rem 0;
            z-index: 9;

            @media #{$vpMobile600} {
              top: 3.9rem;
            }
          }

          .body1 {
            margin-bottom: 2.5rem;
            color: $black;

            @media #{$vpMobile600} {
              margin-bottom: 2rem;
            }
          }
        }

        .button-list {
          display: flex;
          gap: 0.75rem;
          align-items: center;

          &.result-nav {
            @media #{$vpMobile600} {
              flex-direction: row;
              overflow: scroll;
              flex-wrap: nowrap;
              margin-right: -1.25rem;
              padding-top: 0rem;
              padding-bottom: 0.5rem;
            }

            .title-text {
              padding-left: 0;
              &::before {
                display: none;
              }
              .body3 {
                color: $baselineSecondary;
                border-radius: 0.25rem;
                max-width: fit-content;
                padding: 1rem 1.5rem;
                line-height: 1.4;
                border: 0.063rem solid $secondarySimiLight;
                background-color: transparent;
                color: $baselineSecondary;
                transition: all 0.4s ease;
                height: 2.75rem;
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-bottom: 0;
                position: relative;
                @media #{$vpMobile600} {
                  padding: 1rem;
                }
                &.active {
                  background-color: $secondarySemiLight;

                  &::after {
                    opacity: 1;
                    margin-left: 0.75rem;
                    width: 0.5rem;
                    height: 0.5rem;
                  }
                }

                &::after {
                  content: '';
                  width: 0rem;
                  height: 0rem;
                  display: block;
                  border-radius: 3.125rem;
                  background-color: $baselinePrimary;
                  transition: all 0.4s ease;
                  opacity: 0;

                  @media #{$vpMobile600} {
                    //right: 0.8rem;
                  }
                }
              }

              @media #{$vpMobile600} {
                .body3 {
                  font-size: 0.875rem;
                  width: max-content;
                }
              }
            }
          }

        }

        .grid-listing-main {
          margin-top: 5rem;
          margin-bottom: 5rem;

          @media #{$vpMobile600} {
            margin-top: 2rem;
            margin-bottom: 2rem;
          }


          .grid-item {
            margin: 4rem 0 4rem;

            @media #{$vpMobile600} {
              margin: 0rem 0 3rem;
            }

            .heading3 {
              margin-bottom: 0;
              color: $baselineSecondary;
            }

            .item-wrapper {
              display: grid;
              flex-wrap: wrap;
              gap: 1.25rem;
              grid-template-columns: repeat(4, 1fr);
              margin: 2rem 0;
              @media #{$vpTablet991} {
                grid-template-columns: repeat(3, 1fr);
              }
              @media #{$vpMobile600} {
                grid-template-columns: 1fr;
                gap: 2rem;
              }

              .search-result-grid {
                border-radius: 1rem;
                overflow: hidden;
                height: 100%;

                @media #{$vpMobile600} {
                  border-radius: 0.5rem;
                }

                &:last-child {
                  margin-right: 0;
                }

                .post-img {
                  width: 100%;
                  //position: relative;
                  overflow: hidden;
                  transform: translateZ(0);

                  img {
                    width: 100%;
                    height: 12.688rem;
                    object-fit: cover;
                    border-radius: 1rem 1rem 0 0;

                    @media #{$vpMobile600} {
                      height: 10.9375rem;
                    }
                  }
                }

                .content-box {
                  background: linear-gradient(0deg, #f9f7f3, #f9f7f3),
                    linear-gradient(0deg, #ffffff, #ffffff);
                  padding: 1.5rem;
                  height: 17.3125rem;

                  @media #{$vpMobile600} {
                    height: auto;
                    padding: 1.56rem;
                  }

                  .content-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                    color: $baselineSecondary;

                    @media #{$vpMobile600} {
                      gap: 1.5rem;
                    }

                    .heading5 {
                      margin-bottom: 1rem;
                      color: $baselineSecondary;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      line-height: 1.4;

                      @media #{$vpMobile600} {
                        font-size: 1.25rem;
                      }
                    }

                    .body3 {
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 3;
                      -webkit-box-orient: vertical;
                      min-height: 4.188rem;
                      margin: 0;

                      @media #{$vpMobile600} {
                        font-size: 0.875rem;
                        margin: 0;
                        min-height: 3.75rem;
                      }
                    }

                    .main-primary-btn {
                      height: 2.875rem;
                      padding: .75rem 1.6rem;

                      @media #{$vpMobile600} {
                        padding: .5rem 1.12rem;
                        height: 2.250rem;
                        line-height: 1.4;
                      }
                    }
                  }
                }
              }
            }

            .main-secondary-btn {
              &.with-ic {
                margin: 0 auto;
                width: 100%;
                height: 2.75rem;
                @media #{$vpMobile600} {
                  .btn-text {
                    font-size: 0.875rem;
                  }

                  img {
                    width: 1rem;
                    height: 1rem;
                  }
                }
              }
            }
          }
        }

        .tag-main.categories-tag {
          margin-top: 0;

          .body1 {
            margin-bottom: 2rem;
            font-size: 1.75rem;
            line-height: 1.3;
            color: #222223;

            @media #{$vpMobile600} {
              font-size: 1.25rem;
            }
          }
        }
      }
    }

    &.no-results-search {
      .search-notFound {
        .searchResults {
          .heading5 {
            margin-bottom: 2.5rem;

            @media #{$vpMobile600} {
              margin-top: 1rem;
              margin-bottom: 1.5rem;
            }
          }
        }

        .no-results {
          margin-bottom: 5rem;

          @media #{$vpMobile600} {
            margin-top: 1rem;
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }

  &.no-results-search {
    .filter-main {
      padding-bottom: 18.125rem;
      gap: 5rem;

      @media #{$vpMobile600} {
        padding-bottom: 11.125rem;
      }

      .tag-main {
        @media #{$vpMobile600} {
          //margin-top: 2rem;
        }

        .search-notFound {
          .no-results {
            @media #{$vpMobile600} {
              margin: 0 0 2rem 0;
            }

            .heading5 {
              margin-bottom: 1.5rem;
              color: #222223;
              line-height: 1.3;

              @media #{$vpMobile600} {
                font-size: 1rem;
                line-height: 1.4;
                margin-bottom: 2rem;
              }
            }

            ul {
              li.body2 {
                color: #222223;
                padding-left: 0;
                &::before {
                  display: none;
                }
                span {
                  color: #6F7274;

                  &::after {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .search-mobile-header {
    position: relative;
    display: none;

    @media #{$vpMobile600} {
      display: block;
    }

    .header {
      .container--header {
        .header-wrapper {
          a {
            .logo {
              opacity: 1;
            }
          }
        }
      }
    }

    .filter-close {
      position: absolute;
      top: 50%;
      left: auto;
      right: 1.25rem;
      transform: translateY(-50%);
      z-index: 99;

      .close-btn {
        color: $baselineSecondary;
      }
    }
  }

  .filter-main {
    background-color: $baselineSecondaryLight;
    padding: 7.5rem;
    min-height: 30.875rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 100%;
    margin-left: auto;

    @media #{$vpMobile600} {
      padding: 5rem 1.25rem;
      width: 100%;
      gap: 2rem;
      height: 100%;
    }

    .search-mobile-header {
      display: none;

      @media #{$vpMobile600} {
        display: block;
      }
    }

    .search-input-wrapper {
      .search-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: .063rem solid $secondarySemiLight;
        padding-bottom: 1rem;

        .input-field {
          background: $white;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          border-bottom: 0;
          background-color: transparent;
          padding: 0 4rem 0 0;
          border-radius: 0;
          width: 100%;
          height: auto;
          color: $baselineSecondary;
           /*rtl:raw: line-height: 2; */

          @media #{$vpMobile600} {
            padding: 0 3rem 0 0;
          }
        }

        .btn-search {
          border: 0;
          background-color: transparent;
          outline: 0;

          @media #{$vpMobile600} {
            right: 0;
            padding: 0;
          }

          .search-btn {
            .searchIcon {
              width: 2.5rem;
              height: 2.5rem;

              path {
                fill: $baselineSecondary;
              }

              @media #{$vpMobile600} {
                width: 2rem;
                height: 2rem;
              }
            }
          }

          .cancel-btn {
            .cancleIcon {
              height: 3rem;
              width: 3rem;

              path {
                fill: $baselineSecondary;
              }

              @media #{$vpMobile600} {
                width: 2.5rem;
                height: 2.5rem;
              }
            }
          }

          &.active {
            .search-icon {
              display: none;
            }
          }

          .search-icon,
          .cancel-icon {
            width: 2.5rem;
            height: 2.5rem;

            path {
              width: 2.5rem;
              height: 2.5rem;

              @media #{$vpMobile600} {
                width: 2rem;
                height: 2rem;
              }
            }

            @media #{$vpMobile600} {
              width: 2rem;
              height: 2rem;
            }

            path {
              fill: $baselineSecondary;

            }
          }

          .cancel-icon {
            display: none;
          }
        }

      }

      .body2 {
        margin-top: 1rem;
        margin-bottom: 0;
        color: #222223;

        @media #{$vpMobile600} {
          display: none;
        }
      }
    }

    .close {
      position: unset;
      width: 3.625rem;
      height: 3.625rem;
      border: 1px solid $baselinePrimary;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      opacity: 1;
      cursor: pointer;

      @media #{$vpMobile600} {
        width: 2.75rem;
        height: 2.75rem;
        margin-left: auto;
      }

      .close-btn {
        width: 1rem;
        height: 1rem;
      }
    }

    .search-input-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: column;

      .heading4 {
        margin-bottom: 0;
      }

      .clear-btn {
        padding: 0.5rem 1.125rem;
        height: 2.25rem;
        outline: none;
      }
    }

    .tag-main {
      display: inline-block;

      &.categories-tag {
        margin-top: 3rem;
      }

      .body1 {
        margin: 0 0 1.5rem 0px;

        @media #{$vpMobile600} {
          margin: 0 0 2rem 0px;
          font-size: 1.25rem;
          line-height: 1.3;
        }
      }

      .tag-inner {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;

        @media #{$vpMobile600} {
          gap: 0.5rem;
          row-gap: 1rem;
        }

        .title-text {
          padding-left: 0;
          &::before {
            display: none;
          }
          input[type=checkbox]:checked+label {
            background-color: $secondarySemiLight;
            padding-right: 2.75rem;

            @media #{$vpMobile600} {
              padding-right: 1.75rem;
            }

            &::after {
              opacity: 1;
            }
          }

          .body3 {
            border-radius: 0.25rem;
            max-width: fit-content;
            padding: 1rem 1.5rem;
            line-height: 1.4;
            border: 0.063rem solid $secondarySimiLight;
            background-color: transparent;
            color: $baselineSecondary;
            transition: all 0.4s ease;
            height: 2.75rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-bottom: 0;
            position: relative;

            @media #{$vpMobile600} {
              padding: 0.75rem 1rem;
              font-size: 0.875rem;
            }

            &:after {
              content: '';
              width: 0.5rem;
              height: 0.5rem;
              display: block;
              border-radius: 3.125rem;
              background-color: $baselinePrimary;
              transition: all 0.4s ease;
              opacity: 0;
              position: absolute;
              right: 1.5rem;

              @media #{$vpMobile600} {
                right: 0.8rem;
              }
            }

            &:hover {
              background-color: $secondarySemiLight;
            }

          }
        }

      }
    }

    .filter-action {
      margin-top: auto;

      .filter-btn {
        padding: 1.3125rem 2rem;
        width: 100%;
        outline: none;

        @media #{$vpMobile600} {
          padding: 0.875rem 1.5rem;
        }
      }
    }
  }
}

.header-search-overlay {
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background-color: #00000080;
    position: absolute;
    z-index: 10;
    transition: all 0.5s ease-out;
    top: 8.5rem;
  }

  .sec-header-search-drawer {
    transform: translateY(0%);
    transition: all 0.5s ease-out;
    opacity: 1;
  }
}