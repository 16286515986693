.sec-podcast-banner {
  padding: 7.5rem 0;

  @media #{$vpMobile600} {
    padding: 5rem 0;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media #{$vpMobile600} {
      flex-direction: column;
    }

    .content-wrapper {
      flex: 0 0 58.45%;
      max-width: 58.45%;

      @media #{$vpMobile600} {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .heading2 {
        padding-bottom: 2rem;
        border-bottom: 0.063rem solid $secondarySemiLight;
        margin-bottom: 0;

        @media #{$vpMobile600} {
          font-size: 1.75rem;
          line-height: 1.3;
        }
      }

      .meta-content {
        padding-top: 2rem;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .date-tag-wrapper {
          display: flex;
          align-items: center;
          gap: 2rem;

          .title {
            p {
              font-size: 0.75rem;
              line-height: 1.4;
              font-weight: 700;
              color: $neutralGray400;
            }
          }

          .desc {
            p {
              color: $neutralGray400;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 300;
            }
          }
        }

        .button-wrapper {
          button {
            border: 0.063rem solid $secondarySimiLight;

            span:last-child {
              @media #{$vpMobile600} {
                padding-left: 0;
              }
            }

            img {
              width: 1.125rem;
              height: 1.125rem;
            }

            @media #{$vpMobile600} {
              border: 0.063rem solid $secondarySemiLight;
              border-radius: 100%;
              width: 3.475rem;
              height: 3.475rem;
              padding: 0;
              align-items: center;
              justify-content: center;
            }

            .btn-text {
              @media #{$vpMobile600} {
                display: none;
              }
            }
          }
        }
      }

      .audio-player {
        display: flex;
        align-items: center;
        gap: 1.25rem;
        padding-top: 2.5rem;

        .pauseBtn {
          display: none;
        }

        @media #{$vpMobile600} {
          padding-top: 2rem;
          padding-bottom: 2rem;
        }

        #waveform {
          width: 100%;
        }

        img {
          width: 7.625rem;
          height: 7.625rem;
          cursor: pointer;

          @media #{$vpMobile600} {
            width: 3.5rem;
            height: 3.5rem;
          }
        }
      }
    }

    .image-wrapper {
      flex: 0 0 32.9%;
      max-width: 32.9%;
      overflow: hidden;
      border-radius: 1rem;

      img {
        width: 100%;
      }

      @media #{$vpMobile600} {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
}