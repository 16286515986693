.sec-Initatives-rcu {
  padding: 100px 0;
  height: 100vh;
  background: linear-gradient(
      0deg,
      rgba(242, 239, 231, 0.5),
      rgba(242, 239, 231, 0.5)
    ),
    linear-gradient(0deg, #ffffff, #ffffff);

  .sec-Initatives-rcu-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media #{$vpMobile600} {
      flex-direction: column;
    }

    .sec-Initatives-timeline-sidebar {
      width: 40%;
      height: 100vh;
      padding-right: 30px;
    }

    .sec-Initatives-timeline-block {
      min-width: 50%;
      height: 100vh;
      position: relative;

      .timeline-block-wrapper {
        position: relative;
        width: 90%;

        .sec-detail {
          position: absolute;
          top: auto;
          bottom: 0;
          height: 100%;
          z-index: 1;
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          padding: 25px;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 34.8%,
            rgba(0, 0, 0, 0.6) 100%
          );
          border-radius: 30px;

          .timeline-inner {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .heading5 {
              color: $white;
            }

            .main-primary-btn {
              padding: 16px 24px;
              min-width: 130px;
            }
          }
        }

        .sec-img {
          width: 100%;
          height: 400px;
          object-fit: cover;
          border-radius: 32px;
        }
      }
    }
  }
}
