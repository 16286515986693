.sec-job-detail {
  .wrapper {
    display: flex;

    @media #{$vpMobile600} {
      flex-direction: column;
    }

    .left-wrapper {
      width: 50.5rem;
      height: 51.1875rem;
      position: relative;

      @media #{$vpMobile600} {
        width: 100%;
        height: 100%;
      }

      &::before {
        content: "";
        background-color: $baselineSecondaryLight;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
      }

      .left-container-wrapper {
        padding: 4rem 0 7.94rem 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 51.1875rem;
        position: relative;
        z-index: 2;

        @media #{$vpMobile600} {
          padding: 2.5rem 0 1.5rem;
          height: 100%;
        }

        .content-wrapper {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .heading3 {
            margin: 0;
            color: $baselineSecondary;
          }

          .date-location-wrapper {
            display: flex;
            align-items: center;
            gap: 1rem;

            .body2 {
              margin: 0;
              color: $baselineSecondary;
            }
          }
        }

        .buttons-wrapper {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          justify-content: flex-end;

          @media #{$vpMobile600} {
            position: fixed;
            bottom: 0;
            padding: 1rem 1.25rem;
            background-color: $baselineSecondaryLight;
            width: 100%;
            left: 0;
            right: 0;
            gap: 0.5rem;
          }

          .main-secondary-btn {
            @media #{$vpMobile600} {
              height: 3rem;

              .btn-text {
                font-size: 0.875rem;
                line-height: 1.4;
              }
            }
          }

          .main-primary-btn {
            @media #{$vpMobile600} {
              height: 3rem;
            }

            &.with-ic {
              .btn-text {
                font-size: 0.875rem;
                line-height: 1.4;
              }
            }

          }

          button {
            svg {
              width: 1rem;
              height: 1rem;
              /*rtl:raw: transform: rotate(180deg); */

              path {
                fill: $baselineSecondary;
              }
            }
          }
        }
      }
    }

    .right-wrapper {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      width: 69.5rem;
      padding: 4rem 7.5rem 7.94rem 10.12rem;
      @media #{$vpTablet991} {
        padding: 4rem;
      }
      @media #{$vpMobile600} {
        padding: 2.5rem 1.25rem 5.06rem 1.25rem;
        width: 100%;
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .key-duties-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          row-gap: 2rem;

          .key-duty {
            width: 25.25rem;
            display: flex;
            gap: 1.125rem;
            align-items: center;

            .icon-tree {
              width: 3rem;
              height: 3rem;
              flex-shrink: 0;
              padding: 10px;
              background-color: #f9f7f3;
              border-radius: 50%;

              @media #{$vpMobile600} {
                width: 2.5rem;
                height: 2.5rem;
              }
            }

            .body3 {
              margin: 0;
              color: $baselineSecondary;

              @media #{$vpMobile600} {
                font-size: 0.875rem;
              }
            }

            img {
              width: 3rem;
              height: 3rem;

              @media #{$vpMobile600} {
                width: 2.5rem;
                height: 2.5rem;
              }
            }
          }
        }
      }

      .body2 {
        margin: 0;
        color: $baselineSecondary;
        line-height: 1.4;
      }

      .heading4 {
        margin: 0;
        color: $baselineSecondary;
        line-height: 1.3;

        @media #{$vpMobile600} {
          font-size: 1.5rem;
        }
      }
    }
  }
  &.dark-bg {
    .wrapper {
      .left-wrapper {
        .left-container-wrapper {
          .content-wrapper {
            .heading3 {
              color: $white;
            }
            .date-location-wrapper {
              .body2 {
                color: $white;
              }
            }
          }
        }
        &::before {
          background-color: #000;
        }
      }
    }
    .right-wrapper {
      .heading4 {
        color: $white;
      }
      .body2 {
        color: $white;
      }
      .content-wrapper 
      {
        .key-duties-wrapper {
          .key-duty {
            .body3 {
              color: $white;
            }
          }
        }
      }
    }
  }
}