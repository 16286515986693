.editorial-folder {
  height: 100%;
  width: 100%;
  color: $white;
  display: flex;
  align-items: center;
  position: relative;
  /*rtl:raw:height: 100%; */

  // @media #{$vpMobile600} {
  //   height: 46.25rem;
  // }

  &::before {
    content: "";
    display: block;
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(10.35deg,
        rgba(0, 0, 0, 0.5) 43.98%,
        rgba(0, 0, 0, 0) 94.56%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .main-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .ImageBgBox {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      .bg-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .wrapper-box {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      z-index: 3;
      height: 100%;

      @media #{$vpMobile600} {
        flex-direction: column;
        justify-content: center;
      }

      .content-left {
        width: 30.4375rem;

        @media #{$vpMobile600} {
          margin-bottom: 1.875rem;
        }

        .heading3 {
          color: $white;
        }
      }

      .content-right {
        width: 69.5625rem;

        @media #{$vpTablet767} {
          width: 100%;
        }

        .card-wrapper {
          border: 1px solid $white;
          border-radius: 2rem;

          @media #{$vpMobile600} {
            border-radius: 1rem;
          }


        }

        .card-heading {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .arrow-svg {
            fill: $white;

            svg {
              width: 1.5rem;
              height: 1.5rem;

              /*rtl:raw:transform: rotateY(190deg) */
              @media #{$vpMobile600} {
                width: 1rem;
                height: 1rem;
              }
            }
          }
        }
      }
    }
  }
}