.sec-animated-banner {
  height: 100vh;
  overflow: hidden;

  .wrapper {
    position: relative;
    height: 100%;

    .content {
      position: absolute;
      z-index: 1;
      color: $white;
      top: 17.875rem;

      .heading-wrapper {
        display: flex;
        max-width: 45.625rem;

        h1 {
          margin: 0;
        }

        @media #{$vpMobile600} {
          width: 100%;
        }

        .quoteText-val {
          display: none;

          @media #{$vpTablet991} {
            display: block;
            font-size: 2.5rem;
            line-height: 1.3;
            font-weight: 300;
          }
        }

        .quote-word {
          @media #{$vpTablet991} {
            display: none;
          }
        }
      }

      .text-wrapper {
        opacity: 0;
        filter: blur(80px);

        @media #{$vpTablet991} {
          opacity: 1;
          filter: blur(0px);
        }
      }

      .para-wrapper {
        p {
          margin: 0;
          padding-top: 1.87rem;

          @media #{$vpMobile600} {
            padding-top: 1rem;
          }
        }
      }
    }

    .banner-stars-img {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;
      z-index: 0;

      .bsi-wrapper {
        position: relative;

        .bsi-backdrop {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(0deg,
              rgba(0, 0, 0, 0.6) 0%,
              rgba(0, 0, 0, 0.6) 100%);

          @media #{$vpTablet991} {
            // width: 0;
            // height: 0;
            background: linear-gradient(0deg,
                rgba(0, 0, 0, 0.15) 0%,
                rgba(0, 0, 0, 0) 100%);
          }
        }

        // &::after{
        //   content: "";
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   width: 100%;
        //   height: 100%;
        //   background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
        //   // z-index: 3;
        // }
        .bsiw-box {
          height: 150vh;
          width: 100%;

          // transform: translateY(-50vh);
          @media #{$vpTablet991} {
            transform: translateY(-50vh);
          }
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .banner-img-2 {
      position: absolute;
      top: auto;
      // left: 64%;
      // transform: translateX(-50%);
      // bottom: 11.063rem;
      bottom: -26.5rem;
      left: 15%;
      /*rtl:raw: right: 18%;; */
      overflow: hidden;
      transform: scale(0);
      transform-origin: 50% 49%;
      // transform-origin: 40rem 40rem;
      width: 80rem;
      height: 80rem;

      @media #{$vpTablet991} {
        bottom: 7.5rem;
        left: 47%;
        transform: scale(1);
        transform-origin: unset;
        width: auto;
        height: auto;
      }

      @media #{$vpMobile600} {
        bottom: 7.5rem;
        left: 44%;
        transform: scale(1);
        transform-origin: unset;
        width: auto;
        height: auto;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }

    .banner-img-3 {
      position: absolute;
      bottom: 0;
      transform: translateY(100%);
      width: 100%;

      @media #{$vpTablet991} {
        transform: translateY(0);
      }

      img {
        height: 100%;
        width: 100%;

        @media #{$vpMobile600} {
          width: 56.875rem;
          height: 16rem;
          object-fit: cover;
        }
      }
    }

    .banner-img-4 {
      position: absolute;
      bottom: 0;
      left: 0;
      /*rtl:raw:left: 0; */
      /*rtl:raw:right:auto; */
      transform: translateY(100%);

      @media #{$vpTablet991} {
        transform: translateY(0);
      }

      img {
        width: 37.15631rem;
        height: 37.15631rem;

        @media #{$vpMobile600} {
          width: 10.77613rem;
          height: 10.77613rem;
        }
      }
    }

    .banner-img-5 {
      position: absolute;
      bottom: 0;
      right: 0;
      /*rtl:raw:right: 0; */
      /*rtl:raw:left:auto; */
      transform: translateY(100%);

      @media #{$vpTablet991} {
        transform: translateY(0);
      }

      img {
        width: 38.8125rem;
        height: 58.1875rem;

        @media #{$vpMobile600} {
          width: 10.77613rem;
          height: 10.77613rem;
        }
      }
    }
  }
}