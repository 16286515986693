.cookies-sec {
  position: fixed;
  bottom: 48px;
  z-index: 9999;
  display: none;

  @media #{$vpMobile600} {
    bottom: 40px;
  }

  .sec-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 105rem;
    background-color: $baselineSecondaryLight;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;

    @media #{$vpTablet991} {
      width: 100%;
    }

    @media #{$vpMobile600} {
      flex-direction: column;
      padding: 1.5rem;
    }

    .content-wrapper {
      @media #{$vpTablet991} {
        max-width: 60%;
      }

      @media #{$vpMobile600} {
        max-width: 100%;
      }

      p {
        margin: 0;
      }
    }

    .cta-wrapper {
      @media #{$vpMobile600} {
        margin-top: 1.5rem;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }

      button {
        margin-left: 1rem;


        @media #{$vpMobile600} {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-left: 0;
          margin-bottom: 1rem;
          width: 100%;
          height: auto;
          max-width: 100%;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &.notification-message {
    display: block;

    .sec-wrapper {
      .content-wrapper {
        display: flex;
        justify-content: flex-start;

        @media #{$vpTablet991} {
          max-width: 100%;
        }

        .img-wrapper {
          width: 1.5rem;
          height: 1.5rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .inner-content-box {
          padding-left: 1.5rem;
          width: 70%;

          @media #{$vpMobile600} {
            width: 100%;
          }

          .body2 {
            margin: 0;
          }
        }
      }

      .cta-wrapper {
        .main-primary-btn {
          width: max-content;

          @media #{$vpMobile600} {
            width: 100%;
          }
        }
      }
    }
  }
  &.snackbar-message {
    .sec-wrapper {
      position: relative;
      padding: 2rem;
      @media #{$vpMobile600} {
        padding: 2.5rem 1.5rem 1.5rem 1.5rem;
      }
      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 1rem;
        background-color: #C10719;
        left: 0;
        top: 0;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        @media #{$vpMobile600} {
          width: 100%;
          height: 1rem;
        }
      }
      .content-wrapper {
        max-width: 55%;
          flex: 0 0 55%;
          @media #{$vpMobile600} {
            max-width: 100%;
            flex: 0 0 100%;
          }
        .inner-content-box {
          padding: 0;
        }
      }
      .cta-wrapper {
        max-width: 40%;
        flex: 0 0 40%;
        display: flex;
        justify-content: flex-end;
        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }
  }
  &.warning-message {
    .sec-wrapper {
      &::before {
        background-color: #F5C200;
      }
    }
  }
  &.success-message {
    .sec-wrapper {
      &::before {
        background-color: #007018;
      }
    }
  }
  &.dark-bg {
    .sec-wrapper {
      background-color: rgba($color: #fff, $alpha: 0.1);
    }
  }
  &.gray-bg {
    .sec-wrapper {
      background-color: $white;
    }
  }
}