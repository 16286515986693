.download-report-form {
  .download-img-txtBox-section {
    display: flex;
    justify-content: space-between;
    border-radius: 2rem;
    background-color: $baselineSecondaryLight;

    @media #{$vpMobile600} {
      flex-direction: column;
      border-radius: 1rem;
    }

    .error {
      color: red;
      display: none;
    }
    .invalid-email-error {
      color: red;
      display: none;
    }
    .content-wrapper {
      max-width: 65rem;
      flex: 0 0 65rem;
      padding: 5rem 0 5rem 5rem;
      @media #{$vpTablet991} {
        max-width: 45rem;
        flex: 0 0 45rem;
        padding: 2rem 0 2rem 2rem;
      }
      .heading-para {
        display: flex;
        flex-direction: column;

        .heading2 {
          margin: 0;
          padding-bottom: 1rem;
        }

        .body1 {
          margin: 0;
        }
      }

      .download-form {
        display: flex;
        // align-items: center;
        gap: 2rem;
        padding-top: 2.063rem;

        @media #{$vpMobile600} {
          flex-direction: column;
        }

        .form-fields-row {
          width: 26.875rem;

          @media #{$vpMobile600} {
            width: 100%;
          }

          input {
            background: $white;
            border: none;
            padding: 0 1.25rem;
            border-radius: 0.5rem;
            width: 100%;
            height: 4.375rem;
          }
        }
      }

      @media #{$vpMobile600} {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 1.938rem 1.25rem 3rem 1.25rem;
      }

      .btn-wraper {
        display: flex;
        padding-top: 2.25rem;

        @media #{$vpMobile600} {
          flex-direction: column;
          padding-top: 1.5rem;
        }

        .with-ic {
          @media #{$vpMobile600} {
            margin-left: 0;
            margin-top: 1rem;
            padding: 0.88rem 1.15rem;
            height: 3rem;
            max-width: 100%;
          }

          .btn-text {
            font-size: 0.875rem;
          }
        }
      }
    }

    .img-box {
      max-width: 34rem;
      flex: 0 0 34rem;
      border-radius: 0 2rem 2rem 0;
      overflow: hidden;
      @media #{$vpTablet991} {
        max-width: 20rem;
        flex: 0 0 20rem;
      }
      @media #{$vpMobile600} {
        border-radius: 0;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 0 2rem 2rem 0;
        object-fit: cover;

        @media #{$vpMobile600} {
          border-radius: 0 0 1rem 1rem;
        }
      }

      @media #{$vpMobile600} {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
  &.dark-bg {
    .download-img-txtBox-section {
      background-color: rgba($color: #fff, $alpha: 0.1);
    }
  }
  &.gray-bg {
    .download-img-txtBox-section {
      background-color: $white;
      .content-wrapper {
        .download-form {
          .form-fields-row {
            input {
              background-color: #f9f7f3;
            }
          }
        }
      }
    }
  }
}