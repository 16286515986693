.highcharts-figure {
  .line-chart-wrapper {
    display: flex;
    background-color: $baselineSecondaryLight;
    @media #{$vpMobile600} {
      flex-direction: column;
    } 

    .line-chart-container-left {
      width: 50%;
      .highcharts-point {
        clip-path: circle(50%);
      }
      @media #{$vpMobile600} {
        width: 100%;
      }
    }
    .line-chart-container-right {
      width: 50%;
      .highcharts-point {
        clip-path: circle(50%);
      }
      @media #{$vpMobile600} {
        width: 100%;
      }
    }
  }
}
