.about-alula {
  .alula-wrapper {
    .sub-title {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1rem;
        width: 100%;
        height: 0.063rem;
        background-color: $baselinePrimary;
      }
    }

    .content-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1.25rem;

      .content-grid {
        max-width: 36%;
        flex: 0 0 36%;

        .heading2 {
          margin: 0;
        }

        @media #{$vpTablet767} {
          max-width: 100%;
          flex: 0 0 100%;
          width: 100%;
        }
      }

      .pera-section {
        max-width: 49.5%;
        flex: 0 0 49.5%;

        .body2 {
          margin: 0;
        }

        @media #{$vpTablet767} {
          max-width: 100%;
          flex: 0 0 100%;
          padding-top: 1.5rem;
        }
      }

      @media #{$vpTablet767} {
        flex-direction: column;
        padding-top: 1.5rem;
      }
    }

    .royal-galance-wrapper {
      display: flex;
      gap: 1.25rem;
      padding-top: 3rem;
      position: relative;
      z-index: 1;

      @media #{$vpTablet767} {
        flex-direction: column;
        padding-top: 1.5rem;
      }

      .video-section {
        position: relative;
        width: 51.875rem;

        @media #{$vpTablet767} {
          width: 100%;
        }

        .video-wrapper {
          height: 100%;
          width: 100%;
          overflow: hidden;

          @media #{$vpTablet767} {
            height: 21.875rem;
            cursor: none;
          }

          .video-js {
            border-radius: 0.75rem;
            max-width: 100%;
            height: 100%;
            width: 100%;

            @media #{$vpTablet767} {
              height: 21.875rem;
            }

            video {
              width: 100%;
              height: 100%;
              border-radius: 0.75rem;
              object-fit: cover;
              z-index: 2;
            }

            .vjs-control-bar {
              display: none;
            }
          }
        }

        .video-content {
          position: absolute;
          bottom: 0;
          color: $text-white;
          padding: 0 0 2.5rem 2.5rem;
          max-width: 90%;
          z-index: 9;

          @media #{$vpTablet767} {
            max-width: 100%;
            padding: 0 1.5rem 1.5rem 1.5rem;
          }

          .heading4{
            @media #{$vpTablet767} {
              font-size: 1.5rem;
              line-height: 1.3;
            }
          }

          .btn-box {
            padding-top: 2.5rem;

            @media #{$vpTablet767} {
              padding-top: 3rem;
            }
          }
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 0.75rem;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          z-index: 2;
        }
      }

      .content-box {
        width: 34.1875rem;
        display: flex;
        flex-direction: column;
        gap: 1.25rem;

        .stamp-sec {
          border: 0.063rem solid $secondarySemiLight;
          border-radius: 0.75rem;

          .img-wrapper {
            display: flex;
            justify-content: flex-end;
            padding-bottom: 0.94rem;
            @media #{$vpMobile600} {
              max-width: 5.813rem;
              width: 100%;
              margin-left: auto;
            }
          }

          .inner-content {
            display: flex;
            align-items: center;
            padding: 0 2rem 2rem 2rem;

            @media #{$vpMobile600} {
              padding: 0 1.5rem 1.5rem 1.5rem;
            }

            .heading5 {
              width: 21.25rem;
              line-height: 1.3;
              @media #{$vpMobile600} {
                font-size: 1.25rem;
              }
            }

            .btn-wrapper {
              width: 9.9375rem;
              display: flex;
              justify-content: flex-end;

              .small-btn {
                @media #{$vpMobile600} {
                  padding: 0.5rem 1.12rem;
                  width: 3.125rem;
                  height: 1.875rem;
                  display: flex;
                  align-items: center;
                }

                img {
                  @media #{$vpMobile600} {
                    width: 0.875rem;
                    height: 0.875rem;
                  }
                }
              }

              .btn-text {
                @media #{$vpMobile600} {
                  display: none;
                }
              }

              .btn-arrow {
                display: none;
                /*rtl:raw:transform: rotate(180deg);*/
                @media #{$vpMobile600} {
                  display: block;
                }
              }
            }
          }
        }

        @media #{$vpTablet767} {
          width: 100%;
          margin-top: 1rem;
        }

        .festival-wrapper {
          border-radius: 0.75rem;
          border: 0.063rem solid $secondarySemiLight;
          position: relative;
          height: 18.125rem;

          @media #{$vpTablet767} {
            height: 10.438rem;
          }

          .img-box {
            max-width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 0.75rem;
            }
          }

          .fest-content-box {
            display: flex;
            justify-content: space-between;
            position: absolute;
            bottom: 0;
            padding: 0 2rem 2rem 2rem;
            width: 100%;
            z-index: 1;
            align-items: flex-end;

            .heading5 {
              max-width: 65%;
              overflow: hidden;
              margin: 0;

              @media #{$vpTablet767} {
                font-size: 1.5rem;
                line-height: 1.3;
              }
            }

            @media #{$vpTablet767} {
              padding: 0 1.5rem 1.5rem 1.5rem;
            }

            .fest-btn-box {
              .small-btn {
                @media #{$vpMobile600} {
                  padding: 0.5rem 1.12rem;
                  width: 3.125rem;
                  height: 1.875rem;
                  display: flex;
                  align-items: center;
                }

                img {
                  @media #{$vpMobile600} {
                    width: 0.875rem;
                    height: 0.875rem;
                  }
                }
                .btn-text {
                  @media #{$vpMobile600} {
                    display: none;
                  }
                }

                .btn-arrow {
                  display: none;
                  @media #{$vpMobile600} {
                    display: block;
                    /*rtl:raw:transform: rotate(180deg);*/
                  }
                }
              }
            }
          }

          &.sty-001 {
            .fest-content-box {
              color: $text-white;
            }

            .fest-btn-box {
              .small-btn {
                background-color: $text-white;
                border: none;
                &:hover {
                  background-color: #f2efe7;
                }
              }
            }

            &::after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 0.75rem;
              background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.5) 0%,
                rgba(0, 0, 0, 0) 100%
              );
            }
          }

          &:first-child {
            margin-bottom: 1.25rem;
          }
        }
      }

      .map-section {
        width: 16.4375rem;
        display: flex;
        flex-direction: column;

        @media #{$vpMobile600} {
          flex-direction: row;
        }

        .map-img-wrapper {
          border: 0.063rem solid $secondarySemiLight;
          border-radius: 0.75rem;
          margin-bottom: 1.25rem;
          height: 100%;

          img {
            border-radius: 0.75rem;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          @media #{$vpTablet767} {
            max-width: 48.5%;
            flex: 0 0 48.5%;
            height: 100%;
            margin-bottom: 0;
          }
          .location-map {
            height: 100%;
            border-radius: 0.75rem;
          }
        }

        .temprature-wrapper {
          border: 0.063rem solid $secondarySemiLight;
          border-radius: 0.75rem;
          padding: 1.5rem;
          width: 100%;

          .content-sec {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .body3 {
              margin: 0;

              @media #{$vpTablet767} {
                font-size: 0.875rem;
              }
            }

            .weather-box {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .body3{
                @media #{$vpTablet767} {
                  font-size: 0.875rem;
                }
              }

              .icon-wraper {
                padding-right: 0.563rem;

                img {
                  width: 1.5rem;
                  height: 1.5rem;

                  @media #{$vpMobile600} {
                    width: 1rem;
                    height: 1rem;
                  }
                }
              }
            }

            .date-box {
              .body3 {
                margin: 0;

                @media #{$vpTablet767} {
                  font-size: 0.875rem;
                }
              }
            }

            &.temp-date-wrapper {
              padding-top: 1.188rem;

              @media #{$vpTablet767} {
                flex-direction: column;
              }

              .heading3 {
                margin: 0;

                @media #{$vpTablet767} {
                  width: 100%;
                }
              }

              .date-box {
                @media #{$vpTablet767} {
                  width: 100%;
                }
              }
            }
          }

          @media #{$vpTablet767} {
            max-width: 48.5%;
            flex: 0 0 48.5%;
            padding: 1rem;
          }
        }

        @media #{$vpTablet767} {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;
        }
      }
    }
  }
  &.dark-bg {
      .alula-wrapper {
        .royal-galance-wrapper {
          .content-box {
            .festival-wrapper {
              &.sty-001 {
                .fest-btn-box {
                  .small-btn {
                    background-color: transparent;
                    border: .063rem solid #9f8332;
                    &:hover {
                      background-color: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
}
