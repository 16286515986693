.reach-out {
  .box-wrapper {
    display: flex;
    justify-content: space-between;

    .img-wrapper {
      max-width: 32.84%;
      flex: 0 0 32.84%;
      border-radius: 1rem;
      overflow: hidden;
      @media #{$vpTablet991} {
        flex: 0 0 35.84%;
        max-width: 35.84%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
      }

      @media #{$vpMobile600} {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }

    .content-wrapper {
      max-width: 50%;
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media #{$vpTablet991} {
        max-width: 55%;
        flex: 0 0 55%;
      }
      .heading2 {
        margin: 0;
      }

      .content-para {
        .body1 {
          margin: 0;
        }

        .contact-info {
          display: flex;
          flex-wrap: wrap;

          .info-box {
            display: flex;
            align-items: center;
            max-width: 25.9375rem;
            flex: 0 0 25.9375rem;
            padding-top: 3rem;

            .img-box {
              width: 3rem;
              height: 3rem;
              background-color: $baselineSecondaryLight;
              border-radius: 1.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              @media #{$vpMobile600} {
                width: 2.5rem;
                height: 2.5rem;
              }
              img {
                width: 1.5rem;
                height: 1.5rem;
                object-fit: cover;
              }
            }

            .info-inner-content {
              padding-left: 1rem;
              &.sty--1 {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                .call-btn {
                  padding: 0.5rem 1.125rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  display: none;
                  @media #{$vpMobile600} {
                    display: block;
                    width: max-content;
                  }
                }
              }
              .body1 {
                margin: 0;

                a {
                    color: $baselineSecondary;
                    unicode-bidi: plaintext;
                }
              }
            }

            @media #{$vpMobile600} {
              max-width: 100%;
              flex: 0 0 100%;
              padding-top: 1.25rem;
            }
          }

          @media #{$vpMobile600} {
            flex-direction: column;
            padding-top: 1.25rem;
          }
        }

        .btn-wrapper {
          padding-top: 3rem;

          @media #{$vpMobile600} {
            padding-top: 3rem;
            display: flex;
            flex-direction: column;
          }
          .main-secondary-btn {
            max-width: 100%;
            text-align: center;
          }
        }

        @media #{$vpMobile600} {
          padding-top: 2.5rem;
        }
      }

      @media #{$vpMobile600} {
        max-width: 100%;
        flex: 0 0 100%;
        padding-top: 5rem;
      }
    }

    @media #{$vpMobile600} {
      flex-direction: column;
    }
  }
}