.editorial-2 {

  .images-wrapper {
    .img-box-2 {
      overflow: hidden;
      border-radius: 0.75rem;
      height: 37.5rem;
      flex: 0 0 70.953%;
      max-width: 70.953%;
      video {
        height: 37.5rem;
        object-fit: cover;
        width: 100%;
        border-radius: 0.75rem;
        @media #{$vpMobile600} {
          height: 11rem !important;
        }
      }
      .BigImage {
        width: 100% !important;
        height: 100%;
      }
      @media #{$vpMobile600} {
        flex: 0 0 100%;
        max-width: 100%;
        height: 100%;
        padding-left: 0;
        width: 100%;
      }
      
    }
  }

  button {
    @media #{$vpMobile600} {
      width: 100%;
    }
  }

  &.investing-in-alula {
    background-color: $white;

  
  }

  .content-wrapper {
    display: flex;
    align-items: start;
    justify-content: space-between;

    &.about-content {
      padding-top: 1rem;
    }

    @media #{$vpMobile600} {
      flex-direction: column;
    }

    .body3 {
      color: $baselinePrimary;
      padding-bottom: 2rem;
      margin: 0;

      span {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 0.063rem;
          background-color: $baselinePrimary;
          left: 0;
          bottom: -1rem;
        }
      }
    }

    .heading-box {
      flex: 0 0 43.3%;
      max-width: 43.3%;

      @media #{$vpMobile600} {
        flex: 0 0 100%;
        max-width: 100%;
        padding-top: 0;
      }
    }

    .para-box {
      flex:0 0 50%;
      max-width: 50%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      @media #{$vpMobile600} {
        flex:0 0 100%;
       max-width: 100%;
      }
      .body1,
      .body2 {
        margin: 0;
      }

      .action-btn-wrapper {
        margin-top: 3rem;
        @media #{$vpMobile600} {
          margin-top: 0;
        }
        .main-primary-btn {
          margin-right: 1rem;

          @media #{$vpMobile600} {
            margin-right: 0;
            margin-bottom: 1rem;
            width: 100%;
            display: block;
            max-width: 100%;
            text-align: center;
          }
        }
      }

      .action-box {
        margin-top: 3rem;
        display: flex;
        gap: 1rem;

        @media #{$vpMobile600} {
          flex-direction: column;
        }

        .main-primary-btn {
          padding: 1.313rem 1.875rem;

          @media #{$vpMobile600} {
            padding: 0.75rem;
          }
        }
      }

      @media #{$vpMobile600} {
        width: 100%;
        padding-top: 1rem;
      }
    }
  }

  .btn-box {
    padding-top: 3rem;
  }

  .images-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 5rem;

    .img-box {
      overflow: hidden;
      border-radius: 0.75rem;
      flex: 0 0 24.534%;
      max-width: 24.534%;
      @media #{$vpMobile600} {
        flex: 0 0 47.534%;
        max-width: 47.534%;
      }
      img {
        height: 25.5rem;
        width: 25.5rem;
        object-fit: cover;
        border-radius: 0.75rem;
  
        @media #{$vpMobile600} {
          height: 11.013rem !important;
          width: 10.313rem !important;
        }
      }
    }

    @media #{$vpMobile600} {
      gap: 1.25rem;
      padding-top: 2rem;
      flex-direction: column;
    }
    .img-grid2{
      // height: 37.5rem;
      object-fit: cover;
      flex: 0 0 71.68%;
      max-width: 71.68%;
      border-radius: 0.75rem;
      overflow: hidden;
      video {
        height: 37.5rem;
        object-fit: cover;
        width: 100%;
        border-radius: 0.75rem;
        @media #{$vpMobile600} {
          height: 11rem !important;
        }
      }
      img{
        width: 100%;
        height: 37.5rem;
        object-fit: cover;
        @media #{$vpMobile600} {
           height: 100%;
           width: 100% !important;
        }

      }

      @media #{$vpMobile600} {
        // height: 11rem !important;
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
      }
    }

    
  }

  &.about-the-project {
    background-color: transparent;

    .images-wrapper {
      @media #{$vpMobile600} {
        padding-top: 2.5rem;
        gap: 1.5rem;
      }
    }
    .project-wrapper {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      @media #{$vpMobile600} {
        gap: 2.38rem;
      }

      .content-wrapper {
        .heading-box {
          padding-top: 0;
          .heading-inner {
            h2 {
              margin: 0;
            }
          }
        }
      }

      .project {
        display: flex;
        gap: 5rem;
        padding-top: 2.5rem;
        @media #{$vpMobile600} {
          flex-direction: column;
          gap: 2.5rem;
        }

        .para-box {
          width: 50rem;

          @media #{$vpMobile600} {
            width: 100%;
          }

          p {
            margin: 0;
          }
        }

        .project-details {
          width: 50rem;
          display: flex;
          flex-direction: column;
          gap: 1.5rem;

          @media #{$vpMobile600} {
            width: 100%;
            gap: 1rem;
          }

          p {
            margin: 0;
            @media #{$vpMobile600} {
              font-size: 0.875rem;
            }
          }

          .project-details-item {
            display: flex;
            align-items: center;
            gap: 2.5rem;
            border-bottom: 0.063rem solid $secondarySemiLight;
            padding-bottom: 1.5rem;

            &:last-child {
              border-bottom: none;
              padding-bottom: 0;
            }
            .item-description {
              a {
                color: $baselineSecondary;
              }
            }
            .primaryColor {
              color: $baselinePrimary;
              width: 14.5625rem;

              @media #{$vpMobile600} {
                width: 9.6875rem;
              }
            }

            .simplePara {
              @media #{$vpMobile600} {
                width: 9.6875rem;
              }
            }

            &.logo-row {
              @media #{$vpMobile600} {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 1.25rem;
              }

              .logos {
                display: flex;
                gap: 1.25rem;

                @media #{$vpMobile600} {
                  gap: 1rem;
                }

                .insvg {
                  width: 7rem;
                  height: 2.75rem;

                  @media #{$vpMobile600} {
                    width: 6.5rem;
                    height: 2.5625rem;
                  }
                }
              }

              .primaryColor {
                @media #{$vpMobile600} {
                  width: 100%;
                }
              }
            }
            &.tags-row{
              padding-top: 0;
              .item-title{
                @media #{$vpTablet991} {
                  width: 100%;
                }
              }
              .item-description{
                @media #{$vpTablet991} {
                  width: 100%;
                  margin-top: 0.625rem;
                }
              }
              .tags-wrapper{
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                .tag-box{
                  padding: 0.688rem 1.5rem;
                  border: 0.063rem solid $secondarySemiLight;
                  border-radius: 0.25rem;
                  margin-right: 0.5rem;
                  // margin-top: 0.75rem;
                  
                  @media #{$vpTablet991} {
                    padding: 0.75rem 1.5rem;
                    margin-right: 0.5rem;
                    margin-top: 0.625rem;
                  }
                  &:last-child{
                    margin-right: 0;
                  }
                }
              }
              @media #{$vpTablet991} {
                flex-direction: column;
              }
            }
          }
        }
      }
    }
  }
}
