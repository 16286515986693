.sec-footer {
  background-color: $baselineSecondary;

  .sec-footer-wrapper {
    padding: 5rem 0;

    @media #{$vpMobile600} {
      padding: 5rem 0 2.5rem 0;
    }

    .footer-menu {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-bottom: 0.063rem solid $neutralGray400;

      @media #{$vpTablet1024} {
        align-items: flex-start;
      }

      @media #{$vpMobile600} {
        border: 0;
      }

      .ft-menu-col-1 {
        width: 100%;
        padding: 3rem 0;

        @media #{$vpTablet1024} {
          width: 30.125rem;
          flex: auto;
          padding-left: 2rem;
        }

        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
          padding: 2.5rem 0;
        }

        &.ft-menu-col-5 {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          position: relative;
          padding-bottom: 3rem;

          @media #{$vpTablet1024} {
            width: 21.375rem;
            flex: auto;
            padding-left: 0;
          }

          @media #{$vpMobile600} {
            padding: 0;
          }

          &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: $neutralGray400;
            height: 0.063rem;
            width: 100%;

            @media #{$vpTablet1024} {
              display: none;
            }
          }

          .news-letter-title {
            width: 34.1875rem;

            @media #{$vpMobile600} {
              width: 100%;
              padding-bottom: 0;
            }
          }

          .news-letter-info {
            width: 33.3125rem;

            @media #{$vpMobile600} {
              width: 100%;
            }
            .error {
              color: red;
              display: none;
            }

            .invalid-email-error {
              color: red;
              display: none;
            }
          }

          .icons-wrap--desktop {
            a {
              margin-left: 2rem;
              width: 1.85844rem;
              height: auto;

              &:hover {
                svg {
                  path {
                    fill: $baselinePrimary;
                  }
                }
              }
            }

            @media #{$vpTablet1280} {
              display: none;
            }
          }
        }

        .menu-wrap {
          display: flex;
          justify-content: space-between;
          @media #{$vpTablet1024} {
            flex-wrap: wrap;
          }


          .ft-menu-col1 {
            width: 8.75rem;

            @media #{$vpTablet1024} {
              width: 100%;
            }

            .has-submenu {
              margin-bottom: 2.5rem;

              .body4 {
                margin-top: 0;
                font-weight: 500;

                @media #{$vpTablet1024} {
                  margin-top: 0.625rem;
                }
              }

              @media #{$vpTablet1024} {
                margin-bottom: 0;
              }

              &.dual-col {
                margin-bottom: 0;
              }

              p {
                @media #{$vpTablet1024} {
                  border-bottom: 0.063rem solid $neutralGray500;
                  position: relative;
                  padding-bottom: 1.25rem;
                }

                .arrow-img {
                  position: absolute;
                  right: 0;
                  top: 3px;
                  display: none;
                  width: 0.938rem;
                  height: 0.563rem;

                  @media #{$vpTablet1024} {
                    display: block;
                  }
                }

                strong {
                  font-weight: 500;
                }
              }

              a {
                margin-bottom: 0.938rem;
                line-height: normal;
                position: relative;

                &:last-child {
                  margin-bottom: 0;
                }

                &::after {
                  content: "";
                  position: absolute;
                  bottom: -2px;
                  left: 0;
                  width: 0;
                  height: 1px;
                  background-color: $white;
                  transition: all 0.3s ease-in-out;
                }

                &:hover {
                  &::after {
                    width: 100%;
                  }
                }

                .body5 {
                  color: $white;

                  strong {
                    margin-bottom: 0.938rem;
                    display: block;
                  }
                }
              }

              .submenu {
                @media #{$vpTablet1024} {
                  opacity: 0;
                  height: 0;
                  transition: 0.35s ease;
                }

                li {
                  margin-bottom: 0.938rem;
                  padding-left: 0;
                  @media #{$vpMobile600} {
                    padding-left: 0.938rem;
                  }
                  &::before {
                    display: none;
                  }
                }

                &.sty-1 {
                  display: flex;
                  justify-content: space-between;

                  .submenu-item {
                    width: 11.6875rem;

                    &.list-2 {
                      width: 9.75rem;
                    }
                  }
                }
              }

              &.show {
                .body4 {
                  .arrow-img {
                    transform: rotate(180deg);
                  }
                }
              }
              .submenu {
                &.show--options {
                  @media #{$vpTablet1024} {
                    opacity: 1;
                    height: auto;
                  }
                }
              }
            }

            &.sty-1 {
              width: 25.4375rem;

              @media #{$vpTablet1024} {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .ft-term-social {
      display: none;

      @media #{$vpTablet1280} {
        display: block;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3rem 0;
        border-bottom: 0.063rem solid $neutralGray400;
      }

      @media #{$vpMobile600} {
        padding: 0 0 2.5rem 0;
        flex-direction: column-reverse;
      }

      .icons-wrap {
        @media #{$vpMobile600} {
          margin: 0 0 2rem 0;
        }

        a {
          img {
            margin-left: 2rem;

            @media #{$vpMobile600} {
              margin: 0 1rem;
            }
          }
        }
      }

      .content-box {
        a {
          margin: 0 1.25rem;

          @media #{$vpMobile600} {
            margin: 0 1rem;
          }
        }
      }
    }

    .ft-logo-sec {
      .logo-wrap {
        display: flex;
        justify-content: space-between;
        padding-top: 3rem;
        align-items: center;
        .brand-logo {
          .insvg {
            width: 64px;
            height: auto;
          }
        }
        a {
          img {
            width: 236px;
            height: auto;
          }
          .insvg {
            width: 236px;
            height: auto;
          }
        }
      }

      .content-box--desktop {
        @media #{$vpTablet1280} {
          display: none;
          width: 0;
          height: 0;
        }

        a {
          margin: 0 1.25rem;
        }
      }
    }

    .bottom-description{
      padding-top: 1.5rem;
      @media #{$vpMobile600} {
        text-align: center;
      }
    }
    .news-letter-info {
      .heading5 {
        color: $white;
      }

      .news-letter-inner {
        position: relative;

        @media #{$vpTablet1024} {
          margin-top: 1.5rem;
        }

        form {
          width: auto;
          display: block;
        }

        .form-field {
          .control-label {
            color: $white;
          }
        }

        .news-letter-input {
          width: 100%;
          padding: 0.938rem 3.125rem 0.938rem 1.25rem;
          border-radius: 0.75rem;
          background-color: transparent;
          border: 0.063rem solid $netureGray;
          font-size: 1.5rem;
          color: $white;
          /*rtl:raw:line-height: 2;*/

          @media #{$vpMobile600} {
            font-size: 1rem;
          }

          &::placeholder {
            color: $white;
          }
        }

        .news-letter-submit-btn,
        input[type=submit]
         {
          background-image: url("../img/icon/input-icon.svg");
          background-repeat: no-repeat;
          padding: 0;
          width: 2.5rem;
          height: auto;
          color: transparent;
          background-position: center;
          background-color: transparent;
          border: 0;
          position: absolute;
          top: 65%;
          left: auto;
          right: 0.938rem;
          transform: translateY(-50%);
          background-size: contain;
          &:hover {
            svg {
              path {
                fill: $baselinePrimary;
              }
            }
          }

          /*rtl:raw:transform: translateY(-50%) scale(-1)*/

          img {
            // transform: rotate(180deg);
            /*rtl:raw:transform: rotate(180deg)*/
          }
        }
      }
    }
  }
}

.darkTheme {
  .sec-footer {
    background-color: #1d1e20;
  }
}