.sec-banner {
  color: $white;
  overflow: hidden;
  min-height: 100vh;
  position: relative;

  .banner-wrapper {
    height: 100vh;
    display: flex;
  }

  .bannerTitle-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4rem;

    @media #{$vpMobile600} {
      gap: 2rem;
    }

    p {
      color: $white;
      border-bottom: 0.063rem solid $white;

      @media #{$vpMobile600} {
        font-size: 0.875rem;
      }
    }

    .heading1 {
      max-width: 62rem;

      @media #{$vpMobile600} {
        max-width: 100%;
        font-size: 2.5rem;
      }
    }
  }

  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;

    .banner-content {
      padding: 0;
      z-index: 1;
      position: absolute;
      width: 80%;
      margin: 0 auto;
      text-align: center;

      @media #{$vpTablet1024} {
        width: 100%;
      }

      @media #{$vpMobile600} {
        bottom: 7rem;
      }

      .banner-servises-boxes {
        padding: 4rem 0 0 0;

        .servises-box {
          display: flex;
          gap: 0.625rem;
          justify-content: center;

          @media #{$vpMobile600} {
            max-width: 14.25rem;
          }

          @media #{$vpTablet991} {
            flex-direction: column;
          }

          .banner-sr-box {
            width: 17.0625rem;
            height: 5.5rem;
            padding: 1.813rem 1.5rem;
            background-color: $white;
            border-radius: 0.75rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: all 0.5s ease;
            margin: 0 0.313rem;

            &:hover {
              .banner-desktop-content {
                justify-content: flex-start;

                @media #{$vpTablet991} {
                  justify-content: space-between;
                }
              }
            }

            @media #{$vpTablet1024} {
              padding: 0 1rem;
            }

            @media #{$vpTablet991} {
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-between;
              height: 7.188rem;
              margin-right: 1.25rem;
              padding: 1rem;

              .body5 {
                display: block;
                text-align: left;
              }

              &:hover {
                @media #{$vpTablet991} {
                  .banner-desktop-content {
                    .body1 {
                      display: block;
                    }

                    .body5 {
                      display: none;
                    }
                  }
                }
              }
            }

            .banner-desktop-content {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .body1 {
                color: $baselineSecondary;
                margin: 0;
                // transition: all 0.35s ease-in-out;
                @media #{$vpMobile600} {
                  font-size: 1.125rem;
                }
              }

              // img {
              //   transition: all 0.35s ease-in-out;
              // }

              .body5 {
                display: none;
                margin: 0;
                text-align: left;
              }
            }

            &:hover {
              background-color: $secondarySemiLight;

              @media #{$vpTablet991} {
                background-color: $white;
              }

              img {
                margin-right: 1.25rem;
                @media #{$vpTablet991} {
                  margin-right: 0;
                }
              }

              .banner-desktop-content {
                .body1 {
                  display: none;
                  // transform: scale(1.1);
                  @media #{$vpTablet991} {
                    display: block;
                  }
                }

                // img {
                //   transform: scale(1.1);
                // }

                .body5 {
                  display: block;
                  color: $baselineSecondary;

                  @media #{$vpTablet991} {
                    display: none;
                  }
                }
              }
            }

            .banner-mobile-desription {
              display: none;

              .body5 {
                color: $baselineSecondary;
                margin: 0;
              }

              @media #{$vpTablet991} {
                display: block;
                width: 8.34rem;
              }
            }
          }
        }

        .slick-slider {
          .slick-list {
            overflow: visible;
          }
        }

        .custom-bullets {
          position: relative;

          .slick-dots {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            gap: 0.5rem;
            position: absolute;
            bottom: -1.875rem;
            right: 0;
            transform: translateX(-50%);
            left: 50%;
            line-height: normal;

            li {
              line-height: normal;

              button {
                width: 0.625rem;
                height: 0.625rem;
                font-size: 0;
                border-radius: 50%;
                padding: 0;
                border: 0.063rem solid #fff;
                background-color: transparent;
                transition: all 0.4s ease;
              }

              &.slick-active {
                button {
                  background-color: $white;

                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .video-bg-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    @media #{$vpMobile600} {
      cursor: none;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(10.35deg,
          rgba(0, 0, 0, 0.5) 43.98%,
          rgba(0, 0, 0, 0) 94.56%);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}