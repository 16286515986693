.role{
    background: $baselineSecondaryLight;
    .wrapper{
        display: flex;
        .left-info{
            width:40%;
        }
        .right-info{
            width: 60%;
            background: $white;
        }
    }
}