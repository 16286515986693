.editorial-media {
  color: $baselineSecondary;

  @media #{$vpMobile600} {
    padding-bottom: 2.5rem;
  }

  .wrapper {
    display: flex;

    @media #{$vpMobile600} {
      flex-direction: column;
    }

    .image {
      width: 50%;

      @media #{$vpMobile600} {
        width: 100%;
      }

      img {
        border-radius: 2rem 0 0 2rem;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media #{$vpMobile600} {
          border-radius: 2rem 2rem 0 0;
        }
      }
    }

    .content {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 3rem;
      border-radius: 0 2rem 2rem 0;
      background: $baselineSecondaryLight;

      @media #{$vpMobile600} {
        width: 100%;
        padding: 1.25rem;
        height: 23.125rem;
        border-radius: 0 0 2rem 2rem;
      }

      .top-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;

        .date {
          display: flex;
          justify-content: space-between;

          .tag {
            padding: 0.375rem 1rem;
            background: $secondarySemiLight;
            border-radius: 0.25rem;
          }
        }
      }
      .bottom-wrapper {
        margin-top: 1.5rem;
        a {
          display: block;
        }
      }
    }
  }
  &.dark-bg {
    .wrapper {
      .content {
        background-color: rgba($color: #fff, $alpha: 0.1);
        .top-wrapper {
          .date {
            .body3 {
              color: $white;
              &.tag {
                background-color: $white;
                color: #2c2e34;
              }
            }
          }
        }
      }
    }
  }
  &.gray-bg {
    .wrapper {
      .content {
        background-color: $white;
      }
    }
  }
}