.sec-alula {
  height: 100%;
  position: relative;
  z-index: 2;

  &::before {
    content: "";
    display: block;
    position: absolute;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(10.35deg,
        rgba(0, 0, 0, 0.5) 43.98%,
        rgba(0, 0, 0, 0) 94.56%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .video-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .wrapper-box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    @media #{$vpTablet767} {
      flex-direction: column;
    }

    .content-left {
      width: 30.4375rem;

      @media #{$vpTablet991} {
        width: 100%;
      }

      @media #{$vpTablet767} {
        margin-bottom: 1.875rem;
      }

      .heading3 {
        color: $white;
      }
    }

    .content-right {
      width: 69.5625rem;

      @media #{$vpTablet991} {
        width: 100%;
      }

      .card-wrapper {
        border: 0.063rem solid $white;
        border-radius: 2rem;


        @media #{$vpTablet767} {
          border-radius: 1rem;
        }

        .card-box {
          padding: 2rem;
          border-radius: 2rem;
          border-bottom: 0;
          color: $white;
          position: relative;

          @media #{$vpTablet767} {
            padding: 1.25rem;
            border-radius: 1rem 1rem 0 0;
          }

          .heading3 {
            margin-bottom: 0;
          }

          .body1 {
            opacity: 0;
            // height: 0;
            // display: -webkit-box;
            // -webkit-line-clamp: 1; 
            // -webkit-box-orient: vertical;
            // overflow: hidden;
            // text-overflow: ellipsis;
            min-height: 4rem; 
          }

          &:hover {
            background-color: $white;
            // transition: all 0.6s ease;
            @media #{$vpTablet1024} {
              background-color: initial;
            }
            .card-heading {
              .heading3 {
                color: $baselinePrimary;
                @media #{$vpTablet1024} {
                  color: $white;
                }
              }
            }

            .body1 {
              opacity: 1;
              color: $baselineSecondary;
              max-height: 7rem;
              /*rtl:raw:line-height: 1.4; */
              overflow-y: auto;
              width: 34.9375rem;
              margin: 2.5rem 0 0 auto;
              height: auto;
              @media #{$vpTablet1024} {
                width: auto;
                color: $white;
              }
              &::-webkit-scrollbar {
                display: none;
              }
            }

            .arrow-svg {
              svg {
                path {
                  stroke: $baselinePrimary;
                  @media #{$vpTablet1024} {
                    stroke: $white;
                  }
                }
              }
            }

            .heading4 {
              color: $darkGold;
            }

            .heading3 {
              color: $baselineSecondary;
              @media #{$vpTablet1024} {
                color: $white;
              }
            }
          }

          &.active {
            background-color: $white;
            border-bottom: 0.063rem solid;


            @media #{$vpTablet767} {
              padding: 1.25rem;
              border-radius: 1rem;
              border-bottom: 0;

            }

            .card-heading {
              .heading3 {
                color: $baselinePrimary;
              }
            }

            .arrow-svg {
              svg {
                path {
                  stroke: $baselinePrimary;
                }
              }
            }

            .heading4 {
              color: $darkGold;
            }

            .heading3 {
              color: $baselineSecondary;
            }

            .body1 {
              opacity: 1;
              height: 100%;
              color: $baselineSecondary;
              max-height: 7rem;
              /*rtl:raw:line-height: 1.4; */
              overflow-y: auto;
              width: 34.9375rem;
              margin: 2.5rem 0 0 auto;

              @media #{$vpTablet1024} {
                width: 100%;
                max-height: 6.25rem;
                margin-top: 3.125rem;
              }
              @media #{$vpMobile600} {
                font-size: 1.125rem;
              }
            }


          }









        }

        &.hover0 {
          .card-box {
            &:nth-child(2) {
              border-bottom: 0.063rem solid $white;

              @media #{$vpTablet767} {
                border-radius: 1rem
              }
            }
          }
        }

        &.hover1 {
          .card-box {
            &:nth-child(2) {
              border-top: 0.063rem solid $white;
            }

          }
        }

        &.hover2 {
          .card-box {
            &:nth-child(2) {
              border-top: 0.063rem solid $white;
            }
          }
        }


      }

      .card-heading {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .arrow-svg {
          fill: $white;

          svg {
            width: 1.5rem;
            height: 1.5rem;

            /*rtl:raw:transform: rotateY(190deg) */
            @media #{$vpTablet767} {
              width: 1rem;
              height: 1rem;
            }
          }
        }
      }
    }
  }

  @media #{$vpMobile600} {
    height: 55.75rem;
  }
  &.dark-bg {
    .wrapper-box {
      .content-right {
        .card-wrapper {
          .card-box {
            .body1 {
              color: $white;
            }
            &.active {
              background-color: #1D1E20;
              .heading3 {
                color: $white;
                .card-content {
                  .body1 {
                    color: $white;
                  }
                }
              }
            }
            &:hover {
              .body1 {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}