.TandC {
  .title {
    padding: 4rem 0 8.75rem 0;

    @media #{$vpMobile600} {
      padding: 5rem 0 5rem;
    }

  }

  .content-wrapper {

    .row1 {
      padding-top: 12.5rem;

      &:first-child {
        padding-top: 0;
      }

      .inner-content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 -0.75rem;

        .wrap {
          max-width: 50%;
          flex: 0 0 50%;
          padding-top: 0.625rem;

          .body1 {
            margin: 0 0.75rem;
            padding-top: 1.875rem;
          }

          @media #{$vpMobile600} {
            max-width: 100%;
            flex: 0 0 100%;
          }

        }

        @media #{$vpMobile600} {
          flex-direction: column;
        }

      }

      @media #{$vpMobile600} {
        padding-top: 5rem;
      }
    }
  }
}