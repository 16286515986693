
.header {
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%);
  z-index: 800;
  position: fixed;
  width: 100%;
  transition: all 0.5s ease;

  a {
    font-weight: 300;
  }

  .ham-open-close {
    img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(4%) saturate(578%) hue-rotate(243deg) brightness(116%) contrast(100%);
      width: 1.875rem;
      height: 1.875rem;
    }
    .insvg {
      width: auto;
      height: 30px;
    }
  }

  &:hover {
    .hamburger-search-wrapper {
      img {
        filter: brightness(0) saturate(100%) invert(15%) sepia(4%) saturate(1538%) hue-rotate(188deg) brightness(94%) contrast(90%);
        width: 2rem;
        height: 2rem;
      }

      .ham-open-close {
        img {
          filter: brightness(0) saturate(100%) invert(15%) sepia(4%) saturate(1538%) hue-rotate(188deg) brightness(94%) contrast(90%);

          &.hamburger-mobile {
            width: 1.875rem;
            height: 1.875rem;
          }

          &.hamburger-close {
            width: 1.875rem;
            height: 1.875rem;
          }
        }
      }
    }
  }

  .header-logo {
    width: 14.70638rem;
    height: 2.88094rem;
    opacity: 0;
    transition: all 0.4s ease-out;

    &.show {
      opacity: 1;
      transition: all 0.4s ease-out;
    }

    @media #{$vpMobile600} {
      display: flex;
      align-items: center;
      width: 9.49913rem;
      height: 1.86088rem;
    }

    svg {
      width: 14.70638rem;
      height: 2.88094rem;

      rect {
        fill: $white !important;
      }

      path {
        fill: $white !important;
      }
      polygon {
        fill: $white !important;
      }

      @media #{$vpMobile600} {
        width: 9.49913rem;
        height: 1.86088rem;
      }
    }
  }

  .mbl-menu-wrapper {
    display: none;
    position: absolute;
    width: 100%;
    height: 100vh;
    background: $white;
    top: 6.875rem;
    left: 0;
    right: 0;
    padding: 0 1.25rem 1rem;
    transform: translateX(100%);
    transition: all 0.5s ease-out;
    overflow: scroll;

    .mbl-menu {
      height: auto;
      overflow: auto;
      margin-bottom: 60px;

      .mbl-third-level-menu {
        a {
          font-size: 0.875rem;
        }
      }
    }

    &.active {
      transform: translateX(0);
    }

    .lang-contact-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1.875rem;
      gap: 1.25rem;

      a {
        color: $baselineSecondary;
        background-color: #f8f6f2;
        padding: 1.5rem;
        border-radius: 0.5rem;
        height: 3.5rem;
        display: flex;
        align-items: center;
        width: 50%;
        justify-content: center;
        gap: 0.5rem;

        img {
          filter: brightness(0) saturate(100%) invert(15%) sepia(4%) saturate(1538%) hue-rotate(188deg) brightness(94%) contrast(90%);
          width: 0.938rem;
        }
        .menu-dropdown-icon {
          path {
            fill: #2c2e34;
          }
        }
      }
      .mob-lang-dropdown{
        margin-top: 4.375rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        padding: 0 1.25rem;
        visibility: hidden;
        opacity: 0;
        z-index: 2;
        .dropdowm--wrapper{
          background-color: #f8f6f2;
          padding: 0.5rem 1rem;
          border-radius: 0.5rem;
          ul{
            li{
              padding: 1.25rem 0;
              width: 100%;
              border-bottom: 0.063rem solid $neutralGray200;
              a{
                border-bottom: 0;
                padding: 0;
                display: inline-block;
                height: 100%;
                width: 100%;
                &.active{
                  position: relative;
                  &::after{
                    content: '';
                    position: absolute;
                    top: 0;
                    right: auto;
                    background-image: url('/assets/img/icon/check.png');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 1rem;
                    height: 1rem;
                    margin-left: 0.5rem;
                    margin-top: 0.25rem;
                  }
              }
            }
            &:last-child{
              border-bottom: 0;
            }
          }
        }
      }
      // @media #{$vpTablet991} {
      //   margin-top: 3.438rem;
      // }
    }
    }

    .mbl-menu {
      .active {
        border-bottom: 0.063rem solid $baselinePrimary;
        color: $baselinePrimary;
        transition: all 0.4s ease-out;

        svg {
          path {
            fill: $baselinePrimary;
          }
        }
      }

      .gray {
        border-bottom: 0.063rem solid $neutralGray300;
        color: $neutralGray300;
        transition: all 0.4s ease-out;

        svg {
          path {
            fill: $neutralGray300;
          }
        }
      }

      .black-text {
        border-bottom: 0.063rem solid $neutralGray200;
        color: $baselineSecondary;
        transition: all 0.4s ease-out;

        svg {
          path {
            fill: $baselineSecondary;
          }
        }
      }

      ul {
        display: flex;
        flex-direction: column;

        .active {
          >a {
            color: $baselinePrimary;
            border-bottom: 0.063rem solid $baselinePrimary;

            img {
              filter: invert(50%) sepia(6%) saturate(3676%) hue-rotate(7deg);
              transition: all 0.4s ease-out;
            }
          }
        }

        .rotate {
          transform: rotate(180deg);
          transition: all 0.4s ease-out;
        }

        .mbl-second-level-menu,
        .mbl-third-level-menu,
        .mbl-fourth-level-menu,
        .mbl-fifth-level-menu,
        .mbl-living-second,
        .mbl-business-second,
        .mbl-partners-second,
        .mbl-about-second,
        .mbl-media-second,
        .mbl-contact-second {
          padding-left: 0.938rem;
          display: none;
          transition: all 0.4s ease-out;
          pointer-events: none;

          li {
            height: 0;
          }

          &.show-submenu {
            display: block;
            transition: all 0.4s ease-out;
            pointer-events: all;

            li {
              height: auto;
            }
          }
        }

        a {
          color: $baselineSecondary;
          border-bottom: 0.063rem solid $neutralGray200;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.938rem 0;
          transition: all 0.4s ease-out;
          @media #{$vpMobile600} {
            font-size: 1rem;
          }
          &:hover {
            border-bottom: 0.063rem solid $baselinePrimary;
            transition: all 0.4s ease-out;
            color: $baselinePrimary;

            svg {
              transition: all 0.4s ease-out;

              path {
                fill: $baselinePrimary;
              }

              &.rotate {
                transform: translate(3px, 0) rotate(180deg);
                transition: all 0.4s ease-out;
              }
            }
            @media #{$vpTablet1024} {
              color: $baselineSecondary !important;
              border-bottom: 0.063rem solid $neutralGray200;
              svg {
                transition:none;
  
                path {
                  fill: #2c2e34;
                }
  
                &.rotate {
                  transform: none;
                  transition: all 0.4s ease-out;
                }
              }
            }
          }
          @media #{$vpTablet1024} {
           &.active {
            border-bottom: 0.063rem solid $baselinePrimary;
            transition: all 0.4s ease-out;
            color: $baselinePrimary !important;
            svg {
              transition: all 0.4s ease-out;

              path {
                fill: $baselinePrimary;
              }

              &.rotate {
                transform: translate(3px, 0) rotate(180deg);
                transition: all 0.4s ease-out;
              }
            }
           } 
          }
          svg {
            width: 0.75rem;
            transition: all 0.4s ease-out;
            margin-right: 10px;
            path {
              fill: $baselineSecondary;
            }
          }
        }
      }
    }

    .mbl-eservice-btn {
      text-align: center;
      padding: 3.125rem 0;
      position: relative;
      bottom: 60px;
      left: 0;

      a {
        color: $baselineSecondary;
        border: 0.063rem solid $baselinePrimary;
        padding: 0.938rem 0;
        border-radius: 12.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.625rem;
        @media #{$vpMobile600} {
          font-size: 1rem;
        }
        img {
          filter: brightness(0) saturate(100%) invert(15%) sepia(4%) saturate(1538%) hue-rotate(188deg) brightness(94%) contrast(90%);
          width: 1rem;
          height: 1.063rem;
        }
        .insvg {
          path {
            fill: #2c2e34;
          }
        }
      }
    }

    @media #{$vpTablet1024} {
      display: block;
    }
  }

  &:hover {
    background: $white;
    .e-service-btn {
      .insvg {
        path {
          fill: #2c2e34;;
        }
      }
    }
    .menu-link {
      .search-btn-header {
        .insvg {
          path {
            fill: #2c2e34;
          }
        }
      }
    }
    .header-logo {
      svg {
        rect {
          fill: #BA9863 !important;
        }

        path {
          fill: #BA9863 !important;
        }
        polygon {
          fill: #BA9863 !important;
        }
      }
    }

    .menu-link a {
      color: $baselineSecondary ;

      .menu-dropdown-icon {
        filter: brightness(0) saturate(100%) invert(14%) sepia(16%) saturate(425%) hue-rotate(187deg) brightness(91%) contrast(88%);
      }
    }

    .e-service-btn,
    .search-btn-header {
      // border: 0.063rem solid $baselineSecondaryLight !important;
      background: $baselineSecondaryLight;
      img {
        filter: brightness(0) saturate(100%) invert(14%) sepia(16%) saturate(425%) hue-rotate(187deg) brightness(91%) contrast(88%);
      }
    }
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .hamburger-close {
      display: none;
    }

    .hamburger-search-wrapper {
      display: none;

      @media #{$vpTablet1024} {
        display: flex;
        align-items: center;
        gap: 1.25rem;
      }
      @media #{$vpMobile390} {
        max-width: 22%;
      }
    }

    .header-listing {
      display: flex;
      align-items: center;
      justify-content: center;

      @media #{$vpTablet1024} {
        display: none;
      }

      ul.main-menu {
        display: flex;
        align-items: center;

        .menu-link {
          margin: 0 0.75rem;
          height: 100%;
          display: flex;
          align-items: center;

          .search-btn-header {
            padding: 1.2rem 1.5rem;
            border: 0.063rem solid $white;
            border-radius: 12.5rem;
            &:hover {
              background-color: transparent;
            border: 0.063rem solid $baselinePrimary;
            }
            img {
              width: 1rem;
              height: 1rem;
            }
          }

          .mega-menu-open.mega-menu {
            display: block;
          }

          .main-mega-menu {
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;

            .mega-menu {
              position: absolute;
              width: 100%;
              top: 4.688rem;
              left: 0;
              background-color: $white;
              display: none;
              border-top: 0.063rem solid $secondarySemiLight;
              // height: 18rem;
              // overflow-y: scroll;
              .ul-wrapper {
                // display: none;
                visibility: hidden;
                pointer-events: none;
                opacity: 0;
                height: 0;
                animation: fade-out 500ms forwards;
                padding: 0 1.5rem;
                @keyframes fade-out {
                  0% {
                    opacity: 1;
                  }

                  100% {
                    opacity: 0;
                  }
                }

                &.hideAnimation {
                  animation: fade-out 500ms forwards;
                }

                .has-subitems-2 {
                  .menu-dropdown-icon {
                    /*rtl:raw:margin-top: -0.188rem*/
                  }
                }
              }

              .showCol {
                &.ul-wrapper {
                  border-left: 0.063rem solid $secondarySemiLight;
                  // padding: 0 1.5rem;
                  visibility: visible;
                  pointer-events: all;
                  opacity: 1;
                  height: 100%;
                  animation: fade-in 500ms forwards;

                  @keyframes fade-in {
                    0% {
                      opacity: 0;
                    }

                    100% {
                      opacity: 1;
                    }
                  }
                }

                &.border-left {
                  border-left: 0.063rem solid $secondarySemiLight;
                }
              }

              .mega-menu-wrapper {
                display: flex;
              }

              .mega-menu-col {
                width: 25%;
                padding: 0 3.75rem 0 0;

                .ul-wrapper-first {
                  .has-subitems-1 {
                    .menu-dropdown-icon {
                      /*rtl:raw:margin-top: -0.188rem*/
                    }
                  }
                }

                &.mega-col-3 {
                  padding: 0 1.25rem;
                }

                &.mega-col-4 {
                  padding: 0;
                }

                &.mega-col-2.showCol2Ul {
                  padding: 0 1.5rem;
                }

                &.mega-col-3.showCol3Ul {
                  padding: 0 1.5rem;
                }

                &.mega-col-4.showCol4Ul {
                  padding: 0 1.5rem;
                }

                .hideDiv {
                  display: none;
                  transition: all 0.25s ease-out;
                }

                .image-text-wrapper {
                  position: relative;
                  transition: all 0.25s ease-out;

                  img {
                    height: 12.5rem;
                    width: 43.125rem;
                    object-fit: cover;
                    border-radius: 0.75rem;
                  }

                  h5 {
                    position: absolute;
                    color: $white;
                    bottom: 0;
                    left: 0.625rem;
                  }
                }

                ul {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 0.875rem;

                  li {
                    display: inline-block;
                    width: 100%;


                    .active {
                      a {
                        color: $baselinePrimary !important;

                        img {
                          filter: invert(50%) sepia(6%) saturate(3676%) hue-rotate(7deg);
                        }
                      }
                    }

                    a {
                      color: $baselineSecondary;

                      img {
                        width: 0.938rem;
                        transform: rotate(-90deg);
                        margin-top: 0.188rem;
                        filter: brightness(0) saturate(100%) invert(13%) sepia(9%) saturate(767%) hue-rotate(188deg) brightness(98%) contrast(87%);
                      }

                      &:hover {
                        color: $baselinePrimary !important;
                      }
                    }
                  }
                }
              }
            }
          }

          &:hover {
            .menu-dropdown-icon {
              transform: translate(3px, 0px) rotate(180deg);
            }
          }

            .e-service-btn {
              padding: 1.3125rem 2rem;
              gap: 0.625rem;
              border: 0.063rem solid $white;
              border-radius: 12.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
              line-height: normal;
              flex-direction: row-reverse;
              &:hover {
                background-color: transparent;
                border: 0.063rem solid rgba(159, 131, 50, 0.1);
              }
              .insvg {
                width: 1rem;
                height: 1rem;
              }
            }
          .menu-dropdown-icon {
            width: 1.25rem;
            height: 1.25rem;
            transition: all 0.35s ease-out;
            fill: $white;
            width: 1rem;
            height: 1rem;
          }
          &:hover {
            .menu-dropdown-icon {
              transform: rotateZ(180deg);
            }
          }
          &.lang-link{
            .main-mega-menu{
              .mega-menu{
                .mega-menu-wrapper{
                  .mega-menu-col{
                    .ul-wrapper-first{
                      ul{
                        li{
                          .lang--switch{
                            &.active{
                              position: relative;
                              &::after{
                                content: '';
                                position: absolute;
                                top: 0;
                                right: -1.5rem;
                                background-image: url('/assets/img/icon/check.png');
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: contain;
                                width: 1rem;
                                height: 1rem;
                                margin-left: 0.25rem;
                                // margin-top: 0.625rem;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.header-scrolled {
    background: $white;
    z-index: 1100;
    position: fixed;
    top: 0;

    .header-wrapper {
      .header-logo {
        svg {
          rect {
            fill: #BA9863 !important;
          }

          path {
            fill: #BA9863 !important;
          }
          polygon {
            fill: #BA9863 !important;
          }
        }
      }

      .header-listing {
        ul.main-menu {
          .menu-link {
            a {
              color: $baselineSecondary;

              // img {
              //   filter: brightness(0) saturate(100%) invert(14%) sepia(16%) saturate(425%) hue-rotate(187deg) brightness(91%) contrast(88%);
              // }
              .insvg {
                filter: brightness(0) saturate(100%) invert(14%) sepia(16%) saturate(425%) hue-rotate(187deg) brightness(91%) contrast(88%);
                path {
                  fill: #2c2e34;
                }
              }
            }

            .e-service-btn {
              background: $baselineSecondaryLight;
              &:hover {
                background-color: transparent;
                // border: 0.63rem solid $baselinePrimary;
              }
              .insvg {
                path {
                  fill: #2c2e34;
                }
              }
            }
            .search-btn-header {
              background: $baselineSecondaryLight;
              &:hover {
                background-color: transparent;
                border: 0.63rem solid $baselinePrimary;
              }
              .insvg {
                path {
                  fill: #2c2e34;
                }
              }
            }
          }

          &.has-dropdown {
            /*rtl:raw:margin-top: -0.188rem*/
          }
        }
      }

      .hamburger-search-wrapper {
        img {
          filter: brightness(0) saturate(100%) invert(14%) sepia(16%) saturate(425%) hue-rotate(187deg) brightness(91%) contrast(88%);
          width: 1.875rem;
          height: 1.875rem;
        }
        .insvg {
          path {
            fill: #2c2e34;
          }
        }
      }

      .ham-open-close {
        .insvg {
          filter: brightness(0) saturate(100%) invert(14%) sepia(16%) saturate(425%) hue-rotate(187deg) brightness(91%) contrast(88%);
        }
        .insvg {
          path {
            fill: #2c2e34;
          }
        }
      }
    }

    
  }
  &.dark-bg {
    .header-wrapper {
      .header-listing {
        ul {
          &.main-menu {
            .menu-link {
              a {
                color: $white;
                .insvg {
                  path {
                    fill: $white;
                  }
                }
              }
              .e-service-btn {
                background-color: rgba($color: #fff, $alpha: 0.1);
                .insvg {
                  path {
                    fill: $white;
                  }
                }
              }
              .search-btn-header {
                background-color: rgba($color: #fff, $alpha: 0.1);
                .insvg {
                  path {
                    fill: $white;
                  }
                }
              }
              .main-mega-menu {
                .mega-menu {
                  background-color: #000;
                  .mega-menu-col {
                    ul {
                      li {
                        a {
                          color: $white;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .mbl-menu-wrapper {
      background: #000;
      .lang-contact-wrapper {
        a {
          background-color: rgba($color: #fff, $alpha: 0.1);
          color: $white;
          position: relative;
         
      }
     
    }
      .mbl-menu {
        ul {
          a {
            color: $white;
          }
        }
      }
      .mbl-eservice-btn {
        a {
          color: $white;
          .insvg {
            path {
              fill: #fff;
            }
          }
        }
      }
    }
  }
}

.header-light {
  header {
    background: $white;
    &:hover {
      border-bottom: none;
    }
    .header-wrapper {
      .header-logo {
        rect {
          fill: #BA9863 !important;
        }
        path {
          fill: #BA9863 !important;
        }
        polygon {
          fill: #BA9863 !important;
        }
      }
      .header-listing {
        ul.main-menu {
          .menu-link {
            a {
              color: $baselineSecondary;
              svg {
                path {
                  fill: #2c2e34;
                }
              }
              img {
                filter: brightness(0) saturate(100%) invert(14%) sepia(16%) saturate(425%) hue-rotate(187deg) brightness(91%) contrast(88%);
              }
            }
            .e-service-btn,
            .search-btn-header {
              background: $baselineSecondaryLight;
              &:hover {
                background: $baselineSecondaryLight;
                border: 1px solid rgba(159, 131, 50, .1);
              }
            }
          }
        }
      }

      .hamburger-search-wrapper {
        img {
          filter: brightness(0) saturate(100%) invert(14%) sepia(16%) saturate(425%) hue-rotate(187deg) brightness(91%) contrast(88%);
          width: 1.875rem;
          height: 1.875rem;
        }
      }

      .ham-open-close {
        img {
          filter: brightness(0) saturate(100%) invert(14%) sepia(16%) saturate(425%) hue-rotate(187deg) brightness(91%) contrast(88%);
        }
      }
    }

    // &:hover {

    //   .e-service-btn,
    //   .search-btn-header {
    //     border: none !important;
    //   }
    // }
  }

}

.menuOpen {
  .header {
    .header-wrapper {
      .header-listing {
        ul {
          &.main-menu {
            .menu-link {
              .mega-menu {
                &.hide {
                  display: none;

                  &.show--menu {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.main-mega-menu {
  opacity: 0;
  height: 0;
  transition: all 0.4s ease-out;
}

.menuOpen {
  .main-mega-menu {
    opacity: 1;
    height: auto;
    transition: all 0.4s ease-out;
  }
}

.components-listing {
  .component-title {
    text-align: center;
    margin: 0;
  }

 
}
body {
  &.active {
    .header {
      .header-wrapper {
        .hamburger-close {
          display: block;
        }
        .hamburger-mobile {
          display: none;
        }
      }
    }
  }
}

html[lang="fr"] {
  .header {
    .header-wrapper {
      .header-listing {
        ul.main-menu {
          .menu-link {
            margin: 0 0.5rem;
              .e-service-btn {
                padding: 1.3125rem 1.5rem;
              }
          }
        }
      }
    }
  }
}