.hero-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  color: $white;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;

  &.sec-visiting-alula-hero {
    .wrapper {
      &::before {
        background: linear-gradient(90deg,
            rgba(0, 0, 0, 0.3) 0%,
            rgba(0, 0, 0, 0) 64.04%);
      }
    }

    .hero-content {
      position: relative;
      z-index: 1;
      height: 100%;
    }
  }

  @media #{$vpMobile600} {
    padding: 0rem 0rem 5rem 0rem;
    align-items: flex-end;
  }

  .wrapper {
    width: 100%;
    height: 100%;

    .hero-content {
      z-index: 2;
      position: relative;
      height: 100%;
    }

    .banner-img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      background: linear-gradient(0deg,
          rgba(0, 0, 0, 0.25),
          rgba(0, 0, 0, 0.25)),
        linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 64.04%);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }

    .title-wrap {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 12.5rem;
      gap: 4rem;

      @media #{$vpMobile600} {
        bottom: 5rem;
        gap: 2rem;
      }

      h1 {
        width: 60.75rem;
        margin: 0;

        @media #{$vpMobile600} {
          width: 100%;
        }
      }

      p {
        width: 44.875rem;
        margin: 0;

        @media #{$vpMobile600} {
          width: 100%;
        }
      }
    }

    .report-download {
      display: flex;
      border: 0.063rem solid $white;
      border-radius: 1rem;
      width: 25.1875rem;
      height: 20.3125rem;

      @media #{$vpMobile600} {
        width: 21.875rem;
        height: 100%;
      }

      .report {
        padding: 1.5rem;
        border-radius: 1rem;
        width: 14.0625rem;
        height: 20.3125rem;
        display: flex;
        align-items: center;
        justify-content: end;
        position: relative;
        cursor: pointer;

        &.active {
          cursor: auto;
        }

        @media #{$vpMobile600} {
          width: 12.21306rem;
          height: 13.5rem;
        }

        .download-div {
          background: $baselineSecondaryLight;
          height: 100%;
          color: $baselineSecondary;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          border-radius: 1rem;
          display: none;
          justify-content: space-between;
          flex-direction: column;
          padding: 1.5rem;

          .heading-4 {
            line-height: 2.6rem;
          }

          &.active {
            display: flex;
          }

          img {
            width: 1.27606rem;
            height: 1.27606rem;
          }

          button {
            border: none;
            background: $white;
            border-radius: 12.5rem;
            padding: 1rem 2rem;
          }
        }

        &:nth-child(1) {
          z-index: 3;
          border-right: 0.063rem solid #fff;
        }

        &:nth-child(2) {
          z-index: 2;
          margin-left: -6.25rem;
          border-right: 0.063rem solid #fff;
        }

        &:nth-child(3) {
          z-index: 1;
          margin-left: -6.25rem;
        }
      }
    }

    .video-bg-banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;

      &::before {
        content: "";
        display: block;
        position: absolute;
        background: linear-gradient(0deg,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)),
          linear-gradient(10.35deg,
            rgba(0, 0, 0, 0.5) 43.98%,
            rgba(0, 0, 0, 0) 94.56%);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &.gray-bg {
    .wrapper {
      .title-wrap {
        h1 {
          color: $baselineSecondary;
        }
        p {
          color: $baselineSecondary;
        }
      }
    }
  }
  &.sec-initatives-hero{
    @media #{$vpMobile600} {
      padding: 0;
    }
  }
}

.sec-annual-report-hero {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  width: 100%;
  color: $white;
  display: flex;
  align-items: center;
  z-index: 1;
  position: relative;

  .wrapper {
    width: 100%;

    .banner-img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      background: linear-gradient(0deg,
          rgba(0, 0, 0, 0.25),
          rgba(0, 0, 0, 0.25)),
        linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 64.04%);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }

    .hero-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 2;
      position: relative;

      .title-wrap {
        width: 60.75rem;
        display: flex;
        flex-direction: column;
        gap: 4rem;

        @media #{$vpMobile600} {
          width: 100%;
          gap: 2rem;
        }

        .heading1 {
          @media #{$vpMobile600} {
            font-size: 2.5rem;
            line-height: 3.25rem;
            margin: 0;
            /*rtl:raw: line-height: 4rem; */
          }
        }

        .body3 {
          max-width: 80%;

          @media #{$vpMobile600} {
            max-width: 100%;
            margin: 0;
          }
        }
      }

      @media #{$vpMobile600} {
        flex-direction: column;
        gap: 2rem;
        position: absolute;
        bottom: 2.5rem;
        left: 1.25rem;
        right: 1.25rem;
      }

      .report-download {
        display: flex;
        border: 0.063rem solid $white;
        border-radius: 1rem;
        width: 25.1875rem;
        height: 20.3125rem;

        @media #{$vpMobile600} {
          width: 21.875rem;
          height: 100%;
        }

        .report {
          padding: 1.5rem;
          border-radius: 1rem;
          width: 14.0625rem;
          height: 20.3125rem;
          display: flex;
          align-items: center;
          justify-content: end;
          position: relative;
          cursor: pointer;
          transition: all 0.4s ease-out;

          &.active {
            cursor: auto;
            transition: all 0.4s ease-out;
            @media #{$vpMobile600} {
              width: 13.54rem;
            }
          }

          @media #{$vpMobile600} {
            width: 12.21306rem;
            height: 13.5rem;
          }

          .download-div {
            background: $baselineSecondaryLight;
            height: 100%;
            color: $baselineSecondary;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-radius: 1rem;
            justify-content: space-between;
            flex-direction: column;
            padding: 1.5rem;
            display: none;

            .heading-4 {
              line-height: 2.6rem;
            }

            &.active {
              display: flex;
            }

            img {
              width: 1.27606rem;
              height: 1.27606rem;
            }

            button {
              border: none;
              background: $white;
              transition: all 0.35s ease;
              border-radius: 12.5rem;
              padding: 1rem 2rem;
              color: #2c2e34;
              &:hover {
                background: #f2efe7;
                color: #2c2e34;
              }
            }
          }

          &:nth-child(1) {
            z-index: 3;
            border-right: 0.063rem solid #fff;
          }

          &:nth-child(2) {
            z-index: 2;
            margin-left: -6.25rem;
            border-right: 0.063rem solid #fff;
          }

          &:nth-child(3) {
            z-index: 1;
            margin-left: -6.25rem;
          }
        }
      }
    }
  }

  &.variant {
    .wrapper {
      .banner-img {
        opacity: 0;
        transition: all 0.4s ease-out;

        &.active-banner {
          opacity: 1;
          transition: all 0.4s ease-out;
        }
      }
    }
  }
}