.sec-royal-commission {
  .sec-wrapper {
    padding-bottom: 6.25rem;
    @media #{$vpMobile600} {
      padding-bottom: 2rem;
    }
    .sec-heading-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0 3.125rem;

      @media #{$vpTablet991} {
        flex-wrap: wrap;
      }

      @media #{$vpMobile600} {
        padding: 1rem 0 1.5rem;
      }

      .sec-heading-inner {
        width: 43rem;

        @media #{$vpTablet991} {
          width: 100%;
        }

        .heading2 {
          @media #{$vpMobile600} {
            margin: 0;
          }
        }
      }

      .sec-para-inner {
        width: 51.875rem;

        @media #{$vpTablet991} {
          width: 100%;
        }
      }
    }

    .slider-wrap {
      display: flex;
      // flex-wrap: wrap;
      width: auto;

      @media #{$vpMobile600} {
        flex-direction: column-reverse;
        width: 100%;
      }

      .royal-commission-main {
        width: 30.313rem;
        z-index: 2;
        background-color: $white;
        border-top-left-radius: 1.25rem;
        border-bottom-left-radius: 1.25rem;


        @media #{$vpMobile600} {
          width: 100%;
          border-bottom-right-radius: 0.75rem;
          border-bottom-left-radius: 0.75rem;
        }


        .slick-list {
          height: 100%;
        }

        .slick-track {
          height: 100% !important;
        }

        .sec-post-content-box {
          .sec-post-content-info {
            transform: translateX(100%);
            transition: all 1s ease-in-out;
            opacity: 1;
            height: 100%;
            background-color: $secondaryLight;
            padding: 2rem 3.75rem 2rem 2rem;
            border-top-left-radius: 1.25rem;
            border-bottom-left-radius: 1.25rem;
            display: flex;
            flex-direction: column;

            @media #{$vpMobile600} {
              border-bottom-left-radius: 0.75rem;
              border-bottom-right-radius: 0.75rem;
              border-top-left-radius: 0;
              padding: 1.25rem;
              margin-bottom: 0;
              transform: translateX(0);
            }

            p {
              margin: 0;
              &.is--trimmed {
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                display: -webkit-box;
              }
            }

            &.opportunities-content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
            }

            img {
              width: 1.5rem;
              height: auto;
              margin-bottom: 3.25rem;

              @media #{$vpMobile600} {
                display: none;
              }
            }

            .heading3 {
              margin-bottom: 2.5rem;
              width: 18.75rem;

              @media #{$vpMobile600} {
                margin-bottom: 1rem;
                width: 100%;
              }
            }

            .main-primary-btn {
              margin-top: 2.5rem;
              padding: 0.75rem 1.5rem;
              max-width: max-content;
              display: flex;
              align-items: center;
              @media #{$vpMobile600} {
                padding: 0.5rem 1.12rem;

              }
              .insvg {
                display: none;
                margin-left: 0.625rem;
                @media #{$vpMobile600} {
                  display: block;
                }
              }
              &.with-ic{
                span{
                  &:last-child{
                    padding-left: 0;
                  }
                }
              }
            }
          }
        }

        .slick-slide {
          &.slick-active {
            +.slick-slide {
              .sec-post-content-info {
                transform: translateX(100%);
                opacity: 1;

                @media #{$vpMobile600} {
                  transform: translateX(0);
                }
              }
            }

            .sec-post-content-info {
              transform: translateX(0);
              opacity: 1;
              max-height: 33.125rem;
              min-height: 33.125rem;
              @media #{$vpMobile600} {
                max-height: 100%;
                min-height: 100%;
              }
              li {
                margin-bottom: 10px;
              }
            }
          }
        }

        .slick-dots {
          justify-content: center;
          @media #{$vpMobile600} {
          }
        }
      }

      .royal-commission-nav {
        width: 30.3125rem;
        z-index: 0;

        @media #{$vpMobile600} {
          width: 100%;
          border-bottom-right-radius: 0;
        }

        .sec-post-img {
          width: 100%;
          position: relative;
          max-height: 33.125rem;
          min-height: 33.125rem;
          @media #{$vpMobile600} {
            max-height: 12rem;
            min-height: 12rem;
          }
          img {
            height: 100%;
          }
          .image-wrapper {
            height: 100%;
            img {
              border-radius: 1.25rem;
              height: 100%;
            }
          }
          .heading3 {
            position: absolute;
            bottom: 2.75rem;
            left: 2.5rem;
            color: $white;
            width: 18.75rem;

            @media #{$vpMobile600} {
              width: 100%;
            }
          }
        }

        .slick-list {
          overflow: visible;
          padding: 0 !important;
          height: 100%;
          margin-right: 1px;

          @media #{$vpMobile600} {
            overflow: hidden;
          }
        }

        .slick-track {
          height: 480px;
          @media #{$vpDesktop1440} {
            height: 350px;
          }
          @media #{$vpMobile600} {
            height: 100%;
          }
        }

        .slick-slide {
          padding: 0 1.25rem 0 0;
          height: 100%;
          .img-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% - 1.25rem);
            border-radius: 1rem;
            height: 100%;
            background: $white;
            opacity: 0.5;
            @media #{$vpMobile600} {
              width: 100%;
              border-radius: 0.75rem 0.75rem 0 0;
            }
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            max-height: 33.125rem;
            min-height: 33.125rem;
            border-radius: 1.25rem;
            @media #{$vpMobile600} {
              max-height: 12rem;
              min-height: 12rem;
            }
          }
          @media #{$vpMobile600} {
            padding: 0;
            border-top-left-radius: 0.75rem;
            border-top-right-radius: 0.75rem;
          }

          &.slick-active {
            img {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
              @media #{$vpMobile600} {
                border-bottom-right-radius: 0;
                border-top-left-radius: 0.75rem;
                border-top-right-radius: 0.75rem;
              }
            }

            .img-overlay {
              opacity: 0;
            }

            .heading3 {
              opacity: 0;
            }
          }

          &.prevSlide {
            // opacity: 0 !important;
            img {
              border-radius: 1.25rem;
              @media #{$vpMobile600} {
                border-top-left-radius: 0.75rem;
                border-top-right-radius: 0.75rem;
              }
            }
          }
        }
      }
    }
    .view-wrapper{
      position: relative;
     .main-secondary-btn{
      display: flex;
      margin-top: 3rem;

      @media #{$vpMobile600} {
          width: max-content;
          top: -25px;
          position: absolute;
          right: 0;
          margin-top: 0;
      }

     }
     .pagination--arrow{
      position: relative;
      padding-top: 3rem;
      .slider-arrow-pagination{
          padding-top: 0;
          .arrow-wrap{
              bottom: auto;
              top: 50%;
              transform: translateY(-50%);
          }
      }
     }
  }

    .slider-arrow-pagination {
      .arrow-wrap {
        width: 100%;
        bottom: -4.688rem;
        @media #{$vpTablet991} {
          bottom: -57px;
        }
      }
    }
  }

  &.about-the-research {
    background-color: $baselineSecondaryLight;

    .sec-wrapper {
      .sec-heading-box {
        align-items: flex-start;
        padding: 0 0 3rem;

        @media #{$vpMobile600} {
          padding: 0 0 1.5rem;
        }

        .sec-heading-inner {
          .heading2 {
            @media #{$vpMobile600} {
              margin-top: 0;
            }
          }
        }

        p {
          margin: 0;
        }
      }

      .slider-wrap {
        height: 30rem;

        @media #{$vpMobile600} {
          height: auto;
        }

        .royal-commission-main {
          background-color: $baselineSecondaryLight;



          .sec-post-content-box {
            .sec-post-content-info {
              background-color: $white;

              @media #{$vpMobile600} {
                padding: 1.25rem;
                padding-top: 3rem;
              }
            }
          }

          .slick-slide {
            .sec-post-content-info {
              background-color: $white;

              &.about-the-research-content {
                height: 100%;
                display: flex;

                .heading-wrapper {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;

                  @media #{$vpMobile600} {
                    justify-content: flex-start;
                  }

                  .heading3 {
                    width: 100%;
                  }

                  p {
                    margin: 0;

                    @media #{$vpMobile600} {
                      font-size: 0.875rem;
                    }
                  }
                }
              }
            }

            &.slick-active {
              .sec-post-content-info {
                background-color: $white;

                &.about-the-research-content {
                  height: 100%;
                  display: flex;

                  .heading-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;

                    @media #{$vpMobile600} {
                      justify-content: flex-start;
                    }

                    .heading3 {
                      width: 100%;
                    }

                    p {
                      margin: 0;

                      @media #{$vpMobile600} {
                        font-size: 0.875rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .royal-commission-nav {
          .sec-post-img {
            .img-overlay {
              background-color: $baselineSecondaryLight;
            }
          }
        }
      }
     
    }
  }

  &.sec-education-culture {
    .sec-wrapper {
      .slider-wrap {
        .royal-commission-main {


          .sec-post-content-box {
            .sec-post-content-info {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;

              .main-primary-btn {
                span {
                  img {
                    display: block;
                    width: 14px;
                    height: 14px;
                    margin-bottom: 0;
                  }
                }

                @media #{$vpMobile600} {
                  margin-top: 3.75rem;

                }
              }
            }
          }
        }
      }
    }
  }

  &.reports-slider {
    .sec-wrapper {
      .slider-wrap {
        .royal-commission-main {
          .sec-post-content-box {
            .sec-post-content-info {
              .heading3 {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  &.annual-report-2-opportunities {
    .sec-wrapper {
      .slider-wrap {
        .royal-commission-main {
          background-color: $white;

          .sec-post-content-info {
            .heading-wrapper {
              p {
                margin: 0;
                margin-bottom: 2.5rem;
                @media #{$vpMobile600} {
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }
          }

          .sec-post-content-box {
            .sec-post-content-info {
              background-color: $baselineSecondaryLight;

              .main-primary-btn {
                padding: 0.75rem 1.5rem;

                @media #{$vpMobile600} {
                  margin-top: 2.5rem;
                }
              }
            }
          }
        }

        .royal-commission-nav {
          .sec-post-img {
            img {
              background: $baselineSecondaryLight;
            }

            .heading3 {
              width: 18.75rem;

              @media #{$vpMobile600} {
                width: 100%;
              }
            }
          }

          .slick-slide {
            &.slick-active {
              img {
                background-color: $baselineSecondaryLight;
              }
            }
          }
        }
      }
      .slider-arrow-pagination {
        .arrow-wrap {
          bottom: -3rem;
        }
      }
    }
  }

  &.developmentPrinciple {
    padding-bottom: 0;

    .sec-wrapper {
      .sec-heading-box {
        .sec-heading-inner {
          width: 100%;
        }
      }

      .slider-wrap {
        margin-bottom: 3.438rem;
        @media #{$vpMobile600} {
          margin-bottom: 0;
        }
        .royal-commission-main {
          .sec-post-content-box {
            .sec-post-content-info {
              position: relative;

              .content-wrapper {
                position: absolute;
                bottom: 0;
                margin-bottom: 2rem;

                .heading3 {
                  width: 24.5rem;
                  margin-bottom: 0;

                  @media #{$vpMobile600} {
                    width: 100%;
                  }
                }

                @media #{$vpMobile600} {
                  position: initial;
                  margin-bottom: 2.5rem;
                }
              }

              @media #{$vpMobile600} {
                padding-top: 5.25rem;
                margin-bottom: 0;
              }
            }
          }

          .slick-dots {
            @media #{$vpMobile600} {
              margin-top: 1.5rem;
            }
          }
        }

        .royal-commission-nav {
          .slick-list {
            .slick-track {
              .sec-post-img {
                height: 30rem;

                .content-wrapper {
                  position: absolute;
                  bottom: 0;
                  padding: 2.5rem;

                  .heading3 {
                    position: initial;
                    bottom: 0;
                    left: 0;
                    width: 24.5rem;
                    margin-bottom: 0;

                    @media #{$vpMobile600} {
                      width: 100%;
                    }
                  }
                }

                @media #{$vpMobile600} {
                  height: 12rem;
                }
              }
            }
          }
        }
      }
      .slider-arrow-pagination {
        .arrow-wrap {
          bottom: 0.625rem;
        }
      }
    }
  }
  &.development-principles {
    .sec-wrapper {
      .royal-commission-nav {
        .slick-list {
          .slick-track {
            .sec-post-img {
              .content-wrapper {
                position: absolute;
                bottom: 2.5rem;
                left: 2.5rem;
                .heading3 {
                  width: 24.5rem;
                  margin-bottom: 0;
                  position: unset;
                  @media #{$vpMobile600} {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
      .slider-arrow-pagination{
        padding-top: 3.56rem;
      }
    }
    &.dark-bg {
        .sec-wrapper {
          .slider-wrap {
            .royal-commission-main {
              background-color: #120f14;
              .sec-post-content-box {
                .sec-post-content-info {
                  background-color: #120f14;
                  .insvg {
                    path {
                      fill: $white;
                    }
                  }
                }
              }
            }
            .royal-commission-nav {
              .sec-post-img {
                .image-wrapper {
                  .img-overlay {
                    background: #120f14;
                  }
                }
              }
            }
          }
        }
    }
  }
  &.gray-bg {
    .sec-wrapper {
      .slider-wrap {
        .royal-commission-main {
          .sec-post-content-box {
            .sec-post-content-info {
              background-color: $white;
              .main-primary-btn {
                border: .063rem solid #9f8332;;
              }
            }
          }
        }
        .royal-commission-nav {
          .slick-slide {
            &.slick-active {
              img {
                background-color: $white;
              }
            }
          }
        }
      }
    }
  }
}

.dark-bg {
  .sec-royal-commission {
    &.development-principles {
      &.sec-padded {
        padding-bottom: 4.75rem;
      }

      .sec-wrapper {
        .sec-heading-inner {
          width: 100%;
        }
      }
    }

    .sec-wrapper {
      .slider-wrap {
        .royal-commission-main {
          background-color: #120f14;



          .slick-track {
            @media #{$vpMobile600} {
              height: 100% !important;
            }
          }

          .sec-post-content-info {
            background-color: #17161b;

            .heading-wrapper {
              display: flex;
              flex-direction: column;

              .heading3 {
                margin-bottom: 0;
                width: 100%;
              }
            }

            svg {
              width: 1.5rem;

              path {
                fill: $white;
              }
            }
          }
        }

        .royal-commission-nav {
          .sec-post-img {
            .image-wrapper {
              position: relative;
              height: 100%;

              .img-overlay {
                border-radius: 1rem;
                background: #00000080;
              }
            }
          }
        }
      }
    }
  }
}

.dark-bg {
  &.sec-royal-commission {
    &.development-principles {
      &.sec-padded {
        padding-bottom: 4.75rem;

        @media #{$vpMobile600} {
          padding-bottom: 0;
        }
      }

      .sec-wrapper {
        .sec-heading-box {
          padding-top: 0;

          .sec-heading-inner {
            width: 100%;

            .heading2 {
              margin: 0;
            }
          }
        }
      }
    }
    .sec-wrapper {
      .slider-wrap {
        .royal-commission-main {
          background-color: #120f14;
          .slick-slide {
            &.slick-active {
              .sec-post-content-info {
                background-color: #120f14;
              }
            }
          }


          .slick-track {
            @media #{$vpMobile600} {
              height: 100% !important;
            }
          }

          .sec-post-content-info {
            background-color: #17161b;

            .heading-wrapper {
              display: flex;
              flex-direction: column;

              .heading3 {
                margin-bottom: 0;
                width: 100%;
              }
            }

            svg {
              width: 1.5rem;

              path {
                fill: $white;
              }
            }
          }
        }

        .royal-commission-nav {
          .sec-post-img {
            .image-wrapper {
              position: relative;
              height: 100%;

              .img-overlay {
                border-radius: 1rem;
                background: #00000080;
              }
            }
          }
        }
      }
    }
  }
}

body{
  &.darkTheme{
    .development-principles{
      &.gray-bg{
        background-color: transparent !important;
        .sec-wrapper {
          .slider-wrap {
            .royal-commission-main {
              background-color: #120f14;
              .slick-track {
                @media #{$vpMobile600} {
                  height: 100% !important;
                }
              }
    
              .sec-post-content-info {
                background-color: $baselineSecondary;
                svg{
                  path{
                    fill: $text-white;
                  }
                }
                .heading-wrapper {
                  .heading3 {
                    margin-bottom: 0;
                    width: 100%;
                    color: $text-white;
                  }
                }
              }
            }
    
            .royal-commission-nav {
              .sec-post-img {
                .image-wrapper {
                  position: relative;
                  height: 100%;
    
                  .img-overlay {
                    border-radius: 1rem;
                    background: #00000080;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
      &.sec-padded{
        padding-top: 8.94rem;
        @media #{$vpMobile600} {
          padding-top: 0;
        }
      }
    }
  }
}