.portfolio-detail-sec {
  padding: 5rem 0 12.5rem 0;

  .logo-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -1.25rem -0.625rem;

    .logo-grid {
      max-width: 25%;
      flex: 0 0 25%;
      border-radius: 1rem;
      padding: 1.25rem 0.625rem 0;

      .img-wrapper {
        overflow: hidden;
        border-radius: 1rem 1rem 0 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 1rem 1rem 0 0;
          @media #{$vpMobile600} {
            border-radius: 0.5rem 0.5rem 0 0;
          }
        }
        @media #{$vpMobile600} {
          border-radius: 0.5rem 0.5rem 0 0;
        }
      }

      .content-wrapper {
        padding: 1.5rem;
        background-color: $baselineSecondaryLight;
        border-radius: 0 0 1rem 1rem;

        .heading5 {
          margin: 0;
          padding-bottom: 1rem;

          @media #{$vpMobile600} {
            font-size: 1.25rem;
          }
        }

        .body3 {
          margin: 0;

          @media #{$vpMobile600} {
            font-size: 0.875rem;
          }
        }

        .btn-wrapper {
          margin-top: 1.5rem;

          a {
            padding: 0.75rem 1.5rem;
            display: block;

            @media #{$vpTablet767} {
              padding: 0.5rem 1.12rem;
            }
          }
        }

        @media #{$vpTablet767} {
          padding: 1.4rem;
          border-radius: 0 0 .5rem .5rem;
        }
      }

      @media #{$vpTablet991} {
        max-width: 33.33%;
        flex: 0 0 33.33%;
      }
      @media #{$vpMobile600} {
        max-width: 100%;
        flex: 0 0 100%;
        border-radius: 0.5rem;
      }
    }
  }

  .side-popup {
    max-width: 50.5rem;
    width: 100%;
    position: fixed;
    height: 100vh;
    top: 0;
    right: -56.25rem;
    /*rtl:raw:right: -56.25rem; */
    z-index: 9999;
    background: $baselineSecondaryLight;

    .popup-wrapper {
      height: 100%;
      .slick-list {
        height: 100%;
      }
      .slide {
        width: 100%;
        .img-box {
          position: relative;
          height: 30rem;
          @media #{$vpMobile600} {
            height: auto;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media #{$vpMobile600} {
              height: auto;

            }
          }

          .crossIcon {
            position: absolute;
            top: 4rem;
            left: 3.063rem;
            width: 3.625rem;
            height: 3.625rem;
            object-fit: cover;

            @media #{$vpMobile600} {
              top: 2rem;
              right: 1.5rem;
              width: 2.75rem;
              height: 2.75rem;
              left: auto;

            }
          }
        }

        .content-wrapper {
          padding: 3rem 0 3rem 3rem;
          max-width: 40rem;

          .heading4 {
            margin: 0;
            padding-bottom: 1.5rem;
          }

          .body3 {
            margin: 0;

            @media #{$vphMobile650} {
              overflow-y: scroll;
              max-height: 6.875rem;
            }
          }

          .btn-box {
            padding-top: 2rem;
            @media #{$vpMobile600} {
              padding-top: 1.5rem;
            }
            .main-primary-btn {
              display: flex;
              @media #{$vpMobile600} {
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;
                display: flex;
                text-align: center;
                justify-content: center;

              }

            }

            @media #{$vphMobile650} {
              padding-top: 1.5rem;
            }
          }

          @media #{$vpMobile600} {
            max-width: 100%;
            padding: 2.5rem 1.25rem;
          }

          @media #{$vphMobile650} {
            padding: 1.25rem 1.25rem 0;
          }
        }
      }


    }

    .slider-arrow-pagination {
      padding: 0;

      .arrow-wrap {
        right: 7.5rem;
        bottom: 0;
        padding-bottom: 4.5rem;
        @media #{$vpMobile600} {
          right: 1.5rem;
          padding-bottom: 1.5rem;
        }
        .slide-arrow {
          .custom-arrows {
            width: 3rem;
            height: 3rem;
          }
        }

      }

      @media #{$vpMobile600} {
        display: block;
      }
    }
    @media #{$vpTablet1024} {
      height: 100svh;
      bottom: 1rem;
    }
  }

  @media #{$vpMobile600} {
    padding: 2.5rem 0 5rem 0;
    .popup-wrapper {
      height: 100vh;
    }
  }

  &.gray-bg {
    .logo-wrapper {
      .logo-grid {
        .content-wrapper {
          background-color: #ffffff !important;
          .btn-wrapper {
            .main-primary-btn {
              background: #f9f7f3;
            }
          }
        }
      }
    }
  }
}

.popup-open {
  overflow-y: hidden;

}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  display: none;
  z-index: 99;
}