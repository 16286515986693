.qoute-sec {
  .ql-wrapper {
    position: relative;
    height: 100%;

    .img-box {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) -33.57%,
          rgba(0, 0, 0, 0.7) 100%
        );
      }
    }

    .ql-content-wrapper {
      display: flex;
      justify-content: space-between;
      height: 100%;
      position: relative;
      padding: 18.75rem 0;

      .ql-thumbnil {
        max-width: 14.68%;
        flex: 0 0 14.68%;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 100%;
          border: 0.25rem solid $secondarySimiLight;

          // @media #{$vpTablet1024} {
          //   height: 12.5rem;
          // }
          @media #{$vpTablet767} {
            width: 8.75rem;
            height: 8.75rem;
          }
        }

        @media #{$vpDesktop1440} {
          max-width: 19%;
          flex: 0 0 19%;
        }

        @media #{$vpTablet1229} {
          max-width: 22%;
          flex: 0 0 22%;
        }

        @media #{$vpTablet991} {
          max-width: 25%;
          flex: 0 0 25%;
        }

        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
          margin: 0 auto 6.25rem;
        }
      }

      .inner-content {
        max-width: 65%;
        flex: 0 0 65%;
        display: flex;
        flex-direction: column;
        color: $text-white;
        position: relative;

        .heading3 {
          padding-bottom: 3rem;
          position: relative;
          margin: 0;

          span {
            &:first-child {
              padding-right: 2.5rem;
              /*rtl:raw:padding-right: 0rem; */
              /*rtl:raw:padding-left: 2.5rem; */

              @media #{$vpMobile600} {
                padding-right: 0.625rem;
                position: relative;
                top: -1.563rem;
              }

              img {
                margin-bottom: 1.25rem;
                width: 2rem;
                height: 2rem;
                /*rtl:raw:transform: scaleX(-1); */

                @media #{$vpDesktop1440} {
                  margin-bottom: 0;
                }

                @media #{$vpMobile600} {
                  width: 2.188rem;
                  height: 2.188rem;
                }
              }
            }

            &:last-child {
              padding-left: 2.5rem;
              /*rtl:raw:padding-right: 2.5rem; */
              /*rtl:raw:padding-left: 0rem; */

              @media #{$vpMobile600} {
                padding-left: 0;
              }

              img {
                position: absolute;
                bottom: 3.4rem;
                width: 2rem;
                height: 2rem;
                /*rtl:raw:transform: scaleX(-1); */

                @media #{$vpMobile600} {
                  bottom: 0.4rem;
                  left: 13rem;
                  width: 2.188rem;
                  height: 2.188rem;
                    /*rtl:raw: bottom: 0; left: 0; right: auto; */
                    /*rtl:raw: bottom: 0; left: 0; right: auto; */
                    /*rtl:raw: bottom: 0; left: 0; right: auto; */
                }
              }
            }
          }
        }

        span {
          font-weight: 300;
          font-size: 1rem;
        }

        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }

      @media #{$vpMobile600} {
        flex-direction: column;
        padding: 5rem 0;
      }
    }
  }

  &.financial-times-quote {
    .ql-wrapper {
      .ql-content-wrapper {
        justify-content: center;

        .inner-content {
          max-width: 69.5625rem;
          flex: 0 0 69.5625rem;

          @media #{$vpMobile600} {
            max-width: 100%;
            flex: 0 0 100%;
          }
        }
      }
    }
  }

  &.annual-report-quote {
    background-color: $baselineSecondaryLight;
    .ql-wrapper {
      .ql-content-wrapper {
        padding: 0;
        .ql-thumbnil {
          width: 28.0625rem;
          flex: auto;
          max-width: 28.0625rem;
          display: flex;
          justify-content: center;

          @media #{$vpMobile600} {
            width: 100%;
            max-width: 100%;
          }

          img {
            border: none;
            border-radius: 0;

            @media #{$vpMobile600} {
              width: 10.5625rem;
              height: 13.125rem;
            }
          }
        }

        .inner-content {
          width: 69.5625rem;
          max-width: 69.5625rem;
          flex: auto;
          color: $baselineSecondary;
          justify-content: center;
          @media #{$vpTablet991} {
            padding-left: 2rem;
          }
          @media #{$vpMobile600} {
            width: 100%;
            max-width: 100%;
            padding-left: 0;
          }

          .heading3 {
            span {
              &:first-child {
                padding-right: 2.5rem;
                /*rtl:raw:padding-right: 0rem !important; */
                /*rtl:raw:padding-left: 0rem !important; */

                @media #{$vpMobile600} {
                  padding-right: 0.625rem;
                }

                svg {
                  margin-bottom: 1.25rem;
                  width: 2rem;
                  height: 2rem;
                  /*rtl:raw:transform: scaleX(-1); */

                  path {
                    fill: $baselinePrimary;
                  }

                  @media #{$vpDesktop1440} {
                    margin-bottom: 0;
                  }

                  @media #{$vpMobile600} {
                    width: 2.188rem;
                    height: 2.188rem;
                  }
                }
              }

              &:last-child {
                padding-left: 2.5rem;
                /*rtl:raw:padding-right: 0rem !important; */
                /*rtl:raw:padding-left: 0rem !important; */

                @media #{$vpMobile600} {
                  padding-left: 0;
                }

                svg {
                  position: absolute;
                  bottom: 3.4rem;
                  width: 2rem;
                  height: 2rem;
                  /*rtl:raw:transform: scaleX(-1); */

                  path {
                    fill: $baselinePrimary;
                  }

                  @media #{$vpMobile600} {
                    bottom: 0.4rem;
                    left: auto;
                    right: 0;
                    width: 2.188rem;
                    height: 2.188rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.secQuote--wrapper{
    padding: 18.75rem 0;
    .ql-wrapper{
      .quote-content{
        .inner-content{
          display: flex;
          flex: 0 0 67%;
          flex-direction: column;
          justify-content: space-between;
          max-width: 67%;
          position: relative;
          .heading3{
            margin: 0;
            padding-bottom: 3rem;
            position: relative;
            span{
              font-size: 1rem;
              font-weight: 300;
              &:first-child{
                padding-right: 2.5rem;
                img{
                  height: 2rem;
                  margin-bottom: 1.25rem;
                  width: 2rem;
                }
              }
              &:last-child{
                padding-left: 2.5rem;
                img{
                  bottom: 3.4rem;
                  height: 2rem;
                  position: absolute;
                  width: 2rem;
                }
              }
            }
          }
          @media #{$vpMobile600} {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }
      .quote-image-wrapper{
        bottom: auto;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        max-width: 32%;
        flex: 0 0 32%;
        
        img{
          height: 60rem;
          opacity: 0.3;
          width: 100%;
          object-fit: cover;
          @media #{$vpMobile600} {
            height: 100%;
            width: 100%;
          }
        }
        
        @media #{$vpMobile600} {
          margin: 0 0 0 auto;
          padding-top: 2rem;
          position: relative;
          text-align: right;
          top: 0;
          transform: translateY(0);
          max-width: 65%;
        }
      }
    }
  }
  &.dark-bg {
    .ql-wrapper {
      .ql-content-wrapper {
        .inner-content {
          span {
            color: $white;
          }
        }
      }
    }
  }
}
body{
  &.darkTheme{
    .secQuote--wrapper{
      &.dark-bg{
        background-color: #000 !important;
        color: #fff;
        @media #{$vpMobile600} {
          padding: 3rem 0 0;
        }
      }
    }
  }
}
