.headingAnimate {
    position: relative;

    .wordAnimateWrap {
        position: relative;
        display: block;
        overflow: hidden;
    }

    .wordAnimate {
        transform-origin: 0 100%;
        display: block;
    }
}