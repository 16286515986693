.sec-e-services {
  @media #{$vpMobile600} {
    padding-top: 2.5rem;
  }

  .sec-wrapper {
    @media #{$vpMobile600} {
      position: relative;
    }
    .sec-heading-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0 3.125rem;

      .sec-heading-inner {
        max-width: 40%;
        flex: 0 0 40%;

        @media #{$vpTablet767} {
          max-width: 100%;
          flex: 0 0 100%;
          margin-bottom: 1rem;
        }
      }

      .sec-para-inner {
        max-width: 50%;
        flex: 0 0 50%;

        .body2 {
          @media #{$vpTablet767} {
            font-size: 1.125rem;
          }
        }

        @media #{$vpTablet767} {
          max-width: 100%;
          flex: 0 0 100%;
          padding-left: 0;
        }
      }
    }

    .slider-wrapper {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 12.5rem;
        height: 100%;
        background-color: #fff;
        left: -13.125rem;
        top: 0;
      }
      .sec-post-content-box {
        .sec-post-content-info {
          background-color: $baselineSecondaryLight;
          padding: 2.5rem 2rem 2rem;
          border-radius: 1rem;
          min-height: 375px;
          position: relative;
          height: 100%;
          @media #{$vpMobile600} {
            border-radius: 0.5rem;
          }
          .body1 {
            @media #{$vpTablet767} {
              font-size: 1.125rem;
            }
          }

          a {
            font-size: 1rem;
            line-height: 1.4;
            display: block;
            padding: 0;
            position: absolute;
            bottom: 2rem;
            left: 2rem;
            
            span{
              padding: 0.75rem 1.5rem;
            }
          }

          @media #{$vpMobile600} {
            padding: 2rem 1.5rem 1.5rem 1.5rem;
          }

          img {
            margin-bottom: 2rem;
            width: 2.25rem;
            height: auto;
            @media #{$vpTablet767} {
              margin-bottom: 1.5rem;
            }
          }

          .heading5 {
            margin-bottom: 2rem;
            line-height: 1.3;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
          }

          p {
            margin-bottom: 2.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            // min-height: 5.625rem;
            display: -webkit-box;

            @media #{$vpTablet767} {
              margin-bottom: 2rem;
              min-height: 5rem;
            }
          }
        }
      }

      .slick-list {
        margin-right: -0.625rem;
        margin-left: -0.625rem;
        overflow: visible;

        @media #{$vpTablet767} {
          margin-right: -0.313rem;
          margin-left: -0.313rem;
        }
      }
      .slick-track {
        height: 100% !important;
        display: flex !important;
      }
      .slick-slide {
        padding: 0 0.625rem;
        // height: 100%;
        height: auto !important;
        @media #{$vpTablet767} {
          padding: 0 0.313rem;
        }
      }
      .slick-dots {
        @media #{$vpMobile600} {
          justify-content: flex-start;
          position: absolute;
          bottom: -60px;
        }
        @media #{$vpMobile390} {
          bottom: -50px;
        }
      }
    }
  }
  .pagination--arrow {
    position: relative;
    padding-top: 3rem;
    .slider-arrow-pagination {
      position: relative;
      display: block;
      @media #{$vpMobile600} {
        padding-top: 1.5rem;
        max-width: 50%;
        margin-left: auto;
      }
  
      .arrow-wrap {
        position: absolute;
        right: 0;
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
        width: 18.75rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media #{$vpMobile600} {
          display: none;
        }
        .slide-arrow {
          display: flex;
          justify-content: space-between;
  
          .custom-arrows {
            width: 3.125rem;
            height: 3.125rem;
            background-color: #fff;
            color: #fff;
            text-align: center;
            line-height: 3.125rem;
            cursor: pointer;
            border: 0.063rem solid $baselinePrimary;
            border-radius: 50%;
            z-index: 1;
            position: relative;
  
            img {
              width: 1rem;
              height: 1rem;
            }
  
            &.next {
              right: 0;
              margin-left: 1rem;
            }
  
            &.prev {
              margin-left: 1rem;
            }
  
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
  
        .pagination-wrap {
          .custom-pagination {
            display: flex;
            justify-content: center;
            align-items: center;
          }
  
          .custom-pagination-label {
            margin: 0 0.625rem;
            color: $baselineSecondary;
            font-weight: 500;
          }
        }
      }
      a {
        // @media #{$vpMobile600} {
        //   position: absolute;
        //   right: 0;
        //   bottom: -90px;
        // }
        .main-secondary-btn {
          @media #{$vpMobile600} {
            max-width: max-content;
            margin-left: auto;
          }
        }
      }
    }
  }
  &.data-library {
    .sec-press-release-wrapper {
      .sec-press-release-post-wrapper {
        position: relative;
        &::after {
          background-color: #fff;
          content: "";
          height: 100%;
          left: -13.125rem;
          position: absolute;
          top: 0;
          width: 12.5rem;
        }
        .slick-list {
          margin-left: -.625rem;
          margin-right: -.625rem;
          overflow: visible;
        }
        .card-wrapper {
          padding: 0 .625rem;
        }
      }
    }
  }
  &.dark-bg {
    .sec-wrapper {
      .slider-wrapper {
        .sec-post-content-box {
          .sec-post-content-info {
            background-color: rgba($color: #fff, $alpha: 0.1);
          }
        }
        &::after {
          background-color: #000;
        }
      }
    }
  }
  &.gray-bg {
    .sec-wrapper {
      .slider-wrapper {
        .sec-post-content-box {
          .sec-post-content-info {
            background-color: $white;
          }
        }
        &::after {
          background-color: #f9f7f3;
        }
      }
    }
  }
}

html[lang="ar"] {
  .sec-e-services {
    .sec-wrapper {
      .slider-wrapper {
        .sec-post-content-box {
          .sec-post-content-info {
            .heading5 {
              line-height: 1.75;
            }
          }
        }
      }
    }
  }
}
