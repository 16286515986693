.faq-form-main {
  @media #{$vpMobile600} {
    padding: 2.5rem 0 5rem;
  }

  .error {
    color: red;
    display: none;
  }
  .invalid-email-error {
    color: red;
    display: none;
  }

  .invalid-number-error {
    color: red;
    display: none;
  }
  .error-border {
    border: 0.063rem solid red;
    border-radius: 0.5rem;
  }

  .wrapper {
    display: flex;
    align-items: flex-start;
    padding: 5rem;
    background: $baselineSecondaryLight;
    justify-content: space-between;
    border-radius: 2rem;

    @media #{$vpMobile600} {
      padding: 40px 1.25rem;
      flex-direction: column;
      gap: 1.25rem;
      border-radius: 1rem;
    }

    .content {
      width: 39.125rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      @media #{$vpTablet991} {
        padding: 1.5rem;
      }
      @media #{$vpMobile600} {
        width: 100%;
        gap: 1rem;
      }

      .body2 {
        @media #{$vpMobile600} {
          font-size: 1.125rem;
        }
      }
    }
  }



  .faq-form-box {
    width: 43rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media #{$vpMobile600} {
      width: 100%;
    }

    .twoColRow {
      display: flex;
      gap: 1.25rem;

      @media #{$vpMobile600} {
        flex-direction: column;
        gap: 2rem;
      }

      .flexColRow {
        width: 50% !important;

        @media #{$vpMobile600} {
          width: 100% !important;
        }
      }
    }

    .form-fields-row {
      display: flex;
      // gap: 1.25rem;

      &.flexColRow {
        flex-direction: column;
      }

      @media #{$vpMobile600} {
        flex-direction: column;
      }

      input {
        background: $white;
        border: none;
        padding: 1.25rem 1rem;
        border-radius: 0.5rem;
        width: 100%;
        height: 4.5625rem;

        @media #{$vpMobile600} {
          padding: 1.5rem 1rem;
        }
      }

      input.file-upload-field {
        border-top-right-radius: 1.875rem;
        border-bottom-right-radius: 1.875rem;
        cursor: pointer;
      }

      .file-upload-wrapper {
        position: relative;
        width: 100%;

        input::-webkit-file-upload-button {
          display: none;
        }

        img {
          position: absolute;
          right: 1.875rem;
          top: 20.5rem;
          z-index: 2;
          cursor: pointer;
          filter: brightness(0) saturate(100%) invert(14%) sepia(7%) saturate(896%) hue-rotate(187deg) brightness(103%) contrast(91%);
          height: 10.5rem;
          width: 10.5rem;
        }

        &:after {
          content: attr(data-text);
          font-size: 1rem;
          position: absolute;
          top: 0.313rem;
          left: 0.313rem;
          background: $white;
          padding: 0.625rem 10.313rem;
          width: 100%;
          pointer-events: none;
          height: 100%;
          display: flex;
          align-items: center;
          border-radius: 0.5rem 12.5rem 12.5rem 0.5rem;
          color: $neutralGray400;
          height: 3.75rem;
        }

        &:before {
          content: "Browse";
          position: absolute;
          top: 0;
          right: 0.125rem;
          height: 4.375rem;
          background: transparent;
          color: $baselineSecondary;
          z-index: 25;
          font-size: 1rem;
          padding: 0 3.75rem 0 40.313rem;
          pointer-events: none;
          display: flex;
          align-items: center;
          border: 0.063rem solid $baselinePrimary;
          border-radius: 12.5rem;
        }
      }
    }

    .form-row-dropdown {
      display: flex;
      flex-direction: column;
      position: relative;

      img {
        position: absolute;
        right: 1.25rem;
        top: 50%;
        pointer-events: none;
        transform: translateY(-50%);
      }

      .select2 {
        width: 100% !important;

        .selection {
          .select2-selection {
            display: block;
            height: 100%;
            border: none;
            border-radius: 0.5rem;

            .select2-selection__rendered {
              background: $white;
              border: none;
              padding: 1.25rem 1rem;
              border-radius: 0.5rem;
              width: 100%;
              height: 4.5625rem;
              color: $neutralGray400;
              appearance: none;
              position: relative;
              display: flex;
              align-items: center;

              @media #{$vpMobile600} {
                padding: 1.5rem 1rem;
              }
            }

            .select2-selection__arrow {
              display: none;
            }
          }
        }
      }
    }

    .message {
      .message-label {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .body3 {
          color: $neutralGray300;

          span {
            color: $baselineSecondary;
          }
        }

      }

      textarea {
        width: 100%;
        height: 8.2rem;
        border: none;
        padding: 1.25rem 1rem;
        border-radius: 0.5rem;

        @media #{$vpMobile600} {
          padding: 1.5rem;
          height: 11.25rem;
        }
      }
    }

    .submit-btn {
      display: flex;
      justify-content: flex-start;
      padding-top: 1.25rem;

      @media #{$vpMobile600} {
        padding-top: 0;
      }

      input {
        background: $white;
        border-radius: 12.5rem;
        border: 0.063rem solid $baselinePrimary;
        padding: 1.3125rem 2rem;
        transition: all 0.4s ease-out;

        @media #{$vpMobile600} {
          width: 100%;
        }

        &:hover {
          background: $baselinePrimary;
          transition: all 0.4s ease-out;
          color: $white;
        }
      }
    }
  }
}

.select2-dropdown {
  border: none;
  border-radius: 0.5rem;

  .select2-results__option--highlighted[aria-selected] {
    background-color: $baselinePrimary;
  }

  .select2-search {
    display: none;
  }

  .select2-results__option {
    padding: 0.625rem 1.25rem 0.625rem 1.25rem;
  }
}