.reveal-cards {
  .sec-wrapper {
    .sec-heading-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0 3.125rem;
      @media #{$vpTablet991} {
        flex-wrap: wrap;
      }
      @media #{$vpMobile600} {
        padding: 1rem 0 1.5rem;
      }
      .sec-heading-inner {
        width: 43rem;
        @media #{$vpTablet991} {
          width: 100%;
        }
        .heading2 {
          @media #{$vpMobile600} {
            margin: 2.5rem 0 1.5rem 0;
          }
        }
      }
      .sec-para-inner {
        width: 51.875rem;
        @media #{$vpTablet991} {
          width: 100%;
        }
      }
    }

    .slider-wrap {
      display: flex;
      flex-wrap: wrap;
      width: 60.625rem;
      @media #{$vpMobile600} {
        flex-direction: column-reverse;
        width: 100%;
      }
      .royal-commission-main {
        width: 30.313rem;
        z-index: 2;
        background-color: $secondaryLight;
        padding: 2rem 3.75rem 2rem 2rem;
        border-top-left-radius: 1.25rem;
        border-bottom-left-radius: 1.25rem;
        @media #{$vpMobile600} {
          width: 100%;
          border-bottom-left-radius: 1.25rem;
          border-bottom-right-radius: 1.25rem;
          border-top-left-radius: 0;
          padding: 1.25rem;
          margin-bottom: 2.5rem;
        }
        .slick-list {
          height: 100%;
        }
        .slick-track {
          height: 100%;
        }
        .sec-post-content-box {
          .sec-post-content-info {
            transform: translateX(-2.5rem);
            transition: all 0.35s ease;
            opacity: 0;
            img {
              width: 1.5rem;
              height: auto;
              margin-bottom: 3.25rem;
              @media #{$vpMobile600} {
                display: none;
              }
            }
            .heading3 {
              margin-bottom: 2.5rem;
              width: 18.75rem;
              @media #{$vpMobile600} {
                margin-bottom: 1rem;
              }
            }
            .main-primary-btn {
              margin-top: 2.5rem;
              @media #{$vpMobile600} {
                margin-top: 3.75rem;
                height: 2.25rem;
                display: flex;
                align-items: center;
                padding: 0.5rem 1.12rem;
                width: fit-content;
                justify-content: center;
              }
            }
          }
        }
        .slick-slide {
          &.slick-active {
            + .slick-slide {
              .sec-post-content-info {
                transform: translateX(2.5rem);
                opacity: 0;
              }
            }
            .sec-post-content-info {
              transform: translateX(0);
              opacity: 1;
            }
          }
        }
        .slick-dots {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          margin-top: 3rem;
        }
      }
      .royal-commission-nav {
        width: 30.3125rem;
        z-index: 0;
        @media #{$vpMobile600} {
          width: 100%;
          border-bottom-right-radius: 0;
        }
        .sec-post-img {
          width: 100%;
          position: relative;
          img {
            border-radius: 1.25rem;
            height: 100%;
          }
          .img-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            opacity: 0.5;
          }
          .heading3 {
            position: absolute;
            bottom: 2.75rem;
            left: 2.5rem;
            color: #fff;
          }
        }
        .slick-list {
          overflow: visible;
          padding: 0 !important;
          height: 100%;
          margin-right: 0.063rem;
          @media #{$vpMobile600} {
            overflow: hidden;
          }
        }
        .slick-track {
          height: 100%;
        }
        .slick-slide {
          padding: 0 1.25rem 0 0;
          height: 100%;
          @media #{$vpMobile600} {
            padding: 0;
          }
          &.slick-active {
            img {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              width: 100%;
              height: 100%;
              @media #{$vpMobile600} {
                border-bottom-right-radius: 0;
                border-top-left-radius: 1.25rem;
              }
            }
            .img-overlay {
              opacity: 0;
            }
            .heading3 {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
