//gallery modal style start
.modal {
    display: none;
    position: fixed;
    z-index: 9999;
    padding: 3.125rem 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.9);

    @media #{$vpMobile600} {
        padding-bottom: 5.5rem;
    }

    // anual-report-modal varent
    &.anual-report-modal {
        .container--custom {
            .modal-content {
                .inner-content {
                    @media #{$vpMobile600} {
                        gap: 1.25rem;
                    }

                    .mediaContent {
                        @media #{$vpMobile600} {
                            height: 17.5rem;
                        }

                        .content-detail {
                            position: relative;
                            border-radius: 1rem;
                            overflow: hidden;
                            width: 34.1875rem;

                            @media #{$vpMobile600} {
                                width: 21.875rem;
                            }

                            .modal-img {
                                max-width: 34.1875rem;
                                max-height: 43.1875rem;
                                object-fit: cover;

                                @media #{$vpMobile600} {
                                    width: 21.875rem;
                                    height: 13.375rem;
                                }
                            }

                            .cate-date-header {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;
                                margin: 0;
                                text-transform: capitalize;
                                position: absolute;
                                padding: 1.5rem 1.5rem 0;

                                @media #{$vpMobile600} {
                                    padding: 1.25rem 1.25rem 0;
                                }

                                .post-date {
                                    color: $white;
                                    margin: 0;

                                    @media #{$vpMobile600} {
                                        font-size: 0.875rem;
                                    }
                                }

                                .category {
                                    color: $baselineSecondary;
                                    padding: 0.375rem 1rem;
                                    background-color: $secondarySimiLight;
                                    border-radius: 0.25rem;
                                    margin: 0;
                                    text-transform: uppercase;

                                    @media #{$vpMobile600} {
                                        font-size: 0.875rem;
                                    }
                                }
                            }

                            .bottom-detail {
                                padding: 1.5rem;
                                background-color: $white;

                                @media #{$vpMobile600} {
                                    padding: 1.25rem;
                                    height: 4.125rem;
                                }

                                .heading5 {
                                    margin: 0;
                                    line-height: 1.3;
                                    color: $baselineSecondary;
                                }
                            }
                        }
                    }

                    .media-info {
                        .titleBox {
                            gap: 0.5rem;

                            .heading3 {}

                            #media-description {
                                font-size: 1.125rem;
                            }
                        }
                    }
                }
            }
        }





        .media-form {
            .input-inner {
                flex-direction: row !important;

                @media #{$vpMobile600} {
                    flex-direction: column !important;
                }
            }
        }
    }

    .container--custom {
        height: 100%;

        .modal-content {
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            background-color: transparent !important;

            .inner-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 100%;

                @media #{$vpMobile600} {
                    flex-direction: column;
                    gap: 1.5rem;
                    max-height: max-content;
                    overflow-y: auto;
                }

                .mediaContent {
                    width: 60.6875rem;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    @media #{$vpTablet991} {
                        margin-right: 1.5rem;
                    }
                    @media #{$vpMobile600} {
                        width: 100%;
                        margin-top: 3.75rem;
                        height: 27.25rem;
                        margin-right: 0;
                    }

                    #modalImg {
                        height: auto;
                        width: 65%;
                        object-fit: cover;
                    }

                    #modalVideo {
                        width: 100%;
                        height: 32.375em;
                        object-fit: cover;

                        @media #{$vpMobile600} {
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }

                .media-info {
                    text-align: center;
                    color: #fff;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    width: 34.1875rem;
                    min-height: 32.56rem;
                    flex-direction: column;

                    @media #{$vpMobile600} {
                        width: 100%;
                        height: auto;
                        gap: 1.5rem;
                        margin-bottom: 1.25rem;
                    }

                    .media-form {
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        width: 100%;
                        align-items: flex-start;

                        .mobile-view {
                            display: none;
                            align-items: center;
                            gap: 1rem;
                            width: 100%;
                            flex-direction: column;

                            @media #{$vpMobile600} {
                                display: flex;

                                &.for-mobile-input {
                                    display: flex !important;
                                }
                            }


                            .back-btn-box {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                gap: 1rem;
                                width: 100%;
                                display: none;

                                @media #{$vpMobile600} {
                                    display: flex;
                                }

                                .back-btn {
                                    width: 3rem;
                                    min-width: 3rem;
                                    height: 3rem;
                                    padding: 0;
                                    background-color: $white;
                                    border-radius: 50%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    border: 0;

                                    svg {
                                        pointer-events: none;
                                        width: 1.08288rem;
                                        height: 1.0625rem;

                                        path {
                                            fill: $baselineSecondary;
                                        }
                                    }
                                }

                                .Formsubmit {
                                    background-color: $white;
                                    color: $baselineSecondary;
                                    border-color: $white;
                                    width: 100%;
                                    height: 3rem;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    gap: 0.625rem;


                                    svg {
                                        pointer-events: none;
                                        width: 1.08288rem;
                                        height: 1.0625rem;

                                        path {
                                            fill: $baselineSecondary;
                                        }
                                    }
                                }
                            }

                        }

                        .input-inner {
                            display: flex;
                            width: 100%;
                            flex-direction: column;
                            gap: 1rem;

                            @media #{$vpMobile600} {
                                &.for-desk-input {
                                    display: none !important;
                                }
                            }

                            .form-fields-row {
                                width: 100%;
                                text-align: left;

                                input {
                                    background: $white;
                                    border: none;
                                    padding: 1.5rem 1rem;
                                    border-radius: 0.5rem;
                                    width: 100%;
                                    height: 4.5625rem;
                                    font-size: 1.125rem;
                                    line-height: 1.4;
                                    font-weight: 300;

                                    @media #{$vpMobile600} {
                                        padding: 1rem;
                                        height: 4.25rem;
                                        font-size: 1rem;
                                    }

                                    &::placeholder {
                                        font-size: 1.125rem;
                                        line-height: 1.4;
                                        font-weight: 300;

                                        @media #{$vpMobile600} {
                                            font-size: 1rem;
                                        }
                                    }
                                }

                                .error {
                                    color: red;
                                    display: none;
                                }
                                .invalid-email-error {
                                    color: red;
                                    display: none;
                                  }
                                .error-border {
                                    border: 0.063rem solid red !important;
                                    border-radius: 0.5rem !important;
                                }
                            }
                        }


                        .submit-btn {
                            &.for-desk {
                                width: 100%;
                                display: flex;

                                .for-desk-submit {
                                    background-color: $white;
                                    border-color: $white;
                                }

                                .for-mbl-submit {
                                    display: none;
                                }

                                @media #{$vpMobile600} {
                                    .for-desk-submit {
                                        display: none;
                                    }
                                }

                                @media #{$vpMobile600} {
                                    .for-mbl-submit {
                                        display: block;
                                    }
                                }

                            }

                            .for-mbl-submit {
                                background-color: $white;
                                color: $baselineSecondary;
                                border-color: $white;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 0.625rem;

                                @media #{$vpMobile600} {
                                    width: 100%;
                                    height: 3rem;
                                }


                                svg {
                                    pointer-events: none;
                                    width: 1.08288rem;
                                    height: 1.0625rem;

                                    path {
                                        fill: $baselineSecondary;
                                    }
                                }


                            }

                        }

                    }

                    .titleBox {
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;

                        #media-title {
                            text-align: left;
                            margin-bottom: 0;
                        }

                        #media-description {
                            margin: 0;
                            text-align: left;

                            @media #{$vpMobile600} {
                                font-size: 0.875rem;
                            }
                        }
                    }


                    #downloadBtn {
                        width: 10.45925rem;
                        height: 3.16713rem;
                        border: 0.063rem solid $white;
                        background-color: $white;

                        @media #{$vpMobile600} {
                            width: 2.75rem;
                            height: 2.75rem;
                            padding: 0;
                        }

                        &:hover {
                            opacity: 0.8;
                        }
                    }

                    .download-icon {
                        @media #{$vpMobile600} {
                            padding-left: 0;
                        }
                    }

                    .btn-text {
                        @media #{$vpMobile600} {
                            display: none;
                        }
                    }
                }

            }
        }

        .nav-wrapper {
            position: absolute;
            top: auto;
            bottom: 5rem;
            display: flex;
            align-items: center;
            left: auto;
            right: 7.5rem;
            gap: 1rem;

            @media #{$vpMobile600} {
                position: unset;
                justify-content: flex-end;
            }

            .slide-counter {
                .counter {
                    margin: 0 10px;
                    color: $baselineSecondary;
                    font-weight: 500;
                    line-height: 1.5;
                  
                    .current-slide {
                        color: $text-white;
                    }
                  
                    .total-slides,
                    .seperator {
                        color: $neutralGray300;
                    }
                  }
                .body3 {
                    color: $white;
                    font-weight: 500;
                    line-height: 1.5;
                    // display: none;
                }
            }

            .innerNav {
                display: flex;
                gap: 1rem;
                align-items: center;
                /*rtl:raw:flex-direction: row-reverse; */

                .prev,
                .next {
                    background-color: rgba(0, 0, 0, 0.5);
                    color: white;
                    padding: 0.625rem;
                    border: none;
                    cursor: pointer;
                    z-index: 999;
                    width: 2.875rem;
                    height: 2.875rem;
                    border: 0.063rem solid $baselinePrimary;
                    border-radius: 100%;
                    outline: none;
                    transition: 0.3s;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: static;
                    transform: translate(0, 0);

                    &:hover,
                    &:focus {
                        border: 1px solid $baselinePrimary;
                        opacity: .75;
                    }

                    .arrowImg {
                        width: 1rem;
                    }
                }

                .prev {
                    left: 0;

                    @media #{$vpMobile600} {
                        left: 0;
                    }
                }

                .next {
                    right: 0;

                    @media #{$vpMobile600} {
                        right: 0;
                    }
                }
            }
        }

        .close {
            transition: 0.3s;
            width: 3.4375rem;
            height: 3.4375rem;
            border: 1px solid $baselinePrimary;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 1;
            position: absolute;
            left: auto;
            right: 7.5rem;
            z-index: 99;

            @media #{$vpMobile600} {
                width: 2.75rem;
                height: 2.75rem;
                right: 1.25rem;
            }

            .insvg {
                width: 1rem;
            }

            &:hover,
            &:focus {
                text-decoration: none;
                cursor: pointer;
            }
        }
    }

    .nav-wrapper {

        .prev,
        .next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
            padding: 0.625rem;
            border: none;
            cursor: pointer;
            z-index: 999;
            width: 3.625rem;
            height: 3.625rem;
            border: 0.063rem solid $baselinePrimary;
            border-radius: 100%;
            outline: none;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;

            @media #{$vpMobile600} {
                width: 2.75rem;
                height: 2.75rem;
            }

            &:hover,
            &:focus {
                border: 1px solid $baselinePrimary;
                opacity: 0.75;
            }
        }

        .prev {
            left: 0;

            @media #{$vpMobile600} {
                left: 0;
            }
        }

        .next {
            right: 0;

            @media #{$vpMobile600} {
                right: 0;
            }
        }
    }
}

.anual-report-modal {
    .modal-content {
        .inner-content {
            .mediaContent {
                .content-detail {
                    #modalImg {
                        width: 100%;
                        min-height: 43.1875rem;
                        @media #{$vpMobile600} {
                            min-height: 13.38rem;
                        }
                    }
                }
            }
        }
    }
}


.open-modal-btn {

    svg,
    span {
        pointer-events: none;
    }
}

.light-box-triger {
    pointer-events: none;
}

//gallery modal style end