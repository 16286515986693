.initiatives-detail {
  padding-top: 7.5rem;

  @media #{$vpMobile600} {
    padding-top: 5rem;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 4rem;

    @media #{$vpMobile600} {
      gap: 4rem;
    }

    .content-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      @media #{$vpMobile600} {
        flex-direction: column;
        gap: 32px;
      }

      p {
        max-width: 35%;
        flex: 0 0 35%;

        @media #{$vpMobile600} {
          max-width: 100%;
          flex: 0 0 100%;
        }
      }
    }

    .image-wrapper {
      img {
        border-radius: 32px 32px 0px 0px;
        height: 600px;
        width: 100%;

        @media #{$vpMobile600} {
          height: 350px;
        }
      }
    }
  }
}