.level1 {
  transform: translateY(50px);
  transition: all 0.4s ease-out;
  transition-delay: 0.4s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 0.20s ease-out;
    transition-delay: 0.20s;
  }

  &.sec-animate {
    transform: translateX(0);
    opacity: 1;
  }
}

.level2 {
  transform: translateY(50px);
  transition: all 0.6s ease-out;
  transition-delay: 0.6s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 0.3s ease-out;
    transition-delay: 0.3s;
  }

  &.sec-animate2 {
    transform: translateX(0);
    opacity: 1;
  }
}

.level3 {
  transform: translateY(50px);
  transition: all 0.8s ease-out;
  transition-delay: 0.8s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 0.4s ease-out;
    transition-delay: 0.4s;
  }

  &.sec-animate3 {
    transform: translateX(0);
    opacity: 1;
  }
}

.level4 {
  transform: translateY(50px);
  transition: all 1s ease-out;
  transition-delay: 0.8s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 0.5s ease-out;
    transition-delay: 0.4s;
  }

  &.sec-animate4 {
    transform: translateX(0);
    opacity: 1;
  }
}

.level5 {
  transform: translateY(50px);
  transition: all 1.2s ease-out;
  transition-delay: 0.8s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 0.6s ease-out;
    transition-delay: 0.4s;
  }

  &.sec-animate5 {
    transform: translateX(0);
    opacity: 1;
  }
}

.level6 {
  transform: translateY(50px);
  transition: all 1.3s ease-out;
  transition-delay: 0.9s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 0.65s ease-out;
    transition-delay: 0.45s;
  }

  &.sec-animate6 {
    transform: translateX(0);
    opacity: 1;
  }
}

.level7 {
  transform: translateY(50px);
  transition: all 1.4s ease-out;
  transition-delay: 1s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 0.7s ease-out;
    transition-delay: 0.5s;
  }

  &.sec-animate7 {
    transform: translateX(0);
    opacity: 1;
  }
}

.level8 {
  transform: translateY(50px);
  transition: all 1.5s ease-out;
  transition-delay: 1s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 0.75s ease-out;
    transition-delay: 0.5s;
  }

  &.sec-animate8 {
    transform: translateX(0);
    opacity: 1;
  }
}

.level9 {
  transform: translateY(50px);
  transition: all 1.6s ease-out;
  transition-delay: 1s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 0.8s ease-out;
    transition-delay: 0.5s;
  }

  &.sec-animate9 {
    transform: translateX(0);
    opacity: 1;
  }
}

.level10 {
  transform: translateY(50px);
  transition: all 1.65s ease-out;
  transition-delay: 1s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 0.85s ease-out;
    transition-delay: 0.5s;
  }

  &.sec-animate10 {
    transform: translateX(0);
    opacity: 1;
  }
}

.level11 {
  transform: translateY(50px);
  transition: all 1.7s ease-out;
  transition-delay: 1s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 0.9s ease-out;
    transition-delay: 0.5s;
  }

  &.sec-animate11 {
    transform: translateX(0);
    opacity: 1;
  }
}

.level12 {
  transform: translateY(50px);
  transition: all 1.75s ease-out;
  transition-delay: 1s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 0.95s ease-out;
    transition-delay: 0.5s;
  }

  &.sec-animate12 {
    transform: translateX(0);
    opacity: 1;
  }
}

.level13 {
  transform: translateY(50px);
  transition: all 1.8s ease-out;
  transition-delay: 1s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 1s ease-out;
    transition-delay: 0.5s;
  }

  &.sec-animate13 {
    transform: translateX(0);
    opacity: 1;
  }
}

.level14 {
  transform: translateY(50px);
  transition: all 1.85s ease-out;
  transition-delay: 1s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 1.2s ease-out;
    transition-delay: 0.5s;
  }

  &.sec-animate14 {
    transform: translateX(0);
    opacity: 1;
  }
}

.level15 {
  transform: translateY(50px);
  transition: all 1.9s ease-out;
  transition-delay: 1s;
  opacity: 0;

  @media #{$vpTablet767} {
    transition: all 1.25s ease-out;
    transition-delay: 0.5s;
  }

  &.sec-animate15 {
    transform: translateX(0);
    opacity: 1;
  }
}